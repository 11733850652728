<template>
    <vue-scroll class="page-devis-voyage">
        <div class="page-header">
            <h1>Création de devis micro <span style="color:#ffa409">Habitation</span>
                <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>

        <resize-observer @notify="__resizeHanlder" />


        <div v-if="hidesearchClient && !isMobile"
            class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
                <el-row :gutter="15" justify="center">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de
                                téléphone</el-radio>
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse
                                e-mail</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" style="margin-top:15px">
                    <el-col :span="10">
                        <el-input v-if="NumberInput" type="text" v-model="telephoneclient"
                            placeholder="Votre numéro de téléphone" class="input-with-select">
                            <el-button v-if="clientsearch === 'téléphone'" @click="rechercherClient()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                        <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail"
                            class="input-with-select">
                            <el-button v-if="clientsearch === 'email'" @click="rechercheClientByMail()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span
                        style="font-weight: bold;color: #ffa409;">{{ usersearch.prenom + ' '
                            + usersearch.nom }}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary"
                        round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser = true" type="primary"
                        round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
                <el-row :gutter="15" justify="center">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de
                                téléphone</el-radio>
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse
                                e-mail</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" style="margin-top:15px">
                    <el-col :span="24">
                        <el-input v-if="NumberInput" type="number" v-model="telephoneclient"
                            placeholder="Votre numéro de téléphone" class="input-with-select">
                            <el-button v-if="clientsearch === 'téléphone'" @click="rechercherClient()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                    <el-col :span="24" style="margin-top:15">
                        <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail"
                            class="input-with-select">
                            <el-button v-if="clientsearch === 'email'" @click="rechercheClientByMail()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span
                        style="font-weight: bold;color: #ffa409;">{{ usersearch.prenom +
                            ' ' + usersearch.nom }}</span><br><el-button style="margin-top:13px" @click="hidesearch"
                        type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser = true" type="primary"
                        round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:10px">
                <h3>Vous souhaitez une assurance pour :</h3>
                <el-row :gutter="24" style="margin-top:20px">
                   
                                <el-col :span="12">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Choix Pack</label>
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="MicroHabitatPackage" size="medium" :label="Micro_habitation.pack1">Pack 1</el-radio>
                                                <el-radio v-model="MicroHabitatPackage" :label="Micro_habitation.pack2">Pack 2</el-radio>
                                            </div>
                                        </el-col>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Durée en jours</label>
                                        <el-select style="width: 100%!important;" v-model="dureeInDay" placeholder="Selectionner">
                                            <el-option
                                            v-for="items in Micro_habitation.durationInDay"
                                            :key="items"
                                            :label="items + ' jours'"
                                            :value="items">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </el-col>
                            </el-row>
                
            </div>

            <div class="mt-5">
                <div>
                    <div justify="center" style="margin-top:30px">
                        <el-row type="flex" class="row-bg" justify="center">
                            <el-col :span="24">
                                <el-descriptions style="font-size:20px" v-if="MicroHabitatPackage == Micro_habitation.pack1">
                                    <el-descriptions-item label="Garanties inclus ">
                                        <span style="color:#ffa409"> RESPONSABILITE CIVILE, INCENDIE</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                                <el-descriptions style="font-size:20px" v-if="MicroHabitatPackage == Micro_habitation.pack2">
                                    <el-descriptions-item
                                        label="Garanties inclus">
                                        <span style="color:#ffa409">
                                            RESPONSABILITE CIVILE, INCENDIE, DEGAT DES EAUX
                                        </span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <div style="margin-top:15px">
                <el-button @click="DevisNextStep" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 2 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:10px">
                <h3>Vous souhaitez une assurance pour :</h3>
                <el-row :gutter="20" style="margin-top:20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Choix Pack</label>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-radio v-model="MicroHabitatPackage" size="medium" :label="Micro_habitation.pack1">Pack 1</el-radio>
                                    <el-radio v-model="MicroHabitatPackage" :label="Micro_habitation.pack2">Pack 2</el-radio>
                                </div>
                            </el-col>
                        </div>
                    </el-col>

                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple">
                            <label>Durée en jours</label>
                            <el-select style="width: 100%!important;" v-model="dureeInDay" placeholder="Selectionner">
                                <el-option
                                v-for="items in Micro_habitation.durationInDay"
                                :key="items"
                                :label="items + ' jours'"
                                :value="items">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button @click="DevisNextStep" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Contractant</span>
                                            </div>
                                            <div  >
                                                <ul style="list-style:none">
                                                    <li>Nom : <span class="to-right assuraf-orange">{{
                                                        Micro_habitation.Proprietaire.Nom
                                                    }}</span>
                                                    </li>
                                                    <li>Prénom : <span class="to-right assuraf-orange">{{
                                                        Micro_habitation.Proprietaire.Prenom }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">DÉTAILS DES COTISATIONS</h3>
                            <el-row :gutter="24" style="margin-top:20px">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Garanties</span>
                                            </div>
                                            <div  >
                                                <ul style="list-style:none">
                                                    <li>Pack : <span class="to-right assuraf-orange">{{ MicroHabitatPackage.namePack }}</span></li>
                                                    <li>Durée : <span class="to-right assuraf-orange">{{ dureeInDay }} jours</span></li>
                                                    <li>Garanties : <span class="to-right assuraf-orange">{{ MicroHabitatPackage.garantie }}</span></li>
                                                    <li>Branche : <span class="to-right assuraf-orange">{{ Micro_habitation.branche }}</span></li>
                                                    <li>Assureur : <span class="to-right assuraf-orange">{{ Micro_habitation.assureur }}</span> </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
                            <el-row :gutter="24" style="margin-top:20px">
                                <el-col :span="12">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Date d'effet</label>
                                        <el-date-picker @change="dateDiff" style="width: 100%!important;"
                                            v-model="Micro_habitation.dateEffet" type="date" format="yyyy/MM/dd"
                                            placeholder="Choississez une date" :picker-options="dateAfterToday">
                                        </el-date-picker>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Date d"échéance</label>
                                        <el-input placeholder="Date d'échéance" v-model="Micro_habitation.dateEcheance" readonly>
                                        </el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary"
                        round>Valider</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Contractant</span>
                                            </div>
                                            <div  >
                                                <ul style="list-style:none">
                                                    <li>Nom : <span class="to-right assuraf-orange">{{
                                                        Micro_habitation.Proprietaire.Nom
                                                    }}</span>
                                                    </li>
                                                    <li>Prénom : <span class="to-right assuraf-orange">{{
                                                        Micro_habitation.Proprietaire.Prenom }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">DÉTAILS DES COTISATIONS</h3>
                            <el-row :gutter="24" style="margin-top:20px">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Garanties</span>
                                            </div>
                                            <div  >
                                                <ul style="list-style:none">
                                                    <li>Pack : <span class="to-right assuraf-orange">{{ MicroHabitatPackage.namePack }}</span></li>
                                                    <li>Durée : <span class="to-right assuraf-orange">{{ dureeInDay }} jours</span></li>
                                                    <li>Garanties : <span class="to-right assuraf-orange">{{ MicroHabitatPackage.garantie }}</span></li>
                                                    <li>Branche : <span class="to-right assuraf-orange">{{ Micro_habitation.branche }}</span></li>
                                                    <li>Assureur : <span class="to-right assuraf-orange">{{ Micro_habitation.assureur }}</span> </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
                            <el-row :gutter="24" style="margin-top:20px">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Date d'effet</label>
                                        <el-date-picker @change="dateDiff" style="width: 100%!important;"
                                            v-model="Micro_habitation.dateEffet" type="date" format="yyyy/MM/dd"
                                            placeholder="Choississez une date" :picker-options="dateAfterToday">
                                        </el-date-picker>
                                    </div>
                                </el-col>

                                <el-col :span="24" style="margin-top:15px">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Date d"échéance</label>
                                        <el-input placeholder="Date d'échéance" v-model="Micro_habitation.dateEcheance" readonly>
                                        </el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary"
                        round>Valider</el-button>
                </div>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
import MicroHabitation from "../../../models/micro/MicroHabitation"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
    if (!phone.length) {
        return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
    } else {
        return { valid: true, error: null };
    }
};

const validateEmail = email => {
    if (!email.length) {
        return { valid: false, error: "L'adresse e-mail est obligatoire!" };
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return { valid: false, error: "Veuillez entrer un email valide!" };
    } else {
        return { valid: true, error: null };
    }
};

export default {
    name: "micro_habitation",

    data() {
        return {
            dureeInDay : null,
            dureeInMonth : null,
            MicroHabitatPackage : null,
            isMobile: false,
            modalgenerationloading: false,
            oldDate: null,
            disableDateAssure: true,
            money: {
                thousands: " ",
                precision: false,
                masked: false,
            },
            dateNaiss: null,
            duree: null,
            
            errors: {},
            genre: null,
            animation: "animate-in",

            Micro_habitation : new MicroHabitation(),
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() < (dayjs(new Date())).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            selected: [],
            age: null,
            usersearch: [],
            telephoneclient: '',
            NumberInput: false,
            EmailInput: false,
            e1: 1,

            emailclient: null,
            emailC: this.emailclient,
            telephoneC: this.telephoneclient,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            hideDive_vehicule: null,
            password1: null,
            garanties: ['HOSPITALISATION', 'DECES ET INVALIDITE TOTALE ET DEFINITIVE'],
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            userRole: null,
            formule: null,
           
        }
    },

    methods: {
        dateDiff: function () {

            if (this.Micro_habitation.dateEffet) {
                let inter = dayjs(this.Micro_habitation.dateEffet).add(this.dureeInDay, "day").format("YYYY-MM-DD");
                this.Micro_habitation.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
            }
        },
       

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format("DD-MM-YYYY");
            }
        },

        /*===Send of data to API allowing create proposition for the customer===*/
        CreateDevis: function () {
            if(this.Micro_habitation.dateEffet){
                this.modalgenerationloading = true

            const MicroHabitationData = {
                pack : this.MicroHabitatPackage.namePack,
                duree: this.dureeInDay,
                branche: this.Micro_habitation.branche,
                assureurs: this.Micro_habitation.assureur,
                userid: this.usersearch._id, //store.state.user._id,
                clientid: this.usersearch._id, //store.state.user._id,
                telephone: this.usersearch.telephone_port,
                createur: store.state.user._id,
                garanties: this.MicroHabitatPackage.garantie,
                dateEffet: dayjs(this.Micro_habitation.dateEffet).format("YYYY-MM-DD"),
                dateEcheance: this.Micro_habitation.dateEcheance,
                dateSouscription: new Date(),
                FuturPaiement: null,
                plateforme: 'Dash',
                habitation : [],
                proprietaire: this.Micro_habitation.Proprietaire,
            };

            if (this.$store.state.user.role == "Agent") {
                Sunu.courtierid = this.$store.state.user.societe;
            }
            
            Api().post('/micro/contrat/habitationtempo', MicroHabitationData).then(result => {
                this.modalgenerationloading = true
                this.$store.dispatch('setDevisPayement', result.data)
                setTimeout(() => (
                    this.modalgenerationloading = false,
                    this.$message.success(result.data.message, this.AlertMessage)
                ), 4000);
            })

            

            setTimeout(() => {
                this.modalgenerationloading = false
            }, 20000)
            }else {
                this.$message.error('Date effet obligatoire !', this.AlertMessage)
            }
            
        },
        DevisStepOne() {
            this.e1 = 2;
        },
        DevisNextStep() {
            if (this.MicroHabitatPackage) {
                if(this.dureeInDay){
                    this.Micro_habitation.Proprietaire.nom = this.usersearch.nom;
                    this.Micro_habitation.Proprietaire.prenom = this.usersearch.prenom;
                    this.e1 = 3;
                }else {
                    this.$message.error('Durée obligatoire !', this.AlertMessage)
                }
            }else {
                this.$message.error('Pack obligatoire !', this.AlertMessage)
            }
        },

        getUserRoles() {
            Api().get('/roles/all')
                .then(response => {
                    this.listRoles = response.data.AllRoles
                    for (let lr of response.data.AllRoles) {
                        if (lr.name == "User") {
                            this.userRole = lr._id
                        }
                    }
                }).catch(err => { console.log("failed getAllUserRoles"); })
        },

        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },
        hidesearch() {
            this.hidesearchClient = false;
            this.e1 = 2;
        },

        // Creation d'un user avant de faire son devis
        HubspotSend(param) {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param) {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) {
                const user = {
                    nom: this.nom,
                    prenom: this.prenom,
                    datenaissance: this.datenaissance,
                    profession: this.profession,
                    adresse: this.adresse,
                    sexe: this.genre,
                    telephone_port: this.telephoneclient,
                    email: this.emailclient,
                    password: this.password1,
                    confirmPass: this.password1,
                    role: this.userRole,
                    createdby: store.state.user._id,
                    photo: this.file,
                    piece_identite: this.piece_identite,
                    numero_piece: this.numero_piece,
                    plateforme: 'Dash',
                    created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                    .then(resultat => {
                        this.HubspotSend(user)
                        this.MailChimpInscriptionCompte(user)
                        if (resultat.status == 200) {
                            if (resultat.data.numero) {
                                this.$message.error(resultat.data.msg, this.AlertMessage)
                            } else {
                                this.$store.dispatch('setClient', resultat.data.data)
                                this.usersearch = resultat.data.data
                                this.AddUser = false
                                this.clientnonOk = false
                                this.hidesearchClient = false
                                this.e1 = 1
                            }
                        } else {
                            this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                        }

                    })
                    .catch(err => {
                        this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                    })

            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },
        check_client_search() {
            if (this.clientsearch == 'téléphone') {
                this.NumberInput = true
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if (this.clientsearch == 'email') {
                this.NumberInput = false
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },
        isMobile: function () {
            if (screen.width <= 760) {
                return true
            } else {
                return false
            }
        },

        rechercherClient() {
            if (this.telephoneclient) {
                const validPhone = validatePhone(this.telephoneclient);
                this.errors.phone = validPhone.error;

                if (validPhone.valid == true) {
                    Api().get('/user/phone/' + this.telephoneclient)
                        .then(resultat => {
                            if (resultat.data.telephone_port === this.telephoneclient) {
                                this.clientOk = true
                                this.clientnonOk = false
                                this.usersearch = resultat.data;
                                this.$store.dispatch('setClient', resultat.data)
                            }

                        })
                        .catch(e => {
                            this.clientnonOk = true
                            this.clientOk = false
                            this.telephoneC = this.telephoneclient;
                        })
                } else {
                    this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
                }
            } else {
                this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
            }


        },
        rechercheClientByMail() {
            if (this.emailclient) {

                const validEmail = validateEmail(this.emailclient);
                this.errors.email = validEmail.error;

                if (validEmail.valid == true) {
                    Api().get('/user/email/' + this.emailclient)
                        .then(resultat => {
                            if (resultat.data.email === this.emailclient) {
                                this.clientOk = true
                                this.clientnonOk = false
                                this.usersearch = resultat.data;
                                this.$store.dispatch('setClient', resultat.data)
                            }
                        }).catch(e => {
                            this.clientnonOk = true
                            this.clientOk = false
                            this.emailC = this.emailclient;
                        })
                } else {
                    this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
                }
            } else {
                this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

            }
        },
        __resizeHanlder: _.throttle(function (e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700)
    },
    mounted() {
        window.addEventListener("resize", this.__resizeHanlder)
        this.getUserRoles()
    },

    created() {
        this.init()
    },
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>