<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>
        Génération de devis <span style="color: #ffa409">Flotte/Auto</span>
      </h1>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-10">
      <div>
        <el-row :gutter="15">
          <el-col :span="12">
            <div>
              <h4>
                Email:
                <span style="font-weight: bold; color: #ffa409">{{
                  user.email
                }}</span>
              </h4>
            </div>
            <div>
              <h4>
                Client :
                <span style="font-weight: bold; color: #ffa409">{{
                  user.prenom + " " + user.nom
                }}</span>
              </h4>
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              <h4>
                Telephone:
                <span style="font-weight: bold; color: #ffa409">{{
                  user.telephone_port
                }}</span>
              </h4>
            </div>
            <div>
              <h4>
                total vehicules :
                <span style="font-weight: bold; color: #ffa409">{{
                  totalVehicule
                }}</span>
              </h4>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-divider />
      <div>
        <h3>Période de couverture</h3>
        <el-row :gutter="20" v-if="!isMobile">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Durée de la garantie (en mois)</label>
              <el-select @change="dateDiff()" style="width: 100%" v-model="duree" placeholder="Selectionner">
                <el-option v-for="item in DureeMois" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date d'effet</label>
              <el-date-picker style="width: 100% !important" @change="dateDiff()" v-model="dateEffet" type="date"
                placeholder="Date d'effet" :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date d'échéance</label>
              <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Durée de la garantie (en mois)</label>
              <el-select @change="dateDiff()" style="width: 100%" v-model="duree" placeholder="Selectionner">
                <el-option v-for="item in DureeMois" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date d'effet</label>
              <el-date-picker style="width: 100% !important" @change="dateDiff()" v-model="dateEffet" type="date"
                placeholder="Date d'effet" :picker-options="dateAfterToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date d'échéance</label>
              <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div>
        <h3>Liste des assureurs</h3>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <el-checkbox-group v-model="assureurs">
                <el-checkbox v-for="(assur, index) in listeAssureurs" :key="index" :label="assur.societe">
                  <template>
                    <img width="50" height="40" :src="assur.logo" />
                    <!-- <h3>{{assur.societe.substr(0, 5)}}</h3> -->
                  </template>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 30px">
        <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="SendData()" type="primary"
          round>Valider</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import store from "../../../store";
import dayjs from "dayjs";

import _ from "underscore";

export default {
  name: "ValidationFlotte",
  data: () => ({
    modalgenerationloading: false,
    showTable: false,
    tableData: [],
    user: {},
    listeAssureurs: [],
    assureurs: [],
    totalVehicule: 0,
    neant: "N.A",
    reference_flotte: "",
    duree: null,
    DureeMois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    dateAfterToday: {
      disabledDate(time) {
        return time.getTime() < dayjs(new Date()).add(-1, "days").toDate(); //dayjs.datediff(Date.now(), );
      },
    },
    capital_AvRec: 500000,
    dateEffet: null,
    dateEcheance: null,
  }),
  methods: {
    dateDiff: function () {
      if (this.dateEffet && this.duree) {
        let inter = dayjs(this.dateEffet)
          .add(this.duree, "month")
          .format("YYYY-MM-DD");
        this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
      } else {
        // this.duree = 1;
        this.dateEffet = dayjs(new Date()).format("YYYY-MM-DD");
        let inter = dayjs(this.dateEffet)
          .add(this.duree, "month")
          .format("YYYY-MM-DD");
        this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
      }
    },
    getListePartenaire: function () {
      switch (this.$store.state.user.role) {
        case "Courtier":
          Api()
            .post("/branchecourtier/get/partenaire", {
              userid: this.$store.state.user._id,
              branche: "Automobile",
            })
            .then((response) => {
              this.listeAssureurs = response.data.partenaires[0].assureurs;
            })
            .catch((error) => {
              console.log(error);
            });
          break;

        case "Agent":
          Api()
            .post("/branchecourtier/get/partenaire", {
              userid: this.$store.state.user._id,
              branche: "Automobile",
            })
            .then((response) => {
              this.listeAssureurs = response.data.partenaires[0].assureurs;
            })
            .catch((error) => {
              console.log(error);
            });
          break;

        default:
          Api()
            .get("/partenaire/partners/auto")
            .then((response) => {
              this.listeAssureurs = response.data.partenaires;
            })
            .catch((error) => {
              console.log(error);
            });
          break;
      }
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getFlotte() {
      if (this.$route.params.id) {
        Api()
          .get("/tempflotte/liste/vehicule/" + this.$route.params.id)
          .then((result) => {
            this.reference_flotte = this.$route.params.id;
            this.showTable = true;
            this.tableData = result.data.flottes;
            this.user = this.tableData[0].userid;
            this.totalVehicule = this.tableData.length;
          })
          .catch((err) => {
            this.$message.error(
              "Formatage du fichier non reconnu !",
              this.AlertMessage
            );
          });
      } else {
        this.$router.push({ name: "listeFlotte" });
      }
    },
    ListAutoCreateForCars: function () {
      const listAuto = [];

      for (let i = 0; i < this.tableData.length; i++) {
        const obj = this.yearsDiff(this.tableData[i].dateCircule);
        const Oneauto = {
          categorie: this.tableData[i].categorie,
          tonnage: this.tableData[i].tonnage,
          optionUtilitaire: this.tableData[i].optionUtilitaire,
          volumetrie2_roues: this.tableData[i].volumetrie2_roues,
          optionPersoTrans: this.tableData[i].optionPersoTrans,
          carburation: this.tableData[i].carburation,
          marque: this.tableData[i].marque,
          carosserie: this.tableData[i].carosserie,
          model: this.tableData[i].model,
          force: this.tableData[i].force,
          dateCircule: this.tableData[i].dateCircule,
          age: obj.age,
          annee_mois: obj.an,
          nbplace: this.tableData[i].nbplace,
          valeur: this.tableData[i].valeur,
          valeur_venale: this.tableData[i].valeur_venale,
          immatriculation: this.tableData[i].immatriculation,
          flotte_mono: this.tableData[i].flotte_mono,
          option_Individuel_Accident:
            this.tableData[i].option_Individuel_Accident,
          garanties: this.tableData[i].garanties,
          capitalAvRec:
            Number(this.tableData[i].capitalAvRec) || this.capital_AvRec,
        };
        if (this.categorie == "2_roues") {
          Oneauto.force = this.neant;
          Oneauto.dateCircule = this.neant;
          Oneauto.valeur = this.neant;
          Oneauto.valeur_venale = this.neant;
          Oneauto.nbplace = this.neant;
        }

        listAuto.push(Oneauto);
      }
      return listAuto;
    },

    // calculDuree(dateEffet, dateEcheance) {
    //   const date1 = dayjs(dateEffet);
    //   const date2 = dayjs(dateEcheance);
    //   return date2.diff(date1, "month");
    // },
    yearsDiff(dateCircule) {
      let obj = {
        age: 0,
        an: "",
      };
      const date1 = dayjs(dateCircule);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      obj.age = daydiff;

      if (obj.age <= 29) {
        obj.an = "jour(s)";
      } else if ((obj.age >= 30, obj.age <= 360)) {
        let year = Math.floor(obj.age / 30);
        obj.age = year;
        obj.an = "mois";
      } else if (obj.age >= 360) {
        let year = Math.floor(obj.age / 360);
        obj.age = year;
        obj.an = "an(s)";
      }
      return obj;
    },
    SendData() {
      console.log(this.reference_flotte);
      if (this.dateEffet) {
        if (this.assureurs.length >= 1) {
          const listAuto = this.ListAutoCreateForCars();
          const manyAutoToSave = {
            userid: this.user._id,
            proprietaire: {
              prenom: this.user.prenom,
              nom: this.user.nom,
            },
            dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
            dateEcheance: dayjs(this.dateEcheance).format("YYYY-MM-DD"),
            createur: this.$store.state.user._id,
            branche: "Automobile",
            duree: this.duree,
            dateSouscription: dayjs(new Date()).format("YYYY-MM-DD"),
            vehicule: listAuto,
            assureurs: this.assureurs,
            reference_flotte: this.reference_flotte,
          };

          if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
            manyAutoToSave.courtierid = this.$store.state.user.societe;
          }

          Api()
            .post("devis/add", manyAutoToSave)
            .then((result) => {
              if (result.data.message) {
                this.modalgenerationloading = true;
                this.$store.dispatch("setDevis", result.data);
                setTimeout(
                  () => (
                    (this.modalgenerationloading = false),
                    this.$router.go(
                      this.$router.push({
                        name: "liste_devis_paiement",
                      })
                    )
                  ),
                  3000
                );
              }
            });
        } else {
          this.$message.error(
            "Choisissez au minimum un assureur !",
            this.AlertMessage
          );
        }
      } else {
        this.$message.error(
          "La date d'effet est obligatoire !",
          this.AlertMessage
        );
      }
    },
  },
  mounted() {
    this.getFlotte();
    this.getListePartenaire();
    // this.duree = 1
  },
};
</script>

<style></style>