<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <small style="font-weight: bold;font-style: italic;color: lightcoral;">
            Log des actions du système
        </small>
        <div class="toolbar-box flex align-center" v-if="!isMobile" >
            <div class="box grow">
                <el-input placeholder="Rechercher branche..." prefix-icon="el-icon-search" v-model="search" clearable>
                </el-input>
            </div>
            <div class="box grow">
                <!--        A ne pas supprimer-->
            </div>
        </div>

        <div v-if="isMobile" style="margin-bottom:15px">
            <el-row :gutter="15">
                <el-col :span="24" style="margin-top:15px">
                    <el-input placeholder="Rechercher branche..." prefix-icon="el-icon-search" v-model="search" clearable>
                    </el-input>
                </el-col>
            </el-row>
        </div>

        <div class="table-box card-base card-shadow--medium box grow border small" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
            <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready" @selection-change="handleSelectionChange">
                <el-table-column label="Date" min-width="150" fixed prop="created_at" class="small" height="10"></el-table-column>
                <el-table-column label="Type d'action" min-width="150" fixed prop="item_type"></el-table-column>
                <el-table-column label="Description" min-width="150" fixed prop="description"></el-table-column>
                <el-table-column label="Créateur" min-width="150" fixed prop="userid">
                    <template slot-scope="scope">
                        <span class="date"> <a href="#">{{ scope.row.userid.prenom }} - {{ scope.row.userid.nom }}</a>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="50">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="setItemToJson(scope.row)" type="success" icon="el-icon-plus" circle title="Json">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
                :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
                :total="total"></el-pagination>
        </div>


        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
            <el-table :data="listInPage" style="width: 100%" v-if="ready" @selection-change="handleSelectionChange">
                <el-table-column label="Action" min-width="150" fixed prop="item_type" class="small" height="10"></el-table-column>
                <el-table-column label="Utilisateur" min-width="150" fixed prop="userid">
                    <template slot-scope="scope">
                        <span class="date"> <a href="#">{{ scope.row.userid.prenom }} - {{ scope.row.userid.nom }}</a>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="50">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="setItemToJson(scope.row)" type="success" icon="el-icon-plus" circle title="Json">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
                :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
                :total="total"></el-pagination>
        </div>

        <el-dialog title="Suppression de branche" width="50%" :visible.sync="jsondetails" v-if="jsondetails == true">
            <div class="grid-content bg-purple">
                <el-card class="box-card flex ">
                    <ul style="width: 100%;">
                        <li class="li">Date de logs : <span class="data"> {{ Tojson.created_at }} </span> </li>
                        <li class="li">Type de logs : <span class="data"> {{ Tojson.item_type }}</span> </li>
                        <li class="li">Utilisateur : <span class="data"> {{ Tojson.userid.prenom }} - {{ Tojson.userid.nom }}</span> </li>
                        <li class="li">Addresse IP : <span class="data"> {{ Tojson.adress_ip }} </span> </li>
                        <li class="li">Action : <span class="data"> {{ Tojson.description }} </span> </li>
                    </ul> 
                </el-card>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>


//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
    name: "StyledTablePage",
    data() {
        return {
            listlogs: [],
            Tojson:null,
            detailbranche: [],
            showpack: false,
            nombranche: null,
            fields: [
                { key: 'branche', sortable: true, label: 'Nom branche' },
                { key: 'action', label: 'Action' },
            ],
            modalgenerationloading: false,
            optionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            ldevis: [],
            du: null,
            au: null,
            listdevis: [],
            modalgeneration: false,
            detailsDevis: [],
            showdevis: false,
            pageOptions: [100, 5, 10, 15],
            modalInfo: {
                title: '',
                content: ''
            },
            userflette: [],
            fletteRows: null,
            username: null,
            data: {},
            html: '',
            Mycounter: 0,
            dismissSecs: 3,
            totalRows: null,
            perPage: 8,
            UpdateOk: false,
            deleteConfirmation: false,
            jsondetails: false,
            dismissCountDown: 0,
            detailsUser: [],
            listuser: null,
            filter: null,
            showuser: false,
            voidField: false,
            isMobile: false,
            ready: false,
            width: 0,
            height: "auto",
            loading: false,
            search: "",
            pagination: {
                page: 1,
                size: 100,
                sizes: [10, 15, 20, 30, 50, 100],
                layout: "total, ->, prev, pager, next, jumper, sizes",
                small: false
            },
            list: this.listlogs,//users,
            editMode: false,
            itemsChecked: [],
            dialogUserVisible: false,
            currentId: 0,
        }
    },
    computed: {
        listFiltered() {
            return this.listlogs.filter(obj => {
                let ctrl = false
                for (let k in obj) {
                    if (
                        obj[k] &&
                        obj[k]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                    )
                        ctrl = true
                }
                return ctrl
            })
        },

        listSortered() {
            let prop = this.sortingProp
            let order = this.sortingOrder
            return [].concat(
                this.listFiltered.sort((item1, item2) => {
                    let val1 = ""
                    let val2 = ""

                    val1 = item1[prop]
                    val2 = item2[prop]
                    if (order === "descending") {
                        return val2 < val1 ? -1 : 1
                    }
                    return val1 < val2 ? -1 : 1
                })
            )
        },
        listInPage() {
            let from = (this.currentPage - 1) * this.itemPerPage
            let to = from + this.itemPerPage * 1
            return this.listFiltered.slice(from, to)
        },
        total() {
            return this.listFiltered.length
        },
        currentPage: {
            get() {
                return this.pagination.page
            },
            set(val) {
                this.pagination.page = val
            }
        },
        itemPerPage() {
            return this.pagination.size
        },
        selectedItems() {
            return this.itemsChecked.length || 0
        }
    },
    watch: {
        isBusy(newVal, oldVal) {
            if (newVal !== oldVal) {
                const tableScrollBody = this.$refs["my-table"].$el;
                if (newVal === true) {
                    tableScrollBody.classList.add("overflow-hidden");
                } else {
                    tableScrollBody.classList.remove("overflow-hidden");
                }
            }
        },

        itemPerPage(val) {
            this.ready = false
            this.currentPage = 1

            setTimeout(() => {
                this.ready = true
            }, 500)
        },
        search(val) {
            this.currentPage = 1
        }
    },
    methods: {
        setItemToJson(item){
            delete item.userid.affiliated_partner
            delete item.userid.primary_partner
            this.Tojson = item
            this.jsondetails = true
        },

        exportToExcel() {
            //API de genereation donne en excel
        },

        get_all_logs() {

            Api().get('/systemelogger/get/all')
                .then(resultat => {
                    this.listlogs = resultat.data.logs
                    this.totalRows = this.listlogs.length
                    console.log(this.listlogs)
                });
        },

        AlertMessage() {
            this.$message({
                showClose: false,
                duration: 5000
            });
        },

        toggleDetails(item) {
            this.detailsUser = item
            this.editUser = true
        },

        ready: function () {
            var self = this;

            setInterval(function () {
                self.$data.ticker = Date.now();
            }, 1000);
        },

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format('DD-MM-YYYY')
            }
        },



        calcDims() {
            const tableWrapper = document.getElementById("table-wrapper")
            if (tableWrapper) this.width = tableWrapper.clientWidth

            if (!this.isMobile && tableWrapper) {
                this.height = tableWrapper.clientHeight - 44
            }

            if (this.width < 480) {
                this.pagination.small = true
                this.pagination.layout = "prev, pager, next"
            } else if (this.width >= 480 && this.width < 700) {
                this.pagination.small = false
                this.pagination.layout = "prev, pager, next, ->, sizes"
            } else {
                this.pagination.small = false
                this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
            }

            this.ready = true
        },
        handleResize: _.throttle(function (e) {
            this.ready = false
            this.width = 0
            setTimeout(this.calcDims, 1000)
        }, 500),
        handleSelectionChange(val) {
            this.itemsChecked = val
        },
        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        }
    },
    filters: {
        selected: function (value, sel) {
            if (!value) return ""
            if (!sel) return value

            value = value.toString()
            sel = sel.toString()

            const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
            if (startIndex !== -1) {
                const endLength = sel.length
                const matchingString = value.substr(startIndex, endLength)
                return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
            }

            return value
        }
    },
    created() {
        this.init()
        this.get_all_logs()
    },
    mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.get_all_logs()
        } else {
            this.$router.go(this.$router.push('/login'))
        }

        //ie fix
        if (!window.Number.parseInt) window.Number.parseInt = parseInt

        this.calcDims()
    }
}
</script>
  
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
    &.overflow {
        overflow: auto;
    }

    .toolbar-box {
        &.hidden {
            visibility: hidden;
        }
    }

    .table-box {
        overflow: hidden;

        &.hidden {
            visibility: hidden;
        }
    }
}
</style>
  
<style lang="scss">
@import "../../../assets/scss/_variables";
.el-table__body .el-table__row{
    border: 1px solid red!important;;
    height: 12px!important;
}
.data {
    color :red;
    font-weight: bold;
}
ul li{
    list-style: none;
    border-bottom: 1 solid #000;
}
.page-table {
    .toolbar-box {
        margin-bottom: 10px;

        .card-shadow--medium {

            &.el-input,
            &.el-select {
                border-radius: 4px;

                input {
                    border-color: transparent;
                    background-color: lighten($background-color, 2%);
                    color: $text-color;

                    &:hover {
                        border-color: #c0c4cc;
                    }

                    &:focus {
                        border-color: $text-color-accent;
                    }
                }
            }

            &.el-button {
                border-color: transparent;
            }
        }
    }

    .clickable {
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
    }

    .sel-string {
        .sel {
            background: transparentize($text-color, 0.8);
            border-radius: 5px;
            //text-transform: uppercase;
        }
    }
}

@media (max-width: 768px) {
    .page-table {
        .toolbar-box {
            display: block;
            overflow: hidden;
            font-size: 80%;
            padding-bottom: 10px;

            &>* {
                display: inline-block;
                min-width: 120px;
                height: 22px;
                //background: rgba(0, 0, 0, 0.04);
                margin-bottom: 16px;
            }
        }
    }
}
</style>
  