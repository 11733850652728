<template>
  <vue-scroll class="page-devis-voyage">
    <div class="page-header">
      <h1>
        Nouvelle<span style="color: #ffa409"> configuration systéme</span>
        <theme-picker style="float: right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder" />

    <div
      class="
        card-base
        card-shadow--medium
        bg-white
        black-text
        ph-5
        p0
        pb-20
        mt-20
      "
      v-if="!isMobile"
    >
      <div style="margin-top: 20px; padding: 10px 20px;">
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Libelle de la configuration</label>
              <el-input
                placeholder="Libelle de la configuration"
                v-model="config.libelle"
              >
              </el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Type de la configuration</label>
              <el-select
                style="width: 100% !important"
                v-model="config.type"
                placeholder="Selectionner"
              >
                <el-option
                  v-for="(items, index) in types"
                  :key="index"
                  :label="items"
                  :value="items"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" style="margin-top: 25px">
            <div class="grid-content bg-purple-light">
              <label>Nom de la configuration (pas d'espace)</label>
              <el-input
                placeholder="Nom de la configuration"
                v-model="config.config_name"
              >
              </el-input>
            </div>
          </el-col>
          <el-col :span="12" style="margin-top: 25px">
            <div class="grid-content bg-purple-light">
              <label>Valeur de la configuration</label>
              <el-input
                placeholder="Valeur de la configuration"
                v-model="config.config_value"
              >
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 15px; padding: 0px 20px;">
        <el-button @click="createConfig" type="primary" round>Créer</el-button>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts";
import Chance from "chance";
const chance = new Chance();

//debut importation assuraf
import dayjs from "dayjs";
import Api from "../../../services/Api";
import store from "../../../store";
//fin importation assuraf

export default {
  name: "devis_voyage",

  data() {
    return {
      isMobile: false,
      types: ["text", "email", "textarea", "email", "password", "color"],

      config: {
        libelle: "",
        type: "",
        config_name: "",
        config_value: "",
        user: null,
      },
      //fin initialisation des models d'assuraf
    };
  },
  mounted() {
    window.addEventListener("resize", this.__resizeHanlder);
  },

  methods: {
    init() {
      if (window.innerWidth <= 768) this.isMobile = true;
    },

    // Creation de branche
    createConfig() {
      if (
        this.config.libelle &&
        this.config.type &&
        this.config.config_name &&
        this.config.config_value
      ) {
        this.config.user = store.state.user._id;

        Api()
          .post("/systemeconfig/saveone", this.config)
          .then((rep) => {
            if (rep.data) {
              this.$message.success("Opération reussite ! ", this.AlertMessage);
              this.config = {
                libelle: "",
                type: "",
                config_name: "",
                config_value: "",
                user: null,
              };
            }
          });
      } else {
        this.$message.error(
          "tous les champs sont obligatoire !",
          this.AlertMessage
        );
      }
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },

    __resizeHanlder: _.throttle(function (e) {
      if (this.chart) {
        this.chart.resize();
      }
      if (this.pie) {
        this.pie.resize();
      }
    }, 700),
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
