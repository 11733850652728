import Api from "./Api"

function sms_sender_validator(data){
    if(data.phone_number!="" && data.sms_objet!="" && data.message!=""){
        return null
    }else{
        return 'Remplissez les champs obligatoires (*)'
    }
}

async function data_sender(data){
  
    try {
        return (await Api().post("/smsservice/send", data)).data
    } catch (error) {
        console.log(error)
       return error.response.data
    }
}

export default {
    sms_sender_validator,
    data_sender
}