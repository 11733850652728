<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des contrats<span style="color:#ffa409"> échus</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

     <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher contrat échu..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher contrat echu..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="!isMobile">
      <div class="box grow">
        <h3>Recherche par période de création</h3>
        <el-date-picker
            v-model="du"
            type="date"
            format="yyyy/MM/dd"
            placeholder="Choississez une date">
        </el-date-picker>
        <el-date-picker
            style="margin-left:15px"
            v-model="au"
            type="date"
            format="yyyy/MM/dd"
            placeholder="Choississez une date">
        </el-date-picker>
        <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
      </div>
    </div>
    <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="isMobile">
      <div class="box grow">
        <h3>Recherche par période de création</h3>
        <el-date-picker
            v-model="du"
            type="date"
            format="yyyy/MM/dd"
            placeholder="Choississez une date">
        </el-date-picker>
        <el-date-picker
            style="margin-top:15px"
            v-model="au"
            type="date"
            format="yyyy/MM/dd"
            placeholder="Choississez une date">
        </el-date-picker>
        <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
      </div>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
      <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
      <el-table-column label="Date Souscription" fixed min-width="150" prop="dateSouscription">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
      <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope ="scope">
            <span class="date"> <a href="#" @click="SetDetailUser(scope.row.user)">{{scope.row.user.prenom}} - {{scope.row.user.nom}}</a></span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Code Partenaire" min-width="150" prop="codep">
          <template slot-scope ="scope">
              <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid">
          <template slot-scope ="scope" v-if="scope.row.partenaireid.societe.length <=10">
            <span class="date">{{scope.row.partenaireid.societe}}</span>
          </template>
          <template slot-scope ="scope" v-else>
            <span class="date">{{scope.row.partenaireid.societe.substring(0,10) + '...'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope ="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Partenaire" min-width="150" prop="courtierid.societe"></el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="creator">
          <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
            <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
            <span v-else>
              Admin
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="220">
          <template slot-scope="scope">
            <el-button 
              :disabled="scope.row.brancheid.branche !='Automobile' && 
              scope.row.brancheid.branche !='Habitation' && 
              scope.row.brancheid.branche !='Voyage'" 
              @click="Edit_devis(scope.row)" 
              type="primary" 
              icon="el-icon-edit" 
              size="mini" circle title="Renouveller"></el-button>
            <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="downloadAttestationProvisoire(scope.row)" type="primary" icon="el-icon-download" circle></el-button>
            <el-button :disabled="scope.row.brancheid.branche != 'Automobile'" @click="dialogAvenant(scope.row)"
              type="primary" icon="el-icon-edit" size="mini" circle title="Avenant"></el-button>

          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

     <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
      <el-table-column label="Date Souscription" min-width="150" prop="dateSouscription">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
      <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope ="scope">
            <span class="date"> <a href="#" @click="SetDetailUser(scope.row.user)">{{scope.row.user.prenom}} - {{scope.row.user.nom}}</a></span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Code Partenaire" min-width="150" prop="codep">
          <template slot-scope ="scope">
              <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid">
          <template slot-scope ="scope" v-if="scope.row.partenaireid.societe.length <=10">
            <span class="date">{{scope.row.partenaireid.societe}}</span>
          </template>
          <template slot-scope ="scope" v-else>
            <span class="date">{{scope.row.partenaireid.societe.substring(0,10) + '...'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope ="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Partenaire" min-width="150" prop="courtierid.societe"></el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="creator">
          <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
            <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
            <span v-else>
              Admin
            </span>
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="90">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="downloadAttestationProvisoire(scope.row)" type="primary" icon="el-icon-download" circle></el-button>
            <el-button :disabled="scope.row.brancheid.branche != 'Automobile'" @click="dialogAvenant(scope.row)"
              type="primary" icon="el-icon-edit" size="mini" circle title="Avenant"></el-button>

          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>


    <el-dialog width="70%!important" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de Souscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                  <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                  <li v-if="detailsContrat.brancheid.branche=='Voyage' && detailsContrat.increment_voyage">
                    Numéro Increment Voyage : <span class="to-right assuraf-orange">{{detailsContrat.increment_voyage}}</span></li>
                  <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                  <li>Prime Nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                  <li v-if="detailsContrat.brancheid.branche=='Automobile' && CheckIfTypeDefined(detailsContrat.devisid) !=null">
                    FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid!=null">{{detailsContrat.devisid.fga.toLocaleString()}}</span></li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                  <li v-if="detailsContrat.partner_creator!=null">Partenaires : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                </ul>
                <hr>

                <!-- VIsualisation Population Contrat Santé -->
                <ul style="list-style:none" v-if="detailsContrat.brancheid.branche=='Santé'">
                  <p> <b>Population : </b> </p>
                  <li> Adulte Homme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Adulte.Homme}}</span></li>
                  <li> Adulte Femme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Adulte.Femme}}</span></li>
                  <li> Enfant Garçon :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Enfant.Garcon}}</span></li>
                  <li> Enfant Fille :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Enfant.Fille}}</span></li>
                  <li> Senior Homme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Senior.Homme}}</span></li>
                  <li> Senior Femme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Senior.Femme}}</span></li>
                  <li style="font-weight: bolder;"> 
                      Total  :  <span class="to-right assuraf-orange">
                        {{detailsContrat.devisid.details_pack_sante.assures.nombre_assures}}
                      </span>
                  </li>
                </ul>
                <!-- VIsualisation Population Contrat Vie -->
                <ul style="list-style:none" v-if="detailsContrat.brancheid.branche=='Vie'">
                  <p> <b>Population : </b> </p>
                  <li> 
                      Assuré :  
                      <span class="to-right assuraf-orange">
                        {{detailsContrat.details[0].contractor.Prenom}} - {{detailsContrat.details[0].contractor.Nom}}
                      </span>
                  </li>

                  <li> 
                    Souscripteur :  
                      <span class="to-right assuraf-orange">
                        {{detailsContrat.details[0].customer.Prenom}} - {{detailsContrat.details[0].customer.Nom}}
                      </span>
                  </li>

                  <li> 
                    Bénéficiaire :  
                      <span class="to-right assuraf-orange">
                        {{detailsContrat.details[0].beneficiary.Prenom}} - {{detailsContrat.details[0].beneficiary.Nom}}
                      </span>
                  </li>
                  <li style="font-weight: bolder;"> 
                      Total  :  <span class="to-right assuraf-orange">{{countViePopulation(detailsContrat.details[0])}}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsContrat.user.prenom.toUpperCase()}}</span></li>
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsContrat.user.nom.toUpperCase()}}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{detailsContrat.user.telephone_port}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsContrat.user.email}}</span></li>
                  <li>Date inscription : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                  <li>
                    <img :src="detailsContrat.user.photo" alt="" width="200">
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

       <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="detailsContrat.brancheid.branche=='Automobile'" style="margin-top:15px">
        <el-table
            :data="listInPageAuto"
            style="width: 100%"
            :height="height"
            v-if="ready"
            @selection-change="handleSelectionChange"
        >
          <el-table-column label="Marque" min-width="150"  prop="marque" :fixed="!isMobile">
          </el-table-column>
          <el-table-column label="Modèle" min-width="120" prop="model"></el-table-column>
          <el-table-column label="Puissance fiscale" min-width="180" prop="force"></el-table-column>
          <el-table-column label="Nombre de place" min-width="150" prop="nbplace"></el-table-column>
          <el-table-column label="Valeur à neuf" min-width="150" prop="valeur"></el-table-column>
          <el-table-column label="Valeur vénale" min-width="120" prop="valeur_venale"></el-table-column>
          <el-table-column label="Immatriculation" min-width="180" prop="immatriculation"></el-table-column>
          <el-table-column label="Carburation" min-width="150" prop="carburation"></el-table-column>
        </el-table>

        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="totalAuto"
        ></el-pagination>
      </div>

      <el-row :gutter="15" v-if="detailsContrat.user_on_gray_card">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom sur la carte grise: <span class="to-right assuraf-orange">{{detailsContrat.user_on_gray_card.nom.toUpperCase()}}</span></li>
                  <li>Prénom sur la carte grise : <span class="to-right assuraf-orange">{{detailsContrat.user_on_gray_card.prenom.toUpperCase()}}</span></li>
                 </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <div class="mt-5">
        <a :href="detailsContrat.lien_contrat">Télécharger le contrat</a>
        <el-button class="ml-5" type="danger" round v-if="$store.state.user.email==$store.state.user.email=='admin@assuraf.com' || $store.state.user.email=='mowencleopas@assuraf.com'" @click="DeleteContrat(detailsContrat)">Supprimer le contrat</el-button>
      </div>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de Souscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                  <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                  <li v-if="detailsContrat.brancheid.branche=='Voyage' && detailsContrat.increment_voyage">
                    Numéro Increment Voyage : <span class="to-right assuraf-orange">{{detailsContrat.increment_voyage}}</span></li>
                  <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                  <li>Prime Nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                  <li v-if="detailsContrat.brancheid.branche=='Automobile' && CheckIfTypeDefined(detailsContrat.devisid) !=null">
                    FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid!=null">{{detailsContrat.devisid.fga.toLocaleString()}}</span></li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                  <li v-if="detailsContrat.partner_creator!=null">Partenaires : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsContrat.user.prenom.toUpperCase()}}</span></li>
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsContrat.user.nom.toUpperCase()}}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{detailsContrat.user.telephone_port}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsContrat.user.email}}</span></li>
                  <li>Date inscription : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                  <li>
                    <img :src="detailsContrat.user.photo" alt="" width="200">
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

       <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="detailsContrat.brancheid.branche=='Automobile'" style="margin-top:15px">
        <el-table
            :data="listInPageAuto"
            style="width: 100%"
            :height="height"
            v-if="ready"
            @selection-change="handleSelectionChange"
        >
          <el-table-column label="Marque" min-width="150" prop="marque" :fixed="!isMobile">
          </el-table-column>
          <el-table-column label="Modèle" min-width="120" prop="model"></el-table-column>
          <el-table-column label="Puissance fiscale" min-width="180" prop="force"></el-table-column>
          <el-table-column label="Nombre de place" min-width="150" prop="nbplace"></el-table-column>
          <el-table-column label="Valeur à neuf" min-width="150" prop="valeur"></el-table-column>
          <el-table-column label="Valeur vénale" min-width="120" prop="valeur_venale"></el-table-column>
          <el-table-column label="Immatriculation" min-width="180" prop="immatriculation"></el-table-column>
          <el-table-column label="Carburation" min-width="150" prop="carburation"></el-table-column>
        </el-table>

        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="totalAuto"
        ></el-pagination>
      </div>

      <el-row :gutter="15" v-if="detailsContrat.user_on_gray_card">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom sur la carte grise: <span class="to-right assuraf-orange">{{detailsContrat.user_on_gray_card.nom.toUpperCase()}}</span></li>
                  <li>Prénom sur la carte grise : <span class="to-right assuraf-orange">{{detailsContrat.user_on_gray_card.prenom.toUpperCase()}}</span></li>
                 </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <div class="mt-5">
        <a :href="detailsContrat.lien_contrat">Télécharger le contrat</a>
      </div>
      <div class="mt-5">
        <el-button type="danger" round v-if="$store.state.user.email=='admin@assuraf.com' || $store.state.user.email=='mowencleopas@assuraf.com'" @click="DeleteContrat(detailsContrat)">Supprimer le contrat</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%!important" title="Détails client" :visible.sync="showUser" v-if="showUser==true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>
                    <img :src="detailsUser.photo" width="180" alt="photo de profil">
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails client" :visible.sync="showUser" v-if="showUser==true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>
                    <img :src="detailsUser.photo" alt="Photo de profil" width="100">
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog width="70%" title="Formulaire des avenants" persistent :visible.sync="showAvenant"  v-if="showAvenant">
      <avenants-component  :detailsContrat="detailsContrat" ></avenants-component>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
//fin importation assuraf

export default {
  name: "contrat_echu",
  components: {
      avenantsComponent: () => import('../../../components/avenant/render.component.vue'),
    },
  data() {
    return {
      showAvenant: false,
      modalgenerationloading : false,
      show_comment_contrat:false,
      set_comment_contrat:false,
      commentcount:null,
      commentlist:null,
      comment:{userid:null, comment_text:null, contratid:null, createdat:null},

      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      JsonView:false,
      Delete_reason:null,
      checked: null,
      user_name: true,
      client_name: false,
      isToday: false,
      isNotToday: false,
      Sunu: false,
      Salama : false,
      Allianz : false,
      du: null,
      au: null,
      Askia: false,
      Axa: false,
      Amsa : false,
      devisclient: false,
      modalgeneration: false,
      modalNumPolice: false,
      modal_Delete_ok: false,
      modalNumPoliceAttest: false,
      NumPolice: null,
      NomPrenom: null,
      PrenomSurCarte: null,
      NomSurCarte: null,
      categorie: null,
      NumPoliceAttest: null,
      NumTalon: null,
      showUser: false,
      paramclient: null,
      detailsContrat: [],
      listcontrat: [],
      userid: null,
      userRole: null,
      showcontrat: false,
      showAttestation: false,
      notification_text: null,
      sonam:"SONAM GENERALE ASSURANCES",

      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listcontrat,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() 
    {
      if(this.search.length >= 3){
          return this.listcontrat.filter(obj => {
            let ctrl = false
            for (let k in obj) 
            {
              /* Si la valeur courante est un String, et correspond à la valeur recherchée */
              if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                  ctrl = true
                  /*on le push dans le tableau */
                  //this.listcontrat.push(obj) 
              }else{
                 /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                if(Array.isArray(obj[k])){
                  obj[k].filter(nobj=>{
                    for(let l in nobj){
                        /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                        if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                          ctrl = true
                          /* on le push dans le tableau */
                          //this.listcontrat.push(obj)
                        }
                    }
                  })
                   /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                }else if(!Array.isArray(obj[k]) && typeof obj[k]=="object" && obj[k]!=null){
                  /*== On crée un nouveau tablequ vide ==*/
                  let my_new_array = []

                  /*== On push la valeur courante dans le nouveau tableau ==*/ 
                  my_new_array.push(obj[k])
                  
                  /*On refiltre le nouveau tableau atrribut par attribut*/
                  my_new_array.filter(nobj=>{
                    for(let l in nobj){
                        /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                        if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                          ctrl = true
                          
                          /* on le push dans le tableau */
                          //this.listcontrat.push(obj)
                        }
                    }
                  })
                }
              }
            }
            return ctrl
          })
      }else{
         return this.listcontrat.filter(obj => {
          let ctrl = false
          for (let k in obj) 
          {
            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
            ctrl = true
          }
          return ctrl
        })
      }

    },
    /*listFiltered() {
      return this.list.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },*/
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listcontrat.length
    },

    listFilteredAuto() {
      return this.detailsContrat.details[0].vehicules.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    /*listFilteredAuto() {
      return this.list.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },*/
    listSorteredAuto() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFilteredAuto.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPageAuto() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFilteredAuto.slice(from, to)
    },
    totalAuto() {
      return this.listFilteredAuto.length
    },


    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    dialogAvenant(contrat){
      this.showAvenant = true;
      this.detailsContrat = contrat;
    },

    countViePopulation(data){
      if(data.contractor.Nom==data.customer.Nom && data.contractor.Prenom==data.customer.Prenom){
        return 2
      }else if(data.contractor.Nom==data.customer.Nom &&  data.contractor.Prenom==data.customer.Prenom && data.contractor.Nom==data.beneficiary.Nom && data.contractor.Prenom==data.beneficiary.Prenom){
        return 1
      }else{
        return 3
      }
    },
    exportToExcel(){
          //API de genereation donne en excel
        },
    //Provisory Attestation download
        downloadAttestationProvisoire(contrat){
             if (contrat.brancheid.branche == 'Voyage' && contrat.partenaireid.societe==this.sonam)
             {
                //this.modalgeneration = true
                this.modalgenerationloading = true;
                let url = contrat.lien_contrat
                setTimeout(() => (
                this.modalgenerationloading = false,
                    window.open(url)), 7000);
            } else {
                Api().get('contrat/attestation/download/' + contrat.id)
                    .then(response => {
                       //this.modalgeneration = true
                       this.modalgenerationloading = true;
                        let url = response.data.lien
                        setTimeout(() => (
                        this.modalgenerationloading = false,
                            window.open(url)), 7000);
                    })
            }
        },

        checkedControl() {
            if (this.checked) {
                this.client_name = false
                this.user_name = true
                this.NomPrenom = null
                this.PrenomSurCarte = null
                this.NomSurCarte = null

            } else if (this.checked = true) {
                this.client_name = true
                this.user_name = false
            }
        },

        CheckIfTypeDefined(item){
            return typeof item
        },

        /*==== EXPORT Liste Contrat en XLS ====*/
        exportToExcel(){
              Api().get('/export/contrat')
                .then(resultat => {
                    this.modalgeneration = true
                    let url = resultat.data.lien
                    setTimeout(() => (
                        this.modalgeneration = false,
                        window.open(url)), 7000
                    );
                });
        },


        DateFormatter: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format('DD-MM-YYYY')
            }
        },

        MinuteFormatter(TheDate){
             if (TheDate) {
                return dayjs(new Date()).format('HH:mm')
            }
        },

        downloadAttestationPdf() {
            let pdfName = this.detailsContrat.user.prenom
            var canvas = document.getElementById('canvas')
            html2canvas(canvas).then(function (canvas) {
                var imgData = canvas.toDataURL('image/png')
                var doc = new jsPDF('landscape')
                doc.addImage(imgData, 'PNG', 10, 65)
                doc.save('Attestation-' + pdfName + '.pdf')
            })
            this.showAttestation = false

        },

        detailAttestation: function () {
            let today = dayjs(new Date()).format('DD-MM-YYYY')
            if(this.DateFormatter(this.detailsContrat.dateEffet)== today){
                this.isToday = true
                this.isNotToday = false
            } else {
                this.isNotToday = true
                this.isToday = false
            }

            this.showAttestation = true
            if (this.detailsContrat.partenaireid.societe == 'SUNU') {
                this.Sunu = true
                this.Askia = false
                this.modalNumPoliceAttest = true
            } else if (this.detailsContrat.partenaireid.societe == 'PROVIDENCE') {
                this.Sunu = true
                this.Askia = false
                this.modalNumPoliceAttest = true
            } else if (this.detailsContrat.partenaireid.societe == 'NSIA') {
                this.Sunu = true
                this.Askia = false
                this.modalNumPoliceAttest = true
            } else if (this.detailsContrat.partenaireid.societe == 'ASKIA') {
                this.Askia = true
                this.Sunu = false
                this.Axa = false
                this.modalNumPoliceAttest = true
            } else if (this.detailsContrat.partenaireid.societe == 'AXA') {
                this.Axa = true
                this.Askia = false
                this.Sunu = false
                this.modalNumPoliceAttest = true
            } else if(this.detailsContrat.partenaireid.societe == 'AMSA'){
                this.Axa = false
                this.Askia = false
                this.Sunu = false
                this.Amsa = true
                this.modalNumPoliceAttest = true
            }
            if (this.showAttestation == true) {
                this.showcontrat = false
            }
            if (this.detailsContrat.user.profession == null) {
                this.detailsContrat.user.profession = '-'
            }
        },

        detailcontrat(item) {
            this.detailsContrat = item
            this.showcontrat = true
        },
        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format('DD-MM-YYYY')
            }
        },

        DeleteContrat(item) 
        {
            Api().post('/contrat/remove/',  {id:item.id})
                .then((result) => {
                    if (result.data.rep.n == 1) {
                        this.showcontrat = false
                        this.getContrat()
                    }
                }).catch((err) => {
                    console.log('Oups !')
                });
        },

        getContrat() 
        {
           if (this.$store.state.user.role == 'agent général') 
           {
                Api().get('/contrat/assureur/echus/' + this.$store.state.user.societe)
                    .then(resultat => {
                        this.listcontrat = resultat.data.contrats
                        this.totalRows = resultat.data.contrats.length
                    });
            } else if (this.$store.state.user.role == 'Agent') 
            {
                Api().get('/contrat/courtier/echus/' + this.$store.state.user.societe)
                    .then(resultat => {
                        this.listcontrat = resultat.data.contrats
                        this.totalRows = resultat.data.contrats.length
                    });
            } else if (this.$store.state.user.role == 'assureur') 
            {
                Api().get('/contrat/assureur/echus/' + this.$store.state.user.societe)
                    .then(resultat => {
                        this.listcontrat = resultat.data.contrats
                        this.totalRows = resultat.data.contrats.length
                    });
            } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') 
            {
                Api().get('/contrat/echus')
                    .then(resultat => {
                        this.listcontrat = resultat.data.contrats
                        this.totalRows = resultat.data.contrats.length
                    });
            }

        },
        
        SetDetailUser(details) 
        {
            this.detailsUser = details
            this.showUser = true
        },

        Validate_numePolice(contrat) {
            if (this.NumPolice) {
                contrat.NumPolice = this.NumPolice
                this.Contract_generator(contrat)
            } else {
                this.$toasted.show('Numéro de police obligatoire ! ', this.ToastTabError)
            }

        },

        Validate_numePoliceAttest() {
            if (this.checked) {
                if (this.PrenomSurCarte && this.NomSurCarte && this.NumPolice && this.categorie) {
                    let data = {
                        idContart: this.detailsContrat.id,
                        nom: this.NomSurCarte,
                        prenom: this.PrenomSurCarte
                    }
                    Api().post('/contrat/update/user_on_gray_card', data)
                        .then((result) => {
                            if (result.data.response.nModified || result.data.response.ok) {
                                this.modalNumPoliceAttest = false
                            }
                        }).catch((err) => {});

                } else {
                    this.$toasted.show('Champs obligatoires ! ', this.ToastTabError)
                }

            } else {
                if (this.NumPolice && this.categorie) {
                    this.modalNumPoliceAttest = false
                } else {
                    this.$toasted.show('Champs obligatoires ! ', this.ToastTabError)
                }
            }

        },

        Contract_generator: function (contrat) {
            this.modalNumPolice = false
            this.modalNumPoliceAttest = false

            switch (contrat.brancheid.branche) {
                case 'Automobile':
                    let data = {
                        idContrat: contrat.id,
                        numpolice: this.NumPolice,
                        numTalon: this.NumTalon
                    }
                    Api().post('contrat/definitif/auto', data)
                        .then(response => {
                            this.NumPolice = null
                            this.modalgeneration = true
                            let url = response.data.lien
                            setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
                        })
                    break;
                case 'Habitation':
                    Api().get('contrat/definitif/mrh/' + contrat.id)
                        .then(response => {
                            this.modalgeneration = true
                            let url = response.data.lien
                            setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
                        })
                    break;
                case 'Voyage':
                    let Traveldata = {
                        idContrat: contrat.id,
                        numpolice: this.NumPolice,
                        numTalon: this.NumTalon
                    }
                    Api().post('contrat/definitif/voyage', Traveldata)
                        .then(response => {
                            this.modalgeneration = true
                            let url = response.data.lien
                            setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
                        })
                    break;

            }

        },
        FiltreDate() {
            if (this.du && this.au) {
                switch (store.state.user.role) {
                    case 'Admin':
                        Api().post('contrat/filtreContrat/periode', {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;

                    case 'Agent':
                        Api().post('contrat/filtreContrat/courtier/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;

                    case 'assureur':
                        Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;
                    case 'agent général':
                        Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.assureur, {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;

                    default:
                        break;
                }
            }


        },
        









    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    detaildevis(item) {

      this.detailsDevis = item
      this.showdevis = true
    },

    // Fonction de download du devis en fichier pdf
    Downloa_devis: function (devis) {
      Api().get('devis/download/' + devis._id)
          .then(response => {
            //this.modalgeneration = true
            this.modalgenerationloading = true;
            let url = response.data.lien
            setTimeout(() => (
                this.modalgenerationloading = false,
                    window.open(url)), 7000);
          })
    },

    Edit_devis: function (item) 
    {
      switch (item.brancheid.branche) 
      {
        case 'Automobile':
          Api().get('devis/' + item.devisid._id)
              .then(response => {
                // console.log(response  )
                // process.exit()
                this.$store.dispatch('setDevisReopen', response.data.devis)
                this.$router.go(this.$router.push({ name: 'avenant_update_devis_auto'}))
              })
          break;

      }
    },


    Remover() {
      Api().post('sinistre/remove')
          .then(rep => {})
    },

    getDevis() {

      if (this.$store.state.user.role == 'agent général') {
        Api().get('/devis/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = this.listdevis.length
            });
      } else if (this.$store.state.user.role == 'Agent') {
        Api().get('/devis/courtier/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = resultat.data.devis.length
            });

      } else if (this.$store.state.user.role == 'assureur') {
        Api().get('/devis/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = resultat.data.devis.length
            });
      } else if (this.$store.state.user.role == 'Admin') {
        Api().get('/devis/')
            .then(resultat => {
              this.listdevis = resultat.data.devis
              this.totalRows = resultat.data.devis.length
            });
      }

    },

    devisDeleteConfirmation(item) {
      this.modalDelete = true
      this.detailsDevis = item
    },

    ArchivateDevis(devis) {
      let id = devis._id
      Api().post('devis/archivate/' + id)
          .then(resp => {
            this.getDevis()
          })
    },

    AddEtatUser() {
      Api().post('devis/addetatuser')
          .then(resultat => {})
          .catch(err => {})
    },

    DeletingDevis(id) {
      Api().delete('devis/delete/' + id)
          .then(resultat => {
            if (resultat.data.rep.n == 1) {
              this.modalDelete = false
              this.getDevis()
            }
          })
          .catch(err => {})
    },

    FiltreDate() {
            if (this.du && this.au) {
                switch (store.state.user.role) {
                    case 'Admin':
                        Api().post('contrat/filtreContrat/periode', {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;
                      case 'SuperAdmin':
                            Api().post('contrat/filtreContrat/periode', {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listcontrat = resultat.data.contrats
                                });
                            break;
                    case 'Agent':
                        Api().post('contrat/filtreContrat/courtier/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;

                    case 'assureur':
                        Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;
                    case 'agent général':
                        Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.assureur, {
                                du: this.du,
                                au: this.au
                            })
                            .then(resultat => {
                                this.listcontrat = resultat.data.contrats
                            });
                        break;

                    default:
                        break;
                }
            }


        },

    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },

    pushMenu()
    {
      if(this.menu_name && this.userSubmenu.length >= 1)
      {
        this.UserFinalMenu.push({
          user : this.detailsUser.id,
          theme:this.menu_name,
          collapse:this.collapse,
          config:this.userSubmenu
        })
        this.menu_name   = null
        this.userSubmenu = []
        this.collapse    = null
      }else{
        this.$message.error('Remplissez les champs vides !', this.AlertMessage)
      }
    },

    create_user_menu()
    {
      if(this.UserFinalMenu.length >=1 )
      {
        Api().post("/menu/add", this.UserFinalMenu)
            .then((result) => {
              this.$message.success('Opération effectuée !', this.AlertMessage)
              console.log(result.data)
              this.UserFinalMenu = []
            }).catch((err) => {
          console.log(err)
        });
      }else{
        this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
      }
    },

    merge(){
      Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
          .then((result) => {
            this.showuser = false
            if(result.data.result){
              alert('Opération réussie')
            }
          }).catch((err) => {
        console.log('=======err=========')
      });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.showuser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList:function(){
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
          .then(response => {
            if (response.data) {
              this.showAlert()
            }
            this.showAlert()
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
          .then(resultat => {
            if (resultat.status == 200) {
              this.modalDelete = false
              this.getUserList()
            }
          })
          .catch(err => {})
    },

    getUserMenu(id){
      Api().post('/menu/user/menu', {userid:id})
          .then((result) => {
            this.usermenu = result.data.menus
            console.log(result.data.menus)
          }).catch((err) => {
        console.log(err)
      });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    //this.getContrat()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getContrat()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";


.detailsSeeAttest li {
    list-style: none !important;
    line-height: 16px;
    font-family: Arial, sans-serif;
}

.AttestPoliceSize {
    font-size: 12px !important;
}

.detailsSee li {
    list-style: none;
    border-bottom: 1px solid #000;
}


.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
.button-filtre{
  padding: 12px!important;
  margin : 0 15px!important;
  background: #C0C7CF!important;
  border:none!important;
  color:#fff!important;
}
</style>
