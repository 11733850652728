export default class Coupon {
    constructor() {
        this.type = null
        this.reduction = null
        this.du = null
        this.au = null
        this.codep = null
        this.branche = null
        this.assureur = null
        this.courtier = null
        this.withcode = false
        this.createur = null
        this.client = null
        this.plateforme = null
        this.debut_contrat = null
        this.fin_contrat = null
    }
}

