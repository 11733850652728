<template>
    <vue-scroll class="page-devis-voyage" >
      <div class="page-header">
        <h1>Création de devis <span style="color:#ffa409">MRP</span> <span style="font-size : 1rem"> (Multirisque professionnelle)</span></h1>
      </div>
  
      <resize-observer @notify="__resizeHanlder"/>
  
      <div v-if="hidesearchClient &&!isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div style="margin-top: 15px;">
          <h3>Rechercher client</h3>
          <el-row :gutter="15" justify="center">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="10">
              <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
              </el-input>
              <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>
  
        <div style="margin-top: 15px;" v-if="clientOk">
            <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
        </div>
  
        <div style="margin-top: 15px;" v-if="clientnonOk">
            <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
        </div>
      </div>
  
       <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
              <div style="margin-top: 15px;">
                  <h3>Rechercher client</h3>
                <el-row :gutter="15" justify="center">
                  <el-col :span="12">
                    <div class="grid-content bg-purple">
                      <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                      <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="15" style="margin-top:15px">
                  <el-col :span="24">
                    <el-input v-if="NumberInput" type="number" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                      <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                  </el-col>
                    <el-col :span="24" style="margin-top:15">
                     <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                      <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                    </el-col>
                </el-row>
              </div>
  
               <div style="margin-top: 15px;" v-if="clientOk">
                  <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
              </div>
  
              <div style="margin-top: 15px;" v-if="clientnonOk">
                  <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
              </div>
          </div>
  
  
      <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div style="margin-top:15px">
          <h3>Simulation :</h3>
          <el-row :gutter="15" type="flex" class="row-bg" justify="center">
            <el-col :span="24">
              <div>
                <el-form>
                  <el-form-item>
                    <el-radio-group v-model="choix_mrp" @change="switchMrp">
                      <el-radio label="mrp_value">MRP</el-radio>
                      <el-radio label="mrp_rc_value">MRP + Responsabilité Civile</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
  
        <div>
          <div v-show="Mrp">
            <h3 style="margin-bottom:-5px">Je crée une MRP en tant :</h3>
            <el-row :gutter="15" type="flex" class="row-bg" justify="center">
              <el-col :span="24">
                  <div>
                    <el-form>
                      <el-form-item>
                        <el-radio-group v-model="qualiteassure" @change="CheckFieldChange()">
                          <el-radio label="locataire">Locataire</el-radio>
                          <el-radio label="occupant">Propriétaire occupant</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-col>
            </el-row>

            <h3 style="margin-bottom:5px">avec les valeurs suivantes :</h3>
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="grid-content bg-purple" v-if="qualiteassure == 'locataire'">
                  <label>Loyer mensuel *</label>
                  <el-input type="number" min="10000" placeholder="Votre loyer mensuel" @change="CheckFieldChange()" v-model="valeur_location">
                  </el-input>
                </div>

                <div class="grid-content bg-purple" v-else>
                    <label>Valeur bâtiment *</label>
                    <el-input type="number" min="10000" placeholder="Valeur de votre bâtiment" @change="CheckFieldChange()" v-model="valeur_proprio">
                    </el-input>
                  </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  <label>Valeur mobilier *</label>
                  <el-input type="number" min="10000" placeholder="Valeur de votre mobilier" @change="CheckFieldChange()" v-model="valeur_mobilier">
                  </el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  <label>Chiffres d'affaires</label>
                  <el-input type="number" min="10000" @change="CheckFieldChange()" placeholder="Valeur de votre chiffres d'affaires" v-model="chiffre_affaire">
                  </el-input>
                </div>
              </el-col>
            </el-row>
  
            <div class="text-center" v-if="monthly_payment != null">
              <h4>Prime annuelle : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
            </div>
          </div>
  
          <div v-show="MrpRc">
            <h3 style="margin-bottom:-5px">Je crée une MRP en tant :</h3>
            <el-row :gutter="15" type="flex" class="row-bg" justify="center">
              <el-col :span="24">
                  <div>
                    <el-form>
                      <el-form-item>
                        <el-radio-group v-model="qualiteassure" @change="CheckFieldChange()">
                          <el-radio label="locataire">Locataire</el-radio>
                          <el-radio label="occupant">Propriétaire occupant</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-col>
            </el-row>
            <h3 style="margin-bottom:5px">avec les valeurs suivantes :</h3>
            <el-row :gutter="20">
                <el-col :span="8">
                    <div v-if="qualiteassure == 'locataire'" class="grid-content bg-purple">
                      <label>Loyer mensuel *</label>
                      <el-input type="number" min="10000" v-model="valeur_locationRc" placeholder="Votre loyer mensuel" @change="CheckFieldChange()">
                      </el-input>
                    </div>
                    <div v-else class="grid-content bg-purple">
                        <label>Valeur bâtiment *</label>
                        <el-input type="number" min="10000" v-model="valeur_proprioRc" placeholder="Valeur de votre bâtiment" @change="CheckFieldChange()">
                        </el-input>
                      </div>
                  </el-col>
                  <el-col :span="8" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                      <label>Valeur mobilier *</label>
                      <el-input type="number" min="10000" v-model="valeur_mobilierRc" placeholder="Valeur de votre mobilier" @change="CheckFieldChange()">
                      </el-input>
                    </div>
                  </el-col>
                  <el-col :span="8" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                      <label>Chiffres d'affaires *</label>
                      <el-input type="number" min="10000" v-model="chiffre_affaireRc" placeholder="Valeur de votre chiffres d'affaires" @change="CheckFieldChange()">
                      </el-input>
                    </div>
                  </el-col>
            </el-row>
  
            <div class="text-center" v-if="monthly_payment != null">
              <h4>Prime annuelle : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
            </div>
          </div>
        </div>
  
        <div style="margin-top:15px">
          <el-button v-if="Simule" @click="fieldsControl" type="primary" round>Simuler</el-button>
          <el-button v-if="SkipSimule" @click="DevisNextStep" type="primary" round>Suivant</el-button>
        </div>
      </div>
  
       <div v-if="e1 == 1 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div style="margin-top:15px">
          <h3>Simulation :</h3>
          <el-row :gutter="15" type="flex" class="row-bg" justify="center">
            <el-col :span="24">
              <div>
                <el-form>
                  <el-form-item>
                    <el-radio-group v-model="choix_mrp" @change="switchMrp">
                      <el-radio label="mrp_value">MRP</el-radio>
                      <el-radio label="mrp_rc_value">MRP + Responsabilité Civile</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
  
        <div>
          <div v-show="Mrp">
            <h3 style="margin-bottom:-5px">Je crée une MRP en tant :</h3>
            <el-row :gutter="20" type="flex" class="row-bg" justify="center">
              <el-col :span="24">
                <div>
                  <el-form>
                    <el-form-item>
                      <el-radio-group v-model="qualiteassure" @change="CheckFieldChange()">
                        <el-radio label="locataire">Locataire</el-radio>
                        <el-radio label="occupant">Propriétaire occupant</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>

            <h3 style="margin-bottom:-10px">avec les valeurs suivantes :</h3>
            <el-row :gutter="20">
                 <el-col :span="24" style="margin-top:15px">
                <div v-if="qualiteassure == 'locataire'" class="grid-content bg-purple">
                  <label>Loyer mensuel *</label>
                  <el-input type="number" min="10000" placeholder="Votre loyer mensuel" @change="CheckFieldChange()" v-model="valeur_location">
                  </el-input>
                </div>

                <div v-else class="grid-content bg-purple">
                  <label>Valeur bâtiment *</label>
                  <el-input type="number" min="10000" placeholder="Valeur de votre bâtiment" @change="CheckFieldChange()" v-model="valeur_proprio">
                  </el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple">
                  <label>Valeur mobilier *</label>
                  <el-input type="number" min="10000" placeholder="Valeur de votre mobilier" v-model="valeur_mobilier" @change="CheckFieldChange()">
                  </el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple">
                  <label>Chiffres d'affaires</label>
                  <el-input type="number" min="10000" placeholder="Valeur de votre chiffres d'affaires" v-model="chiffre_affaire" @change="CheckFieldChange()">
                  </el-input>
                </div>
              </el-col>
            </el-row>
  
            <div class="text-center" v-if="monthly_payment != null">
              <h4>Prime annuelle : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
            </div>
          </div>
  
          <div v-show="MrpRc">
            <h3 style="margin-bottom:-5px">Je crée une MRP en tant :</h3>
            <el-row :gutter="20" type="flex" class="row-bg" justify="center">
              <el-col :span="24">
                <div>
                  <el-form>
                    <el-form-item>
                      <el-radio-group v-model="qualiteassure" @change="CheckFieldChange()">
                        <el-radio label="locataire">Locataire</el-radio>
                        <el-radio label="occupant">Propriétaire occupant</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>
            <h3 style="margin-bottom:-10px">avec les valeurs suivantes :</h3>
            <el-row :gutter="20">
                <el-col :span="24" style="margin-top:15px">
                <div v-if="qualiteassure == 'locataire'" class="grid-content bg-purple">
                  <label>Loyer mensuel *</label>
                  <el-input type="number" min="10000" placeholder="Votre loyer mensuel" v-model="valeur_locationRc" @change="CheckFieldChange()">
                  </el-input>
                </div>
                <div v-else class="grid-content bg-purple">
                    <label>Valeur bâtiment *</label>
                    <el-input type="number" min="10000" placeholder="Valeur de votre bâtiment" v-model="valeur_proprioRc" @change="CheckFieldChange()">
                    </el-input>
                  </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple">
                  <label>Valeur mobilier *</label>
                  <el-input type="number" min="10000" placeholder="Valeur de votre mobilier" v-model="valeur_mobilierRc" @change="CheckFieldChange()">
                  </el-input>
                </div>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple">
                  <label>Chiffres d'affaires *</label>
                  <el-input type="number" min="10000" placeholder="Valeur de votre chiffres d'affaires" v-model="chiffre_affaireRc" @change="CheckFieldChange()">
                  </el-input>
                </div>
              </el-col>
            </el-row>
            <div class="text-center" v-if="monthly_payment != null">
              <h4>Prime annuelle : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
            </div>
          </div>
        </div>
  
        <div style="margin-top:15px">
          <el-button v-if="Simule" @click="fieldsControl" type="primary" round>Simuler</el-button>
          <el-button v-if="SkipSimule" @click="DevisNextStep" type="primary" round>Suivant</el-button>
        </div>
      </div>
  
      <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div>
          <h3>Contractant :</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Nom</label>
                <el-input placeholder="Votre nom" v-model="ContractantFields.Nom">
                </el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <label>Prénom *</label>
                <el-input placeholder="Votre prenom" v-model="ContractantFields.Prenom">
                </el-input>
              </div>
            </el-col>
          </el-row>
  
          <el-row :gutter="20" style="margin-top: 15px">
            <el-col :span="8">
                <div class="grid-content bg-purple">
                  <label>Téléphone *</label>
                  <el-input type="number" placeholder="Votre numéro de téléphone" v-model="ContractantFields.Telephone">
                  </el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content bg-purple-light">
                  <label>Email *</label>
                  <el-input placeholder="Adresse e-mail" v-model="ContractantFields.Email">
                  </el-input>
                </div>
              </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <label>Adresse *</label>
                <el-input type="text" placeholder="Votre adresse" v-model="ContractantFields.Adresse">
                </el-input>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top: 15px">
            <el-col :span="12">
                <div class="grid-content bg-purple">
                  <label>Nom de la société *</label>
                  <el-input placeholder="Nom de votre société" v-model="ContractantFields.nom_societe">
                  </el-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-light">
                  <label>Situation du chantier *</label>
                  <el-input placeholder="Situation de votre chantier" v-model="ContractantFields.situation_chantier">
                  </el-input>
                </div>
              </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top: 15px" v-if="this.choixOffreFinale == 'Mrp_rc_value'">
            <el-col :span="12">
                <div class="grid-content bg-purple">
                  <label>Activite de la société *</label>
                  <el-input type="number" placeholder="Activité de votre société" v-model="ContractantFields.activity_society">
                  </el-input>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple-light">
                  <label>Nombre employé</label>
                  <el-input placeholder="Nombre de vos employés" v-model="ContractantFields.employe_number">
                  </el-input>
                </div>
              </el-col>
          </el-row>
        </div>
  
        <div style="margin-top:15px">
          <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
          <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
        </div>
      </div>
  
       <div v-if="e1 == 2 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div>
          <h3>Contractant :</h3>
          <el-row :gutter="20">
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple">
                <label>Nom *</label>
                <el-input placeholder="Votre nom" v-model="ContractantFields.Nom">
                </el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple-light">
                <label>Prénom *</label>
                <el-input placeholder="Votre prenom" v-model="ContractantFields.Prenom">
                </el-input>
              </div>
            </el-col>
             <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple">
                <label>Téléphone *</label>
                <el-input type="number" placeholder="Votre numéro de téléphone" v-model="ContractantFields.Telephone">
                </el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple-light">
                <label>Email *</label>
                <el-input placeholder="Adresse e-mail" v-model="ContractantFields.Email">
                </el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple-light">
                <label>Adresse *</label>
                <el-input placeholder="Votre adresse" v-model="ContractantFields.Adresse">
                </el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple-light">
                <label>Nom de la société *</label>
                <el-input placeholder="Nom de votre société" v-model="ContractantFields.nom_societe">
                </el-input>
              </div>
            </el-col>

            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple-light">
                <label>Situation du chantier *</label>
                <el-input placeholder="Situation de votre chantier" v-model="ContractantFields.situation_chantier">
                </el-input>
              </div>
            </el-col>

            <el-col :span="24" style="margin-top:15px" v-if="this.choixOffreFinale == 'Mrp_rc_value'">
              <div class="grid-content bg-purple-light">
                <label>Activité de la société *</label>
                <el-input placeholder="Activité de votre société" v-model="ContractantFields.activity_society">
                </el-input>
              </div>
            </el-col>

            <el-col :span="24" style="margin-top:15px" v-if="this.choixOffreFinale == 'Mrp_rc_value'">
              <div class="grid-content bg-purple-light">
                <label>Nombre employé</label>
                <el-input placeholder="Nombre de vos employés" v-model="ContractantFields.employe_number">
                </el-input>
              </div>
            </el-col>
          </el-row>
        </div>
  
        <div style="margin-top:15px">
          <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
          <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
        </div>
      </div>
  
      <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div>
          <el-row :gutter="24">
            <el-col :span="24">
              <div class="item-box card-shadow--medium" style="margin-top:15px">
                <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>Contractant</span>
                        </div>
                        <div  >
                          <ul style="list-style:none;">
                            <li>Nom : <span class="to-right assuraf-orange">{{ContractantFields.Nom}}</span></li>
                            <li>Prénom : <span class="to-right assuraf-orange">{{ContractantFields.Prenom}}</span></li>  
                            <li>Adresse : <span class="to-right assuraf-orange">{{ContractantFields.Adresse}}</span></li>
                            <li>Téléphone : <span class="to-right assuraf-orange">{{ContractantFields.Telephone}}</span></li>
                            <li>Email : <span class="to-right assuraf-orange">{{ContractantFields.Email}}</span></li>
                            <li>Nom société : <span class="to-right assuraf-orange">{{ContractantFields.nom_societe}}</span></li>
                            <li>Situation chantier : <span class="to-right assuraf-orange">{{ContractantFields.situation_chantier}}</span></li>
                            <li v-if="this.choixOffreFinale == 'Mrp_rc_value'">Activité société : <span class="to-right assuraf-orange">{{ContractantFields.activity_society}}</span></li>
                            <li v-if="this.choixOffreFinale == 'Mrp_rc_value'">Nombre employés : <span class="to-right assuraf-orange">{{ContractantFields.employe_number}}</span></li>
                          </ul>
                        </div>
                      </el-card>
                    </div>
                  </el-col>
                 
                 
                </el-row>
              </div>
  
              <div class="item-box card-shadow--medium" style="margin-top:15px">
                <h3 class="text-center">DÉTAILS</h3>
                <el-row :gutter="24" style="margin-top:20px">
                  <el-col :span="24">
                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>Primes</span>
                        </div>
                        <div  >
                          <ul style="list-style:none">
                            <li>Durée : <span class="to-right assuraf-orange">{{duree}} mois</span></li>
                            <li>Prime nette : <span class="to-right assuraf-orange">{{this.prime_nette.toLocaleString()}} CFA</span></li>
                            <li>Taxe : <span class="to-right assuraf-orange">{{this.taxe.toLocaleString()}} CFA</span></li>
                            <li>Accessoires : <span class="to-right assuraf-orange">{{this.accessoire.toLocaleString()}} CFA</span></li>
                            <li>Prime TTC : <span class="to-right assuraf-orange">{{this.monthly_payment.toLocaleString()}} CFA</span></li>
                          </ul>
                        </div>
                      </el-card>
                    </div>
                  </el-col>
                </el-row>
              </div>
  
              <div class="item-box card-shadow--medium" style="margin-top:15px;;margin-left: 20px;margin-right: 20px;">
                <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
                <el-row :gutter="24" style="margin-top:20px">
                  <el-col :span="12">
                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                      <label>Date d'effet</label>
                      <el-date-picker
                          @change="dateDiff"
                          style="width: 100%!important;"
                          v-model="dateEffet"
                          type="date"
                          format="yyyy/MM/dd"
                          placeholder="Choississez une date"
                          :picker-options="dateAfterToday">
                      </el-date-picker>
                    </div>
                  </el-col>
  
                  <el-col :span="12">
                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                      <label>Date d"échéance</label>
                      <el-input placeholder="Date d'échéance" v-model="dateEcheance" readonly>
                      </el-input>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
  
          <div style="margin-top:15px">
            <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
            <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary" round>Valider</el-button>
          </div>
        </div>
      </div>
  
      <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div>
          <el-row :gutter="24">
            <el-col :span="24">
              <div class="item-box card-shadow--medium" style="margin-top:15px">
                <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="grid-content bg-purple">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>Contractant</span>
                        </div>
                        <div  >
                          <ul style="list-style:none;margin-left: -10%;">
                            <li>Nom : <span class="to-right assuraf-orange">{{ContractantFields.Nom}}</span></li>
                            <li>Prénom : <span class="to-right assuraf-orange">{{ContractantFields.Prenom}}</span></li>  
                            <li>Adresse : <span class="to-right assuraf-orange">{{ContractantFields.Adresse}}</span></li>
                            <li>Téléphone : <span class="to-right assuraf-orange">{{ContractantFields.Telephone}}</span></li>
                            <li>Email : <span class="to-right assuraf-orange">{{ContractantFields.Email}}</span></li>
                            <li>Nom société : <span class="to-right assuraf-orange">{{ContractantFields.nom_societe}}</span></li>
                            <li>Situation chantier : <span class="to-right assuraf-orange">{{ContractantFields.situation_chantier}}</span></li>
                            <li v-if="this.choixOffreFinale == 'Mrp_rc_value'">Activité société : <span class="to-right assuraf-orange">{{ContractantFields.activity_society}}</span></li>
                            <li v-if="this.choixOffreFinale == 'Mrp_rc_value'">Nombre employés : <span class="to-right assuraf-orange">{{ContractantFields.employe_number}}</span></li>
                          </ul>
                        </div>
                      </el-card>
                    </div>
                  </el-col>
                 
                 
                </el-row>
              </div>
  
              <div class="item-box card-shadow--medium" style="margin-top:15px">
                <h3 class="text-center">DÉTAILS</h3>
                <el-row :gutter="24" style="margin-top:20px">
                  <el-col :span="24">
                    <div class="grid-content bg-purple">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span>Primes</span>
                        </div>
                        <div  >
                          <ul style="list-style:none;margin-left: -10%;">
                            <li>Durée : <span class="to-right assuraf-orange">{{duree}} mois</span></li>
                            <li>Prime nette : <span class="to-right assuraf-orange">{{this.prime_nette.toLocaleString()}} CFA</span></li>
                            <li>Taxe : <span class="to-right assuraf-orange">{{this.taxe.toLocaleString()}} CFA</span></li>
                            <li>Accessoires : <span class="to-right assuraf-orange">{{this.accessoire.toLocaleString()}} CFA</span></li>
                            <li>Prime TTC : <span class="to-right assuraf-orange">{{this.monthly_payment.toLocaleString()}} CFA</span></li>
                          </ul>
                        </div>
                      </el-card>
                    </div>
                  </el-col>
                </el-row>
              </div>
  
              <div class="item-box card-shadow--medium" style="margin-top:15px">
                <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
                <el-row :gutter="24" style="margin-top:20px">
                  <el-col :span="24">
                    <div class="grid-content bg-purple">
                      <label>Date d'effet</label>
                      <el-date-picker
                          @change="dateDiff"
                          style="width: 100%!important;"
                          v-model="dateEffet"
                          type="date"
                          format="yyyy/MM/dd"
                          placeholder="Choississez une date"
                          :picker-options="dateAfterToday">
                      </el-date-picker>
                    </div>
                  </el-col>
  
                  <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                      <label>Date d"échéance</label>
                      <el-input placeholder="Date d'échéance" v-model="dateEcheance" readonly>
                      </el-input>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
  
          <div style="margin-top:15px">
            <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
            <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary" round>Valider</el-button>
          </div>
        </div>
      </div>
  
      <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
        <el-form ref="form" :model="valid" label-width="120px" label-position="top">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Nom</label>
                <el-input placeholder="Votre nom" v-model="nom"></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <label>Prénom</label>
                <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
              </div>
            </el-col>
          </el-row>
  
          <el-row :gutter="20" style="margin-top:15px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Numéro de téléphone</label>
                <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <label>Adresse e-mail</label>
                <el-input placeholder="votre adresse e-mail" v-model="EmailInput"></el-input>
              </div>
            </el-col>
          </el-row>
  
          <el-row :gutter="20" style="margin-top:15px">
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <label>Sexe</label>
                <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                  <el-option label="Homme" value="homme"></el-option>
                  <el-option label="Femme" value="femme"></el-option>
                  <el-option label="entreprise" value="entreprise"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div style="margin-top:15px">
          <el-button type="primary" round @click="createuser()">Créer</el-button>
        </div>
      </el-dialog>
  
      <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
        <el-form ref="form" :model="valid" label-width="120px" label-position="top">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <label>Nom</label>
                <el-input placeholder="Votre nom" v-model="nom"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple-light">
                <label>Prénom</label>
                <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple">
                <label>Numéro de téléphone</label>
                <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple-light">
                <label>Adresse e-mail</label>
                <el-input placeholder="votre adresse e-mail" v-model="EmailInput"></el-input>
              </div>
            </el-col>
             <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple">
                <label>Sexe</label>
                <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                  <el-option label="Homme" value="homme"></el-option>
                  <el-option label="Femme" value="femme"></el-option>
                  <el-option label="entreprise" value="entreprise"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div style="margin-top:15px">
          <el-button type="primary" round @click="createuser()">Créer</el-button>
        </div>
      </el-dialog>
  
      <el-dialog title="Modalité des prochains paiements" :visible.sync="PaymentFuturMode">
        <el-form ref="form" :model="valid" label-width="120px" label-position="top">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="grid-content bg-purple-light">
                <label>Choix paiement</label>
                <el-select style="width:100%" v-model="PaymentFutur" placeholder="Selectionner une modalité">
                  <el-option value="null" selected disabled="">choix paiement</el-option>
                  <el-option value="cash">Cash</el-option>
                  <el-option value="cheque">Cheque</el-option>
                  <el-option value="prelevement">Prelevement bancaire</el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div style="margin-top:15px">
          <el-button type="primary" round @click="ValidPaymentFutur">Valider</el-button>
        </div>
      </el-dialog>
    </vue-scroll>
  </template>
  
  <script>
  import Chance from "chance"  
  //debut importation assuraf
  import dayjs from 'dayjs'
  import Api from '../../../../services/Api'
  import store from '../../../../store'
  import pays from '../../../../common/countries'
  import configMrpConfig from '../../../../common/mrp_config.json'
  //fin importation assuraf
  import customPassword from "../../../../services/password_generator"
 
  
  const validatePhone = phone => {
    if(!phone.length){
      return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
    }else{
      return { valid: true, error: null };
    }
  
  };
  
  const validateEmail = email => {
    if (!email.length) {
      return {valid: false, error: "L'adresse e-mail est obligatoire!"};
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      return { valid: false, error: "Veuillez entrer un email valide!" };
    } else {
      return { valid: true, error: null };
    }
  };
  
  export default {
    name: "devis_sunu_assur",
  
    data() {
      return {
        mrpRcOption : false,
        test_model : null,
        configMpr : null,
        valeur_location : null,
        valeur_proprio : null,
        chiffre_affaire : null,
        qualiteassure : 'occupant',
        rc : false,
        accessoire : 5000,
        taxe : null, 
        duree : 12,
        prime_nette : null, 
        prime_ttc : null,
        primeRc : null,
        valeur_mobilier : null,

        valeur_locationRc : null,
        valeur_proprioRc : null,
        chiffre_affaireRc : null,
        valeur_mobilierRc : null,


        modalgenerationloading : false,
        isMobile : false,
  
        optionsdate: {
          format: "YYYY/MM/DD",
          useCurrent: false,
          maxDate: new Date(),
        },
        optionsdateEffet: {
          format: "YYYY/MM/DD",
          useCurrent: false,
          minDate: new Date(),
        },
        first_questionnaire: true,
        isCapital: true,
        oldDate: null,
        bottomPushNotif: false,
        disableDateAssure: true,
        Modalite: null,
        Assureur: "",
        Simule: true,
        SkipSimule: false,
        money: {
          thousands: " ",
          precision: false,
          masked: false,
        },
        choixOffre: null,
        choixOffreFinale: null,
        dateNaiss: null,
        Mrp: true,
        MrpRc: false,
        Capi_souhaite: null,
        pourcent: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
        choixCapital: {
          Capital: null,
          Mensuel: null,
        },
        dateEffet: null,
        dateEcheance: null,
        e1: 0,
        elevation: 2,
        activeStep: 0,
        checkbox: false,
        errors: {},
        aStorMap: false,
        disable: false,
        valid: null,
        age: null,
        // Contractant fields
        ContractantFields: {
          Genre: null,
          Situation: null,
          Nom: null,
          Prenom: null,
          Adresse: null,
          Telephone: null,
          Email: null,
          situation_chantier : null,
          nom_societe : null,
          activity_society : null,
          employe_number : null
        },
  
        formSteps: [
          {
            title: "CONTRACTANT",
            fields: [{ valid: true }],
          }
        ],
        CreatedDevis: null,
        usersearch: null,
        QuestionnaireTab: [],
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        choix_mrp : 'mrp_value',
  
        buttons: false,
        buttons_2: false,
        buttons_3: false,
        buttons_4: false,
        buttons_5: false,
        first_page: false,
        second_page: false,
        thirst_page: false,
        fourt_page: false,
        five_page: false,
        questionTest: "",
        PaymentFutur: null,
        PaymentFuturMode: false,
  
  
        // numTelephone : '' ,
        amenities: [1, 4],
        genre: null,
  
        fraisLiv: 0,
        fraisPaiement: 0,
        Testons: false,
        adresselivraison: "",
        autoHeight: true,
        x: null,
        dialogAlert: false,
        Devis_pour_souscription: [],
        mode_livraison: null,
  
        menuDelivrance: false,
        monthly_payment: null,
        countries: null,
        quartier: "",
        listegaranties: [],
        frais_livraison: null,
        branche: null,
        dialog: false,
        checked: false,
  
        animation: "animate-in",
        isContractant: true,
        isAssure: false,
        isBenef: false,
        Questionnaire: false,
        QuestionnaireSuite: false,
        isPayment: false,
        menu: false,
        Capi: 0,
        Mensu: 0,
        dateBeforeToday: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        dateAfterToday: {
          disabledDate(time) {
            return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
          }
        },
        dateAfterDateDepart : {
          disabledDate(time) {
            return time.getTime() > this.dateDepart;
          }
        },
        remboursable:null,
        optionsdateDepart: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
          minDate: new Date(),
        },
  
        optionsdateArrivee: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
          minDate: new Date(),
        },
        PassengerBirthOptionsdate: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        optionsdateUser: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
          defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
          maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
        },
        countriesSn: 'Sénégal',
  
        hideForm: false,
        popupListePassager: false,
        steps: [{
          label: 'Etape 1',
          completed: false,
        },
          {
            label: 'Etape 2',
            completed: false,
          },
          {
            label: 'Etape 3',
            completed: false,
          },
        ],
        clientnonOk: false,
        clientOk: false,
        clientsearch: null,
        adulte_enfant: null,
        seen: true,
        selected: [],
        nexted: false,
        selectpays: '',
        ToastTabSucess: {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 10000,
          fitToScreen: true,
          type: 'success',
          className: 'custom-success-class'
        }, // Customized options for success toast
        ToastTabError: {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 10000,
          fitToScreen: true,
          type: 'error'
        }, // Customized options for error toast
        listpays: pays,
        dateDepart: null,
        pays_domicile: null,
        dateArrivee: null,
        garantie: [],
        assureurs: [],
        listpaysprime: [],
        telephoneclient: '',
        destination: null,
        listeAssureurs: [],
        listvoyage: [],
        listPassager: [],
       
  
        items: [{
          text: 'homme'
        },
          {
            text: 'femme'
          },
        ],
  
        NumberInput : false,
        EmailInput : false,
  
        radio: '1',
  
  
  
  
        optionsdateMEC: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
          maxDate: new Date(),
        },
        emailclient: null,
        emailC: this.emailclient,
        telephoneC: this.telephoneclient,
        showId: false,
        showconfirmclient: false,
        recupIdClient: '',
        shownameclient: true,
        hidesearchClient: true,
        nom: null,
        prenom: null,
        email: null,
        profession: null,
        AddUser: false,
        adresse: null,
        piece_identite: null,
        numero_piece: null,
        hideDive_vehicule: null,
  
        password1: null,
  
        garanties: [],
  
  
        voidField: false,
  
        Deb: null,
        selectedcarbu: '',
  
        types: [
          'text',
          'text',
          'number',
        ],
        show: true,
        //fin initialisation des models d'assuraf
        chart: null,
        pie: null,
        gridData: [],
        userRole: null,
      }
    },
    mounted() {
      this.getUserRoles()
      window.addEventListener("resize", this.__resizeHanlder)
    },
  
    methods: {
       getUserRoles(){ 
              Api().get('/roles/all')
              .then(response => {
                  this.listRoles = response.data.AllRoles
                  for(let lr of response.data.AllRoles){
                      if(lr.name=="User"){
                          this.userRole = lr._id
                      }
                  }
              }).catch(err => {console.log("failed getAllUserRoles");})
        },
  
      init() {
          if (window.innerWidth <= 768) this.isMobile = true
      },
      
      formatDate(date) {
        return dayjs(date).format("YYYY-MM-DD");
      },
  
      /*On check le numero de la question dans le tableau avant de pusher*/
      CheckValue(numero) {
        let checker = 0;
        for (let v of this.QuestionnaireTab) {
          if (v.num === numero) {
            checker = 1;
          }
        }
        return checker;
      },
  
    //   DynamicSimule() {
    //     if (this.Mrp) {
    //       if (this.dateNaiss && this.choixCapital.Capital && this.duree) {
    //         this.fieldsControl();
    //       } else {
    //         this.bottomPushNotif = false;
    //       }
    //     } else if (this.MrpRc) {
    //       if (this.dateNaiss && this.choixCapital.Mensuel && this.duree) {
    //         this.fieldsControl();
    //       } else {
    //         this.bottomPushNotif = false;
    //       }
    //     }
    //     //this.Capi.replace
    //   },
  
    /*========== Fonction pour faire choix entre capital ou MrpRc ========*/
    Choix() {
    if ((this.choixOffre = this.Mrp)) {
        this.choixOffreFinale = "Mrp_value";
        this.choixCapital.Mensuel = null;
        this.clearValeurForm;
    } else if ((this.choixOffre = this.MrpRc)) {
        this.choixCapital.Capital = null;
        this.choixOffreFinale = "Mrp_rc_value";
        this.clearValeurForm;
    }
    },

    calculePrimeLocataire(){
        if(this.Mrp == true) {
            // ==================== calcul de la premiere ligne ================
            if(this.valeur_location * 180 <= this.configMrp.mrp_batiment.valeur &&  this.valeur_mobilier <= this.configMrp.mrp_mobilier.valeurDeux) {
                this.prime_nette = 82150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.monthly_payment = (this.prime_nette + this.taxe + this.accessoire)
            } // ==================== calcul de la deuximene ligne ================
            else if(this.valeur_location * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_location * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilier > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilier <= this.configMrp.mrp_mobilier.valeur) {
                this.prime_nette = 183800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.monthly_payment = (this.prime_nette + this.taxe + this.accessoire)
            } // ==================== calcul de la troisieme ligne ================
            else if(this.valeur_location * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_location * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilier > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilier <= this.configMrp.mrp_mobilier.valeurTrois) {
                this.prime_nette = 221300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.monthly_payment = (this.prime_nette + this.taxe + this.accessoire)
            } else {
                this.$message.error("Veuillez nous consulter !", this.AlertMessage)
                this.monthly_payment = null
            }
        }else if(this.MrpRc == true) {
            // ==================== calcul de la premiere ligne ================
            if(this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurDeux && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurOne) {
                this.prime_nette = 132150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurOne
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurDeux && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurOne && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurTwo) {
                this.prime_nette = 157150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurOne
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            }else if(this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurDeux && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurTwo && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurThirt) {
                this.prime_nette = 182150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurOne
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } // ==================== calcul de la deuxieme ligne ================
            else if(this.valeur_locationRc * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeur && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurOne) {
                this.prime_nette = 233800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurDeux
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_locationRc * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeur && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurOne && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurTwo) {
                this.prime_nette = 258800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurDeux
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_locationRc * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeur && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurTwo && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurThirt) {
                this.prime_nette = 283800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurDeux
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } 
            // ==================== calcul de la troisieme ligne ================
            else if(this.valeur_locationRc * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurTrois && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurOne) {
                this.prime_nette = 271300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurTrois
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_locationRc * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurTrois && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurOne && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurTwo) {
                this.prime_nette = 296300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurTrois
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_locationRc * 180 > this.configMrp.mrp_batiment.valeur && this.valeur_locationRc * 180 <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurTrois && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurTwo && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurThirt) {
                this.prime_nette = 321300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurTrois
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            }else  {
                this.$message.error("Veuillez nous consulter !", this.AlertMessage)
                this.monthly_payment = null
            }
        }
    },

    calculePrimeProprio(){
        if(this.Mrp == true) {
                // ====================== calcul de la premiere ligne ================
            if(this.valeur_proprio <= this.configMrp.mrp_batiment.valeur &&  this.valeur_mobilier <= this.configMrp.mrp_mobilier.valeurDeux) {
                this.prime_nette = 82150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.monthly_payment = (this.prime_nette + this.taxe + this.accessoire)
            } // ==================== calcul de la deuximene ligne ================
            else if(this.valeur_proprio > this.configMrp.mrp_batiment.valeur && this.valeur_proprio <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilier > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilier <= this.configMrp.mrp_mobilier.valeur) {
                this.prime_nette = 183800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.monthly_payment = (this.prime_nette + this.taxe + this.accessoire)
            } // ==================== calcul de la troisieme ligne ================
            else if(this.valeur_proprio > this.configMrp.mrp_batiment.valeur && this.valeur_proprio <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilier > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilier <= this.configMrp.mrp_mobilier.valeurTrois) {
                this.prime_nette = 221300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.monthly_payment = (this.prime_nette + this.taxe + this.accessoire)
            } else {
                this.$message.error("Veuillez nous consulter !", this.AlertMessage)
                this.monthly_payment = null
            }
        }else if(this.MrpRc == true) {
            // ==================== calcul de la premiere ligne ================
            if(this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurDeux && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurOne) {
                this.prime_nette = 132150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurOne
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurDeux && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurOne && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurTwo) {
                this.prime_nette = 157150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurOne
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            }else if(this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurDeux && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurTwo && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurThirt) {
                this.prime_nette = 182150;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurOne
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } // ==================== calcul de la deuxieme ligne ================
            else if(this.valeur_proprioRc > this.configMrp.mrp_batiment.valeur && this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeur && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurOne) {
                this.prime_nette = 233800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurDeux
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_proprioRc > this.configMrp.mrp_batiment.valeur && this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeur && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurOne && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurTwo) {
                this.prime_nette = 258800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurDeux
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_proprioRc > this.configMrp.mrp_batiment.valeur && this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeurDeux && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeur && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurTwo && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurThirt) {
                this.prime_nette = 283800;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurDeux
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } 
            // ==================== calcul de la troisieme ligne ================
            else if(this.valeur_proprioRc > this.configMrp.mrp_batiment.valeur && this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurTrois && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurOne) {
                this.prime_nette = 271300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurTrois
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_proprioRc > this.configMrp.mrp_batiment.valeur && this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurTrois && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurOne && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurTwo) {
                this.prime_nette = 296300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurTrois
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else if(this.valeur_proprioRc > this.configMrp.mrp_batiment.valeur && this.valeur_proprioRc <= this.configMrp.mrp_batiment.valeurDeux && this.valeur_mobilierRc > this.configMrp.mrp_mobilier.valeur && this.valeur_mobilierRc <= this.configMrp.mrp_mobilier.valeurTrois && this.chiffre_affaireRc > this.configMrp.mrp_ca.valeurTwo && this.chiffre_affaireRc <= this.configMrp.mrp_ca.valeurThirt) {
                this.prime_nette = 321300;
                this.taxe = (this.prime_nette + this.accessoire) * 14 / 100
                this.primeRc = this.configMrp.mrp_rc.valeurTrois
                this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
            } else {
                this.$message.error("Veuillez nous consulter !", this.AlertMessage)
                this.monthly_payment = null
            }
        }
    },
  
      /*===Send of data to API allowing create proposition for the customer===*/
      CreateDevis: function () {
        let loyer_mensuel;

        if (this.dateEffet) {

            if(this.qualiteassure == 'locataire') {
                loyer_mensuel =  this.valeur_location
            }else {
                loyer_mensuel = null;
            }

              const Mrp = {
                souscripteur: this.ContractantFields,
                Modalite: this.Modalite,
                duree: this.duree,
                branche: "Multirisque Professionnelle",
                assureurs: "FINAFRICA",
                userid: this.usersearch._id, //store.state.user._id,
                clientid: this.usersearch._id, //store.state.user._id,
                createur: store.state.user._id,
                dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
                dateEcheance: this.dateEcheance,
                dateSouscription: new Date(),
                mrpRc : this.mrpRcOption,
                taxe : this.taxe,
                accessoires : this.accessoire,
                prime_nette : this.prime_nette,
                prime_ttc: this.monthly_payment,
                garanties :['INCENDIE', 'VOL', 'BRIS DE GLACES','DEGAT DES EAUX'],
                valeur_batiment : this.valeur_proprio,
                loyer_mensuel : loyer_mensuel,
                ca : this.chiffre_affaire,
                valeur_mobilier : this.valeur_mobilier,
                FuturPaiement: null,
                plateforme : 'Dash',
              };
  
              if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
                Sunu.courtierid = this.$store.state.user.societe;
              }
              console.log('::::::::::::::: MRP Data :::::::::::::::::', Mrp)
  
              Api()
                  .post("/mrp/create/devis", Mrp)
                  .then((result) => {
                    Api()
                        .get("devis/" + result.data.devis._id)
                        .then((response) => {
                          this.modalgenerationloading = true
                          this.$store.dispatch("setDevisPayement", response.data);
                           setTimeout(() => (
                                this.modalgenerationloading = false,
                                this.$router.go(this.$router.push({
                                name: 'paiement'
                            }))), 4000
                            );
                        });
                  })
                  .catch((err) => {
                    console.log("error");
                  });
            
          
        } else {
          this.$message.error('Date d\'effet obligatoire !', this.AlertMessage)
        }
      },
  
      //Control des champs pour validation
      fieldsControl() {
        this.Choix();
        if (this.choixOffreFinale == "Mrp_value") {
            if(this.qualiteassure == 'occupant') {
                if(this.valeur_proprio ){
                    if (this.valeur_mobilier){
                        this.calculePrimeProprio();
                        if(this.monthly_payment != null) {
                            this.SkipSimule = true,
                            this.Simule = false,
                            this.branche = 'MRP'
                            this.Assureur = 'FINAFRICA'
                        }else {
                            this.SkipSimule = false,
                            this.Simule = true,
                            this.branche = 'MRP'
                            this.Assureur = 'FINAFRICA'
                        }
                    } else {
                        this.$message.error('Veuillez renseigner la valeur du mobilier!', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuillez renseigner la valeur du bâtiment!', this.AlertMessage)
                }
            }else if(this.qualiteassure == 'locataire') {
                if(this.valeur_location ){
                    if (this.valeur_mobilier){ 
                            this.calculePrimeLocataire();
                            if(this.monthly_payment != null) {
                                this.SkipSimule = true,
                                this.Simule = false,
                                this.branche = 'MRP'
                                this.Assureur = 'FINAFRICA'
                            }else {
                                this.SkipSimule = false,
                                this.Simule = true,
                                this.branche = 'MRP'
                                this.Assureur = 'FINAFRICA'
                            }
                    } else {
                        this.$message.error('Veuillez renseigner la valeur du mobilier!', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuillez renseigner le loyer mensuel !', this.AlertMessage)
                }
            }
        } else if (this.choixOffreFinale == "Mrp_rc_value") {
            if(this.qualiteassure == "occupant") {
                if(this.valeur_proprioRc ){
                    if(this.valeur_mobilierRc) {
                        if (this.chiffre_affaireRc ){
                            this.calculePrimeProprio ();
                            if(this.monthly_payment != null) {
                                this.SkipSimule = true,
                                this.Simule = false,
                                this.branche = 'MRP'
                                this.Assureur = 'FINAFRICA'
                            }else {
                                this.SkipSimule = false,
                                this.Simule = true,
                                this.branche = 'MRP'
                                this.Assureur = 'FINAFRICA'
                            }
                        } else {
                            this.$message.error("Veuiller renseigner le chiffre d'affaires !", this.AlertMessage)
                        }
                    } else {
                        this.$message.error('Veuillez renseigner la valeur du mobilier !', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Veuiller renseigner la valeur du bâtiment !', this.AlertMessage)
                }
            }else if(this.qualiteassure == 'locataire') {
                if(this.valeur_locationRc ){
                    if(this.valeur_mobilierRc) { 
                        if (this.chiffre_affaireRc){
                            this.calculePrimeLocataire();
                            if(this.monthly_payment != null) {
                                this.SkipSimule = true,
                                this.Simule = false,
                                this.branche = 'MRP'
                                this.Assureur = 'FINAFRICA'
                            }else {
                                this.SkipSimule = false,
                                this.Simule = true,
                                this.branche = 'MRP'
                                this.Assureur = 'FINAFRICA'
                            }
                        } else {
                            this.$message.error("Veuiller renseigner le chiffre d'affaires !", this.AlertMessage)
                        }
                    } else {
                        this.$message.error('Veuiller renseigner la valeur du mobilier !', this.AlertMessage)
                    }
            } else {
                this.$message.error('Veuiller renseigner le loyer mensuel !', this.AlertMessage)
            }
            }
        }
      },
  
      CheckFieldChange() {
        console.log('show simule button')
        /* if(this.dateNaiss = !this.dateNaiss){*/
        this.Simule = true;
        this.SkipSimule = false;
        /*}*/
        //this.DynamicSimule();
      },
  
      DevisNextStep() {
        // const date1 = dayjs(this.BenefiFields.dateNaissance);
        // const date2 = dayjs(new Date().toISOString().substr(0, 10));
        // let daydiff = date2.diff(date1, "days");
        // let year = Math.floor(daydiff / 360);
        // this.oldDate = this.dateNaiss;
  
        // let toDay = year;
        // let dureeTotal = Number(this.duree);

        // if (this.choixOffreFinale == "Mrp_value") {
          if (this.monthly_payment >= 10000) {
            
                if (store.state.isUserLoggedIn) {
                  this.ContractantFields.Nom = this.usersearch.nom;
                  this.ContractantFields.Prenom = this.usersearch.prenom;
                  this.ContractantFields.Telephone = this.usersearch.telephone_port;
                  this.ContractantFields.Email = this.usersearch.email;
                  this.ContractantFields.Adresse = this.$store.state.user.adresse;
                  this.e1 = 2;
                  //this.ContractantFields.dateNaissance = this.dateNaiss;
                } else {

                }
          } else {
            this.$message.error("La prime doit etre au minimum 10 000 CFA !", this.AlertMessage)
          }
        // } else if (this.choixOffreFinale == "Mrp_rc_value") {
        // //   if (this.BenefiFields.dateNaissance) {
        // //     if (dureeTotal + toDay <= 21) {
        //       if (store.state.isUserLoggedIn) {
        //         this.ContractantFields.Nom = this.usersearch.nom;
        //         this.ContractantFields.Prenom = this.usersearch.prenom;
        //         this.ContractantFields.Telephone = this.usersearch.telephone_port;
        //         this.ContractantFields.Email = this.usersearch.email;
        //         this.ContractantFields.Adresse = this.$store.state.user.adresse;
        //         this.e1 = 2;
        //       }
        // //     } else {
        // //       this.$message.error("Revoir la durée de cotisation. L’âge du bénéficiaire ne doit pas dépasser 21 ans au terme du contrat !", this.AlertMessage)
        // //     }
        // //   } else {
        // //     this.$message.error("Date bénéficiaire obligatoire !", this.AlertMessage)
        // //   }
        // }
      },
      switchMrp() {
       if (this.choix_mrp == 'mrp_value'){
          this.switchMrpStep()
        } else if(this.choix_mrp == 'mrp_rc_value') {
          this.switchMrpRcStep()
        }
      },
  
      switchMrpStep() {
        this.Choix();
        this.Mrp = true;
        this.MrpRc = false;
        this.monthly_payment = null;
        //this.Mensu = 0;
        // this.isCapital = true;
        // this.ChoixCapital.Capital = null;
        this.CheckFieldChange();
        this.clearValeurForm();
      },
  
      switchMrpRcStep() {
        this.Choix();
        this.MrpRc = true;
        this.Mrp = false;
        this.monthly_payment = null;
        //this.Capi = 0;
        this.CheckFieldChange();
        this.clearValeurForm();
        
      },
  
      // fonction pour formattage de Date (:-)
      DateFormater: function (TheDate) {
        if (TheDate) {
          return dayjs(TheDate).format("DD-MM-YYYY");
        }
      },
  
      toggleCheckbox() {
        if (this.checkbox == true) {
          this.AssureFields.Nom = this.ContractantFields.Nom;
          this.AssureFields.Prenom = this.ContractantFields.Prenom;
          this.AssureFields.Profession = this.ContractantFields.Profession;
          this.AssureFields.Adresse = this.ContractantFields.Adresse;
          this.AssureFields.Telephone = this.ContractantFields.Telephone;
          this.AssureFields.Email = this.ContractantFields.Email;
          this.disableDateAssure = true;
  
          //this.AssureControl();
          //this.DynamicSimule();
        } else if (this.checkbox == false) {
          //this.disableDateAssure = false
          //this.dateNaiss = null
          this.AssureFields.dateNaissance = this.oldDate;
          this.dateNaiss = this.oldDate;
          //this.DynamicSimule();
          //this.AssureFields.dateNaissance = this.dateNaiss
          this.ClearForm();
        }
      },
      clearValeurForm() {
        this.chiffre_affaire = null;
        this.valeur_location = null
        this.valeur_proprio = null
        this.chiffre_affaire = null
        this.valeur_mobilier = null
        this.valeur_locationRc = null
        this.valeur_proprioRc = null
        this.chiffre_affaireRc = null
        this.valeur_mobilierRc = null
      },

      ClearForm: function () {
        this.AssureFields.Nom = null;
        this.AssureFields.Prenom = null;
        this.AssureFields.LieuNaissance = null;
        this.AssureFields.Profession = null;
        this.AssureFields.Adresse = null;
        this.AssureFields.Telephone = null;
        this.AssureFields.Email = null;
        this.AssureFields.LieuExe = null;
      },
  
      yearsDiff() {
        const date1 = dayjs(this.dateNaiss).year();
        const date2 = new Date().getFullYear();
        let daydiff = date2 - date1;
        this.age = daydiff;
  
        if (this.age < 0) {
          this.age = null;
          this.$toasted.show(
              "Erreur, date antérieure à la date actuelle! ",
              this.ToastTabError
          );
          this.dateNaiss = null;
        } else if (this.age) {
          if (this.age < 18 || this.age <= 0) {
            this.$toasted.show(
                "Erreur, l'age doit pas être Supérieur à 18 ans! ",
                this.ToastTabError
            );
            this.dateNaiss = null;
          } else if (this.age > 55) {
            this.$toasted.show(
                "L'année de naissance doit être inferieur ou égale à 55 ans!",
                this.ToastTabError
            );
            this.dateNaiss = null;
          }
        } else if (this.age <= 0) {
          if (this.age < 18 || this.age <= 0) {
            this.$toasted.show(
                "Erreur, l'age doit pas etre Supérieur à 18 ans! ",
                this.ToastTabError
            );
            this.dateNaiss = "";
          }
        }
        this.CheckFieldChange();
      },
  
      yearsDiffContractant() {
        const date1 = dayjs(this.ContractantFields.dateNaissance);
        const date2 = dayjs(new Date().toISOString().substr(0, 10));
        let daydiff = date2.diff(date1, "days");
        this.age = daydiff;
  
        if (this.age < 0) {
          this.age = null;
          this.$toasted.show(
              "Erreur, date antérieure à la date actuelle! ",
              this.ToastTabError
          );
          this.ContractantFields.dateNaissance = null;
        } else if (this.age) {
          let year = Math.floor(this.age / 360);
          this.age = year;
          if (this.age < 18 || this.age <= 0) {
            this.$toasted.show(
                "Erreur, l'age doit pas etre supperieur à 18 ans! ",
                this.ToastTabError
            );
            this.ContractantFields.dateNaissance = null;
          }
        } else if (this.age <= 0) {
          let year = Math.floor(this.age / 360);
          this.age = year;
          if (this.age < 18 || this.age <= 0) {
            this.$toasted.show(
                "Erreur, l'age doit pas etre supperieur à 18 ans! ",
                this.ToastTabError
            );
            this.ContractantFields.dateNaissance = null;
          }
        }
      },
  
      /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
      onlyNumber($event) {
        let NumOnly = $event.NumOnly ? $event.NumOnly : $event.which;
        if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
          $event.preventDefault();
        }
      },
  
      dateDiff: function () {
        if (this.dateEffet === "" && this.duree === "") this.dateEcheance = "";
        let inter = dayjs(this.dateEffet)
            .add(this.duree, "month")
            .format("YYYY-MM-DD");
        this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
      },
  
      isMobile: function () {
        if (screen.width <= 760) {
          return true;
        } else {
          return false;
        }
      },
  
      isDesktop: function () {
        if (screen.width > 768) {
          return true;
        } else {
          return false;
        }
      },
  
      nextStep() {
        this.animation = "animate-out";
        setTimeout(() => {
          this.animation = "animate-in";
          this.activeStep += 1;
          (this.isContractant = false), (this.isAssure = true);
        }, 600);
      },
  
      nextStep2() {
        this.animation = "animate-out";
        setTimeout(() => {
          this.animation = "animate-in";
          this.activeStep += 1;
          (this.isContractant = false),
              (this.isAssure = false),
              (this.isBenef = true);
        }, 600);
      },
  
      nextStep3() {
        this.animation = "animate-out";
        setTimeout(() => {
          this.animation = "animate-in";
          this.activeStep += 1;
          (this.isContractant = false),
              (this.isAssure = false),
              (this.isBenef = false),
              (this.QuestionnaireSuite = true);
        }, 600);
      },
  
      nextStep4() {
        this.animation = "animate-out";
        setTimeout(() => {
          this.animation = "animate-in";
          this.activeStep += 1;
          (this.isContractant = false),
              (this.isAssure = false),
              (this.isBenef = false),
              (this.QuestionnaireSuite = false);
          this.isPayment = true;
        }, 600);
      },
  
      ContractControl() {
          if (this.ContractantFields.Nom && this.ContractantFields.Prenom) {
                if (this.ContractantFields.Adresse) {
                    if (this.ContractantFields.Telephone && this.ContractantFields.Email) {
                        if(this.ContractantFields.situation_chantier && this.ContractantFields.nom_societe) {
                            if(this.choixOffreFinale == 'Mrp_rc_value') {
                                if(this.ContractantFields.activity_society) {
                                    this.e1 = 3;
                                }else {
                                    this.$message.error('Activité de la société obligatoire!', this.AlertMessage)
                                }
                                } else  {
                                    this.e1 = 3;
                                }
                        } else  {
                            this.$message.error('Nom et situation de la société obligatoires!', this.AlertMessage)
                        }

                    } else {
                        this.$message.error('Téléphone et Email obligatoires !', this.AlertMessage)
                    }
                } else {
                  this.$message.error('Adresse obligatoire !', this.AlertMessage)
                }
          } else {
            this.$message.error('Nom et Prénom obligatoires !', this.AlertMessage)
          }
      },
  
      validField() {
        if (this.question_7.principal_response === "oui") {
          if (this.question_7.resp_date) {
            this.QuestionnaireTab.push(this.question_7);
            this.e1 = 6;
            this.nextStep4();
            this.bottomPushNotif = false;
          } else {
            this.$toasted.show("Champ obligatoire! ", this.ToastTabError);
          }
        } else if (this.question_7.principal_response === "non") {
          this.QuestionnaireTab.push(this.question_7);
          this.e1 = 6;
          this.nextStep4();
          this.bottomPushNotif = false;
        }
      },
  
      generatePassword: function () {
         this.password1 = customPassword.customPassword(this.nom, this.prenom)
      },
  
  
      nextPage() {
        this.second_page = true;
        this.first_page = false;
        this.QuestionNext.Q8 = true;
      },
  
      nextPageToThirst() {
        this.second_page = false;
        this.first_page = false;
        this.thirst_page = true;
        this.QuestionNext.Q15 = true;
      },
  
      nextPageToFourt() {
        this.second_page = false;
        this.first_page = false;
        this.thirst_page = false;
        this.fourt_page = true;
        this.QuestionNext.Q22 = true;
      },
  
      nextPageToFive() {
        this.second_page = false;
        this.first_page = false;
        this.thirst_page = false;
        this.fourt_page = false;
        this.five_page = true;
        this.QuestionNext.Q29 = true;
      },
  
      ValidPaymentFutur() {
        if (this.PaymentFutur) {
          this.PaymentFuturMode = false;
        } else {
          this.$toasted.show("Choix obligatoire !", this.ToastTabError);
        }
      },
  
      getListePays: function () {
        Api().get('/pays')
            .then(response => {
              this.listpays = response.data
            })
            .catch(err => {
              err
            })
      },
  
      remplissage_passager() {
        this.popupListePassager = true
      },
  
      DurationCalculation() {
        this.dateEffet = this.dateDepart
        this.dateEcheance = this.dateArrivee
        let date_tmp =  dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
        this.duree = date_tmp + 1  //dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
      },
  
  
  
  
  
  
  
      /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
      onlyNumber($event) {
        let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
        if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
          $event.preventDefault();
        }
      },
      AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
      errorNotif(){
        this.$notify({
          title: 'Error',
          message: 'Numéro obligatoire !',
          position: 'top-right',
          type: 'error'
        });
      },
      errorNotifCreateUser(){
        this.$notify({
          title: 'Error',
          message: 'Tout les Champs sont obligatoires !',
          position: 'top-right',
          type: 'error'
        });
      },
      successNotifCreateUser(){
        this.$notify({
          title: 'Success',
          message: 'Utilisateur crée avec succés !',
          position: 'top-right',
          type: 'succes'
        });
      },
      check_client_search(){
        if(this.clientsearch == 'téléphone'){
          this.NumberInput = true
          this.EmailInput = false
          this.emailclient = null
          this.clientOk = false
          this.clientnonOk = false
        } else if(this.clientsearch == 'email'){
          this.NumberInput = false
          this.EmailInput = true
          this.telephoneclient = null
          this.clientOk = false
          this.clientnonOk = false
        }
      },
      isMobile: function () {
        if (screen.width <= 760) {
          return true
        }else {
          return false
        }
      },
  
      rechercherClient() {
        if(this.telephoneclient){
          const validPhone = validatePhone(this.telephoneclient);
          this.errors.phone = validPhone.error;
  
          if(validPhone.valid == true){
            Api().get('/user/phone/' + this.telephoneclient)
                .then(resultat => {
                  if (resultat.data.telephone_port === this.telephoneclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)
                  }
  
                })
                .catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.telephoneC = this.telephoneclient;
                })
          } else {
            this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
          }
        }else {
          this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
        }
  
  
      },
  
      rechercheClientByMail(){
        if(this.emailclient){
  
          const validEmail = validateEmail(this.emailclient);
          this.errors.email = validEmail.error;
  
          if(validEmail.valid == true){
            Api().get('/user/email/' +this.emailclient)
                .then(resultat =>{
                  if (resultat.data.email === this.emailclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)
                  }
                }).catch(e => {
              this.clientnonOk = true
              this.clientOk = false
              this.emailC = this.emailclient;
            })
          } else {
            this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
          }
        }else {
          this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)
  
        }
      },
  
      yearsDiff() {
        const date1 = dayjs(this.dateCircule);
        const date2 = dayjs(new Date().toISOString().substr(0, 10));
        let daydiff = date2.diff(date1, 'days');
        this.age = daydiff
  
        if (this.age <= 29) {
          this.an = this.annee_mois = 'jour(s)'
        } else if (this.age >= 30, this.age <= 360) {
          let year = Math.floor(this.age / 30)
          this.age = year;
          this.an = this.annee_mois = 'mois'
        } else if (this.age >= 360) {
          let year = Math.floor(this.age / 360)
          this.age = year;
          this.an = this.annee_mois = 'an(s)'
        }
  
      },
  
      showidclient() {
        this.showId = false
        this.showconfirmclient = true
        this.shownameclient = false
  
      },
  
      hidesearch() {
        this.hidesearchClient = false
        this.e1 = 1
      },
  
      rechercherbyphone() {
        if (this.usersearch.telephone_port === this.telephoneclient) {
          this.clientOk = true
          this.clientnonOk = false
  
        }
        if (this.usersearch.telephone_port !== this.telephoneclient) {
          this.clientnonOk = true
          this.clientOk = false
          this.telephoneC = this.telephoneclient;
  
        }
      },
  
      onReset(evt) {
        evt.preventDefault();
        this.user.email = '';
        this.user.name = '';
        this.user.food = null;
        this.user.checked = [];
        this.show = false;
        this.$nextTick(() => {
          this.show = true
        });
      },
  
  
      //liste de touts les partenaires
      getListePartenaire: function ()
      {
        if (this.$store.state.user.role == 'Agent')
        {
          Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Automobile"})
              .then(response => {
                this.listeAssureurs = response.data.partenaires[0].assureurs
              }).catch(err => {})
        } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin')
        {
          Api().get('/partenaire/assureur')
              .then(response => {
                this.listeAssureurs = response.data.partenaires
              })
        } else if (this.$store.state.user.role == 'assureur')
        {
          Api().get('/partenaire/' + this.$store.state.user.societe)
              .then(response => {
                this.listeAssureurs = response.data.partenaire
              })
        }
  
      },
  
      HubspotSend(param)
      {
        let data = {
          "email": param.email,
          "firstname": param.prenom,
          "lastname": param.nom,
          "phone": param.telephone_port,
          "inscrit": "Dash"
        };
        Api().post('/testimonial/hubspot', data)
            .then(resp => {
              (resp.data)
            })
            .catch(e => {
              (e)
            })
      },
  
      MailChimpInscriptionCompte(param)
      {
        let data = {
          "email": param.email,
          "FNAME": param.prenom,
          "LNAME": param.nom,
          "PHONE": param.telephone_port,
        };
        Api().post('/testimonial/mailchimp', data)
            .then(resp => {
              (resp.data)
            })
            .catch(e => {
              (e)
            })
      },
      // Creation d'un user avant de faire son devis
      createuser: function () {
  
        this.generatePassword()
        if (this.nom && this.prenom && this.EmailInput && this.genre && this.telephoneclient)
        {
          const user = {
            nom: this.nom,
            prenom: this.prenom,
            datenaissance: this.datenaissance,
            profession: this.profession,
            adresse: this.adresse,
            sexe: this.genre,
            telephone_port: this.telephoneclient,
            email: this.EmailInput,
            password: this.password1,
            confirmPass: this.password1,
            role: this.userRole,
            createdby:store.state.user._id,
            photo: this.file,
            piece_identite: this.piece_identite,
            numero_piece: this.numero_piece,
            plateforme : 'Dash',
            created: dayjs(new Date()).format('YYYY-MM-DD')
          }
          Api().post('user/register', user)
              .then(resultat => {
                  this.HubspotSend(user)
                  this.MailChimpInscriptionCompte(user)
                if (resultat.status == 200) 
                {
                  if(resultat.data.numero){
                      this.$message.error(resultat.data.msg, this.AlertMessage)
                  }else{
                    this.$store.dispatch('setClient', resultat.data.data)
                    this.usersearch = resultat.data.data
                    this.AddUser = false
                    this.clientnonOk = false
                    this.hidesearchClient = false
                    this.e1 = 1
                  }
                } else {
                  this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                }
  
              })
              .catch(err => {
                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
              })
  
        } else {
          this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
        }
  
      },
  
  
  
  
      testVar:function(val){
        if (val!=null)
        {
          return Number(val.replace(/[^0-9.-]+/g, ""));
        } else {
          return "0";
        }
      },
  
  
  
      __resizeHanlder: _.throttle(function(e) {
        if (this.chart) {
          this.chart.resize()
        }
        if (this.pie) {
          this.pie.resize()
        }
      }, 700)
    },
    created() {
          this.init()
          this.configMrp = configMrpConfig[0].mrp
      },
  
    watch : {
      usersearch(val)
      {
        if(val!=null){
          this.dateNaiss = val.datenaissance
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import "../../../assets../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  
  .page-ecommerce-dashboard {
    .widget {
      .icon-box {
        font-size: 30px;
      }
    }
  
    .table-box {
      .item-box {
        &.item-product {
          .product-image {
            width: 50px;
            margin-right: 15px;
            float: left;
  
            img {
              width: 100%;
            }
          }
        }
  
        &.item-status {
          padding: 5px 10px;
  
          &.status- {
            &Complete {
              background: rgba(44, 196, 120, 0.25);
            }
            &Pending {
              background: rgba(247, 186, 42, 0.25);
            }
            &Returned {
              background: rgba(243, 24, 71, 0.25);
            }
            &Paid {
              background: rgba(45, 109, 211, 0.25);
            }
          }
        }
      }
    }
  }
  </style>
  