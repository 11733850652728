<template>
    <vue-scroll class="page-devis-habitaion" >
        <div class="page-header">
			<h1>Création de devis <span style="color:#ffa409">Manuel</span></h1>
		</div>

        <resize-observer @notify="__resizeHanlder"/>

        <div v-if="hidesearchClient && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="10">
                  <el-input v-if="NumberInput" type="number" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
              </el-row>
            </div> 

            <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="24">
                  <el-input v-if="NumberInput" type="number" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                  <el-col :span="24" style="margin-top:15">
                   <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  </el-col>
              </el-row>
            </div>

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>
        
        <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <h3>Vous souhaitez une assurance pour :</h3>
                 <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="8">
                        <div>
                            <label>Branche</label>
                            <el-select style="width: 100%!important;" v-model="branche" placeholder="Selectionner">
                                <el-option v-for="(b, i) in listBranche"
                            :key="i"
                            :label="b.branche"
                            :value="b.branche"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <label>Compagnie</label>
                             <el-select style="width: 100%!important;" v-model="assureur" placeholder="Selectionner">
                                <el-option v-for="(a, i) in listeAssureurs"
                            :key="i"
                            :label="a.societe"
                            :value="a.societe"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <label>Prime Nette</label>
                            <el-input v-money="money" @change="primeNetteCalculation()" placeholder="Montant du prime" v-model="primeNette">
                             <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
            </el-row>
            <el-row :gutter="15" type="flex" class="row-bg" justify="center" style="margin-top:15px">
                      <el-col :span="8">
                        <div>
                            <label>Accessoires</label>
                            <el-input v-money="money" @change="primeNetteCalculation()" placeholder="Montant des accessoires" v-model="accessoires">
                             <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <label>Taxes</label>
                             <el-input v-money="money" @change="primeNetteCalculation()"  placeholder="Montant des taxes" v-model="taxes">
                              <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <label>Prime TTC</label>
                            <el-input disabled placeholder="Montant du prime TTC" v-model="primettc">
                             <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
            </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button @click="Stepper1Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 1 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <h3>Vous souhaitez une assurance pour :</h3>
                 <el-row :gutter="15">
                      <el-col :span="24">
                        <div>
                            <label>Branche</label>
                            <el-select style="width: 100%!important;" v-model="branche" placeholder="Selectionner">
                                <el-option v-for="(b, i) in listBranche"
                            :key="i"
                            :label="b.branche"
                            :value="b.branche"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div>
                            <label>Compagnie</label>
                             <el-select style="width: 100%!important;" v-model="assureur" placeholder="Selectionner">
                                <el-option v-for="(a, i) in listeAssureurs"
                            :key="i"
                            :label="a.societe"
                            :value="a.societe"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div>
                            <label>Prime Nette</label>
                            <el-input v-money="money" @change="primeNetteCalculation()" placeholder="Montant du prime" v-model="primeNette">
                             <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>

                      <el-col :span="24" style="margin-top:15px!important">
                        <div>
                            <label>Accessoires</label>
                            <el-input v-money="money" @change="primeNetteCalculation()" placeholder="Montant des accessoires" v-model="accessoires">
                             <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div>
                            <label>Taxes</label>
                             <el-input v-money="money" @change="primeNetteCalculation()"  placeholder="Montant des taxes" v-model="taxes">
                              <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div>
                            <label>Prime TTC</label>
                            <el-input disabled placeholder="Montant du prime TTC" v-model="primettc">
                             <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
            </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button @click="Stepper1Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'adulte(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="5">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteHomme" readonly>
                                <el-button @click="decrementAdulteHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteFemme" readonly>
                                <el-button @click="decrementAdulteFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="adulteTotal" readonly>
                                <template slot="append">adulte(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'enfant(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="5">
                        <div class="grid-content bg-purple">
                            <label>Garçon(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantGarcon" readonly>
                                <el-button @click="decrementEnfantGarcon()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantGarcon()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Fille(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantFille" readonly>
                                <el-button @click="decrementEnfantFille()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantFille()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="enfantTotal" readonly>
                                <template slot="append">enfant(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <h3 style="font-weight:400;font-size: 20px;">Nombre de sénior(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="5">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorHomme" readonly>
                                <el-button @click="decrementSeniorHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorFemme" readonly>
                                <el-button @click="decrementSeniorFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="seniorTotal" readonly>
                                <template slot="append">sénior(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <h3 style="font-weight:400;font-size: 20px;">Zone de couverture</h3>
                <div style="margin-top:-20px">
                    <el-radio v-for="item in zoneList" :key="item" v-model="zone" :label="item.value"><span style="position:relative;float:right">{{item.name}}</span></el-radio>
                </div>
                <el-divider></el-divider>
                <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                    <el-descriptions-item label="Nombre d'assurés"><span style="color:#ffa409">{{AllAssure}} personne(s)</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="primettc !== null" label="Prime TTC"><span style="color:#ffa409">{{primettc.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 = 1" round>Retour</el-button>
                <el-button type="primary" @click="Stepper2Validator" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 2 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'adulte(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteHomme" readonly>
                                <el-button @click="decrementAdulteHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteFemme" readonly>
                                <el-button @click="decrementAdulteFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="adulteTotal" readonly>
                                <template slot="append">adulte(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                
                <!-- <h3>Nombre d'enfant(s) :</h3> -->
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'enfant(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Garçon(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantGarcon" readonly>
                                <el-button @click="decrementEnfantGarcon()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantGarcon()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Fille(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantFille" readonly>
                                <el-button @click="decrementEnfantFille()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantFille()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="enfantTotal" readonly>
                                <template slot="append">enfant(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <!-- <h3 v-if="isSenior">Nombre de sénior(s)</h3> -->
                <el-divider></el-divider>
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'adulte(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorHomme" readonly>
                                <el-button @click="decrementSeniorHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorFemme" readonly>
                                <el-button @click="decrementSeniorFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="seniorTotal" readonly>
                                <template slot="append">sénior(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-divider></el-divider>
                <h3 style="font-weight:400;font-size: 20px;">Zone de couverture</h3>
                <div style="margin-top:-20px;display:grid">
                    <el-radio @change="calculPrimeZone()" v-for="item in zoneList" :key="item" v-model="zone" :label="item.value"><span style="position:relative">{{item.name}}</span></el-radio>
                </div>

                <el-divider></el-divider>
                <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px">
                                    <el-descriptions-item label="Nombre d'assurés"><span style="color:#ffa409">{{AllAssure}} personne(s)</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px">
                                <el-descriptions-item v-if="primettc !== null" label="Prime TTC"><span style="color:#ffa409">{{primettc.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 = 1" round>Retour</el-button>
                <el-button @click="Stepper2Validator()" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
               <h3>Validation :</h3>
                <div>
                    <h3>Période de couverture</h3>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label v-if="branche != 'Voyage'">Durée de la garantie (en mois)</label>
                                <label v-else>Durée de la garantie</label>
                                <el-select v-if="branche != 'Voyage'" @change="dateDiff()" style="width:100%" v-model="duree" placeholder="Selectionner">
                                    <el-option  v-for="item in DureeMois" :key="item" :label="item" :value="item"></el-option>
                                </el-select>

                                 <el-input type="number" v-else @change="dateDiff()" placeholder="durée de la garantie" v-model="duree">
                                <template slot="append">jour(s)</template>
                                </el-input>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 1">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="Create_Devis()" type="primary" round>Valider</el-button>
                </div>
           </div>
        </div>

          <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
               <h3>Validation :</h3>
                <div>
                    <h3>Période de couverture</h3>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <label v-if="branche != 'Voyage'">Durée de la garantie (en mois)</label>
                                <label v-else>Durée de la garantie</label>
                                <el-select v-if="branche != 'Voyage'" @change="dateDiff()" style="width:100%" v-model="duree" placeholder="Selectionner">
                                    <el-option  v-for="item in DureeMois" :key="item" :label="item" :value="item"></el-option>
                                </el-select>

                                 <el-input type="number" v-else @change="dateDiff()" placeholder="durée de la garantie" v-model="duree">
                                <template slot="append">jour(s)</template>
                                </el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px!important">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px!important">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 1">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="Create_Devis()" type="primary" round>Valider</el-button>
                </div>
           </div>
        </div>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Genre</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre genre">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px!important">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
                  <el-col :span="24" style="margin-top:15px!important">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px!important">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
                  <el-col :span="24" style="margin-top:15px!important">
                    <div class="grid-content bg-purple">
                        <label>Genre</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre genre">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser">Créer</el-button>
            </div>
        </el-dialog>
    </vue-scroll>
</template>

<script>
import Chance from "chance"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
 if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }
};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire!"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
    name: "devis_manuel",
    
    data() {
        return {
            nbreAdulteHomme : 0,
            nbreAdulteFemme : 0,
            nbreEnfantGarcon : 0,
            nbreEnfantFille : 0,
            nbreSeniorHomme : 0,
            nbreSeniorFemme : 0,
            total : null,
            adulteTotal : null,
            enfantTotal :  null,
            seniorTotal :  null,
            zone : null,
            zoneList: [
                {
                    name: 'Sénégal',
                    value: 'Senegal'
                },
                {
                    name: 'Afrique',
                    value: 'Afrique'
                },
                {
                    name: 'Afrique + UE',
                    value: 'Europe'
                },
                {
                    name: 'Monde (Hors USA)',
                    value: 'Monde'
                }
            ],
            modalgenerationloading : false,
            isMobile : false,
            DureeMois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            branche: null,
            primeNette: null,
            accessoires: null,
            taxes: null,
            primettc: null,
            assureur: null,
            listBranche: null,
            CalculDuree:null,
            dateEffet :null,
            duree :null,
            dateEcheance:null,
            telephoneclient: null,
            emailclient: null,
            emailC: this.emailclient,
            telephoneC: this.telephoneclient,
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            showId: false,
            showconfirmclient: false,
            recupIdClient: "",
            shownameclient: true,
            hidesearchClient: true,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            seen: true,
            genre: null,
            password1: null,
            garanties: [],
            listeAssureurs: [],
            suivant: false,
            errors : {},
             dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            money: {
                thousands: ' ',
                precision: false,
                masked: false
            },
            e1: 0,
            assureurs: [],
            usersearch: null,
            proprietaire: 'ASSURAF',
            usersearch: [],
            NumberInput : false,
            EmailInput : false,
            
            radio: '1',
            voidField: false,
          
            Deb: null,
           
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            userRole:null
        }
    },
    mounted() {
        this.getUserRoles()
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getListePartenaire();
            this.getListebranche()
            this.duree = 1
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
   
    methods: {
        /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'adultes hommes  ----------- */
        //Cette fonction permet d'augmenter le nombre d'hommes adultes
        incrementAdulteHomme () { 
            this.adulteTotal++ 
            this.nbreAdulteHomme++ 
            this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal 
            this.CalculAllAdulte()
        },

        //Cette fonction permet de diminuer le nombre d'hommes adultes
        decrementAdulteHomme () { 
            // Si le nombre est déjà à 0, un message d'erreur est affiché et la valeur est remise à 0
            if(this.nbreAdulteHomme === 0) { 
                this.$message.error("Nombre d'homme ne peut pas être négatif !", this.AlertMessage) 
                this.nbreAdulteHomme = 0
                //Si le nombre d'hommes adultes est nul, le nombre total d'adultes est également mis à null.
                if(this.nbreAdulteFemme == null){ 
                this.adulteTotal = null 
                } 
            //Sinon, le nombre d'hommes adultes et le nombre total d'adultes sont tous deux diminués de 1.
            } else { 
                this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                this.nbreAdulteHomme-- 
                this.adulteTotal-- 
                this.CalculAllAdulte()
            } 
        },
        /* --------- Fin de la fonctions qui gerent la decrementation et l'incrementation du nombre d'adultes hommes  ----------- */

        /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'adultes femmes  ----------- */
        //Cette fonction permet d'augmenter le nombre de femmes adultes
        incrementAdulteFemme () { 
            this.adulteTotal++ 
            this.nbreAdulteFemme++ 
            this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal 
            this.CalculAllAdulte()
        },

        //Cette fonction permet de diminuer le nombre de femmes adultes
        decrementAdulteFemme () { 
            // Si le nombre est déjà à 0, un message d'erreur est affiché et la valeur est remise à 0
            if(this.nbreAdulteFemme === 0) { 
                this.$message.error("Nombre de femmes ne peut pas être négatif !", this.AlertMessage) 
                this.nbreAdulteFemme = 0
                //Si le nombre de femmes adultes est nul, le nombre total d'adultes est également mis à null.
                if(this.nbreAdulteFemme == null){ 
                this.adulteTotal = null 
                } 
            //Sinon, le nombre de femmes adultes et le nombre total d'adultes sont tous deux diminués de 1.
            } else { 
                this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                this.nbreAdulteFemme-- 
                this.adulteTotal-- 
                this.CalculAllAdulte()
            } 
        },
        /* --------- Fin de la fonctions qui gerent la decrementation et l'incrementation du nombre d'adultes femmes  ----------- */

        /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'enfants garcons  ----------- */
        //Cette fonction permet d'augmenter le nombre de garçons enfants
        incrementEnfantGarcon () { 
            this.enfantTotal++ 
            this.nbreEnfantGarcon++ 
            this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal 
            this.CalculAllEnfant()
        },

        //Cette fonction permet de diminuer le nombre de garçons enfants
        decrementEnfantGarcon () { 
            // Si le nombre est déjà à 0, un message d'erreur est affiché et la valeur est remise à 0
            if(this.nbreEnfantGarcon === 0) { 
                this.$message.error("Nombre de garçon ne peut pas être négatif !", this.AlertMessage) 
                this.nbreEnfantGarcon = 0
                //Si le nombre de garçon est nul, le nombre total d'enfants est également mis à null.
                if(this.nbreAdulteFemme == null){ 
                this.enfantTotal = null 
                } 
            //Sinon, le nombre de garçon et le nombre total d'enfants sont tous deux diminués de 1.
            } else { 
                this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                this.nbreEnfantGarcon-- 
                this.enfantTotal-- 
                this.CalculAllEnfant()
            } 
        },
        /* --------- Fin de la fonctions qui gerent la decrementation et l'incrementation du nombre d'enfants garçons  ----------- */
        
        /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'enfants filles  ----------- */
        //Cette fonction permet d'augmenter le nombre de filles enfants
        incrementEnfantFille () { 
            this.enfantTotal++ 
            this.nbreEnfantFille++ 
            this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal 
            this.CalculAllEnfant()
        },

        //Cette fonction permet de diminuer le nombre de filles enfants
        decrementEnfantFille () { 
            // Si le nombre est déjà à 0, un message d'erreur est affiché et la valeur est remise à 0
            if(this.nbreEnfantFille === 0) { 
                this.$message.error("Nombre de fille ne peut pas être négatif !", this.AlertMessage) 
                this.nbreEnfantFille = 0
                //Si le nombre de filles est nul, le nombre total d'enfants est également mis à null.
                if(this.nbreAdulteFemme == null){ 
                this.enfantTotal = null 
                } 
            //Sinon, le nombre de filles et le nombre total d'enfants sont tous deux diminués de 1.
            } else { 
                this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                this.nbreEnfantFille-- 
                this.enfantTotal-- 
                this.CalculAllEnfant()
            } 
        },
        /* --------- Fin de la fonctions qui gerent la decrementation et l'incrementation du nombre d'enfants filles  ----------- */

        /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre de sénior homme  ----------- */
        //Cette fonction permet d'augmenter le nombre de sénior homme
        incrementSeniorHomme () { 
            this.seniorTotal++ 
            this.nbreSeniorHomme++ 
            this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal 
            this.CalculAllSenior()
        },

        //Cette fonction permet de diminuer le nombre de sénior homme
        decrementSeniorHomme () { 
            // Si le nombre est déjà à 0, un message d'erreur est affiché et la valeur est remise à 0
            if(this.nbreSeniorHomme === 0) { 
                this.$message.error("Nombre de sénior homme ne peut pas être négatif !", this.AlertMessage) 
                this.nbreSeniorHomme = 0
                //Si le nombre de sénior homme est nul, le nombre total de sénior est également mis à null.
                if(this.nbreSeniorHomme == null){ 
                this.seniorTotal = null 
                } 
            //Sinon, le nombre de sénior homme et le nombre total séniors sont tous deux diminués de 1.
            } else { 
                this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                this.nbreSeniorHomme-- 
                this.seniorTotal-- 
                this.CalculAllSenior()
            } 
        },
        /* --------- Fin de la fonctions qui gerent la decrementation et l'incrementation du nombre de sénior homme  ----------- */

                /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre de sénior femme  ----------- */
        //Cette fonction permet d'augmenter le nombre de sénior femme
        incrementSeniorFemme () { 
            this.seniorTotal++ 
            this.nbreSeniorFemme++ 
            this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal 
            this.CalculAllSenior()
        },

        //Cette fonction permet de diminuer le nombre de sénior femme
        decrementSeniorFemme () { 
            // Si le nombre est déjà à 0, un message d'erreur est affiché et la valeur est remise à 0
            if(this.nbreSeniorFemme === 0) { 
                this.$message.error("Nombre de sénior femme ne peut pas être négatif !", this.AlertMessage) 
                this.nbreSeniorFemme = 0
                //Si le nombre de sénior femme est nul, le nombre total de sénior est également mis à null.
                if(this.nbreSeniorFemme == null){ 
                this.seniorTotal = null 
                } 
            //Sinon, le nombre de sénior femme et le nombre total de séniors sont tous deux diminués de 1.
            } else { 
                this.AllAssure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                this.nbreSeniorFemme-- 
                this.seniorTotal-- 
                this.CalculAllSenior()
            } 
        },
        /* --------- Fin de la fonctions qui gerent la decrementation et l'incrementation du nombre de sénior femme  ----------- */

        /* --------- Fonctions qui totalise le nombre d'adultes  ----------- */
        CalculAllAdulte(){
            let Adulte
            Adulte = this.nbreAdulteHomme + this.nbreAdulteFemme
            this.total =  Adulte
            this.adulteTotal = this.total
        },

        /* --------- Fonctions qui totalise le nombre d'enfants  ----------- */
        CalculAllEnfant() {
            let Enfant
            Enfant = this.nbreEnfantFille + this.nbreEnfantGarcon
            this.total = Enfant
            this.enfantTotal = this.total
        },

        /* --------- Fonctions qui totalise le nombre de seniors  ----------- */
        CalculAllSenior(){
            let Senior
            Senior = this.nbreSeniorFemme + this.nbreSeniorHomme
            this.total =  Senior
            this.seniorTotal = this.total
        },

        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },

        init() {
        if (window.innerWidth <= 768) this.isMobile = true
        },
          AlertMessage() {
            this.$message({
                showClose: false,
                duration : 5000
            });
            },
    primeNetteCalculation(){
        if(this.primeNette!=null &&this.primeNette!=0 && this.accessoires!=null && this.accessoires!=0 && this.taxes!=null && this.taxes!=0)
        {
            let pNette      = Number(this.primeNette.replace(/[^0-9.-]+/g, ""))
            let access      = Number(this.accessoires.replace(/[^0-9.-]+/g, ""))
            let taxe        = Number(this.taxes.replace(/[^0-9.-]+/g, ""))
            this.primettc   = pNette + access + taxe  
        }
      
    },

    //Send request to the API for quote 
    Create_Devis: function() {
        if(this.CalculDuree && this.dateEffet && this.dateEcheance){
            if(this.branche === 'Santé'){
                const data ={
                    userid: this.usersearch._id,
                    createur: this.$store.state.user._id,
                    proprietaire: {
                        prenom: this.usersearch.prenom,
                        nom: this.usersearch.nom
                    },
                    Assure : {
                        Adulte : {
                            Homme : this.nbreAdulteHomme,
                            Femme : this.nbreAdulteFemme
                        },
                        Enfant : {
                            Garcon : this.nbreEnfantGarcon,
                            Fille : this.nbreEnfantFille,
                        },
                        Senior : {
                            Homme : this.nbreSeniorHomme,
                            Femme : this.nbreSeniorFemme
                        },
                        nombre_assures:  this.nbreAdulteHomme + this.nbreAdulteFemme + this.nbreEnfantGarcon + this.nbreEnfantFille + this.nbreSeniorHomme + this.nbreSeniorFemme
                    },
                    zone: this.zone,
                    branche:this.branche,
                    assureur:this.assureur,
                    dateEffet:dayjs(this.dateEffet).format('YYYY-MM-DD'),
                    dateEcheance:dayjs(this.dateEcheance).format('YYYY-MM-DD'),
                    dateSouscription:dayjs(new Date()).format('YYYY-MM-DD'),
                    prime_nette:Number(this.primeNette.replace(/[^0-9.-]+/g, "")),
                    accessoire:Number(this.accessoires.replace(/[^0-9.-]+/g, "")),
                    taxe:Number(this.taxes.replace(/[^0-9.-]+/g, "")),
                    prime_ttc:this.primettc,
                    plateforme : 'Dash',
                }

                if (this.$store.state.user.role == 'Agent') 
                {
                  data.courtier = this.$store.state.user.societe
                }
                
                Api().post("/assuranceOffline/add_devis", data)
                    .then((result) => {
                        Api().get("devis/" + result.data.devis._id)
                            .then((response) => {
                                this.modalgenerationloading = true
                                this.$store.dispatch("setDevisPayement", response.data);
                                 setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'paiement'
                                }))), 4000
                                );
                        });
                    }).catch((err) => {
                        
                    });
            }else {
                const data ={
                    userid: this.usersearch._id,
                    createur: this.$store.state.user._id,
                    proprietaire: {
                        prenom: this.usersearch.prenom,
                        nom: this.usersearch.nom
                    },
                    branche:this.branche,
                    assureur:this.assureur,
                    dateEffet:dayjs(this.dateEffet).format('YYYY-MM-DD'),
                    dateEcheance:dayjs(this.dateEcheance).format('YYYY-MM-DD'),
                    dateSouscription:dayjs(new Date()).format('YYYY-MM-DD'),
                    prime_nette:Number(this.primeNette.replace(/[^0-9.-]+/g, "")),
                    accessoire:Number(this.accessoires.replace(/[^0-9.-]+/g, "")),
                    taxe:Number(this.taxes.replace(/[^0-9.-]+/g, "")),
                    prime_ttc:this.primettc,
                    plateforme : 'Dash',
                }

                if (this.$store.state.user.role == 'Agent') 
                {
                  data.courtier = this.$store.state.user.societe
                }
                
                Api().post("/assuranceOffline/add_devis", data)
                    .then((result) => {
                        Api().get("devis/" + result.data.devis._id)
                            .then((response) => {
                                this.modalgenerationloading = true
                                this.$store.dispatch("setDevisPayement", response.data);
                                 setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'paiement'
                                }))), 4000
                                );
                        });
                    }).catch((err) => {
                        
                    });
            }
            }else{
                this.$message.error('Tout les champs sont obligatoires !', this.AlertMessage)
            }
      
    },
    //Get All branche list
    getListebranche: function () {
      Api().get("/branche/")
        .then((result) => {
          this.listBranche = result.data;
        }).catch((err) => {});
    },

    RoundToMillion(num) {
        var parts = num.split(" ");
        var fac = Math.pow(1000, parts.length - 1)
        return (Math.ceil(parseInt(parts.join("")) / fac) * fac)
    },

    /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
    onlyNumber($event) {
        let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
        if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
            $event.preventDefault();
        }
    },
        
    AlertMessage() {
        this.$message({
        showClose: false,
        duration : 5000
        });
    },
      
    check_client_search(){
        if(this.clientsearch == 'téléphone'){
            this.NumberInput = true 
            this.EmailInput = false
            this.emailclient = null
            this.clientOk = false
            this.clientnonOk = false
        } else if(this.clientsearch == 'email'){
            this.NumberInput = false 
            this.EmailInput = true
            this.telephoneclient = null
                this.clientOk = false
            this.clientnonOk = false
        }
    },

    isMobile: function () {
        if (screen.width <= 760) {
            return true
        }else {
            return false
        }
    },

    rechercherClient() {
        if(this.telephoneclient){
            const validPhone = validatePhone(this.telephoneclient);
            this.errors.phone = validPhone.error;

            if(validPhone.valid == true){
                Api().get('/user/phone/' + this.telephoneclient)
                    .then(resultat => {
                        if (resultat.data.telephone_port === this.telephoneclient) {
                            this.clientOk = true
                            this.clientnonOk = false
                            this.usersearch = resultat.data;
                            this.$store.dispatch('setClient', resultat.data)
                        }
                    }).catch(e => {
                        this.clientnonOk = true
                        this.clientOk = false
                        this.telephoneC = this.telephoneclient;
                    })
            } else {
                this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
            }
        } else {
            this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
        }
    },

    rechercheClientByMail(){
        if(this.emailclient){
            const validEmail = validateEmail(this.emailclient);
            this.errors.email = validEmail.error;

            if(validEmail.valid == true){
                Api().get('/user/email/' +this.emailclient)
                    .then(resultat =>{
                    if (resultat.data.email === this.emailclient) {
                        this.clientOk = true
                        this.clientnonOk = false
                        this.usersearch = resultat.data;
                        this.$store.dispatch('setClient', resultat.data)
                    }
                    }).catch(e => {
                        this.clientnonOk = true
                        this.clientOk = false
                        this.emailC = this.emailclient;
                    })
            } else {
                this.$message.error('Veuillez entrer un e-mail valide !', this.AlertMessage)
            }
        }else {
            this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)
        }
    },

    yearsDiff() {
        const date1 = dayjs(this.dateCircule);
        const date2 = dayjs(new Date().toISOString().substr(0, 10));
        let daydiff = date2.diff(date1, 'days');
        this.age = daydiff

        if (this.age <= 29) {
            this.an = this.annee_mois = 'jour(s)'
        } else if (this.age >= 30, this.age <= 360) {
            let year = Math.floor(this.age / 30)
            this.age = year;
            this.an = this.annee_mois = 'mois'
        } else if (this.age >= 360) {
            let year = Math.floor(this.age / 360)
            this.age = year;
            this.an = this.annee_mois = 'an(s)'
        }
    },

    showidclient() {
        this.showId = false
        this.showconfirmclient = true
        this.shownameclient = false

    },

    hidesearch() {
        this.hidesearchClient = false
        this.e1 = 1
    },

    //liste de touts les partenaires
    getListePartenaire: function () {
        if (this.$store.state.user.role == 'Agent'){
            Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Automobile"})
                .then(response => {
                    this.listeAssureurs = response.data.partenaires[0].assureurs
                }).catch(err => {})
        } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') {
            Api().get('/partenaire/assureur')
                .then(response => {
                    this.listeAssureurs = response.data.partenaires
                })
        } else if (this.$store.state.user.role == 'assureur') {
            Api().get('/partenaire/' + this.$store.state.user.societe)
                .then(response => {
                    this.listeAssureurs = response.data.partenaire
                })
        }
    },

    HubspotSend(param){
        let data = {
            "email": param.email,
            "firstname": param.prenom,
            "lastname": param.nom,
            "phone": param.telephone_port,
            "inscrit": "Dash"
        };
        Api().post('/testimonial/hubspot', data)
            .then(resp => {
                (resp.data)
            }).catch(e => {
                (e)
            })
    },

    MailChimpInscriptionCompte(param){
        let data = {
            "email": param.email,
            "FNAME": param.prenom,
            "LNAME": param.nom,
            "PHONE": param.telephone_port,
        };
        Api().post('/testimonial/mailchimp', data)
            .then(resp => {
                (resp.data)
            })
            .catch(e => {
                (e)
            })
    },

    // Creation d'un user avant de faire son devis
    createuser: function () {
        this.generatePassword()
        if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) {
            const user = {
                nom: this.nom,
                prenom: this.prenom,
                datenaissance: this.datenaissance,
                profession: this.profession,
                adresse: this.adresse,
                sexe: this.genre,
                telephone_port: this.telephoneclient,
                email: this.emailclient,
                password: this.password1,
                confirmPass: this.password1,
                role: this.userRole,
                createdby:store.state.user._id,
                photo: this.file,
                piece_identite: this.piece_identite,
                numero_piece: this.numero_piece,
                plateforme : 'Dash',
                created: dayjs(new Date()).format('YYYY-MM-DD')
            }
        Api().post('user/register', user)
            .then(resultat => {
                this.HubspotSend(user)
                this.MailChimpInscriptionCompte(user)
                if (resultat.status == 200) {
                    if(resultat.data.numero){
                        this.$message.error(resultat.data.msg, this.AlertMessage)
                    }else{
                        this.$store.dispatch('setClient', resultat.data.data)
                        this.usersearch = resultat.data.data
                        this.AddUser = false
                        this.clientnonOk = false
                        this.hidesearchClient = false
                        this.e1 = 1
                    }
                } else {
                    this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                }
            })
            .catch(err => {
                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
            })
        } else {
            this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
        }
    },

    generatePassword: function () {
        this.password1 = customPassword.customPassword(this.nom, this.prenom)
    },

    dateDiff: function () {
        if(this.branche != 'Voyage'){
            if (this.dateEffet && this.duree && this.CalculDuree) {
                let inter = dayjs(this.dateEffet).add(this.duree, this.CalculDuree).format('YYYY-MM-DD');
                this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
            }
        }else {
            if (this.dateEffet && this.duree && this.CalculDuree) {
                if (this.dateEffet === '' & this.duree === '') this.dateEcheance = ''
                this.dateEcheance = dayjs(this.dateEffet).add(this.duree, 'day').format('YYYY-MM-DD')
            }
        }
    },

    /*================================================================================================================================*/
    /*================================================================================================================================*/
    /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
    //Validation des elements du Stepper 1
    Stepper1Validator() {
        if(this.branche && this.assureur && this.primeNette && this.accessoires && this.taxes && this.primettc){
            if (this.branche === 'Santé') {
                this.e1 = 2;
            } else {
                this.e1 = 3
            }
            if(this.branche != 'Voyage'){
                this.CalculDuree = 'month'
                this.duree = null
            }else {
                this.CalculDuree = 'day'
                this.duree = null
            }
        }else {
            this.$message.error('Tout les champs sont obligatoires !', this.AlertMessage)
        }
    },

    //Validation des ellements du Stepper 2
    Stepper2Validator() {
        if(this.adulteTotal || this.enfantTotal ){
            if(this.zone){
                this.e1 = 3
            }else {
                this.$message.error('Zone de couverture obligatoire !', this.AlertMessage)
            }
        }else {
            this.$message.error('Adultes ou enfants obligatoires !', this.AlertMessage)
        }        
    },
       
    __resizeHanlder: _.throttle(function(e) {
        if (this.chart) {
            this.chart.resize()
        }
        if (this.pie) {
            this.pie.resize()
        }
    }, 700)
    },

    created() {
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
