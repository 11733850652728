<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <div class="page-header">
            <h1>Liste des <span style="color:#ffa409"> SMS</span>
                <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>

        <div class="mb-5">
            <el-button @click="createDialogue=true" type="primary" round>Ecrire un nouveau message</el-button>
        </div>

        <div class="toolbar-box flex align-center" v-if="!isMobile">
            <div class="box grow">
                <el-input placeholder="Rechercher frais dossier..." prefix-icon="el-icon-search" v-model="search" clearable></el-input>
            </div>
            <div class="box grow">
                <!--        A ne pas supprimer-->
            </div>
        </div>

        <div v-if="isMobile" style="margin-bottom:15px">
            <el-row :gutter="15">
                <el-col :span="24" style="margin-top:15px">
                    <el-input placeholder="Rechercher frais dossier..." prefix-icon="el-icon-search" v-model="search" clearable></el-input>
                </el-col>
            </el-row>
        </div>

        <resize-observer @notify="handleResize" />
        <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading"
                @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
            <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready" @selection-change="handleSelectionChange">
                <el-table-column label="Date création" min-width="150" fixed prop="created">
                    <template slot-scope="scope">
                        <span class="date">{{ DateFormater(scope.row.created) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Objet" prop="sms_objet" min-width="130"></el-table-column>
                <el-table-column label="Message" prop="message" min-width="150">
                    <template slot-scope="scope">
                        <span class="date">{{ scope.row.message.substr(0,20)+"..." }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Numéro Client" min-width="150" prop="customer.telephone_port">
                    <template slot-scope="scope">
                        <span class="date">{{ scope.row.customer.telephone_port }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="150" :prop="customer">
                    <template slot-scope="scope">
                        <span class="date">
                            {{ scope.row.customer.prenom }} - {{ scope.row.customer.nom }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="Créateur" min-width="150" :prop="userid">
                    <template slot-scope="scope">
                        <span class="date">
                            {{ scope.row.userid.prenom }} - {{ scope.row.userid.nom }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="Statut" min-width="150" prop="logs.messages[0].status.description"></el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="setDetail(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
                :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
            </el-pagination>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
            <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready" @selection-change="handleSelectionChange">
                <el-table-column label="Date création" min-width="150" fixed prop="created">
                    <template slot-scope="scope">
                        <span class="date">{{ DateFormater(scope.row.created) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Objet" prop="sms_objet" min-width="130"></el-table-column>
                <el-table-column label="Message" prop="message" min-width="150">
                    <template slot-scope="scope">
                        <span class="date">{{ scope.row.message.substr(0,20)+"..." }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Numéro Client" min-width="150" prop="customer.telephone_port">
                    <template slot-scope="scope">
                        <span class="date">{{ scope.row.customer.telephone_port }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="150" :prop="customer">
                    <template slot-scope="scope">
                        <span class="date">
                            {{ scope.row.customer.prenom }} - {{ scope.row.customer.nom }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="Créateur" min-width="150" :prop="userid">
                    <template slot-scope="scope">
                        <span class="date">
                            {{ scope.row.userid.prenom }} - {{ scope.row.userid.nom }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="Statut" min-width="150" prop="logs.messages[0].status.description"></el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="setDetail(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
                :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
            </el-pagination>
        </div>

        <el-dialog  title="Détails SMS" :visible.sync="showdetail" v-if="showdetail == true">
            <el-button type="primary" round @click="JsonFormaterView" v-if="$store.state.user.role=='SuperAdmin'||$store.state.user.role == 'Admin'">
                    Détails en JSON
                </el-button>
            <el-row :gutter="10" class="mt-6">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple ">
                        <el-card class="box-card border" style="height:200px">
                            <ul style="list-style:none">
                                <li> 
                                    <span class="boldText"> 
                                        Date de création :
                                    </span>  
                                    <span class="to-right assuraf-orange">
                                         {{ DateFormater(detailSms.created) }} 
                                    </span> 
                                </li>
                                <li>
                                    <span class="boldText"> 
                                        Objet du message :
                                    </span> 
                                    <span class="to-right assuraf-orange"> 
                                        {{ detailSms.sms_objet }} 
                                    </span> 
                                </li>
                                <li>
                                    <span class="boldText">
                                        Numéro du Client :
                                    </span> 
                                    <span class="to-right assuraf-orange"> {{ detailSms.customer.telephone_port }} </span> </li>
                                <li> 
                                    <span class="boldText">
                                        Client : 
                                    </span>
                                    <span class="to-right assuraf-orange">
                                        {{ detailSms.customer.prenom }} {{ detailSms.customer.nom }} 
                                    </span> 
                                </li>
                                <li> 
                                   <span class="boldText">
                                    Créateur : 
                                   </span>
                                    <span class="to-right assuraf-orange">
                                        {{ detailSms.userid.prenom }} {{ detailSms.userid.nom }} 
                                    </span> 
                                </li>
                                <li> 
                                   <span class="boldText">
                                    Statu : 
                                   </span>
                                    <span class="to-right assuraf-orange">
                                        {{ detailSms.logs.messages[0].status.description }}
                                    </span> 
                                </li>
                            </ul>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card border" style="height:200px">
                            <span style="font-weight:bolder">Contenu du message :</span>
                            <div>
                                {{detailSms.message}}
                            </div>
                        </el-card>
                    </div>
                </el-col>

                <el-col class="gridder" :xs="24" :sm="6" :md="8" :lg="24" :xl="24" v-if="JsonView==true">
                    <el-card class="box-card">
                        <div style="background:black;color:white;text-align:left">
                            <pre>
                                {{ detailSms }}
                            </pre>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="40%!important" title="Ecrire un message" :visible.sync="createDialogue" v-if="createDialogue">
            <div class="grid-content bg-purple">
                <el-row :gutter="15">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <label>Numéro du récepteur *</label>
                            <el-input type="primary" v-model="message_object.phone_number" placeholder="Numéro de téléphone"></el-input>
                        </div>
                        <div class="grid-content bg-purple">
                            <label>Objet du message *</label>
                            <el-input type="primary" v-model="message_object.sms_objet" placeholder="Objet"></el-input>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple">
                            <label>Message *</label>
                            <el-input type="textarea" v-model="message_object.message" placeholder="Le message" maxlength="360" show-word-limit></el-input>
                        </div>
                    </el-col>
                </el-row>
                <div class="mb-5" style="margin-top: 15px">
                    <el-button type="danger" @click="send_message()" round >Envoyer</el-button>
                    <el-button type="primary" round @click="createDialogue = false">Annuler</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>
  
<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import smsservice from '../../../services/sms_service'
//fin importation assuraf

export default {
    name: "StyledTablePage",
    data() {
        return {
            JsonView:false,
            message_object :{
                phone_number:'',
                sms_objet:'',
                message:'',
                userid:store.state.user._id
            },
            createDialogue:false,
            listehistory: [],
            detailSms: null,
            showdetail: false,
            filter: null,
            totalRows: null,
            perPage: 4,
            pageOptions: [5, 10, 15],
            data: {},
            totalRows: null,
            perPage: 8,
            filter: null,
            showuser: false,
            modalInfo: {
                title: '',
                content: ''
            },
            voidField: false,
            isMobile: false,
            ready: false,
            width: 0,
            height: "auto",
            loading: false,
            search: "",
            pagination: {
                page: 1,
                size: 100,
                sizes: [10, 15, 20, 30, 50, 100],
                layout: "total, ->, prev, pager, next, jumper, sizes",
                small: false
            },
            list: this.listehistory, //users,
            dialogUserVisible: false,
        }
    },
    computed: {
        listFiltered() {
            if (this.search.length >= 3) {
                return this.listehistory.filter(obj => {
                    let ctrl = false
                    for (let k in obj) {
                        /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                        if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                            ctrl = true
                            /*on le push dans le tableau */
                            //this.listehistory.push(obj) 
                        } else {
                            /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                            if (Array.isArray(obj[k])) {
                                obj[k].filter(nobj => {
                                    for (let l in nobj) {
                                        /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                        if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                                            ctrl = true
                                            /* on le push dans le tableau */
                                            //this.listehistory.push(obj)
                                        }
                                    }
                                })
                                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                            } else if (!Array.isArray(obj[k]) && typeof obj[k] == "object" && obj[k] != null) {
                                /*== On crée un nouveau tablequ vide ==*/
                                let my_new_array = []

                                /*== On push la valeur courante dans le nouveau tableau ==*/
                                my_new_array.push(obj[k])

                                /*On refiltre le nouveau tableau atrribut par attribut*/
                                my_new_array.filter(nobj => {
                                    for (let l in nobj) {
                                        /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                        if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                                            ctrl = true

                                            /* on le push dans le tableau */
                                            //this.listehistory.push(obj)
                                        }
                                    }
                                })
                            }
                        }
                    }
                    return ctrl
                })
            } else {
                return this.listehistory.filter(obj => {
                    let ctrl = false
                    for (let k in obj) {
                        if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                            ctrl = true
                    }
                    return ctrl
                })
            }

        },
        listSortered() {
            let prop = this.sortingProp
            let order = this.sortingOrder
            return [].concat(
                this.listFiltered.sort((item1, item2) => {
                    let val1 = ""
                    let val2 = ""

                    val1 = item1[prop]
                    val2 = item2[prop]
                    if (order === "descending") {
                        return val2 < val1 ? -1 : 1
                    }
                    return val1 < val2 ? -1 : 1
                })
            )
        },
        listInPage() {
            let from = (this.currentPage - 1) * this.itemPerPage
            let to = from + this.itemPerPage * 1
            return this.listFiltered.slice(from, to)
        },
        total() {
            return this.listehistory.length
        },

        listFilteredFlotte() {
            return this.userflette.filter(obj => {
                let ctrl = false
                for (let k in obj) {
                    if (
                        obj[k] &&
                        obj[k]
                            .toString()
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                    )
                        ctrl = true
                }
                return ctrl
            })
        },
        listSorteredFlotte() {
            let prop = this.sortingProp
            let order = this.sortingOrder
            return [].concat(
                this.listFilteredFlotte.sort((item1, item2) => {
                    let val1 = ""
                    let val2 = ""

                    val1 = item1[prop]
                    val2 = item2[prop]
                    if (order === "descending") {
                        return val2 < val1 ? -1 : 1
                    }
                    return val1 < val2 ? -1 : 1
                })
            )
        },
        listInPageFlotte() {
            let from = (this.currentPage - 1) * this.itemPerPage
            let to = from + this.itemPerPage * 1
            return this.listSorteredFlotte.slice(from, to)
            return this.listFilteredFlotte.slice(from, to)
        },
        totalFlotte() {
            return this.listFilteredFlotte.length
        },

        currentPage: {
            get() {
                return this.pagination.page
            },
            set(val) {
                this.pagination.page = val
            }
        },
        itemPerPage() {
            return this.pagination.size
        },
        selectedItems() {
            return this.itemsChecked.length || 0
        }
    },
    watch: {
        itemPerPage(val) {
            this.ready = false
            this.currentPage = 1

            setTimeout(() => {
                this.ready = true
            }, 500)
        },
        search(val) {
            this.currentPage = 1
        }
    },
    methods: {
        JsonFormaterView()
        {
            if(this.JsonView==false)
            {
                this.JsonView = true
            }else{
                this.JsonView = false
            }
        },
        setDetail(item){
            this.detailSms = item
            this.showdetail = true
        },

        async send_message(){
          if (smsservice.sms_sender_validator(this.message_object)==null)
          {
                let resp = await smsservice.data_sender(this.message_object)
                if(resp.status=="NOK"){
                    this.$message.error(resp.message, this.AlertMessage)
                }else{
                    this.$message.success(resp.message, this.AlertMessage)
                    this.getlisteHistory()
                    this.message_object.phone_number=''
                    this.message_object.sms_objet=''
                    this.message_object.message=''
                }   
          } else {
            this.$message.error(smsservice.sms_sender_validator(this.message_object), this.AlertMessage)
          }
        },

        exportToExcel() {
            //API de genereation donne en excel
        },

        AlertMessage() {
            this.$message({showClose: false,duration: 5000});
        },

        DateFormater: function (TheDate) {
            if (TheDate) { return dayjs(TheDate).format('DD-MM-YYYY') }
        },

        getlisteHistory() {
            Api().get('/smsservice/listing')
                .then(resultat => {
                    this.listehistory = resultat.data.smshistory
                    this.totalRows = this.listehistory.length
                });
        },

        ready: function () {
            var self = this;

            setInterval(function () {
                self.$data.ticker = Date.now();
            }, 1000);
        },

        calcDims() {
            const tableWrapper = document.getElementById("table-wrapper")
            if (tableWrapper) this.width = tableWrapper.clientWidth

            if (!this.isMobile && tableWrapper) {
                this.height = tableWrapper.clientHeight - 44
            }

            if (this.width < 480) {
                this.pagination.small = true
                this.pagination.layout = "prev, pager, next"
            } else if (this.width >= 480 && this.width < 700) {
                this.pagination.small = false
                this.pagination.layout = "prev, pager, next, ->, sizes"
            } else {
                this.pagination.small = false
                this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
            }

            this.ready = true
        },

        handleResize: _.throttle(function (e) 
        {
            this.ready = false
            this.width = 0
            setTimeout(this.calcDims, 1000)
        }, 500),

        handleSelectionChange(val) 
        {
            this.itemsChecked = val
        },

        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        }
    },
    filters: {
        selected: function (value, sel) {
            if (!value) return ""
            if (!sel) return value

            value = value.toString()
            sel = sel.toString()

            const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
            if (startIndex !== -1) {
                const endLength = sel.length
                const matchingString = value.substr(startIndex, endLength)
                return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
            }

            return value
        }
    },
    created() {
        this.init()
        this.getlisteHistory()
    },
    mounted() 
    {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getlisteHistory()
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        //ie fix
        if (!window.Number.parseInt) window.Number.parseInt = parseInt
        this.calcDims()
    }
}
</script>
  
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
    &.overflow {
        overflow: auto;
    }

    .toolbar-box {
        &.hidden {
            visibility: hidden;
        }
    }

    .table-box {
        overflow: hidden;

        &.hidden {
            visibility: hidden;
        }
    }
}
</style>
  
<style lang="scss" >
@import "../../../assets/scss/_variables";
textarea{
    height: 130px!important;
}
.box-card ul li {
    margin-left: -3%;
}
.boldText{
    font-weight:bolder
}
.page-table {
    .toolbar-box {
        margin-bottom: 10px;

        .card-shadow--medium {

            &.el-input,
            &.el-select {
                border-radius: 4px;

                input {
                    border-color: transparent;
                    background-color: lighten($background-color, 2%);
                    color: $text-color;

                    &:hover {
                        border-color: #c0c4cc;
                    }

                    &:focus {
                        border-color: $text-color-accent;
                    }
                }
            }

            &.el-button {
                border-color: transparent;
            }
        }
    }

    .clickable {
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
    }

    .sel-string {
        .sel {
            background: transparentize($text-color, 0.8);
            border-radius: 5px;
            //text-transform: uppercase;
        }
    }
}
.gridder{
        margin-top: 10px;
    }
@media (max-width: 768px) {
    .gridder{
        margin-top: 0px;
    }
    .page-table {
        .toolbar-box {
            display: block;
            overflow: hidden;
            font-size: 80%;
            padding-bottom: 10px;

            &>* {
                display: inline-block;
                min-width: 120px;
                height: 22px;
                //background: rgba(0, 0, 0, 0.04);
                margin-bottom: 16px;
            }
        }
    }
}</style>
  