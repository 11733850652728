<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouveau <span style="color:#ffa409">partenaire</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Société</label>
              <el-input placeholder="Nom de la société" v-model="societe">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Email administrateur</label>
              <el-input placeholder="Adresse mail" v-model="email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone Bureau</label>
              <el-input @keypress="onlyNumber" type="number" placeholder="Numéro de téléphone" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Email contact</label>
              <el-input placeholder="Adresse mail" v-model="contact_email">
              </el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Adresse" v-model="adresse">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone Portable</label>
              <el-input @keypress="onlyNumber" type="text" placeholder="Numéro de téléphone" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Pays</label>
              <el-select style="width: 100%" v-model="pays" placeholder="Selectionner">
                <el-option v-for="items in listpays" :key="items.code" :label="items.name" :value="items.name">{{items.name}}</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Ninéa</label>
              <el-input placeholder="ninéa" v-model="ninea">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>RCCM</label>
              <el-input placeholder="RCCM" v-model="rccm">
              </el-input>
            </div>
          </el-col>

        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Type partenaire</label>
              <el-select style="width: 100%" v-model="type_partenaire" placeholder="Selectionner">
                <el-option v-for="item in list_type_partenaire" :key="item" :label="item" :value="item">{{item}}</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Numéro agrément</label>
              <el-input @keypress="onlyNumber" placeholder="Numéro agrément" v-model="numero_agrement">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date agrément</label>
              <el-date-picker
                    style="width: 100%!important;"
                    v-model="date_agrement"
                    type="date"
                    placeholder="Choississez une date"
                    >
                </el-date-picker>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="creerGratuit()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>

     <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Société</label>
              <el-input placeholder="Nom de la société" v-model="societe">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Email</label>
              <el-input placeholder="Adresse mail" v-model="email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Téléphone</label>
              <el-input @keypress="onlyNumber" type="number" placeholder="Numéro de téléphone" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Adresse" v-model="adresse">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Type partenaire</label>
              <el-select style="width: 100%" v-model="type_partenaire" placeholder="Selectionner">
                <el-option v-for="item in list_type_partenaire" :key="item" :label="item" :value="item">{{item}}</el-option>
              </el-select>
            </div>
          </el-col>
         <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Pays</label>
              <el-select style="width: 100%" v-model="pays" placeholder="Selectionner">
                <el-option v-for="items in listpays" :key="items.code" :label="items.name" :value="items.name">{{items.name}}</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Ninéa</label>
              <el-input placeholder="ninéa" v-model="ninea">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>RCCM</label>
              <el-input placeholder="RCCM" v-model="rccm">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Numéro agrément</label>
              <el-input @keypress="onlyNumber" placeholder="Numéro agrément" v-model="numero_agrement">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 15px">
            <div class="grid-content bg-purple">
              <label>Date agrément</label>
              <el-date-picker
                    style="width: 100%!important;"
                    v-model="date_agrement"
                    type="date"
                    placeholder="Choississez une date"
                    >
                </el-date-picker>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="creerGratuit()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import pays from '../../../common/countries.json'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
        isMobile: false,
        dateBeforeToday: {
            disabledDate(time) {
            return time.getTime() > Date.now();
            }
        },
        listeAssureurs: null,
        listeBaranches: null,
        partenaireid :[],
        ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
                }, // Customized options for success toast
                ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
                },// Customized options for error toast
        branche:null,        
        montant:null,        
        partenaireid:null,


             
        
        errors: {},
      options: {
        format: "YYYY/MM/DD",
        useCurrent: false,
      },
      liste_dns: [
        "yahoo.fr",
        "gmail.com",
        "hotmail.fr",
        "gmx.fr",
        "protonmail.com",
        "outlook.com",
        "mail.fr",
      ],
      picked: new Date(),
      step: 1,
      image: null,
      mask: "!#XXXXXXXX",
      menu: false,
      color: "#222",
      config_array: [
        {
          config_name: "devis_vie_prefix",
          config_value: "ET",
          libelle: "Préfix devis vie",
          type: "text",
        },
        {
          config_name: "devis_auto_prefix",
          config_value: "AA",
          libelle: "Préfix devis auto",
          type: "text",
        },
        {
          config_name: "devis_voyage_prefix",
          config_value: "VV",
          libelle: "Préfix devis voyage",
          type: "text",
        },
        {
          config_name: "devis_habitation_prefix",
          config_value: "HH",
          libelle: "Préfix devis habitation",
          type: "text",
        },
        {
          config_name: "devis_sante_prefix",
          config_value: "SS",
          libelle: "Préfix devis santé",
          type: "text",
        },
        {
          config_name: "doc_header",
          config_value:
            "<span> ASSURAF SAS - Assureur Conseils</span> <br><span> Sicap Amitié 3, face VDN - BP 2033 Dakar RP </span> <br><span> Tel : (221) 33 864 00 64 - Email : contact@assuraf.com </span><br>",
          libelle: "Entête des documents PDF",
          type: "textarea",
        },
        {
          config_name: "doc_bottom",
          config_value:
            "<p> Généré via <a href='https://assuraf.com'> Assuraf.com </a> </p>",
          libelle: "Pied de page des documents PDF",
          type: "textarea",
        },
        {
          config_name: "notification_email",
          config_value: "",
          libelle: "Adresse email noreplay",
          type: "email",
        },
        {
          config_name: "smtp_host",
          config_value: "",
          libelle: "Host SMTP",
          type: "text",
        },
        {
          config_name: "smtp_port",
          config_value: "",
          libelle: "Port SMTP",
          type: "text",
        },
        {
          config_name: "smtp_secure",
          config_value: "",
          libelle: "Sécurité SMTP",
          type: "text",
        },
        {
          config_name: "smtp_password",
          config_value: "",
          libelle: "Mot de passe",
          type: "password",
        },
      ],

      config_emailing: [
        {
          config_name: "line_background_color",
          config_value: "#FFA409",
          libelle: "Code couleur d'arrière plan des titres (*)",
          type: "color",
        },
        {
          config_name: "text_color",
          config_value: "#FFFFFFFF",
          libelle: "Code couleur des textes",
          type: "color",
        },
        {
          config_name: "text_color_on_background",
          config_value: "#FFFFFFFF",
          libelle: "Code couleur des text sur fond",
          type: "color",
        },
      ],
      date: new Date(),
      options: {
        format: "YYYY/MM/DD",
        useCurrent: false,
      },
      verification: [
        "devis_vie_prefix",
        "devis_auto_prefix",
        "devis_voyage_prefix",
        "devis_habitation_prefix",
        "devis_sante_prefix",
      ],
      hideTiers: true,
      dismissSecs: 3,
      used: false,
      dismissCountDown: 0,
      societe: null,
      type_partenaire: "tiers",
      list_type_partenaire: ["tiers", "courtier", "assureur"],
      adresse: null,
      telephone_port: null,
      telephone_bur: null,
      email: null,
      contact_email:null,
      ninea: null,
      etat: "enabled",
      file: null,
      optionEtat: [
        { value: "enabled", text: "En marche" },
        { value: "disabled", text: "BLoqué" },
      ],
      rccm: null,
      menu: null,
      pays: "Senegal",
      listpays: pays,
      numero_agrement: null,
      date_agrement: null,
      filename: "Select file",
      voidField: false,
      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.used = true
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
    AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
    onlyNumber($event) {
      let NumOnly = $event.NumOnly ? $event.NumOnly : $event.which;
      if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
        $event.preventDefault();
      }
    },
    step1Validator() {
      if (this.societe && this.adresse && this.type_partenaire && this.pays) {
        if (this.email && this.telephone_port) {
          this.errors = {};

          const validEmail = validateEmail(this.email);
          this.errors.email = validEmail.error;

          const validPhone = validatePhone(this.telephone_port);
          this.errors.phone = validPhone.error;

          if (validEmail.valid == true && validPhone.valid == true) {
            this.step = 2;
          }
        } else {
          this.$toasted.show(
            "Adresse mail et téléphone obligatoires !",
            this.ToastTabError
          );
        }
      } else {
        this.$toasted.show(
          "Tous les champs sont obligatoires",
          this.ToastTabError
        );
      }
    },
    swatchStyle(libelle) {
      let chaine = "";
      for (let index = 0; index < this.config_emailing.length; index++) {
        const element = this.config_emailing[index];
        if (element.libelle == libelle) {
          chaine = {
            backgroundColor: element.config_value,
            cursor: "pointer",
            height: "30px",
            width: "30px",
            borderRadius: element.menu ? "50%" : "4px",
            transition: "border-radius 200ms ease-in-out",
          };
        }
      }
      return chaine;
    },
    goStep(step) {
      this.step = step;
    },
    onChange(type_partenaire) {
      if (type_partenaire == "tiers") {
        this.hideTiers = false;
      } else {
        this.hideTiers = true;
      }
      console.log(type_partenaire);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    Inarray(val, theTable) {
      let searcher = false;
      for (let v of theTable) {
        if (v == val) {
          searcher = true;
        }
      }
      return searcher;
    },
    creerGratuit () {
      if (
        this.ninea &&
        this.rccm &&
        this.numero_agrement &&
        this.date_agrement &&
        this.societe && this.adresse && this.type_partenaire && this.pays
      ) {
        let formData = new FormData();
        formData.append("logo", this.file);
        formData.append("societe", this.societe);
        formData.append("type_partenaire", this.type_partenaire);
        formData.append("adresse", this.adresse);
        formData.append("etat", this.etat);
        formData.append("telephone_port", this.telephone_port);
        formData.append("telephone_bur", this.telephone_bur);
        formData.append("email", this.email);
        formData.append("contact_email", this.contact_email);
        formData.append("ninea", this.ninea);
        formData.append("rccm", this.rccm);
        formData.append("pays", "Senegal");
        formData.append("numero_agrement", this.numero_agrement);
        formData.append("date_agrement", this.date_agrement);
        formData.append("namePartner", this.societe.toUpperCase());
        formData.append("url", "");
        formData.append("password", "");
        formData.append("user", "root");

        for (var i = 0; i < this.config_array.length; i++) {
          if (
            this.Inarray(this.config_array[i].config_name, this.verification) ==
            true
          ) {
            this.config_array[i].config_value = this.config_array[
              i
            ].config_value.replace(/[\W_]/g, "");
          }
          formData.append("config[]", JSON.stringify(this.config_array[i]));
        }

        for (let index = 0; index < this.config_emailing.length; index++) {
          formData.append(
            "config[]",
            JSON.stringify(this.config_emailing[index])
          );
        }

        Api()
          .post("/partenaires/nouveau", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data) {
              this.$message.success('Partenaire créé. Veuillez consulter votre e-mail ! ', this.AlertMessage)
              this.filename = "";
              this.file = "";
              this.societe = "";
              this.adresse = "";
              this.telephone_port = "";
              this.email = "";
              this.contact_email =""
              this.ninea = "";
              this.etat = "enabled";
              this.rccm = "";
              this.numero_agrement = "";
              this.date_agrement = "";
            
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error('Error lors de la création du partinaire! ', this.AlertMessage)
          });
      } else {
        this.$message.error('Tous les champs sont obligatoires ! ', this.AlertMessage)
      }
    },

     init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
   
  },
   created() {
    this.init()
  },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
