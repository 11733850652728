import Proprietaire from "../proprietaire";
export default class MicroAuto {
    constructor() {
        this.durationInDay = [7, 15, 21,30],
        this.durationInMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        this.periodeType = {
            day : "jours",
            month : "mois"
        }
        this.branche = "Micro-auto"
        this.assureur = "SUNU"
        this.dateEffet = null
        this.dateEcheance = null,
        this.pack1 = {
            namePack : "Pack_1",
            garantie : ['RESPONSABILITE CIVILE', 'DEFENSE ET RECOURS','PERSONNES TRANSPORTEES']
        },
        this.pack2 = {
            namePack : "Pack_2",
            garantie : ['RESPONSABILITE CIVILE', 'DEFENSE ET RECOURS','PERSONNES TRANSPORTEES','BRIS DE GLACES']
        },
        this.Proprietaire = new Proprietaire()
    }
}