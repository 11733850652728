<template>
	<div class="page-layout-blank scrollable">
		Blank page
	</div>
</template>

<script>
export default {
	name: 'LayoutBlank'
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';
</style>

