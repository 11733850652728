<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <div class="page-header">
            <h1>Liste des <span style="color:#ffa409">Utilisateurs</span>
            <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>
  
        <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
              <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>
  
        <div class="table-box card-base card-shadow--medium box grow border" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
            <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready" @selection-change="handleSelectionChange">
                <el-table-column class="border-td" label="Prénom" min-width="50" fixed prop="user_firstname"></el-table-column>
                <el-table-column class="border-td" label="Nom" min-width="50" fixed prop="user_name"></el-table-column>
                <el-table-column class="border-td" label="Genre" min-width="50" fixed prop="user_sex"></el-table-column>
                <el-table-column class="border-td" label="Téléphone" min-width="50" fixed prop="user_phone"></el-table-column>
                <el-table-column class="border-td" label="email" min-width="50" fixed prop="user_email"></el-table-column>
                <el-table-column class="border-td" fixed="right" label="Photo" prop="action" min-width="50">
                    <template slot-scope="scope">
                        <img :src="'http://localhost:3002/uploads/user_excel/'+scope.row.user_photo" alt="" srcset="" width="80%" height="auto">
                    </template>
                </el-table-column>
            </el-table>
    
            <el-pagination v-if="ready" :small="pagination.small"  :current-page.sync="pagination.page" :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
            </el-pagination>
        </div>
  
    </div>
  </template>
  
  <script>
  
  
  import _ from "lodash"
  
  //debut importation assuraf
  import dayjs from 'dayjs'
  import Api from '../../services/Api'
  import store from '../../store'
  import axios from 'axios' 
  //fin importation assuraf
  
  export default {
    name: "StyledTablePage",
    data() {
      return {
        listbranche: [],
        detailbranche: [],
        showpack: false,
        nombranche:null,
        fields: [
          {key: 'branche', sortable: true, label: 'Nom branche'},
          {key: 'action', label: 'Action'},
        ],
        modalgenerationloading : false,
        optionsdate: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        ldevis: [],
        du: null,
        au: null,
        listdevis: [],
        modalgeneration: false,
        detailsDevis: [],
        showdevis: false,
        pageOptions: [5, 10, 15],
        modalInfo: {
          title: '',
          content: ''
        },
        userflette:[],
        fletteRows:null,
        username: null,
        data: {},
        html: '',
        Mycounter: 0,
        dismissSecs: 3,
        totalRows: null,
        perPage: 8,
        UpdateOk: false,
        deleteConfirmation: false,
        modalDelete: false,
        dismissCountDown: 0,
        detailsUser: [],
        listuser: null,
        filter: null,
        showuser: false,
        voidField: false,
        isMobile: false,
        ready: false,
        width: 0,
        height: "auto",
        loading: false,
        search: "",
        pagination: {
          page: 1,
          size: 100,
          sizes: [10, 15, 20, 30, 50, 100],
          layout: "total, ->, prev, pager, next, jumper, sizes",
          small: false
        },
        list:  this.listbranche,//users,
        editMode: false,
        itemsChecked: [],
        dialogUserVisible: false,
        currentId: 0,
      }
    },
    computed: {
      listFiltered() {
        return this.listbranche.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },
      listSortered() {
        let prop = this.sortingProp
        let order = this.sortingOrder
        return [].concat(
            this.listFiltered.sort((item1, item2) => {
              let val1 = ""
              let val2 = ""
  
              val1 = item1[prop]
              val2 = item2[prop]
              if (order === "descending") {
                return val2 < val1 ? -1 : 1
              }
              return val1 < val2 ? -1 : 1
            })
        )
      },
      listInPage() {
        let from = (this.currentPage - 1) * this.itemPerPage
        let to = from + this.itemPerPage * 1
        //return this.listSortered.slice(from, to)
        return this.listFiltered.slice(from, to)
      },
      total() {
        return this.listFiltered.length
      },
      currentPage: {
        get() {
          return this.pagination.page
        },
        set(val) {
          this.pagination.page = val
        }
      },
      itemPerPage() {
        return this.pagination.size
      },
      selectedItems() {
        return this.itemsChecked.length || 0
      }
    },
    watch: {
      isBusy(newVal, oldVal) {
        if (newVal !== oldVal) {
          const tableScrollBody = this.$refs["my-table"].$el;
          if (newVal === true) {
            tableScrollBody.classList.add("overflow-hidden");
          } else {
            tableScrollBody.classList.remove("overflow-hidden");
          }
        }
      },
  
      itemPerPage(val) {
        this.ready = false
        this.currentPage = 1
  
        setTimeout(() => {
          this.ready = true
        }, 500)
      },
      search(val) {
        this.currentPage = 1
      }
    },
    methods: {

      getBranche()
      {
        axios.get('http://localhost:3009/user/liste/all')
            .then( resultat => {
                console.log(resultat.data)
                this.listbranche = resultat.data
                this.totalRows = this.listbranche.length
            });
      },
  
  
      AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
  
      toggleDetails(item){
        this.detailsUser = item
        this.editUser = true
      },
  
      ready: function () {
        var self = this;
  
        setInterval(function () {
          self.$data.ticker = Date.now();
        }, 1000);
      },
  
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
  
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
  
  
      DateFormater: function (TheDate) {
        if (TheDate) {
          return dayjs(TheDate).format('DD-MM-YYYY')
        }
      },
  
      userDeleteConfirmation(item) {
  
        this.modalDelete = true
        this.detailsUser = item
  
      },
  
  
  
  
      calcDims() {
        const tableWrapper = document.getElementById("table-wrapper")
        if (tableWrapper) this.width = tableWrapper.clientWidth
  
        if (!this.isMobile && tableWrapper) {
          this.height = tableWrapper.clientHeight - 44
        }
  
        if (this.width < 480) {
          this.pagination.small = true
          this.pagination.layout = "prev, pager, next"
        } else if (this.width >= 480 && this.width < 700) {
          this.pagination.small = false
          this.pagination.layout = "prev, pager, next, ->, sizes"
        } else {
          this.pagination.small = false
          this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
        }
  
        this.ready = true
      },
      handleResize: _.throttle(function(e) {
        this.ready = false
        this.width = 0
        setTimeout(this.calcDims, 1000)
      }, 500),
      handleSelectionChange(val) {
        this.itemsChecked = val
      },
      init() {
        if (window.innerWidth <= 768) this.isMobile = true
      }
    },
    filters: {
      selected: function(value, sel) {
        if (!value) return ""
        if (!sel) return value
  
        value = value.toString()
        sel = sel.toString()
  
        const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
        if (startIndex !== -1) {
          const endLength = sel.length
          const matchingString = value.substr(startIndex, endLength)
          return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
        }
  
        return value
      }
    },
    created() {
      this.init()
      this.getBranche()
    },
    mounted() {
        this.getBranche()
        if (!window.Number.parseInt) window.Number.parseInt = parseInt
        this.calcDims()
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "../../assets/scss/_variables";
  .el-table th.el-table__cell.is-leaf, .el-table td.el-table__cell{
    border: 1px solid red!important;
  }
  .border-td{
    border: 1px solid red;
  }

  .page-table {
    &.overflow {
      overflow: auto;
    }
  
    .toolbar-box {
      &.hidden {
        visibility: hidden;
      }
    }
  
    .table-box {
      overflow: hidden;
  
      &.hidden {
        visibility: hidden;
      }
    }
  }
  </style>
  
  <style lang="scss">
  @import "../../assets/scss/_variables";


  .page-table {
    .toolbar-box {
      margin-bottom: 10px;
  
      .card-shadow--medium {
        &.el-input,
        &.el-select {
          border-radius: 4px;
  
          input {
            border-color: transparent;
            background-color: lighten($background-color, 2%);
            color: $text-color;
  
            &:hover {
              border-color: #c0c4cc;
            }
            &:focus {
              border-color: $text-color-accent;
            }
          }
        }
  
        &.el-button {
          border-color: transparent;
        }
      }
    }
  
    .clickable {
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  
    .sel-string {
      .sel {
        background: transparentize($text-color, 0.8);
        border-radius: 5px;
        //text-transform: uppercase;
      }
    }
  }
  
  @media (max-width: 768px) {
    .page-table {
      .toolbar-box {
        display: block;
        overflow: hidden;
        font-size: 80%;
        padding-bottom: 10px;
  
        & > * {
          display: inline-block;
          min-width: 120px;
          height: 22px;
          //background: rgba(0, 0, 0, 0.04);
          margin-bottom: 16px;
        }
      }
    }
  }
  </style>
  