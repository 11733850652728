<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Utilisateurs <span style="color:#ffa409"> Back office</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="mb-5">
      <el-button type="primary" @click="toCreateNewUser()" round>Créer un nouvel utilisateur</el-button>
    </div>

     <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher utilisateur..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher utilisateur..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
        <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready"
        @selection-change="handleSelectionChange">
        <el-table-column label="Nom" min-width="150" fixed prop="nom"></el-table-column>
        <el-table-column label="Prénom" min-width="150" prop="prenom"></el-table-column>
        <el-table-column label="Adresse" min-width="150" prop="adresse"></el-table-column>
        <el-table-column label="Téléphone" min-width="150" prop="telephone_port"></el-table-column>
        <el-table-column label="Email" prop="email" min-width="300">
          <template slot-scope="scope">
            <span class="sel-string" v-html="$options.filters.selected(scope.row.email, search)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Rôle" min-width="150" prop="roles.name"></el-table-column>
        <el-table-column label="Créateur" prop="createdby." min-width="250">
          <template slot-scope="scope">
            <span class="sel-string" v-if="scope.row.createdby">{{ scope.row.createdby.prenom }} -
              {{ scope.row.createdby.nom }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date création" prop="created" min-width="200">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column label="Etat" prop="etat" min-width="100">
          <template slot-scope="scope">
            <img :src="require('../../../assets/images/online.png')" width="20" alt="" v-if="scope.row.etat == 'Online'">
            <img :src="require('../../../assets/images/offline.png')" width="20" alt=""
              v-if="scope.row.etat == 'Offline'">
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailuser(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" type="primary" @click="toggleDetails(scope.row)" icon="el-icon-edit" circle></el-button>
            <el-button size="mini" @click="userDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
      </el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table :data="listInPage" style="width: 100%" v-if="ready"
        @selection-change="handleSelectionChange">
        <el-table-column label="Nom" min-width="150" prop="nom"></el-table-column>
        <el-table-column label="Prénom" min-width="150" prop="prenom"></el-table-column>
        <el-table-column label="Adresse" min-width="150" prop="adresse"></el-table-column>
        <el-table-column label="Téléphone" min-width="150" prop="telephone_port"></el-table-column>
        <el-table-column label="Email" prop="email" min-width="300">
          <template slot-scope="scope">
            <span class="sel-string" v-html="$options.filters.selected(scope.row.email, search)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Rôle" min-width="150" prop="roles.name"></el-table-column>
        <el-table-column label="Créateur" prop="createdby" min-width="250">
          <template slot-scope="scope">
            <span class="sel-string" v-if="scope.row.createdby">{{ scope.row.createdby.prenom }} -
              {{ scope.row.createdby.nom }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date création" prop="created" min-width="200">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column label="Etat" prop="etat" min-width="100">
          <template slot-scope="scope">
            <img :src="require('../../../assets/images/online.png')" width="20" alt="" v-if="scope.row.etat == 'Online'">
            <img :src="require('../../../assets/images/offline.png')" width="20" alt=""
              v-if="scope.row.etat == 'Offline'">
          </template>
        </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailuser(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" type="primary" @click="toggleDetails(scope.row)" icon="el-icon-edit" circle></el-button>
            <el-button size="mini" @click="userDeleteConfirmation(scope.row)" type="danger" icon="el-icon-delete" circle>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout" :total="total">
      </el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails utilisateur" :visible.sync="showuser" v-if="showuser == true && !isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="merge()" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'">
          Transférer les données sur Super Admin
        </el-button>
        <el-button type="primary" round @click="showConfigMenu = true" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'">
          Configurer le menu de l'utilisateur
        </el-button>
      </div>
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Téléphone Portable : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span> </li>
                  <li>Téléphone bureau : <span class="to-right assuraf-orange">{{ detailsUser.telephone_bur }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Numéro pièce : <span class="to-right assuraf-orange">{{ detailsUser.numero_piece }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                  <li v-if="detailsUser.societe">Agence : <span class="to-right assuraf-orange">{{ detailsUser.societe.societe || null}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <template>
        <el-table :data="usermenu" class="padded-table" height="600" style="width: 100%" row-key="id">
          <el-table-column prop="theme" label="Libellé" min-width="200"> </el-table-column>
          <el-table-column prop="collapse" label="Collapse" min-width="80"> </el-table-column>
          <el-table-column prop="sousmenu" label="Sous Menu" min-width="120">
            <template slot-scope="scope">
              <ul>
                <li v-for="(item, index) in scope.row.config" :key="index">
                  {{ item.libelle }}
                </li>
              </ul>
            </template>
          </el-table-column>

          <el-table-column prop="action" fixed="right" label="Actions">
            <template slot-scope="scope">
              <el-button size="mini" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'"
                @click="MenuDelete(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails utilisateur" :visible.sync="showuser"
      v-if="showuser == true && isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="merge()" v-if="$store.state.user.role == 'SuperAdmin'  || $store.state.user.role == 'Admin'">Transférer les données
          sur admin</el-button>
      </div>
      <div class="mb-5">
        <el-button type="primary" round @click="showConfigMenu = true" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'">Configurer
          le menu de l'Utilisateur</el-button>
      </div>
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Téléphone Portable : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span>
                  </li>
                  <li>Téléphone bureau : <span class="to-right assuraf-orange">{{ detailsUser.telephone_bur }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Numéro pièce : <span class="to-right assuraf-orange">{{ detailsUser.numero_piece }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <template>
        <el-table :data="usermenu" class="padded-table" height="600" style="width: 100%" row-key="id">
          <el-table-column prop="theme" label="Libellé" min-width="200"> </el-table-column>
          <el-table-column prop="collapse" label="Collapse" min-width="80"> </el-table-column>
          <el-table-column prop="sousmenu" label="Sous Menu" min-width="120">
            <template slot-scope="scope">
              <ul>
                <li v-for="(item, index) in scope.row.config" :key="index">
                  {{ item.libelle }}
                </li>
              </ul>
            </template>
          </el-table-column>

          <el-table-column prop="action" fixed="right" label="Actions">
            <template slot-scope="scope">
              <el-button size="mini" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Admin'"
                @click="MenuDelete(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire de modification" :visible.sync="editUser"
      v-if="editUser == true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="8">
              <div>
                <label>Nom</label>
                <el-input v-model="detailsUser.nom" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Prénom</label>
                <el-input v-model="detailsUser.prenom" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Téléphone portable</label>
                <el-input v-model="detailsUser.telephone_port" placeholder="Rechercher..." class="input-with-select">
                </el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Téléphone bureau</label>
                <el-input v-model="detailsUser.telephone_bur" placeholder="Rechercher..." class="input-with-select">
                </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Adresse</label>
                <el-input v-model="detailsUser.adresse" placeholder="Rechercher..." class="input-with-select">
                </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Profession</label>
                <el-input v-model="detailsUser.profession" placeholder="Rechercher..." class="input-with-select">
                </el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Date de naissance</label>
                <el-date-picker style="width: 100%!important;" @change="yearsDiff()" v-model="detailsUser.datenaissance"
                  type="date" placeholder="Choississez une date" :picker-options="dateBeforeToday">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Sexe</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.sexe" placeholder="Selectionner">
                  <el-option label="homme">Homme</el-option>
                  <el-option label="femme">Femme</el-option>
                  <el-option label="entreprise" value="entreprise"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Statut Marital</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.statut_marital"
                  placeholder="Selectionner">
                  <el-option value="célibataire" label="célibataire"></el-option>
                  <el-option value="marié" label="marié(e)"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Adresse mail</label>
                <el-input v-model="detailsUser.email" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Type piéce identité</label>
                <el-input v-model="detailsUser.piece_identite" placeholder="Rechercher..." class="input-with-select">
                </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Numéro piéce</label>
                <el-input v-model="detailsUser.numero_piece" placeholder="Rechercher..." class="input-with-select">
                </el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div class="grid-content bg-purple" v-if="this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'">
                <label>Rôle</label>
                <el-select style="width: 100%" v-model="detailsUser.roles" placeholder="Selectionner">
                  <el-option
                      v-for="role in listRoles"
                      :key="role._id"
                      :label="role.name"
                      :value="role._id"
                    />
                </el-select>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple" v-if="this.$store.state.user.role=='Admin' &&  detailsUser.societe!=undefined  || this.$store.state.user.role=='SuperAdmin' &&  detailsUser.societe!=undefined ">
                <label>Agent administrateur</label>
                <el-select style="width: 100%;" v-model="detailsUser.agent_admin" placeholder="Selectionner">
                <el-option label="Oui" :value="true"> </el-option>
                <el-option label="Non" :value="false"> </el-option>
              </el-select>
              </div>
            </el-col>
          </el-row>

        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdateUser()">Modifier</el-button>
          <el-button type="danger" round @click="editUser = false">Annuler</el-button>
        </div>
         <div class="mb-5" style="margin-top: 15px" v-if="$store.state.user.role == 'SuperAdmin' && detailsUser.roles.name == 'Agent' || $store.state.user.role == 'Admin' && detailsUser.roles.name == 'Agent'">
          <el-button type="primary" round @click="loadConfigBranchePopUp()">Configuration de branche</el-button>
        </div>
      </div>

    </el-dialog>

    <el-dialog width="90%!important" title="Formulaire de modification" :visible.sync="editUser"
      v-if="editUser == true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Nom</label>
                <el-input v-model="detailsUser.nom" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Prénom</label>
                <el-input v-model="detailsUser.prenom" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Téléphone portable</label>
                <el-input v-model="detailsUser.telephone_port" placeholder="Rechercher..." class="input-with-select">
                </el-input>
              </div>
            </el-col>
          </el-row>

          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Téléphone bureau</label>
              <el-input v-model="detailsUser.telephone_bur" placeholder="Rechercher..." class="input-with-select">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Adresse</label>
              <el-input v-model="detailsUser.adresse" placeholder="Rechercher..." class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Profession</label>
              <el-input v-model="detailsUser.profession" placeholder="Rechercher..." class="input-with-select">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Date de naissance</label>
              <el-date-picker style="width: 100%!important;" @change="yearsDiff()" v-model="detailsUser.datenaissance"
                type="date" placeholder="Choississez une date" :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Sexe</label>
              <el-select style="width: 100%!important;" v-model="detailsUser.sexe" placeholder="Selectionner">
                <el-option label="homme">Homme</el-option>
                <el-option label="femme">Femme</el-option>
                <el-option label="entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Statut Marital</label>
              <el-select style="width: 100%!important;" v-model="detailsUser.statut_marital" placeholder="Selectionner">
                <el-option label="célibataire">célibataire</el-option>
                <el-option label="marié">marié(e)</el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Adresse mail</label>
              <el-input v-model="detailsUser.email" placeholder="Rechercher..." class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Type piéce identité</label>
              <el-input v-model="detailsUser.piece_identite" placeholder="Rechercher..." class="input-with-select">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div>
              <label>Numéro piéce</label>
              <el-input v-model="detailsUser.numero_piece" placeholder="Rechercher..." class="input-with-select">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
             <div class="grid-content bg-purple" v-if="this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'">
                <label>Rôle</label>
                <el-select style="width: 100%" v-model="detailsUser.roles" placeholder="Selectionner">
                  <el-option
                      v-for="role in listRoles"
                      :key="role._id"
                      :label="role.name"
                      :value="role._id"
                    />
                </el-select>
              </div>
          </el-col>

        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdateUser()">Modifier</el-button>
          <el-button type="danger" round @click="editUser = false">Annuler</el-button>
        </div>
        <div class="mb-5" style="margin-top: 15px" v-if="$store.state.user.role == 'SuperAdmin' && this.detailsUser.roles.name == 'Agent' || $store.state.user.role == 'Admin' && this.detailsUser.roles.name == 'Agent'">
          <el-button type="primary" round @click="loadConfigBranchePopUp()">Configuration de branche</el-button>
        </div>
      </div>

    </el-dialog>

    <el-dialog width="70%" title="Configuration de menu" :visible.sync="showuser" v-if="showConfigMenu && !isMobile">
      <el-row :gutter="15">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">

              <div slot="header" class="clearfix">
                <span>Nom du menu principal</span>
              </div>
              <div>
                <el-select style="width: 100%!important;" @change="setCollapse()" v-model="menu_name" placeholder="Selectionner">
                  <el-option v-for="(items, k) in submenu" :key="k" :label="items.theme" :value="items.theme">
                    {{ items.theme }}
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:15px">
                <label>id menu pricipal</label>
                <el-input v-model="collapse" placeholder="id menu principal" disabled></el-input>
                <el-button class="mt-5 mb-5" style="float:left" type="primary" @click="pushMenu()" round>Ajouter</el-button>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="16">
          <el-card class="box-card grow" border>
            <div class="grid-content bg-purple" v-for="(item, index) in selectedItemMenu" :key="index" style="margin-bottom: 15px">
                <div slot="header" class="clearfix">
                  <span style="color: red">{{ item.theme }}</span>
                </div>
                <div v-for="(item, id) in item.config" :key="id" style="margin-top:15px">
                  <el-checkbox v-model="userSubmenu" :label="item" border unchecked-value="not_accepted">
                    {{ item.libelle }}
                  </el-checkbox>                 
                </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div style="margin-top:15px;margin-bottom: 30px">
        <el-button type="primary" style="float: right" @click="create_user_menu()" round>Valider le menu</el-button>
      </div>
      
      <el-row  style="margin-top:15px">
        <el-table :data="UserFinalMenu" style="width: 100%" :height="height">
        <el-table-column label="Menu" min-width="200"  prop="theme"></el-table-column>
        <el-table-column label="Sous menu" min-width="250" prop="config">
          <template slot-scope="scope">
            <ul>
              <li v-for="(item, index) in scope.row.config" :key="index">
                {{item.libelle}}
              </li>
            </ul>
          </template>
        </el-table-column>
      </el-table>
      </el-row>

    </el-dialog>

    <el-dialog width="90%" title="Configuration de menu" :visible.sync="showuser" v-if="showConfigMenu && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">

              <div slot="header" class="clearfix">
                <span>Nom du menu principal</span>
              </div>
              <div>
                <el-select style="width: 100%!important;" @change="setCollapse()" v-model="menu_name"
                  placeholder="Selectionner">
                  <el-option v-for="(items,k) in submenu" :key="k" :label="items.theme" :value="items.theme">
                    {{ items.theme }}
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:15px">
                <label>id menu pricipal</label>
                <el-input v-model="collapse" placeholder="id menu principal" disabled></el-input>
                <el-button class="mt-5 mb-5" style="float:left" type="primary" @click="pushMenu()" round>Ajouter
                </el-button>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card" v-for="(item, index) in submenu" :key="index" style="margin-bottom: 15px">
              <div slot="header" class="clearfix">
                <span style="color: red">{{ item.theme }}</span>
              </div>
              <div v-for="(item, id) in item.config" :key="id" style="margin-top:15px">
                <el-checkbox v-model="userSubmenu" :label="item" border unchecked-value="not_accepted">
                  {{ item.libelle }}
                </el-checkbox>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <div style="margin-top:15px;margin-bottom: 30px">
        <el-button type="primary" style="float: right" @click="create_user_menu()" round>Valider le menu</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Branche et assureurs" :visible.sync="modalSociete" v-if="modalSociete && !isMobile">
      <el-row :gutter="15">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">

              <div slot="header" class="clearfix">
                <span>Choisir une branche</span>
              </div>
              <div>
                <el-select style="width: 100%!important;" @change="setCollapse()" v-model="Selectedbranche"
                  placeholder="Selectionner">
                  <el-option v-for="items in branchelist" :key="items" :value="items.branche">
                    {{ items.branche }}
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:15px">
                <el-button class="mt-5 mb-5" style="float:left" type="primary" @click="pushConfig()" round>Ajouter
                </el-button>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card" style="margin-bottom: 15px">
             <div slot="header" class="clearfix">
                <span>Choisir les assureurs</span>
              </div>
              <div v-for="(item, id) in listeAssureurs" :key="id" style="margin-top:15px">
                <el-checkbox v-model="selectedAssureur" :label="item.societe" :value="item" border unchecked-value="not_accepted">
                  {{ item.societe }}
                </el-checkbox>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <div>
         <el-table
          :data="configUserDevisAssureur"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Branche" min-width="200"  prop="branche" >

        </el-table-column>
        <el-table-column label="Assureur" min-width="250" prop="assureurs">
           <template slot-scope="scope">
             <ul>
                    <li v-for="(item, index) in scope.row.assureurs" :key="index">
                        {{item.societe}}
                    </li>
                    </ul>
          </template>
        </el-table-column>
         <el-table-column fixed="right" label="Actions" prop="action" min-width="100" v-if="!isMobile">
          <template slot-scope="scope">
            <el-button @click="DeleteBrancheCourtier(scope.row)" type="danger" icon="el-icon-delete" circle>
            </el-button>
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="260" v-if="isMobile">
          <template slot-scope="scope">
            <el-button @click="DeleteBrancheCourtier(scope.row)" type="danger" icon="el-icon-delete" circle>
            </el-button>
          </template>
        </el-table-column>
        
      </el-table>
      </div>
      <div style="margin-top:15px;margin-bottom: 30px">
        <el-button type="primary" style="float: left" @click="AddbrancheConfig()" round>Valider</el-button>
      </div>
    </el-dialog>

    <el-dialog width="90%" title="Branche et assureurs" :visible.sync="modalSociete" v-if="modalSociete && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">

              <div slot="header" class="clearfix">
                <span>Choisir une branche</span>
              </div>
              <div>
                <el-select style="width: 100%!important;" @change="setCollapse()" v-model="Selectedbranche"
                  placeholder="Selectionner">
                  <el-option v-for="items in branchelist" :key="items" :value="items.branche">
                    {{ items.branche }}
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:15px">
                <el-button class="mt-5 mb-5" style="float:left" type="primary" @click="pushConfig()" round>Ajouter
                </el-button>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top :15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card" style="margin-bottom: 15px">
             <div slot="header" class="clearfix">
                <span>Choisir les assureurs</span>
              </div>
              <div v-for="(item, id) in listeAssureurs" :key="id" style="margin-top:15px">
                <el-checkbox v-model="selectedAssureur" :label="item.societe" :value="item" border unchecked-value="not_accepted">
                  {{ item.societe }}
                </el-checkbox>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <div style="margin-top:15px">
         <el-table
          :data="configUserDevisAssureur"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Branche" min-width="100"  :fixed="!isMobile"></el-table-column>
        <el-table-column label="Assureur" min-width="250" prop="assureurs">
           <template slot-scope="scope">
             <ul>
                    <li v-for="(item, index) in scope.row.assureurs" :key="index">
                        {{item.societe}}
                    </li>
                    </ul>
          </template>
        </el-table-column>
         <el-table-column fixed="right" label="Actions" prop="action" min-width="100" v-if="!isMobile">
          <template slot-scope="scope">
            <el-button @click="DeleteBrancheCourtier(scope.row)" type="danger" icon="el-icon-delete" circle>
            </el-button>
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="150" v-if="isMobile">
          <template slot-scope="scope">
            <el-button @click="DeleteBrancheCourtier(scope.row)" type="danger" icon="el-icon-delete" circle>
            </el-button>
          </template>
        </el-table-column>
        
      </el-table>
      </div>
      <div style="margin-top:15px;margin-bottom: 30px">
        <el-button type="primary" style="float: left" @click="AddbrancheConfig()" round>Valider</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Suppression utilisateur" width="50%" :visible.sync="modalDelete" v-if="modalDelete == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer cet utilisateur?</span>
          </div>
          <div  >
            <ul style="list-style:none">
              <li>Utilisateur : <span class="to-right assuraf-orange">{{ detailsUser.prenom.toUpperCase() }}
                  {{ detailsUser.nom.toUpperCase() }}</span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="DeletingUser(detailsUser._id)">Oui</el-button>
        <el-button type="primary" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>

     <el-dialog :show-close="false" title="Alerte" width="50%" :visible.sync="alertMergeMessage" v-if="alertMergeMessage == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div  >
            <ul style="list-style:none">
              <li><span class="to-right" style="color:red!important">
                <h3>opération réussie, Veuillez supprimer cet utilisateur de la base. Vous serez redirigé vers la liste des utilisateurs en cliquant sur le bouton OK!</h3></span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="toBackOfficeuser()">OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      selectedItemMenu : [],
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      listRoles:null,
      alertMergeMessage : false,
      editUser: false,
      Selectedbranche: null,
      selectedAssureur: [],
      configUserDevisAssureur: null,
      assureurs: [],
      branchelist: null,
      modalSociete: false,
      configHead: [
        { key: "branche", label: "branche" },
        { key: "assureurs", label: "assureur" },
        { key: "action", label: "Action" }
      ],
      selectedUser: null,
      listeAssureurs: null,
      usermenu: [],
      collapse: null,
      menu_name: null,
      showConfigMenu: false,
      submenu: null,
      userSubmenu: [],
      UserFinalMenu: [],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: [],
      filter: null,
      showuser: false,
     
      modalgenerationloading: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listuser, //users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listuser.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
   
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
    getUserRoles(){ 
      this.listRoles = []
      Api().get('/roles/all')
      .then(response => {
        this.listRoles = response.data.AllRoles

        }).catch(err => {console.log("failed getAllUserRoles");})
    },

    toCreateNewUser() {
      this.$router.go(this.$router.push('/user/back/createuser'))
    },

    toBackOfficeuser() {
      this.$router.go(this.$router.push('/users/frontoffice'))
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    toggleDetails(item) {
      this.detailsUser = item
      this.editUser = true
    },

    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", { id: item._id })
        .then((result) => {
          console.log(result)
          Api().post("/branchecourtier/get/all/", { userid: item.userid })
            .then((response) => {
              console.log(response)
              this.configUserDevisAssureur = response.data.partenaires
            })
            .catch((err) => { });
        }).catch((err) => {

        });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", { configuration: this.configUserDevisAssureur })
        .then((result) => {
          this.configUserDevisAssureur = []
        }).catch((err) => {
          console.log(err)
        });
    },

    pushConfig() {
      if (this.Selectedbranche && this.selectedAssureur) {
        this.configUserDevisAssureur.push({
          branche: this.Selectedbranche,
          assureurs: this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp() {
      let item = this.detailsUser
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", { userid: item.id })
        .then((response) => {
          this.configUserDevisAssureur = response.data.partenaires
          console.log(this.configUserDevisAssureur)
        })
        .catch((err) => { });
    },

    getAllbranche() {
      Api().get("/branche/")
        .then((result) => {
          this.branchelist = result.data
        }).catch((err) => {
          console.log(err)
        });
    },

    getListPartenaire: function () {
      if (this.$store.state.user.role == "Agent") {
        Api().post('/branchecourtier/get/all', { userid: this.$store.state.user._id })
          .then(response => {
            this.listeAssureurs = response.data.partenaires
            console.log(this.listeAssureurs)
          }).catch(err => { })

      } else if (this.$store.state.user.role == "agent général") {
        Api().get("/partenaire/" + this.$store.state.user.societe)
          .then((response) => {
            this.listeAssureurs = response.data.partenaire;
          });
      } else if (this.$store.state.user.role == "Admin" || this.$store.state.user.role == 'SuperAdmin') {
        Api().get("/partenaire/")
          .then((response) => {
            this.listeAssureurs = response.data.partenaires; //.assureurs_partenaires
          });
      } else if (this.$store.state.user.role == "assureur") {
        Api().get("/partenaire/" + this.$store.state.user.societe)
          .then((response) => {
            this.listeAssureurs = response.data.partenaire;
          });
      }
    },

    setCollapse() 
    {
      this.collapse = "id_" + Date.now() + "_collapse"
      for(let item of this.submenu)
      {
        if(item.theme==this.menu_name)
        {
          this.selectedItemMenu[0]=item
          for(let v of item.config)
          {
            this.userSubmenu.push(v)
          }
        }
      }
    },

    MenuDelete(item) {
      Api().delete("/menu/" + item._id)
        .then((result) => {
          this.getUserMenu(this.detailsUser._id)
        }).catch((err) => {

        });
    },

    pushMenu() {
      if (this.menu_name && this.userSubmenu.length >= 1) {
        this.UserFinalMenu.push({
          user: this.detailsUser.id,
          theme: this.menu_name,
          collapse: this.collapse,
          config: this.userSubmenu
        })
        this.menu_name        = null
        this.userSubmenu      = []
        this.collapse         = null
        this.selectedItemMenu = []
      } else {
        this.$message.error('Remplissez les champs vides !', this.AlertMessage)
      }
    },

    create_user_menu() {
      if (this.UserFinalMenu.length >= 1) {
        Api().post("/menu/add", this.UserFinalMenu)
          .then((result) => {
            this.$message.success('Opération effectuée', this.AlertMessage)
            console.log(result.data)
            this.UserFinalMenu = []
          }).catch((err) => {
            console.log(err)
          });
      } else {
        this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
      }
    },

    merge() {
      Api().post('/user/merge/userdata', { userid: this.detailsUser.id })
        .then((result) => {
          this.showuser = false
          if (result.data.result) {
            this.$message.success('Opération réussie', this.AlertMessage)
            this.alertMergeMessage = true
          }
        }).catch((err) => {
          console.log('=======err=========')
        });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.showuser = true
      this.showConfigMenu = false
      this.getAllbranche()
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList: function () {
      switch (this.$store.state.user.role) {
        case 'SuperAdmin':
          Api().get('/user')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
        break;

        case 'Admin':
          Api().get('/user')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;

        case 'Agent':
          Api().get('/user/myuser/courtier/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            }).catch(err => {

            });
          break;
      }
    },

    UpdateUser() {
      let idU = this.detailsUser.id
      let item = this.detailsUser
      console.log("item")
      console.log(item)
      Api().post('user/' + idU, item)
        .then(response => {
          this.editUser = false
          this.$message.success('Modification effectuée avec succés !', this.AlertMessage)
        })
        .catch(err => {
        })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().post('user/delete/one', {deleter :store.state.user._id, todelete: id})
        .then(resultat => {
          if (resultat.status == 200) {
            this.modalDelete = false
            this.getUserList()
          }
        })
        .catch(err => { })
    },

    getUserMenu(id) {
      Api().post('/menu/user/menu', { userid: id })
        .then((result) => {
          this.usermenu = result.data.menus
          //console.log(result.data.menus)
        }).catch((err) => {
          console.log(err)
        });

    },

    getAllSousMenu() {
      Api().post("/sousmenu", { roles: this.$store.state.user.role })
        .then((result) => {
          this.submenu = result.data
        }).catch((err) => {

        });
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }
      //return value.toString().replace(new RegExp(sel,"gim"), `<span class="sel">${sel}</span>`)
      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getUserList()
      this.getAllSousMenu()
      this.getListPartenaire()
      this.getAllbranche()
      this. getUserRoles()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
