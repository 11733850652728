<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Transférer<span style="color:#ffa409"> Devis</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div>
        <el-row :gutter="20" style="margin-top: 15px;">
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Numéro de devis</label>
              <el-input placeholder="Numéro de devis" v-model="numero_devis">
              </el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Adresse email du destinataire</label>
              <el-input placeholder="Adresse email" v-model="email_destinataire">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top:15px">
        <el-button @click="forwardDevis()" type="primary" round>Transférer le devis</el-button>
      </div>
    </div>

     <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div>
        <el-row :gutter="20" style="margin-top: 15px;">
          <el-col :span="24">
            <div class="grid-content bg-purple-light">
              <label>Numéro de devis</label>
              <el-input placeholder="Numéro de devis" v-model="numero_devis">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Adresse email du destinataire</label>
              <el-input placeholder="Adresse email" v-model="email_destinataire">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top:15px">
        <el-button @click="forwardDevis" type="primary" round>Transférer le devis</el-button>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import pays from '../../../common/countries'
//fin importation assuraf

const validatePhone = phone => {

  if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else if (phone.length < 9 || phone.length > 9) {
    return { valid: false, error: 'Le numéro de téléphone doit contenir 9 chiffres au minimum.' };
  }else{
    return { valid: true, error: null };
  }

};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse mail est obligatoire!"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
  name: "devis_voyage",

  data() {
    return {
      isMobile: false,
      numero_devis:null,
      email_destinataire:null,
      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    
    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    
    forwardDevis()
    {
      if(this.numero_devis && this.email_destinataire)
      {
        Api().post('/devis/forward', {devis:this.numero_devis, destinataire: this.email_destinataire})
            .then((result) => {
              console.log(result)
              if (result.data.devis.nModified==1){
                this.$message.success("Devis transféré à " + this.email_destinataire, this.AlertMessage)
                this.numero_devis       = null
                this.email_destinataire = null
              }else if (result.data.devis.nModified==0){
                this.$message.error("Le devis est déja lié à " + this.email_destinataire, this.AlertMessage)
                this.email_destinataire       = null
              }
               else{
                this.$message.error("Echec de l'opération", this.AlertMessage)
              }
            }).catch((err) => {
          console.log(err)
        })
      }else{
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },



    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },


    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
    
    
    
  },
   created() {
    this.init()
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
