<template>
    <vue-scroll class="page-devis-voyage">
        <div class="page-header">
            <h1>Création de devis <span style="color:#ffa409">Assur'Hospi</span>
                <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>

        <resize-observer @notify="__resizeHanlder" />


        <div v-if="hidesearchClient && !isMobile"
            class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
                <el-row :gutter="15" justify="center">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de
                                téléphone</el-radio>
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse
                                e-mail</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" style="margin-top:15px">
                    <el-col :span="10">
                        <el-input v-if="NumberInput" type="text" v-model="telephoneclient"
                            placeholder="Votre numéro de téléphone" class="input-with-select">
                            <el-button v-if="clientsearch === 'téléphone'" @click="rechercherClient()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                        <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail"
                            class="input-with-select">
                            <el-button v-if="clientsearch === 'email'" @click="rechercheClientByMail()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span
                        style="font-weight: bold;color: #ffa409;">{{ usersearch.prenom + ' '
                            + usersearch.nom }}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary"
                        round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser = true" type="primary"
                        round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="hidesearchClient && isMobile"
            class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
                <el-row :gutter="15" justify="center">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de
                                téléphone</el-radio>
                            <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse
                                e-mail</el-radio>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="15" style="margin-top:15px">
                    <el-col :span="24">
                        <el-input v-if="NumberInput" type="number" v-model="telephoneclient"
                            placeholder="Votre numéro de téléphone" class="input-with-select">
                            <el-button v-if="clientsearch === 'téléphone'" @click="rechercherClient()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                    <el-col :span="24" style="margin-top:15">
                        <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail"
                            class="input-with-select">
                            <el-button v-if="clientsearch === 'email'" @click="rechercheClientByMail()" slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span
                        style="font-weight: bold;color: #ffa409;">{{ usersearch.prenom +
                            ' ' + usersearch.nom }}</span><br><el-button style="margin-top:13px" @click="hidesearch"
                        type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser = true" type="primary"
                        round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:10px">
                <h3>Simulation :</h3>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <label>Date naissance assuré </label>
                            <el-date-picker @change="yearsDiff" style="width: 100%!important;" v-model="dateNaiss"
                                type="date" format="yyyy/MM/dd" placeholder="Choississez une date"
                                :picker-options="dateBeforeToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Date naissance bénéficiaire</label>
                            <el-date-picker @change="yearsDiffBenef" style="width: 100%!important;"
                                v-model="Assurhospi.Beneficiaire.dateNaissance" format="yyyy/MM/dd" type="date"
                                :picker-options="dateBeforeToday" placeholder="Choississez une date">
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-10">
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Formule </label>
                            <el-select style="width:100%" @change="setFormuleCapitaux" v-model="Assurhospi.capital"
                                placeholder="Selectionner une formule">
                                <el-option value="Selectionner une formule" selected :disabled="true"></el-option>
                                <el-option v-for="(items, idx) in listecapitaux" :key="idx" :label="items.capital"
                                    :value="items.capital">
                                    {{ items.capital.toLocaleString() + " CFA" }}
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Durée en mois </label>
                            <el-select style="width:100%" @change="setFormuleDureeCapitaux" v-model="Assurhospi.duree"
                                placeholder="Nombre de mois">
                                <el-option value="Nombre de mois" selected :disabled="true"></el-option>
                                <el-option v-for="items in nbmois" :key="items" :label="items" :value="items">
                                    {{ items }}
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="mt-5">
                <div v-if="Assurhospi.capital != null">

                    <div justify="center" style="margin-top:30px">
                        <el-row type="flex" class="row-bg" justify="center">
                            <el-col :span="24">
                                <el-descriptions style="font-size:20px">
                                    <el-descriptions-item label="Capital Décès:">
                                        <span style="color:#ffa409"> {{ Assurhospi.capital.toLocaleString() + " CFA" ||
                                            null }}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                                <el-descriptions style="font-size:20px;margin-top:-10px">
                                    <el-descriptions-item
                                        label="Couverture maximale par an (30 nuits d'hospitalisations) :">
                                        <span style="color:#ffa409">
                                            {{ Assurhospi.hospitalisation.toLocaleString() + " CFA" || null }}
                                        </span>
                                    </el-descriptions-item>
                                </el-descriptions>
                                <el-descriptions style="font-size:20px;margin-top:-10px">
                                    <el-descriptions-item label="Prime total:">
                                        <span style="color:#ffa409">{{ Assurhospi.prime.toLocaleString() + " CFA" ||
                                            null }}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </div>


                </div>

            </div>

            <div style="margin-top:15px">
                <!-- <el-button style="float:left" @click="e1 = 1" round>Retour</el-button> -->
                <el-button @click="DevisNextStep" type="primary" round>Suivant</el-button>
            </div>
        </div>
        <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3>Contractant :</h3>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <el-form>
                                <el-form-item>
                                    <label>Genre : </label>
                                    <el-radio-group v-model="Assurhospi.Contractant.Genre">
                                        <el-radio label="homme">Monsieur</el-radio>
                                        <el-radio label="femme">Madame</el-radio>
                                        <el-radio label="entreprise">Entreprise</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col style="margin-top: -30px;" :span="24">
                        <div class="grid-content bg-purple">
                            <el-form>
                                <el-form-item>
                                    <label>Situation : </label>
                                    <el-radio-group v-model="Assurhospi.Contractant.Situation">
                                        <el-radio label="marié">Marié(e)</el-radio>
                                        <el-radio label="célibataire">Célibataire</el-radio>
                                        <el-radio label="veuf">Veuf(ve)</el-radio>
                                        <el-radio label="divorce">Divorcé(e)</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Nom</label>
                            <el-input placeholder="Votre nom" v-model="Assurhospi.Contractant.Nom">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Prénom</label>
                            <el-input placeholder="Votre prenom" v-model="Assurhospi.Contractant.Prenom">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Date de naissance</label>
                            <el-date-picker @change="yearsDiff()" style="width: 100%!important;"
                                v-model="Assurhospi.Contractant.dateNaissance" type="date" format="yyyy/MM/dd"
                                placeholder="Choississez une date" :picker-options="dateBeforeToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Lieu de naissance</label>
                            <el-input placeholder="Votre lieu de naissance" v-model="Assurhospi.Contractant.LieuNaissance">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Profession</label>
                            <el-input placeholder="Votre profession" v-model="Assurhospi.Contractant.Profession">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Adresse</label>
                            <el-input type="text" placeholder="Votre adresse" v-model="Assurhospi.Contractant.Adresse">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Téléphone</label>
                            <el-input type="number" placeholder="Votre numéro de téléphone"
                                v-model="Assurhospi.Contractant.Telephone">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Email</label>
                            <el-input placeholder="Adresse e-mail" v-model="Assurhospi.Contractant.Email" class="focus">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>CNI</label>
                            <el-input type="text" placeholder="Votre numéro de CNI" class="focus"
                                v-model="Assurhospi.Contractant.Cni">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 = 2" round>Retour</el-button>
                <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
            </div>
        </div>
        <div v-if="e1 == 4 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>

                <h3>Assuré :</h3>
                <el-row :gutter="20" type="flex" justify="center">
                    <el-col :span="24">
                        <div class="justfy-center grid-content bg-purple-dark">
                            <p>Le contractant est l'assuré ?</p>
                            <el-switch style="display: block" v-model="checkbox" active-color="#13ce66"
                                inactive-color="#ff4949" active-text="Oui" inactive-text="Non" @change="toggleCheckbox">
                            </el-switch>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Nom *</label>
                            <el-input :disabled="this.checkbox == true" placeholder="Votre nom"
                                v-model="Assurhospi.Assure.Nom">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Prénom *</label>
                            <el-input :disabled="this.checkbox == true" placeholder="Votre prenom"
                                v-model="Assurhospi.Assure.Prenom">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Date de naissance *</label>
                            <el-date-picker :disabled="disableDateAssure" @change="yearsDiff()"
                                style="width: 100%!important;" v-model="Assurhospi.Assure.dateNaissance" type="date"
                                format="yyyy/MM/dd" placeholder="Choississez une date" :picker-options="dateBeforeToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Lieu de naissance</label>
                            <el-input :disabled="this.checkbox == true" placeholder="Votre lieu de naissance"
                                v-model="Assurhospi.Assure.LieuNaissance">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Profession</label>
                            <el-input :disabled="this.checkbox == true" placeholder="Votre profession"
                                v-model="Assurhospi.Assure.Profession">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Adresse</label>
                            <el-input :disabled="this.checkbox == true" type="text" placeholder="Votre adresse"
                                v-model="Assurhospi.Assure.Adresse">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Téléphone</label>
                            <el-input :disabled="this.checkbox == true" type="number"
                                placeholder="Votre numéro de téléphone" v-model="Assurhospi.Assure.Telephone">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Email</label>
                            <el-input :disabled="this.checkbox == true" placeholder="Adresse e-mail"
                                v-model="Assurhospi.Assure.Email">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Lieu d'exercice profession</label>
                            <el-input type="text" min="1" placeholder="Votre lieu d'exercice"
                                v-model="Assurhospi.Assure.LieuExe">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8" style="margin-top: 15px">
                        <div class="grid-content bg-purple-light">
                            <label>CNI *</label>
                            <el-input type="text" min="1" placeholder="Votre lieu d'exercice"
                                v-model="Assurhospi.Assure.Cni">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 3">Retour</el-button>
                    <el-button @click="AssureControl" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 5 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>

                <h3>Bénéficiaire :</h3>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Nom *</label>
                            <el-input placeholder="Votre nom" v-model="Assurhospi.Beneficiaire.Nom">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Prénom *</label>
                            <el-input placeholder="Votre prenom" v-model="Assurhospi.Beneficiaire.Prenom">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>CNI *</label>
                            <el-input placeholder="Votre piéce d'identité" v-model="Assurhospi.Beneficiaire.Cni">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top: 15px">


                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Filiation</label>
                            <el-select style="width:100%" v-model="Assurhospi.Beneficiaire.Filiation"
                                placeholder="Selectionner une filiation">
                                <el-option value="null" selected disabled="">Filiation</el-option>
                                <el-option value="pere_mere">Père/Mère</el-option>
                                <el-option value="fils_fille">Fils/Fille</el-option>
                                <el-option value="frere_soeur">Frère/Soeur</el-option>
                                <el-option value="epoux_epouse">Epoux/Epouse</el-option>
                                <el-option value="autres">Autre (adoptive,..)</el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Date de naissance</label>
                            <el-date-picker disabled @change="yearsDiffBenef()" style="width: 100%!important;"
                                v-model="Assurhospi.Beneficiaire.dateNaissance" type="date" format="yyyy/MM/dd"
                                placeholder="Choississez une date" :picker-options="dateBeforeToday">
                            </el-date-picker>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Lieu de naissance</label>
                            <el-input placeholder="Votre lieu de naissance" v-model="Assurhospi.Beneficiaire.LieuNaissance">
                            </el-input>
                        </div>

                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 4">Retour</el-button>
                    <el-button @click="BenefControl" type="primary" round>Suivant</el-button>
                </div>
            </div>
        </div>

        <div v-if="e1 == 6 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Contractant</span>
                                            </div>
                                            <div>
                                                <ul style="list-style:none">
                                                    <li>Genre : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Genre }}</span>
                                                    </li>
                                                    <li>Situation : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Situation }}</span>
                                                    </li>
                                                    <li>Nom : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Nom
                                                    }}</span>
                                                    </li>
                                                    <li>Prénom : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Prenom }}</span>
                                                    </li>
                                                    <li>Date de naissance : <span class="to-right assuraf-orange">{{
                                                        DateFormater(Assurhospi.Contractant.dateNaissance) }}</span>
                                                    </li>
                                                    <li>Lieu de naissance : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.LieuNaissance }}</span>
                                                    </li>
                                                    <li>Profession : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Profession }}</span>
                                                    </li>

                                                    <li>Adresse : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Adresse }}</span>
                                                    </li>
                                                    <li>Téléphone : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Telephone }}</span>
                                                    </li>
                                                    <li>Email : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Email }}</span>
                                                    </li>
                                                    <li>CNI : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Cni
                                                    }}</span>
                                                    </li>
                                                    <li>Nationalite : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Contractant.Nationalite }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="grid-content bg-purple-light"
                                        style="margin-bottom: 20px;margin-left: 20px;">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Assuré</span>
                                            </div>
                                            <div>
                                                <ul style="list-style:none">
                                                    <li>Nom : <span class="to-right assuraf-orange">{{ Assurhospi.Assure.Nom
                                                    }}</span></li>
                                                    <li>Prénom : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Assure.Prenom }}</span>
                                                    </li>
                                                    <li>Date de naissance : <span class="to-right assuraf-orange">{{
                                                        DateFormater(Assurhospi.Assure.dateNaissance) }}</span>
                                                    </li>
                                                    <li>Lieu de naissance : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Assure.LieuNaissance }}</span>
                                                    </li>
                                                    <li>Profession : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Assure.Profession }}</span>
                                                    </li>
                                                    <li>Adresse : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Assure.Adresse }}</span>
                                                    </li>
                                                    <li>Téléphone : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Assure.Telephone }}</span>
                                                    </li>
                                                    <li>Email : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Assure.Email
                                                    }}</span>
                                                    </li>
                                                    <li>Nationalite : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Assure.Nationalite }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="grid-content bg-purple-light"
                                        style="margin-bottom: 20px;margin-left: 20px;">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Bénéficiaire</span>
                                            </div>

                                            <div>
                                                <ul style="list-style:none">
                                                    <li>Nom : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Beneficiaire.Nom
                                                    }}</span></li>
                                                    <li>Prénom : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Beneficiaire.Prenom }}</span>
                                                    </li>
                                                    <li>Date de naissance : <span class="to-right assuraf-orange">{{
                                                        DateFormater(Assurhospi.Beneficiaire.dateNaissance) }}</span>
                                                    </li>
                                                    <li>Lieu de naissance : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Beneficiaire.LieuNaissance }}</span>
                                                    </li>
                                                    <li>Filiation : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.Beneficiaire.Filiation }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">DÉTAILS DES COTISATIONS</h3>
                            <el-row :gutter="24" style="margin-top:20px">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>Garanties</span>
                                            </div>
                                            <div>
                                                <ul style="list-style:none">
                                                    <li>Durée : <span class="to-right assuraf-orange">{{ Assurhospi.duree }}
                                                            mois</span></li>

                                                    <li>Prime mensuelle : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.prime.toLocaleString() }} CFA</span>
                                                    </li>
                                                    <li>Branche : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.branche ? 'Assur\'Hospi' : '' }}</span>
                                                    </li>
                                                    <li>Assureur : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.assureur }}</span> </li>
                                                    <li>Capital : <span class="to-right assuraf-orange">{{
                                                        Assurhospi.capital.toLocaleString() }} CFA</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="item-box card-shadow--medium" style="margin-top:15px">
                            <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
                            <el-row :gutter="24" style="margin-top:20px">
                                <el-col :span="12">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Date d'effet</label>
                                        <el-date-picker @change="dateDiff" style="width: 100%!important;"
                                            v-model="Assurhospi.dateEffet" type="date" format="yyyy/MM/dd"
                                            placeholder="Choississez une date" :picker-options="dateAfterToday">
                                        </el-date-picker>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                        <label>Date d"échéance</label>
                                        <el-input placeholder="Date d'échéance" v-model="Assurhospi.dateEcheance" readonly>
                                        </el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 5">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary"
                        round>Valider</el-button>
                </div>
            </div>
        </div>
    </vue-scroll>
</template>



<script>
import Beneficiaire from "../../../models/Beneficiaire.class"
import Contractant from "../../../models/Contractant.class"
import Assure from "../../../models/Assure.class"
import AssurHospi from "../../../models/micro/Assurhospi"
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import pays from '../../../common/countries'
import ville from '../../../common/ville.json'
//fin importation assuraf
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
    if (!phone.length) {
        return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
    } else {
        return { valid: true, error: null };
    }
};

const validateEmail = email => {
    if (!email.length) {
        return { valid: false, error: "L'adresse e-mail est obligatoire!" };
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return { valid: false, error: "Veuillez entrer un email valide!" };
    } else {
        return { valid: true, error: null };
    }
};

export default {
    name: "devis_nsia_etudes",

    data() {
        return {

            type_carte: 'CNI',
            isMobile: false,
            modalgenerationloading: false,
            oldDate: null,
            disableDateAssure: true,
            money: {
                thousands: " ",
                precision: false,
                masked: false,
            },
            dateNaiss: null,
            duree: null,
            rente_an: null,
            pourcent: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
            choixCapital: {
                Capital: null,
                Mensuel: null,
            },
            checkbox: false,
            errors: {},
            genre: null,
            animation: "animate-in",

            Assurhospi: new AssurHospi(),


            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() < (dayjs(new Date())).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            selected: [],
            age: null,
            usersearch: [],
            telephoneclient: '',
            NumberInput: false,
            EmailInput: false,
            e1: 1,

            emailclient: null,
            emailC: this.emailclient,
            telephoneC: this.telephoneclient,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            hideDive_vehicule: null,
            password1: null,
            garanties: ['HOSPITALISATION', 'DECES ET INVALIDITE TOTALE ET DEFINITIVE'],
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            userRole: null,
            formule: null,
            nbmois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            listecapitaux: [
                {
                    capital: 100000,
                    hospitalisation: 150000,
                    prime: 340,
                },
                {
                    capital: 500000,
                    hospitalisation: 750000,
                    prime: 1700,
                },
                {
                    capital: 1000000,
                    hospitalisation: 1500000,
                    prime: 3400,
                }

            ]
        }
    },

    methods: {
        setFormuleDureeCapitaux() {
            this.Assurhospi.prime = this.formule.prime * this.Assurhospi.duree

        },
        setFormuleCapitaux() {
            this.formule = this.listecapitaux.find(cap => cap.capital == this.Assurhospi.capital)
            this.Assurhospi.hospitalisation = this.formule.hospitalisation
            this.Assurhospi.prime = this.formule.prime
            this.Assurhospi.duree = 1
        },
        dateDiff: function () {

            //this.Assurhospi.dateEffet = dayjs(today).add(1, "day").format("YYYY-MM-DD");

            if (this.Assurhospi.dateEffet) {
                let inter = dayjs(this.Assurhospi.dateEffet).add(this.Assurhospi.duree, "month").format("YYYY-MM-DD");
                this.Assurhospi.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
            }
        },
        BenefControl() {
            if (this.Assurhospi.Beneficiaire.Nom && this.Assurhospi.Beneficiaire.Prenom) {
                if (
                    this.Assurhospi.Beneficiaire.Cni
                ) {
                    this.dateDiff()
                    this.e1 = 6;
                } else {
                    this.$message.error('le cni est obligatoires !', this.AlertMessage)
                }
            } else {
                this.$message.error('Nom et Prénom obligatoires !', this.AlertMessage)
            }
        },

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format("DD-MM-YYYY");
            }
        },
        toggleCheckbox() {
            if (this.checkbox == true) {
                this.Assurhospi.Assure.Nom = this.Assurhospi.Contractant.Nom;
                this.Assurhospi.Assure.Prenom = this.Assurhospi.Contractant.Prenom;
                // this.Assurhospi.Assure.dateNaissance = this.DateFormater(
                //     this.Assurhospi.Contractant.dateNaissance
                // );
                this.Assurhospi.Assure.LieuNaissance = this.Assurhospi.Contractant.LieuNaissance;
                this.Assurhospi.Assure.Profession = this.Assurhospi.Contractant.Profession;
                this.Assurhospi.Assure.Adresse = this.Assurhospi.Contractant.Adresse;
                this.Assurhospi.Assure.Telephone = this.Assurhospi.Contractant.Telephone;
                this.Assurhospi.Assure.Email = this.Assurhospi.Contractant.Email;
                this.disableDateAssure = true;

                this.AssureControl();
            } else if (this.checkbox == false) {
                //this.disableDateAssure = false
                //this.dateNaiss = null
                this.Assurhospi.Assure.dateNaissance = this.oldDate;
                this.dateNaiss = this.oldDate;
                //this.Assurhospi.Assure.dateNaissance = this.dateNaiss
                this.ClearForm();
            }
        },
        AssureControl() {
            const date2 = dayjs(new Date().toISOString().substr(0, 10));

            const date1 = dayjs(this.Assurhospi.Assure.dateNaissance);
            let daydiff = date2.diff(date1, "days");
            let yearAss = Math.floor(daydiff / 360);
            if (yearAss < 1 || yearAss > 70) {
                this.$message.error("Date assuré doit etre dans l'interval 1 a 70ans !", this.AlertMessage)
                return;
            }
            this.errors = {};
            if (this.Assurhospi.Assure.Nom && this.Assurhospi.Assure.Prenom) {
                if (this.Assurhospi.Assure.dateNaissance) {
                    if (this.Assurhospi.Assure.Telephone && this.Assurhospi.Assure.Email) {
                        if (this.Assurhospi.Assure.Cni) {
                            this.e1 = 5;
                        } else {
                            this.$message.error('Le cni est obligatoire !', this.AlertMessage)
                        }
                    } else {
                        this.$message.error('Téléphone et Email obligatoires !', this.AlertMessage)
                        this.animation = "animate-wrong";
                        setTimeout(() => {
                            this.animation = "";
                        }, 400);
                    }

                } else {
                    this.$message.error('Date de naissance obligatoires !', this.AlertMessage)
                }
            } else {
                this.$message.error('Nom et Prénom obligatoires !', this.AlertMessage)
            }
        },
        ContractControl() {
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            const date1 = dayjs(this.Assurhospi.Contractant.dateNaissance);
            let daydiff = date2.diff(date1, "days");
            let yearAss = Math.floor(daydiff / 360);
            if (yearAss < 18 || yearAss >= 60) {
                this.$message.error("Date contractant doit etre dans l'interval 18 a 60ans !", this.AlertMessage)
                return;
            }
            this.errors = {};
            if (this.Assurhospi.Contractant.Genre && this.Assurhospi.Contractant.Situation) {
                if (this.Assurhospi.Contractant.Nom && this.Assurhospi.Contractant.Prenom) {
                    if (this.Assurhospi.Contractant.dateNaissance && this.Assurhospi.Contractant.LieuNaissance) {
                        if (this.Assurhospi.Contractant.Profession && this.Assurhospi.Contractant.Adresse) {
                            if (this.Assurhospi.Contractant.Telephone && this.Assurhospi.Contractant.Email) {
                                this.e1 = 4;
                            } else {
                                this.$message.error('Téléphone et Email obligatoires !', this.AlertMessage)
                            }
                        } else {
                            this.$message.error('Profession et adresse obligatoires !', this.AlertMessage)
                        }
                    } else {
                        this.$message.error('Date et Lieu de naissance obligatoires !', this.AlertMessage)
                    }
                } else {
                    this.$message.error('Nom et Prénom obligatoires !', this.AlertMessage)
                }
            } else {
                this.$message.error('Genre et Situation obligatoires !', this.AlertMessage)
            }
        },

        /*===Send of data to API allowing create proposition for the customer===*/
        CreateDevis: function () {
            this.modalgenerationloading = true

            const Sunu = {
                Contractant: this.Assurhospi.Contractant,
                Assure: this.Assurhospi.Assure,
                Beneficiaire: this.Assurhospi.Beneficiaire,
                duree: this.Assurhospi.duree,
                branche: this.Assurhospi.branche,
                assureurs: this.Assurhospi.assureur,
                userid: this.usersearch._id, //store.state.user._id,
                clientid: this.usersearch._id, //store.state.user._id,
                createur: store.state.user._id,
                garanties: this.Assurhospi.garanties,
                dateEffet: dayjs(this.Assurhospi.dateEffet).format("YYYY-MM-DD"),
                dateEcheance: this.Assurhospi.dateEcheance,
                dateSouscription: new Date(),
                prime_mensuelle: this.Assurhospi.prime,
                capital: Number(this.Assurhospi.capital),
                hospitalisation: Number(this.Assurhospi.hospitalisation),
                FuturPaiement: null,
                plateforme: 'Dash',
            };

            if (this.$store.state.user.role == "Agent") {
                Sunu.courtierid = this.$store.state.user.societe;
            }

            Api()
                .post("/micro/devis/sunuvie", Sunu)
                .then((result) => {
                    Api()
                        .get("devis/" + result.data.devis._id)
                        .then((response) => {
                            this.$store.dispatch("setDevisPayement", response.data);
                            setTimeout(() => (
                                this.modalgenerationloading = false,
                                this.$router.go(this.$router.push({
                                    name: 'paiement'
                                }))), 4000
                            );
                        });
                })
                .catch((err) => {
                    this.modalgenerationloading = false;
                    console.log("error");
                });

            setTimeout(() => {
                this.modalgenerationloading = false
            }, 20000)
        },
        DevisStepOne() {
            this.e1 = 2;
        },
        DevisNextStep() {
            const date2 = dayjs(new Date().toISOString().substr(0, 10));

            const date1 = dayjs(this.Assurhospi.Beneficiaire.dateNaissance);
            let daydiff = date2.diff(date1, "days");
            let yearBen = Math.floor(daydiff / 360);

            this.oldDate = dayjs(this.dateNaiss)
            daydiff = date2.diff(this.oldDate, "days");
            let yearAss = Math.floor(daydiff / 360);
            // mosila2138@gmail.com
            if (this.Assurhospi.Beneficiaire.dateNaissance == null || this.dateNaiss == null || this.Assurhospi.capital == null) {
                this.$message.error("Tous les champs sont obligatoires !", this.AlertMessage)
                return;
            }
            if (yearAss < 18 || yearAss > 60) {
                this.$message.error("Date assuré doit etre dans l'interval 18 a 60ans !", this.AlertMessage)
                return;
            }

            if (store.state.isUserLoggedIn) {
                this.Assurhospi.Contractant.Nom = this.usersearch.nom;
                this.Assurhospi.Contractant.Prenom = this.usersearch.prenom;
                this.Assurhospi.Contractant.Telephone = this.usersearch.telephone_port;
                this.Assurhospi.Contractant.Email = this.usersearch.email;
                this.Assurhospi.Contractant.dateNaissance = this.usersearch.datenaissance;
                this.Assurhospi.Contractant.Genre = this.$store.state.user.sexe;
                this.Assurhospi.Contractant.Situation = this.$store.state.user.statut_marital;
                this.Assurhospi.Contractant.Profession = this.$store.state.user.profession;
                this.Assurhospi.Contractant.Adresse = this.$store.state.user.adresse;
                this.Assurhospi.Contractant.Cni = this.$store.state.user.numero_piece;
                this.e1 = 3;
                this.Assurhospi.Assure.dateNaissance = this.dateNaiss;
            }


        },

        yearsDiff() {
            const date1 = dayjs(this.dateNaiss).year();
            const date2 = new Date().getFullYear();
            let daydiff = date2 - date1;
            this.age = daydiff;

            if (this.age < 0) {
                this.age = null;
                this.$message.error("Erreur, date antérieure à la date actuelle !", this.AlertMessage)
    
                this.dateNaiss = null;
            } else if (this.age >= 360) {
                let year = Math.floor(this.age / 360);
                this.age = year;
                if (this.age > 70) {
                    this.$message.error("Erreur, l'age doit pas être Supérieur à 18 ans !", this.AlertMessage)
                    this.dateNaiss = null;
                }
            }
        },

        yearsDiffBenef() {
            const date1 = dayjs(this.Assurhospi.Beneficiaire.dateNaissance);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, "days");
            this.age = daydiff;

            if (this.age < 0) {
                this.age = null;
                this.$message.error("Erreur, date antérieure à la date actuelle !", this.AlertMessage)
                this.Assurhospi.Beneficiaire.dateNaissance = "";
            }
        },
        getUserRoles() {
            Api().get('/roles/all')
                .then(response => {
                    this.listRoles = response.data.AllRoles
                    for (let lr of response.data.AllRoles) {
                        if (lr.name == "User") {
                            this.userRole = lr._id
                        }
                    }
                }).catch(err => { console.log("failed getAllUserRoles"); })
        },

        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },
        hidesearch() {
            this.hidesearchClient = false;
            this.e1 = 2;
        },

        // Creation d'un user avant de faire son devis
        HubspotSend(param) {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param) {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) {
                const user = {
                    nom: this.nom,
                    prenom: this.prenom,
                    datenaissance: this.datenaissance,
                    profession: this.profession,
                    adresse: this.adresse,
                    sexe: this.genre,
                    telephone_port: this.telephoneclient,
                    email: this.emailclient,
                    password: this.password1,
                    confirmPass: this.password1,
                    role: this.userRole,
                    createdby: store.state.user._id,
                    photo: this.file,
                    piece_identite: this.piece_identite,
                    numero_piece: this.numero_piece,
                    plateforme: 'Dash',
                    created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                    .then(resultat => {
                        this.HubspotSend(user)
                        this.MailChimpInscriptionCompte(user)
                        if (resultat.status == 200) {
                            if (resultat.data.numero) {
                                this.$message.error(resultat.data.msg, this.AlertMessage)
                            } else {
                                this.$store.dispatch('setClient', resultat.data.data)
                                this.usersearch = resultat.data.data
                                this.AddUser = false
                                this.clientnonOk = false
                                this.hidesearchClient = false
                                this.e1 = 1
                            }
                        } else {
                            this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                        }

                    })
                    .catch(err => {
                        this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                    })

            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },
        check_client_search() {
            if (this.clientsearch == 'téléphone') {
                this.NumberInput = true
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if (this.clientsearch == 'email') {
                this.NumberInput = false
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },
        isMobile: function () {
            if (screen.width <= 760) {
                return true
            } else {
                return false
            }
        },

        rechercherClient() {
            if (this.telephoneclient) {
                const validPhone = validatePhone(this.telephoneclient);
                this.errors.phone = validPhone.error;

                if (validPhone.valid == true) {
                    Api().get('/user/phone/' + this.telephoneclient)
                        .then(resultat => {
                            if (resultat.data.telephone_port === this.telephoneclient) {
                                this.clientOk = true
                                this.clientnonOk = false
                                this.usersearch = resultat.data;
                                this.$store.dispatch('setClient', resultat.data)
                            }

                        })
                        .catch(e => {
                            this.clientnonOk = true
                            this.clientOk = false
                            this.telephoneC = this.telephoneclient;
                        })
                } else {
                    this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
                }
            } else {
                this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
            }


        },
        rechercheClientByMail() {
            if (this.emailclient) {

                const validEmail = validateEmail(this.emailclient);
                this.errors.email = validEmail.error;

                if (validEmail.valid == true) {
                    Api().get('/user/email/' + this.emailclient)
                        .then(resultat => {
                            if (resultat.data.email === this.emailclient) {
                                this.clientOk = true
                                this.clientnonOk = false
                                this.usersearch = resultat.data;
                                this.$store.dispatch('setClient', resultat.data)
                            }
                        }).catch(e => {
                            this.clientnonOk = true
                            this.clientOk = false
                            this.emailC = this.emailclient;
                        })
                } else {
                    this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
                }
            } else {
                this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

            }
        },
        __resizeHanlder: _.throttle(function (e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700)
    },
    mounted() {
        window.addEventListener("resize", this.__resizeHanlder)
        this.getUserRoles()
    },

    created() {
        // this.init()
    },
}
</script>