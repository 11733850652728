<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouveau<span style="color:#ffa409"> Courtier</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Nom du courtier" v-model="societe">
              </el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Adresse du courtier" v-model="adresse">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone portable</label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone bureau</label>
              <el-input type="number" placeholder="Numéro téléphone bureau" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Adresse email</label>
              <el-input placeholder="Adresse mail" v-model="email">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Ninea</label>
              <el-input placeholder="Numéro du ninéa" v-model="ninea">
              </el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>RCCM</label>
              <el-input placeholder="RCCM" v-model="rccm">
              </el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Status (Activer / Desactiver)</label>
              <el-select style="width: 100%" v-model="etat" placeholder="Selectionner">
                <el-option label="Desactiver" value="disabled" selected>Desactiver</el-option>
                <el-option label="Activer" value="enabled">Activer</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Numéro agrément</label>
              <el-input placeholder="Numéro agrément" v-model="numero_agrement">
              </el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>date agrément</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="date_agrement"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button @click="CreatePartenaire()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>

     <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Nom du courtier" v-model="societe">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Type</label>
              <el-input placeholder="Adresse du courtier" v-model="type_partenaire" readonly></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Adresse de la société" v-model="adresse">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone portable</label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone bureau</label>
              <el-input type="number" placeholder="Numéro téléphone bureau" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse email</label>
              <el-input placeholder="Adresse mail" v-model="email">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Ninea</label>
              <el-input placeholder="Numéro du ninéa" v-model="ninea">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>RCCM</label>
              <el-input placeholder="RCCM" v-model="rccm">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Etat (Bloqué / En marche)</label>
              <el-select style="width: 100%" v-model="etat" placeholder="Selectionner">
                <el-option label="Desactiver" value="disabled" selected>Desactiver</el-option>
                <el-option label="Activer" value="enabled">Activer</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Numéro agrément</label>
              <el-input placeholder="Numéro agrément" v-model="numero_agrement">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>date agrément</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="date_agrement"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button @click="CreatePartenaire()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
      file : null,
      isMobile: false,
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      date: new Date(),
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      dismissSecs: 3,
      used:false,
      dismissCountDown: 0,
      societe : null,
      type_partenaire : "Courtier",
      adresse : null,
      telephone_port : null,
      telephone_bur : null,
      email : null,
      ninea : null,
      etat : null,
      rccm : null,
      numero_agrement : null,
      date_agrement : null,
      listeAssureurs: [],



      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getListPartenaire()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    this.initChart()
    this.initPie()
    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
       getListPartenaire: function () {
            Api().get('/partenaire/assureur')
                .then(response => {
                    this.listeAssureurs = response.data.partenaires
                })
        },

    
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    toListeAssureur (){
      this.$router.go(this.$router.push('/assureurs/liste'))
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    CreatePartenaire() {

      if (this.societe &&  this.telephone_bur &&  this.email &&  this.etat) {

         const courtier = {
              societe: this.societe,
              adresse: this.adresse,
              telephone_port: this.telephone_port,
              telephone_bur: this.telephone_bur,
              email: this.email,
              ninea: this.ninea,
              rccm: this.rccm,
              etat: this.etat,
              numero_agrement: this.numero_agrement,
              date_agrement: dayjs(this.date_agrement).format("YYYY-MM-DD"),
              type_partenaire: 'Agent',
          }

        Api().post('partenaire/register', courtier)
            .then(response => {
              if (response.data.message) {
                this.$message.success('Courtier crée avec succes !', this.AlertMessage)
                this.$store.dispatch('setNewcourtier', response.data.CreatePartenaire)
                this.file =""
                this.societe =""
                this.type_partenaire =""
                this.adresse =""
                this.telephone_port =""
                this.telephone_bur =""
                this.email =""
                this.ninea =""
                this.rccm =""
                this.numero_agrement =""
                this.date_agrement =""
              }
            }).catch(err=>{
        })
      }else{
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },

    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
    initChart() {
      this.chart = echarts.init(document.getElementById("chart"))
      this.chart.setOption({
        //backgroundColor: '#394056',
        title: {
          top: 20,
          text: "SALES STATISTICS",
          textStyle: { fontWeight: "normal", fontSize: 16, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ },
          left: "1%"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              /*color: '#57617B'*/
            }
          }
        },
        legend: {
          top: 40,
          icon: "rect",
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: ["Product-A", "Product-B", "Product-C"],
          right: "4%",
          textStyle: { fontSize: 12, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ }
        },
        grid: {
          top: 100,
          left: "-5px",
          right: "30px",
          bottom: "2%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                /*color: '#57617B'*/
              }
            },
            data: [
              "13:00",
              "13:05",
              "13:10",
              "13:15",
              "13:20",
              "13:25",
              "13:30",
              "13:35",
              "13:40",
              "13:45",
              "13:50",
              "13:55"
            ]
          }
        ],
        yAxis: [
          {
            show: false,
            type: "value",
            name: "(%)",
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                /*color: '#57617B'*/
              }
            },
            axisLabel: {
              margin: 10,
              textStyle: { fontSize: 14 }
            },
            splitLine: { lineStyle: { color: "#eee" /*color: '#57617B'*/ } }
          }
        ],
        series: [
          {
            name: "Product-A",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: { normal: { width: 1 } },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(19, 206, 102, 0.3)"
                      },
                      {
                        offset: 0.8,
                        color: "rgba(19, 206, 102, 0)"
                      }
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: "rgb(19, 206, 102)",
                borderColor: "rgba(19, 206, 102, 0.27)",
                borderWidth: 12
              }
            },
            data: [220, 182, 191, 134, 150, 120, 110, 125, 145, 122, 165, 122]
          },
          {
            name: "Product-B",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: { normal: { width: 1 } },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(95, 143, 223, 0.3)"
                      },
                      {
                        offset: 0.8,
                        color: "rgba(95, 143, 223, 0)"
                      }
                    ],
                    false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: "rgb(95, 143, 223)",
                borderColor: "rgba(95, 143, 223, 0.2)",
                borderWidth: 12
              }
            },
            data: [120, 110, 125, 145, 122, 165, 122, 220, 182, 191, 134, 150]
          }
        ]
      })
    },
    initPie() {
      this.pie = echarts.init(document.getElementById("pie"))
      this.pie.setOption({
        title: {
          top: 20,
          text: "ORDER STATUS",
          textStyle: { fontWeight: "normal", fontSize: 16, fontFamily: "Nunito Sans" /*color: '#F1F1F3'*/ },
          left: "1%"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        series: [
          {
            name: "Status",
            type: "pie",
            selectedMode: "single",
            radius: [0, "35%"],

            label: {
              normal: {
                position: "inner"
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: 335,
                name: "Orders",
                selected: true,
                itemStyle: { normal: { color: "rgb(95, 143, 223)" } }
              },
              { value: 679, name: "Pending", itemStyle: { normal: { color: "rgb(19, 206, 102)" } } },
              { value: 1548, name: "Delivered", itemStyle: { normal: { color: "rgb(247, 186, 43)" } } }
            ]
          },
          {
            name: "Products",
            type: "pie",
            radius: ["45%", "60%"],

            data: [
              { value: 335, name: "p1", itemStyle: { normal: { color: "#3f84f6" } } },
              { value: 310, name: "p2", itemStyle: { normal: { color: "#4c8bf7" } } },
              { value: 234, name: "p3", itemStyle: { normal: { color: "#5a95f7" } } },
              { value: 135, name: "p4", itemStyle: { normal: { color: "#70a3f8" } } },
              { value: 1048, name: "p5", itemStyle: { normal: { color: "#8ab4fa" } } },
              { value: 251, name: "p6", itemStyle: { normal: { color: "#a3c4fb" } } },
              { value: 147, name: "p7", itemStyle: { normal: { color: "#bfd6fc" } } },
              { value: 102, name: "p8", itemStyle: { normal: { color: "#d4e4fd" } } }
            ],

            itemStyle: {
              normal: {
                color: "rgb(19, 206, 102)"
              }
            }
          }
        ]
      })
    },
    initGridData() {
      const year = new Date().getFullYear()
      const status_list = ["Complete", "Pending", "Returned", "Paid"]

      _.times(10, number => {
        let price = chance.floating({ min: 1, max: 100, fixed: 2 })
        let qnt = chance.integer({ min: 1, max: 5 })
        let amount = price * qnt

        this.gridData.push({
          customer: chance.name(),
          photo: "/static/images/shop/" + chance.integer({ min: 0, max: 19 }) + ".jpg",
          city: chance.city(),
          address: chance.address(),
          email: chance.email(),
          product: chance.sentence({ words: 3 }),
          price: _.replace(price.toFixed(2).toString(), ".", ","),
          qnt,
          amount: _.replace(amount.toFixed(2).toString(), ".", ","),
          status: status_list[chance.integer({ min: 0, max: 3 })],
          date: chance.date({ string: true, year: year }),
          id: number
        })
      })
    }
  },
   created() {
    this.init()
  },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
