<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouveau<span style="color:#ffa409"> partenaire</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom <span class="required">(*)</span> </label>
              <el-input placeholder="Nom du partenaire" v-model="societe">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Type <span class="required">(*)</span>  </label>
              <el-select style="width: 100%" v-model="type_partenaire" placeholder="Selectionner">
                <el-option value="assureur" label="Assureur">Assureur</el-option>
                <el-option value="courtier" label="Courtier">Courtier</el-option>
                <el-option value="tiers" label="Tiers">Tiers</el-option>
                <el-option value="Agent" label="Agent">Agent</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Adresse du partenaire" v-model="adresse">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone portable</label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone bureau <span class="required">(*)</span> </label>
              <el-input type="number" placeholder="Numéro téléphone bureau" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Adresse email <span class="required">(*)</span> </label>
              <el-input placeholder="Adresse mail" v-model="email">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Ninea</label>
              <el-input placeholder="Numéro du ninéa" v-model="ninea">
              </el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>RCCM</label>
              <el-input placeholder="RCCM" v-model="rccm">
              </el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Status (Activer / Desactiver) <span class="required">(*)</span>  </label>
              <el-select style="width: 100%" v-model="etat" placeholder="Selectionner">
                <el-option label="Desactiver" value="disabled" selected>Desactiver</el-option>
                <el-option label="Activer" value="enabled">Activer</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Numéro agrément</label>
              <el-input placeholder="Numéro agrément" v-model="numero_agrement">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date agrément</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="date_agrement"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Logo (.png .jpg .jpeg)</label>
              <el-input type="file" ref="file" v-model="file" placeholder="Logo du partenaire">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button @click="CreatePartenaire()" type="primary" round>Créer</el-button>
        </div>
      </div>
      <small style="color: red;font-style: italic;">(*) champs obligatoires </small>
    </div>

     <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Nom du partenaire" v-model="societe">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Type</label>
              <el-select style="width: 100%" v-model="type_partenaire" placeholder="Selectionner">
                <el-option value="assureur" label="Assureur">Assureur</el-option>
                <el-option value="courtier" label="Courtier">Courtier</el-option>
                <el-option value="tiers" label="Tiers">Tiers</el-option>
                <el-option value="Agent" label="Agent">Agent</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Adresse du partenaire" v-model="adresse">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone portable</label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone bureau</label>
              <el-input type="number" placeholder="Numéro téléphone bureau" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse email</label>
              <el-input placeholder="Adresse email" v-model="email">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Ninea</label>
              <el-input placeholder="Numéro du ninéa" v-model="ninea">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>RCCM</label>
              <el-input placeholder="RCCM" v-model="rccm">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Status (Activer / Desactiver)</label>
              <el-select style="width: 100%" v-model="etat" placeholder="Selectionner">
                <el-option label="Desactiver" value="disabled" selected>Desactiver</el-option>
                <el-option label="Activer" value="enabled">Activer</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Numéro agrément</label>
              <el-input placeholder="Numéro agrément" v-model="numero_agrement">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Date agrément</label>
              <el-date-picker
                  style="width: 100%!important;"
                  v-model="date_agrement"
                  type="date"
                  placeholder="Choississez une date"
                  :picker-options="dateBeforeToday">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Logo (.png .jpg .jpeg)</label>
              <el-input type="file" ref="file" placeholder="Logo du partenaire">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button @click="CreatePartenaire()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
      file : null,
      isMobile: false,
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      date: new Date(),
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      dismissSecs: 3,
      used:false,
      dismissCountDown: 0,
      societe : null,
      type_partenaire : null,
      adresse : null,
      telephone_port : null,
      telephone_bur : null,
      email : null,
      ninea : null,
      etat : null,
      rccm : null,
      numero_agrement : null,
      date_agrement : null,



      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.used = true
    } else {
      this.$router.go(this.$router.push('/login'))
    }
    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    toListeAssureur (){
      this.$router.go(this.$router.push('/assureurs/liste'))
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    CreatePartenaire() {

      if (this.societe &&  this.type_partenaire &&  this.telephone_bur &&  this.email && this.etat) {
        this.file = this.$refs.file.files;

        let formData = new FormData();
        formData.append("logo", this.file);
        formData.append("societe",  this.societe);
        formData.append("namePartner",  this.societe.toUpperCase());
        formData.append("type_partenaire",  this.type_partenaire);
        formData.append("adresse",  this.adresse);
        formData.append("etat",  this.etat);
        formData.append("telephone_port",  this.telephone_port);
        formData.append("telephone_bur",  this.telephone_bur);
        formData.append("email",  this.email);
        formData.append("ninea",  this.ninea);
        formData.append("rccm",  this.rccm);
        formData.append("numero_agrement",  this.numero_agrement);
        formData.append("date_agrement",  this.date_agrement);

        Api().post('partenaire/register', formData, {headers: { "Content-Type": "multipart/form-data" }})
            .then(response => {
              if (response.data) {
                this.$message.success('Partenaire crée avec succes !', this.AlertMessage)
                this.file =""
                this.societe =""
                this.type_partenaire =""
                this.adresse =""
                this.telephone_port =""
                this.telephone_bur =""
                this.email =""
                this.ninea =""
                this.rccm =""
                this.numero_agrement =""
                this.date_agrement =""
              }
            }).catch(err=>{
        })
      }else{
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },

    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700)
  },
   created() {
    this.init()
  },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.required{
  color: red;
  font-weight: bold;
}
.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
