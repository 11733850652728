<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">Coupons</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="mb-5">
      <el-button @click="toCreateCoupon()" type="primary" round>Créer nouveau Coupon</el-button>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input placeholder="Rechercher Coupon..." prefix-icon="el-icon-search" v-model="search" clearable>
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input placeholder="Rechercher Coupon..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table :data="listInPage" style="width: 100%" v-if="ready" :height="height"
        @selection-change="handleSelectionChange">
        <el-table-column label="Réduction" min-width="100" fixed prop="reduction">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.reduction }} </span>
            <span v-if="scope.row.type == 'pourcentage'"> % </span>
            <span v-else> FCFA </span>
          </template>
        </el-table-column>
        <el-table-column label="Date debut" prop="du" min-width="130">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.du) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date fin" prop="au" min-width="130">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.au) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Code coupon" min-width="150" prop="code_coupon"></el-table-column>
        <el-table-column label="Branche" min-width="150" prop="branche">
          <template slot-scope="scope">
            <span v-html="branchesFilter(scope.row.branche)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Assureur" min-width="180" prop="assureur">
          <template slot-scope="scope">
            <span v-html="partenaireFiltre(scope.row.assureur)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Courtier" min-width="180" prop="courtier">
          <template slot-scope="scope">
            <span v-html="partenaireFiltre(scope.row.courtier)"></span>
          </template>
        </el-table-column>
        <el-table-column label="Partenaire" min-width="100" prop="codep">
        </el-table-column>
        <el-table-column label="Créateur" prop="createur" min-width="130">
          <template slot-scope="scope">
            <span class="date">{{ showCreateur(scope.row.createur) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="infoCoupon(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            <el-button size="mini" v-if="$store.state.user.email=='admin@assuraf.com' || $store.state.user.email=='mowencleopas@assuraf.com'" @click="deleteCoupon(scope.row)" type="danger" icon="el-icon-delete"
              circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>
    <el-dialog title="Suppression de Coupon" width="50%" :visible.sync="modalDelete" v-if="modalDelete == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer cet Coupon ?</span>
          </div>
          <div>
            <ul style="list-style:none">
              <li>Reduction :
                <span class="to-right assuraf-orange">{{
                  detailCoupon.reduction
                }}
                  <span v-if="detailCoupon.type == 'pourcentage'"> % </span>
                  <span v-else> FCFA </span>
                </span>

              </li>

              <li>
                Date debut :
                <span class="to-right assuraf-orange">{{
                  DateFormater(detailCoupon.du)
                }}</span>
              </li>

              <li>
                Date fin:
                <span class="to-right assuraf-orange">{{
                  DateFormater(detailCoupon.au)
                }}</span>
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="DeletingCoupon(detailCoupon._id)">Oui</el-button>
        <el-button type="primary" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>

    <el-dialog width="50%" title="Détails" :visible.sync="showCoupon">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style: none">
                  <li>
                    Réduction :
                    <span class="to-right assuraf-orange">{{
                      detailCoupon.reduction
                    }}
                      <span v-if="detailCoupon.type == 'pourcentage'"> % </span>
                      <span v-else> FCFA </span>
                    </span>

                  </li>
                  <li>
                    Date debut :
                    <span class="to-right assuraf-orange">{{
                      DateFormater2(detailCoupon.du)
                    }}</span>
                  </li>

                  <li>
                    Date fin:
                    <span class="to-right assuraf-orange">{{
                      DateFormater2(detailCoupon.au)
                    }}</span>
                  </li>

                  <li>
                    Date de création :
                    <span class="to-right assuraf-orange">{{
                      DateFormater2(detailCoupon.created)
                    }}</span>
                  </li>
                  <li>
                    Créateur:
                    <span class="to-right assuraf-orange">

                      {{ showCreateur(detailCoupon.createur) }}
                    </span>
                  </li>
                  <li>
                    <el-divider />
                  </li>

                  <li>
                    Branche :
                    <span class="to-right assuraf-orange" v-html="branchesFilter(detailCoupon.branche)">
                    </span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Assureur :
                    <span class="to-right assuraf-orange" v-html="partenaireFiltre(detailCoupon.assureur)"></span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Période d'effet du contrat :
                    <span class="to-right assuraf-orange">Du {{DateFormater2(detailCoupon.debut_contrat) }} au {{DateFormater2(detailCoupon.fin_contrat) }} </span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Client:
                    <span class="to-right assuraf-orange" v-html="clientFiltre(detailCoupon.client)"></span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Plateforme:
                    <span class="to-right assuraf-orange" v-html="canalFiltre(detailCoupon.plateforme)"></span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Partenaire :
                    <span class="to-right assuraf-orange">{{
                      detailCoupon.codep
                    }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Agent :
                    <span class="to-right assuraf-orange" v-html="partenaireFiltre(detailCoupon.courtier)"></span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>
  
<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      modalgenerationloading: false,
      detailCoupon: {},
      showCoupon: false,
      editCoupon: false,
      modalDelete: false,
      filter: null,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      liste_coupons: [],
      itemsChecked: [],
    }
  },
  computed: {
    listFiltered() {
      return this.liste_coupons.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },

    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    showCreateur(createur) {
      if (createur && createur.nom) {
        return createur.prenom + " " + createur.nom
      }
      return null
    },
    branchesFilter(branche) {
      if (branche && branche.length !== 0) {
        return `<div> ${this.lib(branche)}</div> `;
      }
      return "";
    },

    partenaireFiltre(assureurs) {
      if (assureurs && assureurs.length !== 0) {
        return `<div> ${this.li(assureurs)}</div> `;
      }
      return "";
    },
    clientFiltre(client) {
      if (client && client.length !== 0) {
        return `<div> ${this.lic(client)}</div> `;
      }
      return "";
    },
    canalFiltre(canal) {
      if (canal && canal.length !== 0) {
        return `<div> ${this.showArray(canal)}</div> `;
      }
      return "";
    },
    
    li(list) {
      let view = "";
      list.forEach((element) => {
        view = view + `<button class="ml-2" type="info">${element.societe}</button>`;
      });
      return view;
    },
    showArray(list) {
      let view = "";
      list.forEach((element) => {
        view = view + `<button class="ml-2" type="info">${element}</button>`;
      });
      return view;
    },
    lib(list) {
      let view = "";
      list.forEach((element) => {
        view = view + `<button class="ml-2" type="info">${element.branche}</button>`;
      });
      return view;
    },
    lic(list) {
      let view = "";
      list.forEach((element) => {
        view = view + `<button class="ml-2" type="info">${element.prenom} ${element.nom} </button>`;
      });
      return view;
    },
    toCreateCoupon() {
      this.$router.push('/coupons/create')
    },
    getCoupons() {
      Api().get('/coupons/all')
        .then(resultat => {
          this.liste_coupons = resultat.data.allCoupons; return
        });
    },

    DeletingCoupon(id) {
      Api().delete('coupons/delete/' + id)
        .then(resultat => {
          if (resultat.status == 201) {
            this.modalDelete = false
            this.$message.success('Coupon supprimée avec succés !', this.AlertMessage)
            this.getCoupons()
          }
        })
    },

    deleteCoupon(coupon) {
      this.modalDelete = true;
      this.detailCoupon = coupon;

    },

    updateCoupon(coupon) {
      this.detailCoupon = coupon;

    },

    infoCoupon(coupon) {
      this.detailCoupon = coupon;
      this.showCoupon = true;
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },
    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },
    DateFormater2: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY HH:mm:ss')
      }
    },
    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }
      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getCoupons()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>
  
<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
  