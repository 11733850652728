<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouvel<span style="color:#ffa409"> utilisateur</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:15px">
        <el-row :gutter="15">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Prénom</label>
              <el-input placeholder="Votre prénom" v-model="prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Userame</label>
              <el-input placeholder="Username" v-model="username">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date de naissance</label>
              <el-date-picker
                    style="width: 100%!important;"
                    v-model="datenaissance"
                    type="date"
                    placeholder="Choississez une date"
                   >
                </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone portable</label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone bureau</label>
              <el-input type="number" placeholder="Numéro téléphone de bureau" v-model="telephone_bur">
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
           <div class="grid-content bg-purple">
               <label>Adresse</label>
               <el-input placeholder="Votre adresse" v-model="adresse">
               </el-input>
             </div>
          </el-col>
          <el-col :span="8">
              <div class="grid-content bg-purple">
                <label>Adresse Email</label>
                <el-input placeholder="Adresse email" v-model="email">
                </el-input>
              </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
                <label>Sexe</label>
                <el-select style="width: 100%" v-model="genre" placeholder="Selectionner">
                  <el-option label="Homme" value="homme">Homme</el-option>
                  <el-option label="Femme" value="femme">Femme</el-option>
                  <el-option label="Entreprise" value="entreprise"></el-option>
                </el-select>
              </div>
            </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Type de pièce</label>
              <el-select style="width: 100%" v-model="type_piece" placeholder="Selectionner">
                <el-option value="passeport">Passprot</el-option>
                <el-option value="Carte CI">CNI</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Numéro pièce d'identité </label>
              <el-input type="number" placeholder="Numéro pièce d'identité " v-model="numero_piece">
              </el-input>
            </div>
          </el-col> 
          <el-col :span="8">
            <div class="grid-content bg-purple" v-if="this.$store.state.user.role=='Agent' || this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'">
              <label>Rôle</label>
              <el-select style="width: 100%" v-model="userRole" placeholder="Selectionner">
                <el-option
                    v-for="role in listRoles"
                    :key="role._id"
                    :label="role.name"
                    :value="role._id"
                  />
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Profession</label>
              <el-input placeholder="Votre profession" v-model="profession">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Statut</label>
              <el-select style="width: 100%" v-model="statut_marial" placeholder="Selectionner">
                <el-option value="marié">Marié</el-option>
                <el-option value="célibataire">Célibataire</el-option>
                <el-option value="veuf">Veuf</el-option>
                <el-option value="veuve">Veuve</el-option>
                <el-option value="divorcé">Divorcé</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nombre d'enfant(s) </label>
              <el-input type="number" placeholder="Nombre d'enfant(s)" v-model="nombre_enfant">
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="8">
              <div class="grid-content bg-purple">
                <label>Photo (.png .jpg .jpeg)</label>
                <el-input type="file" placeholder="Téléphone bureau" v-model="file" ref="file">
                </el-input>
              </div>
            </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="createuser()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:15px">
        <h3>Ajouter un utilisateur :</h3>
        <el-row :gutter="15">
          
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="nom">
              </el-input>
            </div>
          </el-col>
          
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Prénom</label>
              <el-input placeholder="Votre prénom" v-model="prenom">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse</label>
              <el-input placeholder="Votre adresse" v-model="adresse">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone portable</label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Adresse Email</label>
              <el-input placeholder="Adresse email" v-model="email">
              </el-input>
            </div>
          </el-col>
          
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Sexe</label>
              <el-select style="width: 100%" v-model="genre" placeholder="Selectionner">
                <el-option value="homme">Homme</el-option>
                <el-option value="femme">Femme</el-option>
                <el-option label="Entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="24">
            <div class="grid-content bg-purple" v-if="this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'">
              <label>Rôle</label>
              <el-select style="width: 100%" v-model="userRole" placeholder="Selectionner">
                <el-option
                    v-for="role in listRoles"
                    :key="role._id"
                    :label="role.name"
                    :value="role._id"
                  />
              </el-select>
            </div>
          </el-col>

          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Type de pièce d'identité</label>
              <el-select style="width: 100%" v-model="type_piece" placeholder="Selectionner">
                <el-option value="passeport">Passprot</el-option>
                <el-option value="Carte CI">CNI</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone portable</label>
              <el-input type="number" placeholder="Numéro téléphone portable" v-model="telephone_port">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button @click="createuser()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"

const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import { mapState } from 'vuex'
import customPassword from '../../../services/password_generator'

//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
    optionRole : [],
    listRoles: null,
    isMobile: false,
    Selectedbranche:null,
    selectedAssureur:[],
    configUserDevisAssureur:[],
    assureurs:[],
    branchelist:null,
    options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
    },
    file:null,
    debounceTimer: 500,
    saltLength: 10,
    result: "",
    calculating: false,
    nom: '',
    prenom: '',
    username: '',
    email: '',
    datenaissance: '',
    NewUserFornewCourtier: null,
    genre: null,
    type_user: '',
    listeAssureurs: null,
    adresse: '',
    telephone_port: '',
    telephone_bur: '',
    type_piece: null,
    societeAdmin: [],
    modalSocieteAdmin:false,
    numero_piece: '',
    pseudo: '',
    password1: null,
    userRole: '',
    ToastTabSucess: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'success',
        className: 'custom-success-class'
    }, // Customized options for success toast
    ToastTabError: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'error'
    }, // Customized options for error toast
    mode_retrait_contrat: '',
    adresse_livraison_contrat: '',
    validite_piece: '',
    statut_marial: null,
    nombre_enfant: '',
    profession: '',
    gofor: '',
    lastCourtierAdded: null,
    listuser: [],
    userCreated: null,
    show: true,
    modalSociete: false,
    configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"}
    ],
    choosenRole:null,
    voidField: false,

    Deb: null,
    selectedcarbu: '',

    types: [
      'text',
      'text',
      'number',
    ],
    show: true,
    //fin initialisation des models d'assuraf
    chart: null,
    pie: null,
    gridData: [],
    }
  },
  mounted() 
  {
    this.getUserRoles()
    if (store.state.isUserLoggedIn && store.state.user.role !== "User") {
      if (this.$store.state.Newcourtier) {
        this.lastCourtierAdded = store.state.Newcourtier;
      }
      this.getListPartenaire();
      
    } else {
      this.$router.go(this.$router.push("/login"));
    }
    window.addEventListener("resize", this.__resizeHanlder)
  },

  watch: {
    userRole: function (val) {
      if (store.state.user.role == "Admin" || store.state.user.role == 'SuperAdmin') {
        if (val == "Courtier" || val == "Agent" || val == "assureur" || val == "agent général") {
          this.modalSociete = true;
        } else {
          this.modalSociete = false;
        }
      }
    },
  },

  methods: {

    getUserRoles(){ 
      this.listRoles = []
      Api().get('/roles/get/User')
      .then(response => {
        this.listRoles.push(response.data)
      }).catch(err => {console.log("failed getAllUserRoles");})
    },

    disabledBeforeToday(date) {
      const today = new Date();
      return date > today;
    },

    disabledAfterToday(date) {
      const today = dayjs(new Date()).add(-1, 'day')
      return date < today;
    },

    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },


    dateFormated(theDate){
      if(theDate) {
        return dayjs(theDate).format('YYYY-MM-DD') 
      }
    },

    getListPartenaire: function ()
    {
      if (this.$store.state.user.role == "Admin" || this.$store.state.user.role == 'SuperAdmin')
      {
        Api().get("/partenaire/")
                .then((response) => {
                  this.listeAssureurs = response.data.partenaires; //.assureurs_partenaires
                });
      } else if (this.$store.state.user.role == "assureur") {
        Api().get("/partenaire/" + this.$store.state.user.societe)
                .then((response) => {
                  this.listeAssureurs = response.data.partenaire;
                });
      }
    },
    createuser: function () {
      this.datenaissance = this.dateFormated(this.datenaissance)
      if (
        this.nom &&
          this.prenom &&
          this.username && 
          this.profession && 
          this.datenaissance && 
          this.email && 
          this.adresse && 
          this.genre &&
          this.userRole && 
          this.telephone_port && 
          this.numero_piece
        ){
        this.generatePassword();
        
        let formData = new FormData();
        this.file = this.$refs.file.files;
        // this.role = "User"

        formData.append("prenom", this.prenom);
        formData.append("nom", this.nom);
        formData.append("username", this.username);
        formData.append("role", this.userRole);
        formData.append("datenaissance", this.datenaissance);
        formData.append("profession", this.profession);
        formData.append("adresse", this.adresse);
        formData.append("sexe", this.genre);
        formData.append("telephone_port", this.telephone_port);
        formData.append("telephone_bur", this.telephone_bur);
        formData.append("email", this.email);
        formData.append("piece_identite", this.type_piece);
        formData.append("numero_piece", this.numero_piece);
        formData.append("password", this.password1);
        formData.append("photo", this.file);
        formData.append("confirmPass", this.password1);
        formData.append("statut_marital", this.statut_marital);
        formData.append("createdby", this.$store.state.user._id);
        formData.append("nb_enfant", this.nombre_enfant);
        formData.append("created", dayjs(new Date()).format("YYYY-MM-DD"));
        formData.append("plateforme", 'Dash');
        console.log(formData)
        
        const user = {
          nom: this.nom,
          prenom: this.prenom,
          telephone_port: this.telephone_port,
          email: this.email,
        }

        this.HubspotSend(user)
        this.MailChimpInscriptionCompte(user)
        
        if (this.$store.state.user.societe!= undefined && this.$store.state.user.societe !=null ){
          formData.append("societe", this.$store.state.user.societe);
        }
        Api()
        .post("user/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resultat) => {
          if (resultat.status == 200) {
            console.log(resultat)
            this.ClearForm();
            this.$message.success('Nouveau utilisateur ajouté !', this.AlertMessage)
          } else {
            console.log("PAS OK ")
            this.$message.error('Ajout de user échoué !', this.AlertMessage)
          }
        })
        .catch((err) => {
          console.log("ERREUR : "+err);
        });
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }
    },

    // Generation d'un password temporaire pour le user
    generatePassword: function () {
      this.password1 = customPassword.customPassword(this.nom, this.prenom)
    },

    HubspotSend(param) {
      let data = {
        email: param.email,
        firstname: param.prenom,
        username: param.username,
        lastname: param.nom,
        phone: param.telephone_port,
        inscrit: "Dash",
      };
      Api()
      .post("/testimonial/hubspot", data)
      .then((resp) => {
        resp.data;
      })
      .catch((e) => {
        e;
      });
    },

    MailChimpInscriptionCompte(param) {
      let data = {
        email: param.email,
        FNAME: param.prenom,
        UNAME: param.username,
        LNAME: param.nom,
        PHONE: param.telephone_port,
      };
      Api()
          .post("/testimonial/mailchimp", data)
          .then((resp) => {
            resp.data;
          })
          .catch((e) => {
            e;
          });
    },

    setBcryptHash() {
      const self = this;
      self.calculating = true;
      bcrypt.genSalt(self.saltLength, (err, salt) => {
        if (!err) {
          bcrypt.hash(self.password, salt, (err, hash) => {
            if (!err) {
              self.result = `${hash}`;
              self.calculating = false;
            }
          });
        }
      });
    },

    ClearForm() {
      this.file = null;
      this.nom = "";
      this.prenom = "";
      this.username = "";
      this.datenaissance = "";
      this.profession = "";
      this.adresse = "";
      this.genre = "";
      this.telephone_port = "";
      this.telephone_bur = "";
      this.email = "";
      this.type_piece = "";
      this.numero_piece = "";
      this.statut_marital = "";
      this.nb_enfant = "";
    },

    getAllbranche(){
            Api().get("/branche/")
            .then((result) => {
                this.branchelist = result.data
            }).catch((err) => {
                console.log(err)
            });
        },


    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
  },
  computed : {
    // ...mapState(["roles"]),
    
    sortOptions () {
      return this.listuser
      .filter(f => f.sortable)
      .map(f => { return { text: f.label, value: f.key } })
    }
  },

  created() {
    this.init()
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>