import Api from '../../services/Api'

const API_URL = '/borderaux'

const Bordereaux = {
    namespaced: true,
    state: {
        listebordereaux: [],
        bordereaux: {},
        count: 0,
        errors: {},
        message: ''
    },
    getters: {
        listebordereaux: state => state.listebordereaux,
        count: state => state.count,
    },
    mutations: {

        setIndexBordereauxErrors(state, error) {
            state.errors = {
                error: error,
                status: "erreur",
                description: "erreur lors du chargement des bordereaux"
            }
        },
        addBordereauxCommit(state, data) {
            Api().post(API_URL + '/exportBorderauxExpress', {
                description: data.description,
                contratid: data.contrats,
                debut_periode: data.du,
                fin_periode: data.au,
                brancheid: data.branches,
                partenaireid: data.assureurs,
            }).then((data) => {
                state.listebordereaux.push(data.data);
                state.errors = {}
            })

        },
        filterContratEncaissementCommit(state, data) {
            Api().post(API_URL + '/filtreContract', {
                debut_periode: data.debut_periode,
                fin_periode: data.fin_periode,
                partenaireid: data.assureurs[0].id,
                brancheid: data.branches[0]._id,
            }).then((response) => {

                this.state.listebordereaux = []
                this.state.errors = {}
                this.state.listebordereaux.push(response.data.contrats)
                this.count = response.data.count

            }).catch((error) => {
                this.state.listebordereaux = null
                this.state.errors = {
                    error: error
                }
            })
        },
        exportBordereauxCommit(state, data) {
            Api().post(API_URL + '/exportBorderauxExpress', {
                debut_periode: data.debut_periode,
                description: data.description,
                fin_periode: data.fin_periode,
                partenaireid: data.assureurs[0].id,
                brancheid: data.branches[0]._id,
            }).then((response) => {
                state.errors = {}
                state.message = response.message;

            }).catch((error) => {
                console.log("error bordereaux ::: ", error);
                state.errors = {
                    status: 500,
                    error: error,
                }
            })
        },

        removeBordereauxCommit(state, bordereaux) {
            Api().delete(API_URL + '/' +
                bordereaux.id
            ).then((data) => {
                //console.log(data)
                const m = state.listebordereaux.find(listebordereaux => listebordereaux.id === bordereaux.id)
                console.log(m)
                if (m !== undefined) {
                    state.listebordereaux.splice(state.listebordereaux.indexOf(m), 1)
                    state.errors = {}
                } else {
                    state.errors = {
                        status: "erreur",
                        description: "erreur lors de la suppression du bordereaux"
                    }
                }
            })
        },
        updateBordereauxCommit(state, data) {
            Api().put(API_URL + '/' + data.bordereaux.id, {
                bordereaux: data.bordereaux
            }).then((data) => {
                const record = state.listebordereaux.find(listebordereaux => listebordereaux.id === data.data.id)
                state.listebordereaux[record] = data.data
            })
        }
    },
    actions: {

        addBordereauxAction(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('addBordereauxCommit', payload)
                resolve()
            })
        },
        filterContratEncaissement(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('filterContratEncaissementCommit', payload)
                resolve()
            })
        },
        exportBordereauxAction(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('exportBordereauxCommit', payload)
                resolve()
            })
        }
    }

}

export default Bordereaux