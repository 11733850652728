
import Assure  from "../Assure.class";
import Contractant  from "../Contractant.class";
import Beneficiaire  from "../Beneficiaire.class";
export default class Sunuvie {
    constructor() {
        this.prime = 0,
        this.hospitalisation = 0
        this.capital = null
        this.duree = 1
        this.branche = "Assur-Hospi"
        this.assureur = "SUNU-VIE"
        this.dateEffet = null
        this.dateEcheance = null
        this.garanties = ['HOSPITALISATION', 'DECES ET INVALIDITE TOTALE ET DEFINITIVE']
        this.Assure = new Assure()
        this.Contractant = new Contractant()
        this.Beneficiaire = new Beneficiaire()
    }
}