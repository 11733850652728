<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">traces de paiement</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

     <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher trace de paiement..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher trace de paiement..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" fixed prop="datePayement">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.datePayement) }}
          </template>
        </el-table-column>
        <el-table-column label="Devis" min-width="150" prop="numeroDevis"></el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="createdby">
          <template slot-scope="scope">
            <span v-if="scope.row.createdby" class="date">{{ scope.row.createdby.prenom }} - {{ scope.row.createdby.nom }}</span >
          </template>
        </el-table-column>
        <el-table-column label="Statut" min-width="150" prop="STATUT"></el-table-column>
        <el-table-column label="Moyen" min-width="150" prop="moyen_paiement"></el-table-column>
        <el-table-column label="Montant" min-width="150" prop="montant">
          <template slot-scope="scope">
            <span class="sel-string">{{scope.row.montant.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Prénom client" min-width="150" prop="prenom_client">
        </el-table-column>
        <el-table-column label="Nom client" min-width="150" prop="nom_client">
        </el-table-column>
        <el-table-column label="Téléphone" min-width="150" prop="telephone_client"></el-table-column>
        <el-table-column label="Email" min-width="150" prop="email_client"></el-table-column>
          <el-table-column fixed="right" label="Actions" prop="action" min-width="80">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="detail_paiement(scope.row)" icon="el-icon-view" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" prop="datePayement">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.datePayement) }}
          </template>
        </el-table-column>
        <el-table-column label="Devis" min-width="150" prop="numeroDevis"></el-table-column>
        <el-table-column label="Statut" min-width="150" prop="STATUT"></el-table-column>
        <el-table-column label="Moyen" min-width="150" prop="moyen_paiement"></el-table-column>
        <el-table-column label="Montant" min-width="150" prop="montant">
          <template slot-scope="scope">
            <span class="sel-string">{{scope.row.montant.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Prénom client" min-width="150" prop="prenom_client">
        </el-table-column>
        <el-table-column label="Nom client" min-width="150" prop="nom_client">
        </el-table-column>
        <el-table-column label="Téléphone" min-width="150" prop="telephone_client"></el-table-column>
        <el-table-column label="Email" min-width="150" prop="email_client"></el-table-column>
         <el-table-column label="Actions" prop="action" min-width="80">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="detail_paiement(scope.row)" icon="el-icon-view" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails Devis" :visible.sync="show_details" v-if="show_details==true && !isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="JsonFormaterView" v-if="$store.state.user.role=='Admin' || $store.state.user.role=='SuperAdmin'">Détails en JSON</el-button>
      </div>
      <h3>Numero devis : <span style="color: red;">{{item_details.numeroDevis}}</span></h3>
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de paiement : <span class="to-right assuraf-orange">{{DateFormater(item_details.datePayement)}}</span></li>
                  <li>Statut de paiement : <span class="to-right assuraf-orange">{{item_details.STATUT}}</span></li>
                  <li>Moyen de paiement : <span class="to-right assuraf-orange">{{item_details.moyen_paiement}}</span></li>
                  <li>Montant : <span class="to-right assuraf-orange">{{item_details.montant.toLocaleString()}} Fcfa</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{item_details.nom_client}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{item_details.prenom_client}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{item_details.email_client}}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{item_details.telephone_client}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="15" v-if="JsonView" style="margin-top: 15px">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails en JSON </span>
              </div>
              <div>
                <pre style="background:black;color:white">
                  {{item_details}}
                </pre>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails Devis" :visible.sync="show_details" v-if="show_details==true && isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="JsonFormaterView" v-if="$store.state.user.role=='Admin'">Détails en JSON</el-button>
      </div>
      <h3>Numero devis : <span style="color: red;">{{item_details.numeroDevis}}</span></h3>
      <el-row :gutter="15">
       <el-row :gutter="15" v-if="JsonView" style="margin-top: 15px">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails en JSON </span>
              </div>
              <div>
                <pre style="background:black;color:white">
                  {{item_details}}
                </pre>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de paiement : <span class="to-right assuraf-orange">{{DateFormater(item_details.datePayement)}}</span></li>
                  <li>Statut de paiement : <span class="to-right assuraf-orange">{{item_details.STATUT}}</span></li>
                  <li>Moyen de paiement : <span class="to-right assuraf-orange">{{item_details.moyen_paiement}}</span></li>
                  <li>Montant : <span class="to-right assuraf-orange">{{item_details.montant.toLocaleString()}} Fcfa</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{item_details.nom_client}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{item_details.prenom_client}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{item_details.email_client}}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{item_details.telephone_client}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      item_details : [],
      show_details : false,
      debut: null,
      fin: null,
      client_name: '',
      client_firstname: '',
      client_email: '',
      client_phone: '',
      products_ids: '',
      listcontrat: [],
      numero: '',
      HideSearchDevis: false,
      NotFound: false,
      showDetail: false,
      CheckPayment: true,
      detailsContrat: null,
      showcontrat: false,
      dateTransaction: null,
      used: false,
      modalInfoBenef: false,
      TraceTranc: [],
      listpays: null,
      ToastTabSucess: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'success',
        className: 'custom-success-class'
      }, // Customized options for success toast
      ToastTabError: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'error'
      }, // Customized options for error toast
      fields: [
        {
          key: 'dateSouscription',
          label: 'Date Souscription'
        },
        {
          key: 'brancheid.branche',
          label: 'Branche'
        },
        {
          key: 'numeroDevis',
          label: 'Numero'
        },
        {
          key: 'partenaireid.societe',
          label: 'Assureur'
        },
        {
          key: 'dateEffet',
          label: 'Effet'
        },
        {
          key: 'dateEcheance',
          label: 'Echéance'
        },
        {
          key: 'prime_ttc',
          label: 'Prime TTC'
        },
        {
          key: 'userid',
          label: 'Client'
        },
        {
          key: 'action',
          label: 'Action'
        },
      ],
      MoyenSelected: [], // Must be an array reference!
      optionsPaiement: [
        {
          text: 'Orange Money',
          value: 'orange-money'
        },
        {
          text: 'Upay Cash',
          value: 'upay-cash'
        },
        {
          text: 'Intouch',
          value: 'intouch'
        },
        {
          text: 'Upay Autre wallet',
          value: 'upay'
        },
        {
          text: 'Free Money',
          value: 'free-money'
        },
        {
          text: 'Carte bancaire',
          value: 'orabank'
        }
      ],
      EnteteBenef: [
        {
          key: 'nom',
          label: 'Nom'
        },
        {
          key: 'prenom',
          label: 'Prénom'
        },
        {
          key: 'adresse',
          label: 'Adresse'
        },
        {
          key: 'telephone',
          label: 'Téléphone'
        },
        {
          key: 'numero_cni',
          label: 'Numéro CNI'
        },
        {
          key: 'pays',
          label: 'Nationalté'
        },
      ],

      EnteteListTracePays: [
        {
          key: 'datePayement',
          label: 'Date'
        },
        {
          key: 'numeroDevis',
          label: 'Devis'
        },
        {
          key: 'STATUT',
          label: 'STATUT'
        },
        {
          key: 'moyen_paiement',
          label: 'Moyen'
        },
        {
          key: 'montant',
          label: 'Montant'
        },
        {
          key: 'nom_client',
          label: 'Nom Client'
        },
        {
          key: 'prenom_client',
          label: 'Prénom Client'
        },
        {
          key: 'telephone_client',
          label: 'Téléphone Client'
        },
        {
          key: 'email_client',
          label: 'Email Client'
        },
      ],
      modalInfoVoyageur: false,
      continue_btn: false,
      tableau_info_passager: [],
      nom_passager: null,
      prenom_passager: null,
      nationalite_passager: null,
      numero_ci_passager: null,
      date_delivrance: null,
      date_expiration: null,
      showValiderBtn: false,
      tab_perso: [],
      maxPersonne: null,

      nom: null,
      prenom: null,
      adresse: null,
      telephone: null,
      numero_cni: null,
      pays: null,


      JsonView : false,
      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.TraceTranc,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.TraceTranc.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
   
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },

    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },

    total() {
      return this.listFiltered.length
    },

    currentPage: {
      get() {
        return this.pagination.page
      },

      set(val) {
        this.pagination.page = val
      }
    },

    itemPerPage() {
      return this.pagination.size
    },

    selectedItems() {
      return this.itemsChecked.length || 0
    }

  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
     JsonFormaterView()
        {
            if(this.JsonView==false)
            {
                this.JsonView = true
            }else{
                this.JsonView = false
            }
        },
     detail_paiement(item){
          delete item.userid.primary_partner
          delete item.userid.affiliated_partner
          this.item_details = item
          this.show_details = true
        }, 

    GetAllContrat()
    {
      Api().get('/paymentstory/listing')
          .then(resp => {
            this.TraceTranc = resp.data.payment
            this.totalRows = resp.data.count
            this.showDetail = true
            this.HideSearchDevis = true
          })
          .catch(e => {
            this.NotFound = true
            setTimeout(() => (this.NotFound = false), 5000);
          })
    },

    HandleNext(Trace) {
      if (Trace) {
        let dateEff = Trace.datetime.split('-')
        this.dateTransaction = dateEff[0].trim()
        this.CheckPayment = false
      }

    },

    //Envoie sms au client
    sendsmsclient: function (contrat, branche) {
      let host = ''
      let numcli = this.detailsContrat.userid.telephone_port
      let adresstosend = numcli.substring(numcli.length - 9)
      if (branche == 'Santé') {
        host = 'https://api.assuraf.com/api/uploads/Contrat_provisoir_Sante_' + contrat._id + ".pdf"
      } else {
        host = 'https://api.assuraf.com/api/uploads/Contrat_provisoir_' + branche + '_' + contrat._id + ".pdf"
      }

      localStorage.setItem('Host', host)

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'WWySsQKLztBbZ8v7MrNlu2IkVXH3',
      }
      axios.defaults.headers.common['Authorization'] = "Bearer " + 'WWySsQKLztBbZ8v7MrNlu2IkVXH3'
      const textclient = {
        outboundSMSMessageRequest: {
          address: "tel:+221" + adresstosend,
          senderAddress: "tel:+221772981201",
          outboundSMSTextMessage: {
            message: "Assuraf vous informe que votre demande a été bien finalisée et votre attestation est disponible ici " + " " + host
          },
        }
      }

      axios.post('https://api.orange.com/smsmessaging/v1/outbound/tel:+221772981201/requests', textclient, headers)
          .then(res => {
            console.log(res)
          })
          .catch(err => {
            console.error(err)
          })

    },

    ready: function () {
      var self = this;
      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },


    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },


    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },

    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),

    handleSelectionChange(val) {
      this.itemsChecked = val
    },

    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }

  },

  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },

  created() {
    this.init()
    this.used = true
    //this.GetAllContrat()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.used = true
      this.GetAllContrat()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
