<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">RS Monitor</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="mb-5">
      <el-button @click="toCreateRS()" type="primary" round>Créer nouveau RS Follower</el-button>
    </div>

     <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher RS monitor..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher RS monitor..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" fixed prop="created">
        <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.created) }}</span>
          </template></el-table-column>
        <el-table-column label="Nom du RS" prop="nom_rs" min-width="200"></el-table-column>
        <el-table-column label="Nbre de Follower" min-width="150" prop="followers_count"></el-table-column>
        <el-table-column label="État du RS" min-width="150" prop="etat_rs"></el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="130">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailTestimonial(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button size="mini" @click="editMonitor(scope.row)" type="primary" icon="el-icon-edit" circle title="Modifier"></el-button>
            <el-button size="mini" @click="Delete(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" prop="created">
        <template slot-scope ="scope">
            <span class="date">{{ DateFormater(scope.row.created) }}</span>
          </template></el-table-column>
        <el-table-column label="Nom du RS" prop="nom_rs" min-width="200"></el-table-column>
        <el-table-column label="Nbre de Follower" min-width="150" prop="followers_count"></el-table-column>
        <el-table-column label="État du RS" min-width="150" prop="etat_rs"></el-table-column>
         <el-table-column label="Actions" prop="action" min-width="130">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailTestimonial(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button size="mini" @click="editMonitor(scope.row)" type="primary" icon="el-icon-edit" circle title="Modifier"></el-button>
            <el-button size="mini" @click="Delete(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails RS" :visible.sync="showpack" v-if="showpack==true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailRS.created)}}</span></li>
                  <li>Nom RS : <span class="to-right assuraf-orange">{{detailRS.nom_rs}}</span></li>
                  <li>Nombre follower : <span class="to-right assuraf-orange">{{detailRS.followers_count}}</span></li>
                  <li>État : <span class="to-right assuraf-orange">{{detailRS.etat_rs}}</span></li>
                 </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>
                    <p>{{detailRS.contenu}}</p>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

     <el-dialog width="90%!important" title="Détails RS" :visible.sync="showpack" v-if="showpack==true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailRS.created)}}</span></li>
                  <li>Nom RS : <span class="to-right assuraf-orange">{{detailRS.nom_rs}}</span></li>
                  <li>Nombre follower : <span class="to-right assuraf-orange">{{detailRS.followers_count}}</span></li>
                  <li>État : <span class="to-right assuraf-orange">{{detailRS.etat_rs}}</span></li>
                 </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

       <el-row :gutter="15" style="margin-top: 15px">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>
                    <p>{{detailRS.contenu}}</p>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire de modification" :visible.sync="editerMonitor" v-if="editerMonitor==true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
        
            <el-col :span="8">
              <div>
                <label>Nom du RS</label>
                <el-input type="primary" v-model="detailRS.nom_rs" placeholder="Nom RS" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Nombre de followers</label>
                <el-input type="primary" v-model="detailRS.followers_count" placeholder="Nbre followers" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>État</label>
                 <el-select style="width: 100%" v-model="detailRS.etat_rs" placeholder="Selectionner">
                <el-option label="Activé" value="activate"></el-option>
                <el-option label="Desactiver" value="deactivate"></el-option>
              </el-select>
              </div>
            </el-col>
          </el-row>


        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdateRS()">Modifier</el-button>
          <el-button type="danger" round @click="editerMonitor=false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

     <el-dialog width="90%!important" title="Formulaire de modification" :visible.sync="editerMonitor" v-if="editerMonitor==true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24">
              <div>
                <label>Nom du RS</label>
                <el-input type="primary" v-model="detailRS.nom_rs" placeholder="Nom RS" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="24">
              <div>
                <label>Nombre de followers</label>
                <el-input type="primary" v-model="detailRS.followers_count" placeholder="Nbre followers" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="24">
              <div>
                <label>État</label>
                 <el-select style="width: 100%" v-model="detailRS.etat_rs" placeholder="Selectionner">
                <el-option label="Activé" value="activate"></el-option>
                <el-option label="Desactiver" value="deactivate"></el-option>
              </el-select>
              </div>
            </el-col>
          </el-row>


        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="UpdateRS()">Modifier</el-button>
          <el-button type="danger" round @click="editerMonitor=false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
        editerMonitor : false,
        detailMonitor: [],
        nom_rs: null,
        followers_count: null,
        etat_rs: null,
        showpack: false,
        listTestomo: [],
        detailRS: null,
        etat: null,
        filter: null,
        totalRows: null,
        ToastTabSucess: {
            theme: 'toasted-primary',
            position: 'top-center',
            duration: 2000,
            fitToScreen: true,
            type: 'success',
            className: 'custom-success-class'
        }, // Customized options for success toast
        ToastTabError: {
            theme: 'toasted-primary',
            position: 'top-center',
            duration: 2000,
            fitToScreen: true,
            type: 'error'
        }, // Customized options for error toast
        perPage: 3,
        pageOptions: [5, 10, 15],
        fields: [{
                key: 'created',
                label: 'Date création'
            },
            {
                key: 'nom_rs',
                label: 'Nom RS'
            },
            {
                key: 'followers_count',
                label: 'Nombre de Follower'
            },
            {
                key: 'etat_rs',
                label: 'Etat du RS'
            },
            {
                key: 'action',
                label: 'Action'
            },

        ],
      modalDeleteCar:false,
      userflette:[],
      fletteRows:null,
      carFields:[
        {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
        {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
        {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
        {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
        {key:'added_date',label:"Date d'ajout"},
        {key:'action',label:'Action'},
      ],
      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listTestomo,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listTestomo.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },

    listFilteredFlotte() {
      return this.userflette.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSorteredFlotte() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFilteredFlotte.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPageFlotte() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listSorteredFlotte.slice(from, to)
      return this.listFilteredFlotte.slice(from, to)
    },
    totalFlotte() {
      return this.listFilteredFlotte.length
    },

    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
      AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

      UpdateRS() {
          let itemRS = this.detailRS
            if (this.detailRS.nom_rs) {
                if (this.detailRS.followers_count) {
                    if (this.detailRS.etat_rs) {
                        Api().post('/RS/update', itemRS)
                            .then(res => {
                                this.Listing()
                                this.editerMonitor = false
                                this.$message.success("Opération réussie !", this.AlertMessage)
                            })
                    } else {
                      this.$message.error("L'état du RS est obligatoire !", this.AlertMessage)
                    }
                } else {
                  this.$message.error("Le nombre de follower du RS est obligatoire !", this.AlertMessage)
                }
            } else {
              this.$message.error('Le nom du RS est obligatoire !', this.AlertMessage)
            }

        },

     editMonitor(item){
      this.editerMonitor = true
      this.detailRS = item
    },
    
    toCreateRS (){
      this.$router.go(this.$router.push('/RS/create'))
    },


    DateFormater: function(TheDate) {
       if (TheDate) { return dayjs(TheDate).format('DD-MM-YYYY') }
    },
    Listing() {
            Api().get('/RS/listing')
                .then(res => {
                    this.listTestomo = res.data.rs
                    this.totalRows = res.data.rs.length
                })
        },

        detailTestimonial(item) {
            this.detailRS = item
            this.showpack = true
        },

        Delete(item) {
            Api().delete('/RS/delete/' + item._id)
                .then(res => {
                    this.Listing()
                })
        },

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format('DD-MM-YYYY')
            }
        },

        ActivateRS(item) {

        },

        DeactivateRS(item) {

        },


    setCollapse(){
      this.collapse = "id_"+Date.now()+"_collapse"
    },



    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },


    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
          .then(response => {
            if (response.data) {
              this.showAlert()
            }
            this.showAlert()
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    this.Listing()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
     this.Listing()

    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
