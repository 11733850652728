<template>
    <vue-scroll class="page-devis-habitaion" >
        <div class="page-header">
			<h1>Création de devis <span style="color:#ffa409">Habitation</span></h1>
		</div>
       
        <div v-if="hidesearchClient && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="10">
                  <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
              </el-row>
            </div> 

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>

         <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="24">
                  <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                 <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input></el-col>
              </el-row>
            </div> 

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>
        
        <div v-if="e1 == 0" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <h3>Vous souhaitez une assurance pour :</h3>
            <el-row justify="center">
                <el-col>
                <div class="grid-content bg-purple">
                    <el-radio @change="getListHabitation()" v-model="myHabitations" size="medium" label="mes_habitations">Mes logements</el-radio>
                </div>
                </el-col>
                <el-col><div class="grid-content bg-purple-light">
                    <el-radio @change="getListHabitation()" v-model="myHabitations" label="nouveau">Nouveau logement</el-radio>
                </div>
                </el-col>
            </el-row>
        </div>

        <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <h3>Vous souhaitez une assurance pour :</h3>
                 <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="8">
                        <div>
                            <label>Bien à assurer</label>
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                <div class="grid-content bg-purple">
                                    <el-radio v-model="naturelogement" label="appartement">Appartement</el-radio>
                                </div>
                                </el-col>
                                <el-col><div class="grid-content bg-purple-light">
                                    <el-radio v-model="naturelogement" label="maison">Maison</el-radio>
                                </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <label>Nombre de pièces</label>
                            <el-input type="number" placeholder="Nombre de pièces" v-model="nombrepiece">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div v-if="naturelogement === 'appartement' && seen">
                            <label>Superficie (en m2)</label>
                            <el-input type="number" placeholder="Surface developpée" v-model="nombreetage">
                            </el-input>
                        </div>
                         <div v-if="naturelogement === 'maison' && seen">
                            <label>Superficie (en m2)</label>
                            <el-input type="number" placeholder="Surface developpée" v-model="nombreetage">
                            </el-input>
                        </div>
                    </el-col>
            </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 =0" round>Retour</el-button>
                <el-button @click="Stepper1Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 1 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3>Vous souhaitez une assurance pour :</h3>
                 <el-row :gutter="20">
                    <el-col :span="24">
                        <div>
                            <label>Bien à assurer</label>
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                <div class="grid-content bg-purple">
                                    <el-radio v-model="naturelogement" label="appartement">Appartement</el-radio>
                                </div>
                                </el-col>
                                <el-col><div class="grid-content bg-purple-light">
                                    <el-radio v-model="naturelogement" label="maison">Maison</el-radio>
                                </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div>
                            <label>Nombre de pièces</label>
                            <el-input type="number" placeholder="Nombre de pièces" v-model="nombrepiece">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div v-if="naturelogement === 'appartement' && seen">
                            <label>Superficie (en m2)</label>
                            <el-input type="number" placeholder="Surface developpée" v-model="nombreetage">
                            </el-input>
                        </div>
                         <div v-if="naturelogement === 'maison' && seen">
                            <label>Superficie (en m2)</label>
                            <el-input type="number" placeholder="Surface developpée" v-model="nombreetage">
                            </el-input>
                        </div>
                    </el-col>
            </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 =0" round>Retour</el-button>
                <el-button @click="Stepper1Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3>Détails du logement :</h3>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <label>Selectionner un type de toiture</label>
                            <el-select style="width: 100%!important;" v-model="naturecouverture" placeholder="Selectionner">
                                <el-option v-for="items in optionstoiture"
                            :key="items"
                            :label="items.text"
                            :value="items.value"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Environnement immmédiat</label>
                           <el-select style="width: 100%!important;" v-model="environnement_immediat" placeholder="Selectionner">
                               <el-option v-for="items in optionsenvi"
                            :key="items"
                            :label="items.text"
                            :value="items.value"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <label>Adresse du logement</label>
                            <el-input placeholder="Adresse du logement" v-model="adresselogement">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
                <el-button @click="Stepper2Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

         <div v-if="e1 == 2 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3>Détails du logement :</h3>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Selectionner un type de toiture</label>
                            <el-select style="width: 100%!important;" v-model="naturecouverture" placeholder="Selectionner">
                                <el-option v-for="items in optionstoiture"
                            :key="items"
                            :label="items.text"
                            :value="items.value"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple-light">
                            <label>Environnement immmédiat</label>
                           <el-select style="width: 100%!important;" v-model="environnement_immediat" placeholder="Selectionner">
                               <el-option v-for="items in optionsenvi"
                            :key="items"
                            :label="items.text"
                            :value="items.value"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div class="grid-content bg-purple-light">
                            <label>Adresse du logement</label>
                            <el-input placeholder="Adresse du logement" v-model="adresselogement">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
                <el-button @click="Stepper2Validator" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>Propriétaire/Locataire :</h3>
                    <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="12">
                        <div>
                            <label>Propriétaire</label>
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                <div class="grid-content bg-purple">
                                    <el-radio v-model="qualiteassure" label="locataire">Locataire</el-radio>
                                </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                    <el-radio v-model="qualiteassure" label="Proprietaire_et_occupant">Propriétaire occupant</el-radio>
                                </div>
                                </el-col>
                                 <el-col>
                                    <div class="grid-content bg-purple-light">
                                    <el-radio v-model="qualiteassure" label="Proprietaire_non_occupant" disabled="true">Propriétaire non-occupant (Nous consulter)</el-radio>
                                </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div v-if="qualiteassure === 'locataire'">
                            <label>Montant du loyer</label>
                            <el-input placeholder="Montant" v-model="montantLoyer">
                            </el-input>
                        </div>
                    </el-col>
            </el-row>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
                    <el-button @click="Stepper3Validator()" type="primary" round>Suivant</el-button>
                </div>
           </div>
        </div>

        <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>Propriétaire/Locataire :</h3>
                    <el-row :gutter="15">
                      <el-col :span="24">
                        <div>
                            <label>Propriétaire</label>
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                <div class="grid-content bg-purple">
                                    <el-radio v-model="qualiteassure" label="locataire">Locataire</el-radio>
                                </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                    <el-radio v-model="qualiteassure" label="Proprietaire_et_occupant">Propriétaire occupant</el-radio>
                                </div>
                                </el-col>
                                 <el-col>
                                    <div class="grid-content bg-purple-light">
                                    <el-radio v-model="qualiteassure" label="Proprietaire_non_occupant" disabled="true">Propriétaire non-occupant (Nous consulter)</el-radio>
                                </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div v-if="qualiteassure === 'locataire'">
                            <label>Montant du loyer</label>
                            <el-input placeholder="Montant" v-model="montantLoyer">
                            </el-input>
                        </div>
                    </el-col>
            </el-row>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
                    <el-button @click="Stepper3Validator()" type="primary" round>Suivant</el-button>
                </div>
           </div>
        </div>

        <div v-if="e1 == 4 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>Valeur :</h3>
                    <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="12">
                        <div>
                            <label>Valeur du contenu à garantir</label>
                             <el-input v-money="money" placeholder="Montant" v-model="valeurcontenu">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <label>Valeur du bâtiment</label>
                            <el-input v-if="valeurbatiment" placeholder="Montant" v-model="valeurbatiment">
                            </el-input>
                            <el-input v-money="money" v-else placeholder="Montant" v-model="valeurbatiment">
                            </el-input>
                        </div>
                    </el-col>
            </el-row>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="returnToStepTwo">Retour</el-button>
                    <el-button @click="Stepper4Validator()" type="primary" round>Suivant</el-button>
                </div>
           </div>
        </div>

         <div v-if="e1 == 4 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>Valeur :</h3>
                    <el-row :gutter="15">
                      <el-col :span="24">
                        <div>
                            <label>Valeur du contenu à garantir</label>
                             <el-input v-money="money" placeholder="Montant" v-model="valeurcontenu">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                        <div>
                            <label>Valeur du bâtiment</label>
                            <el-input v-if="valeurbatiment" placeholder="Montant" v-model="valeurbatiment">
                            </el-input>
                            <el-input v-money="money" v-else placeholder="Montant" v-model="valeurbatiment">
                            </el-input>
                        </div>
                    </el-col>
            </el-row>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="returnToStepTwo">Retour</el-button>
                    <el-button @click="Stepper4Validator()" type="primary" round>Suivant</el-button>
                </div>
           </div>
        </div>

        <div v-if="e1 == 5" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3>liste des garanties :</h3>
                    <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="24">
                        <div>
                            <label>Garanties de base</label>
                             <div class="grid-content bg-purple">            
                                <el-checkbox-group v-model="garanties">
                                    <el-checkbox label="RESPONSABILITE CIVILE">Responsabilité Civile</el-checkbox>
                                    <el-checkbox label="BRIS DE GLACES">Bris de glaces</el-checkbox>
                                    <el-checkbox label="INCENDIE">Incendie</el-checkbox>
                                    <el-checkbox label="ASSISTANCE DOMICILE">Assistance à domicile</el-checkbox>
                                    <el-checkbox label="DOMMAGES ELECTRIQUES">Dommages électriques</el-checkbox>
                                    <el-checkbox label="DEGAT DES EAUX">Dégat des eaux</el-checkbox>
                                    <el-checkbox label="VOL">Vol</el-checkbox>
                                </el-checkbox-group>
                            </div>

                            <el-divider></el-divider>

                            <label>Autres garanties</label>
                             <div class="grid-content bg-purple">            
                                <el-checkbox-group v-model="garanties">
                                    <el-checkbox label="VOL PAR EFFRACTION">Vol par Effraction</el-checkbox>
                                    <el-checkbox label="REMPLACEMENT SERRURES">Remplacement serrures</el-checkbox>
                                    <el-checkbox label="RESPONSABILITE CIVILE VOYAGE">RC voyage</el-checkbox>
                                    <el-checkbox label="RESPONSABILITE CIVILE ANIMAUX">RC animaux</el-checkbox>
                                    <el-checkbox label="RESPONSABILITE CIVILE SPORT">RC sport</el-checkbox>
                                    <el-checkbox label="RESPONSABILITE CIVILE CHASSE">RC chasse</el-checkbox>
                                    <el-checkbox @change="chech_scolarite()" label="ASSURANCE SCOLARITE">Assurance scolarité</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>

                        
                    </el-col>
                   
            </el-row>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 4">Retour</el-button>
                    <el-button @click="Stepper5Validator()" type="primary" round>Suivant</el-button>
                </div>
           </div>
        </div>

        <div v-if="e1 == 6" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <el-row>
            </el-row>
           <div>
               <h3>Validation :</h3>
                <div>
                    <h3>Liste des assureurs</h3>
                    <el-row :gutter="20" type="flex" justify="center">
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <el-checkbox-group v-model="assureurs">
                                    <el-checkbox v-for="assur in listeAssureurs" :key="assur.societe" :label="assur.societe">
                                        <template>
                                            <img width="50" height="40" :src="assur.logo" alt="logo assur'care">
                                        </template>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div>
                    <h3>Période de couverture</h3>
                    <el-row :gutter="20" v-if="!isMobile">
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Durée de la garantie</label>
                                <el-input placeholder="durée de la garantie" v-model="duree" readonly>
                                <template slot="append">mois</template>
                                </el-input>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" v-if="isMobile">
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <label>Durée de la garantie</label>
                                <el-input placeholder="durée de la garantie" v-model="duree" readonly>
                                <template slot="append">mois</template>
                                </el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="e1 = 5">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="ListHabitationCreate()" type="primary" round>Valider</el-button>
                </div>
           </div>
        </div>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile"> 
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise"  value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile"> 
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise"  value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Mes logements" :visible.sync="ModalListeHabitation" v-if="ModalListeHabitation">
           <el-table :data="userHabitations" class="padded-table" height="200" style="width: 100%" row-key="id">
                  <el-table-column prop="typeBien" label="Type de bien" min-width="200"> </el-table-column>
                  <el-table-column prop="nombrepiece" label="Nombre de pièces" min-width="200"> </el-table-column>
                  <el-table-column prop="superficie" label="Superficie" min-width="120">
                  </el-table-column>
                  <el-table-column prop="naturecouverture" label="Nature couverture" min-width="200"> </el-table-column>
                  <el-table-column prop="adresselogement" label="Adresse" min-width="200"> </el-table-column>
                  <el-table-column prop="valeurcontenu" label="Valeur du contenu" min-width="200">
                    <template slot-scope="scope">
                      {{ scope.row.valeurcontenu }} CFA
                    </template>
                  </el-table-column>
                   <el-table-column prop="valeurbatiment" label="Valeur du batiment" min-width="200">
                    <template slot-scope="scope">
                      {{ scope.row.valeurbatiment }} CFA
                     </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="Actions" prop="action" min-width="120" v-if="!isMobile">
                        <template slot-scope="scope">
                            <el-button @click="getHabitationForDevis(scope.row)" type="primary" icon="el-icon-right" circle title="Voir détails"></el-button>
                            <el-button @click="deleteHabitation(scope.row)" type="danger" icon="el-icon-delete" circle title="Désarchiver"></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actions" prop="action" min-width="120" v-if="isMobile">
                    <template slot-scope="scope">
                        <el-button @click="getHabitationForDevis(scope.row)" type="primary" icon="el-icon-right" circle title="Voir détails"></el-button>
                        <el-button @click="deleteHabitation(scope.row)" type="danger" icon="el-icon-delete" circle title="Désarchiver"></el-button>
                    </template>
                    </el-table-column>
                </el-table>
        </el-dialog>

        <!-- ========= DIALOG NOMBRE D'ENFANT ============ -->
        <!-- ========= DIALOG NOMBRE D'ENFANT ============ -->
        <el-dialog show-close="false" title="Nombre d'enfant(s)" :visible.sync="modal_nombre_enfant">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
                <el-row justify="center">
                   <el-col :span="24">
                        <el-form-item label="Nombre d'enfant(s)">
                            <el-input type="number" placeholder="Saisir nombre d'enfant(s)" v-model="nombre_enfant"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>   
            </el-form>
            <div>
                 <el-button @click="nombre_enfant_validator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <!-- ======================== EXTENSION GARANTIES SALAMA ========================= -->
        <el-dialog show-close="false" title="Capitaux extensions (valable Salama)" width="400px" :visible.sync="popup_extension_salama">
            <el-row justify="center">
                <el-col :span="24" v-if="garantie_vol_efra=='VOL PAR EFFRACTION'">
                    <label for="Stepper24_mobile" class="field-label">{{garantie_vol_efra}}</label>
                    <el-select style="width: 100%!important;" v-model="vol_par_efraction" placeholder="Selectionner">
                        <el-option v-for="item in items_extension" :key="item" :label="item.text"  :value="item.val"></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row justify="center">
                <el-col :span="24" v-if="garantie_deg_eaux=='DEGAT DES EAUX'">
                    <label for="Stepper24_mobile" class="field-label">{{garantie_deg_eaux}}</label>
                    <el-select style="width: 100%!important;" v-model="degat_des_eaux" placeholder="Selectionner">
                        <el-option v-for="item in items_extension" :key="item" :value="item.val"></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row justify="center">
                <el-col :span="24" v-if="garantie_gdg=='BRIS DE GLACES'">                                              
                    <label for="Stepper24_mobile" class="field-label">{{garantie_gdg}}</label>
                    <el-select style="width: 100%!important;" v-model="bris_de_glaces" placeholder="Selectionner">
                        <el-option v-for="item in items_extension_bdg" :key="item" :value="item.val">{{item.text}}</el-option>
                    </el-select>
                </el-col>
            </el-row>
            <div class="mt-6">
                 <el-button @click="check_salamas_extension()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

    </vue-scroll>
</template>

<script>

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
 if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }

};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire !"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
    name: "devis_habitation",
    
    data() {
        return {
            nombre_enfant : null,
          modal_nombre_enfant : false,
          modalgenerationloading : false,
          ModalListeHabitation : false,
          userHabitationId : null,
          habitationDetails : {},
          ModalListeContrat : false,
          userHabitations : [],
          myHabitations : null,
          listHabitations : [],
          headerContrat: [
                {
                  text: "Type de bien",
                  sortable: false,
                  value: "typeBien"
                },
                {
                  text: "Nombre de piéces",
                  sortable: false,
                  value: "nombrepiece"
                },
                {
                  text: "Superficie",
                  sortable: false,
                  value: "superficie"
                },
                {
                  text: "Nature couverture",
                  sortable: false,
                  value: "naturecouverture"
                },
                {
                  text: "Adresse",
                  sortable: false,
                  value: "adresselogement"
                },
                {
                  text: "Valeur contenu",
                  sortable: false,
                  value: "valeurcontenu"
                },
                {
                  text: "Valeur du bâtiment",
                  sortable: false,
                  value: "valeurbatiment"
                },
                {
                  text: "Action",
                  value: "action"
                }
              ],
            headerContratMobile: [
                {
                  text: "Type de bien",
                  sortable: false,
                  value: "typeBien"
                },
                {
                  text: "Superficie",
                  sortable: false,
                  value: "superficie"
                },
                {
                  text: "Adresse",
                  sortable: false,
                  value: "adresselogement"
                }
              ],



          isMobile: false,
          saveValueLoyer : null,
          errors : {},
             dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            optionsdate: {
                minDate: new Date(),
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            money: {
                thousands: '',
                precision: false,
                masked: false
            },
            money_2: {
                thousands: ' ',
                precision: false,
                masked: false
            },
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            telephoneC: null,
            showId: false,
            duree: null,
            e1: null,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            seen: true,
            naturelogement: '',
            nombrepiece: '',
            nombreetage: '',
            naturecouverture: '',
            username: null,
            telephoneclient: null,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            }, // Customized options for success toast
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
            }, // Customized options for error toast
            adresselogement: '',
            assureurs: [],
            Assureur_si_courtier: null,
            valeurcontenu: '',
            usersearch: null,
            valeurbatiment: '',
            qualiteassure: '',
            proprietaire: 'ASSURAF',
            dateEcheance: null,
            dateEffet: '',
            emailclient: null,
            emailC: this.emailclient,
            notifMessage: '',
            montantLoyer: '',
            listeAssureurs: [],
            liste_type_secu: [],
            garanties: ['RESPONSABILITE CIVILE'],
            vol: null,
            items_toiture: ['Tuile (ardoise)', 'Tôle metal', 'paille', ],
            autredommage: [],
            quartierresi: '',
            items_environ_immediat: ['Chantiers a proximité', 'Proche Usine/Marche/Station Essence'],
            environnement_immediat: null,
            selecttoiture: null,
            optionstoiture: [{
                    value: 'Béton',
                    text: 'Béton '
                },
                {
                    value: 'Tuile (ardoise)',
                    text: 'Tuile (ardoise)'
                },
                {
                    value: 'Tôle metal',
                    text: 'Tôle metal'
                },
                {
                    value: 'Paille',
                    text: 'paille'
                },
            ],
            optionsenvi: [{
                value: 'Quartier résidentiel',
                    text: 'Quartier résidentiel '
                },
                {
                    value: 'Chantiers a proximité',
                    text: 'Chantiers a proximité'
                },
                {
                    value: 'Proche Usine/Marche/Station Essence',
                    text: 'Proche Usine/Marche/Station Essence'
                },
            ],
            optionsdateDepart: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            optionsdateArrivee: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            PassengerBirthOptionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            telephoneC: null,
            showId: false,
            nom: null,
            prenom: null,
            email: null,
            duree: null,
            profession:null,
            genre:null,
            datenaissance: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            emailclient: null,
            emailC: this.emailclient,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            hideForm: false,
           
            steps: [{
                    label: 'Etape 1',
                    completed: false,
                },
                {
                    label: 'Etape 2',
                    completed: false,
                },
                {
                    label: 'Etape 3',
                    completed: false,
                },
            ],
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            seen: true,
            selected: [],
            usersearch: [],
            telephoneclient: '',
            destination: null,
         
            NumberInput : false,
            EmailInput : false,
            
            radio: '1',
            voidField: false,
          
            Deb: null,
            selectedcarbu: '',
           
            types: [
                'text',
                'text',
                'number',
            ],
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            gridData: [],
            popup_extension_salama :false,
                vol_par_efraction:null,
                degat_des_eaux : null,
                bris_de_glaces:null,
                current_garantie :'',
                garantie_vol_efra:null,
                garantie_deg_eaux:null,
                garantie_gdg :null,
                items_extension:[
                    {text:"Jusqu' à 500 000", val:500000},
                    {text:"Jusqu' à 1 000 000", val:1000000},
                    {text:"Jusqu' à 2 000 000", val:2000000},
                    {text:"Jusqu' à 5 000 000", val:5000000}
                ],
                items_extension_bdg:[
                    {text:"Jusqu' à 250 000", val:250000},
                    {text:"Jusqu' à 500 000", val:500000},
                    {text:"Jusqu' à 1 000 000", val:1000000},
                    {text:"Jusqu' à 2 000 000", val:2000000}
                ],
                userRole:null
        }
    },
    mounted() {
        this.getUserRoles()
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getListePartenaire();
            this.duree = 12
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
    watch:{
        assureurs(val)
        {
            let cpt = 0
            for(let v of this.assureurs)
            {
                if(v=='SALAMA')
                {
                    for(let g of this.garanties)
                    {
                        if(g=='VOL PAR EFFRACTION' && this.vol_par_efraction==null){
                            this.garantie_vol_efra = g
                            cpt = cpt+1
                        }

                        if(g=='DEGAT DES EAUX' && this.degat_des_eaux ==null){
                            this.garantie_deg_eaux = g
                            cpt = cpt+1
                        }

                        if(g=='BRIS DE GLACES' && this.bris_de_glaces==null){
                            this.garantie_gdg = g
                            cpt = cpt+1
                        }
                    }
                }
                    
            }
            if(cpt>=1){
                this.popup_extension_salama = true 
            }
        },
    },
    methods: {
        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },
        check_salamas_extension()
        {
            let table_ = this.garanties
            for(var i = table_.length - 1; i >= 0; i--)
            {
                if(table_[i]=='VOL PAR EFFRACTION' && this.vol_par_efraction==null){
                    table_.splice(i, 1)
                }

                if(table_[i]=='DEGAT DES EAUX' && this.degat_des_eaux ==null){
                    table_.splice(i, 1)
                }

                if(table_[i]=='BRIS DE GLACES' && this.bris_de_glaces==null){
                    table_.splice(i, 1)
                }
            }
            this.popup_extension_salama=false
        },
        
        loadPopup_extension_salama()
        {
            let cpt = 0
            for(let v of this.assureurs)
            {
                if(v=='SALAMA')
                {
                    for(let g of this.garanties)
                    {
                        if(g=='VOL PAR EFFRACTION' && this.vol_par_efraction==null){
                                    this.garantie_vol_efra = g
                                    cpt = cpt+1
                        }

                        if(g=='DEGAT DES EAUX' && this.degat_des_eaux ==null){
                                    this.garantie_deg_eaux = g
                                    cpt = cpt+1
                        }

                        if(g=='BRIS DE GLACES' && this.bris_de_glaces==null){
                                    this.garantie_gdg = g
                                    cpt = cpt+1
                                        
                         }
                    }
                }
                    
            }
            if(cpt>=1)
            {
                this.popup_extension_salama = true 
            }
            this.popupAddGaranties=false
        },

        chech_scolarite()
        {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++) {
                if (this.garanties[index] == 'ASSURANCE SCOLARITE') {
                    trouver = 1
                }
            }
            if (trouver == 1) {
                this.modal_nombre_enfant = true
            } else {
                this.garanties.pop();
                this.modal_nombre_enfant = false
            }
        },

        nombre_enfant_validator()
        {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++)
            {
                if (this.garanties[index] == 'ASSURANCE SCOLARITE')
                {
                    trouver = 1
                }
            }

            if (trouver == 1 && !this.nombre_enfant || this.nombre_enfant <=0 )
            {
                this.garanties.pop();
                this.modal_nombre_enfant = false
            } else {
                this.modal_nombre_enfant = false
            }
        },
        
        RoundToMillion(num) {
            var parts = num.split(" ");
            var fac = Math.pow(1000, parts.length - 1)
            return (Math.ceil(parseInt(parts.join("")) / fac) * fac)
        },

        saveUserHabitation(){
            if(this.myHabitations == 'mes_habitations'){
            console.log("Déja enregistré !")
            }else {
            const UserHabitation = {
                userid: this.usersearch._id,
                typeBien: this.naturelogement,
                nombrepiece: this.nombrepiece,
                nombreetage: this.nombreetage,
                superficie: this.nombreetage,
                naturecouverture: this.naturecouverture,
                adresselogement: this.adresselogement,
                environnement_immediat: this.environnement_immediat,
                valeurcontenu: this.RoundToMillion(this.valeurcontenu),
                valeurbatiment: Number(this.valeurbatiment.replace(/[^0-9.-]+/g, "")),
                qualiteassure: this.qualiteassure,
                montaLoyer: Number(this.montantLoyer.replace(/[^0-9.-]+/g, "")),
            }
            Api().post("/habitation/save", UserHabitation)
                .then(response => {
                    this.$message.success(response.data.msg, this.AlertMessage)
                });
            }
        },

        getListHabitation(){
            if(this.myHabitations == 'mes_habitations'){
                if(this.listHabitations.length == 0){
                    this.$message.error("Vous n'avez pas de logement !", this.AlertMessage)
                }else{
                this.ModalListeHabitation = true
                this.userHabitations = this.listHabitations
                console.log(this.userHabitations)
                this.myHabitations = 'mes_habitations'
                }
            }else if(this.myHabitations == 'nouveau') {
                this.e1 = 1
            }
            //this.getUserCars()
        },
        deleteHabitation (item){
            const UserHabitation = {
          habitationid : item._id
        }

        Api().post("/habitation/delete", UserHabitation)
            .then(response => {
              if (response.status == 200) {
                 Api().post("habitation/myhouse", {userid: this.usersearch._id})
                        .then(response => {
                        this.userHabitations = response.data.logements
                    });
                    this.$message.success("Logement supprimé avec succés !", this.AlertMessage)

              }
            })
            .catch(err => {})

      },
        getHabitationForDevis(item){
            this.HabitationDetails = item
            this.e1 = 5
            this.ModalListeHabitation = false
        },

        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },
        returnToStepTwo(){
            this.e1 = 3
        },

        ListHabitationCreate: function () {
            if (this.assureurs.length > 0) {
                if (this.dateEffet) {

                    if(this.myHabitations == 'mes_habitations'){
                        let Habitation = {
                        userid: this.usersearch._id,
                        createur: store.state.user._id,
                        typeBien: this.HabitationDetails.typeBien,
                        nombrepiece: this.HabitationDetails.nombrepiece,
                        nombreetage: this.HabitationDetails.nombreetage,
                        superficie: this.HabitationDetails.nombreetage,
                        naturecouverture: this.HabitationDetails.naturecouverture,
                        adresselogement: this.HabitationDetails.adresselogement,
                        proprietaire: {
                            prenom: this.usersearch.prenom,
                            nom: this.usersearch.nom
                        },
                        environnement_immediat: this.HabitationDetails.environnement_immediat,
                        valeurcontenu: this.HabitationDetails.valeurcontenu,
                        valeurbatiment: this.HabitationDetails.valeurbatiment,
                        qualiteassure: this.HabitationDetails.qualiteassure,
                        montaLoyer: this.HabitationDetails.montantLoyer,
                        assureurs: this.assureurs,
                        garanties: this.garanties,
                        nombre_enfant:this.nombre_enfant,
                        branche: 'Habitation',
                        dateEffet: this.dateEffet,
                        dateEcheance: this.dateEcheance,
                        ext_vol_par_efraction: Number(this.vol_par_efraction),
                        ext_degat_des_eaux : Number(this.degat_des_eaux),
                        ext_bris_de_glaces:Number(this.bris_de_glaces),
                        dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                        plateforme : 'Dash',
                      };

                      if (this.$store.state.user.role == 'Agent') {
                        Habitation.courtierid = this.$store.state.user.societe
                    }

                    if (this.montantLoyer) {
                        Habitation.montaLoyer = Number(this.montantLoyer.replace(/[^0-9.-]+/g, ""))
                    }

                    Api().post('devis/add', Habitation)
                        .then(reslutat => {
                             this.modalgenerationloading = true
                            this.$store.dispatch("setDevis", reslutat.data)
                             setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'liste_devis_paiement'
                                }))), 4000
                                );
                        })

                    }else {
                        //this.saveUserHabitation()
                        let Habitation = {
                        userid: this.usersearch._id,
                        proprietaire: {
                            prenom: this.usersearch.prenom,
                            nom: this.usersearch.nom
                        },
                        typeBien: this.naturelogement,
                        nombrepiece: this.nombrepiece,
                        nombreetage: this.nombreetage,
                        naturecouverture: this.naturecouverture,
                        adresselogement: this.adresselogement,
                        valeurcontenu: this.RoundToMillion(this.valeurcontenu),
                        valeurbatiment: (Number(this.valeurbatiment.replace(/[^0-9.-]+/g, ""))),
                        superficie: this.nombreetage,
                        qualiteassure: this.qualiteassure,
                        environnement_immediat: this.environnement_immediat,
                        dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                        assureurs: this.assureurs,
                        garanties: this.garanties,
                        createur: this.$store.state.user._id,
                        branche: 'Habitation',
                        dateEffet: this.dateEffet,
                        ext_vol_par_efraction: Number(this.vol_par_efraction),
                        ext_degat_des_eaux : Number(this.degat_des_eaux),
                        ext_bris_de_glaces:Number(this.bris_de_glaces),
                        dateEcheance: this.dateEcheance,
                        plateforme : 'Dash',
                    }

                    if (this.$store.state.user.role == 'Agent') {
                        Habitation.courtierid = this.$store.state.user.societe
                    }

                    if (this.montantLoyer) {
                        Habitation.montaLoyer = Number(this.montantLoyer.replace(/[^0-9.-]+/g, ""))
                    }

                    Api().post('devis/add', Habitation)
                        .then(reslutat => {
                            this.modalgenerationloading = true
                            this.$store.dispatch("setDevis", reslutat.data)
                             setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'liste_devis_paiement'
                                }))), 4000
                                );
                        })

                    }
                } else {
                    this.$message.error("La date d'effet est obligatoire !", this.AlertMessage)
                }

            } else {
                this.$message.error("Choisissez au moins un assureur !", this.AlertMessage)
            }

        }, 
        //calcul de la valeur du batiment a partir du montant du loyer si c'est locataire
        calculeValeurBatiment() {
            this.saveValueLoyer = this.montantLoyer 
            if(this.qualiteassure === 'locataire'){
                let VB = this.montantLoyer && Number(this.montantLoyer.replace(/[^0-9.-]+/g, ""))
                let fac = 180
                let Total = VB * fac
                this.valeurbatiment = Total.toLocaleString()
            }
        },

        RoundToMillion(num) {
          var parts = num.split(" ");
          var fac = Math.pow(1000, parts.length - 1)
          return (Math.ceil(parseInt(parts.join("")) / fac) * fac)
        },





        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },
        AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
        errorNotif(){
			this.$notify({
				title: 'Error',
				message: 'Numéro obligatoire !',
				position: 'top-right',
				type: 'error'
			});
		},
        errorNotifCreateUser(){
			this.$notify({
				title: 'Error',
				message: 'Tout les Champs sont obligatoires !',
				position: 'top-right',
				type: 'error'
			});
		},
        successNotifCreateUser(){
			this.$notify({
				title: 'Success',
				message: 'Utilisateur crée avec succés !',
				position: 'top-right',
				type: 'succes'
			});
		},
        check_client_search(){
            if(this.clientsearch == 'téléphone'){
                this.NumberInput = true 
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if(this.clientsearch == 'email'){
                this.NumberInput = false 
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },

      rechercherClient() {
        if(this.telephoneclient){
          const validPhone = validatePhone(this.telephoneclient);
          this.errors.phone = validPhone.error;

          if(validPhone.valid == true){
            Api().get('/user/phone/' + this.telephoneclient)
                .then(resultat => {
                  if (resultat.data.telephone_port === this.telephoneclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)
                    this.userHabitationId = this.usersearch._id

                    let useridentity = this.usersearch._id

                    Api().post("habitation/myhouse", {userid: useridentity})
                        .then(response => {
                        this.listHabitations = response.data.logements
                    });
                  }

                })
                .catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.telephoneC = this.telephoneclient;
                })
          } else {
            this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
          }
        }else {
          this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
        }


      },

      rechercheClientByMail(){
        if(this.emailclient){

          const validEmail = validateEmail(this.emailclient);
          this.errors.email = validEmail.error;

          if(validEmail.valid == true){
            Api().get('/user/email/' +this.emailclient)
                .then(resultat =>{
                  if (resultat.data.email === this.emailclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)

                     this.userHabitationId = this.usersearch._id

                    let useridentity = this.usersearch._id

                    Api().post("habitation/myhouse", {userid: useridentity})
                        .then(response => {
                        this.listHabitations = response.data.logements
                    });

                  }
                }).catch(e => {
              this.clientnonOk = true
              this.clientOk = false
              this.emailC = this.emailclient;
            })
          } else {
            this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
          }
        }else {
          this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

        }
      },

        yearsDiff() {
            const date1 = dayjs(this.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, 'days');
            this.age = daydiff

           if (this.age <= 29) {
                this.an = this.annee_mois = 'jour(s)'
            } else if (this.age >= 30, this.age <= 360) {
                let year = Math.floor(this.age / 30)
                this.age = year;
                this.an = this.annee_mois = 'mois'
            } else if (this.age >= 360) {
                let year = Math.floor(this.age / 360)
                this.age = year;
                this.an = this.annee_mois = 'an(s)'
            }

        },

        showidclient() {
            this.showId = false
            this.showconfirmclient = true
            this.shownameclient = false

        },

        hidesearch() {
            this.hidesearchClient = false
            this.e1 = 0
        },

        rechercherbyphone() {
            if (this.usersearch.telephone_port === this.telephoneclient) {
                this.clientOk = true
                this.clientnonOk = false

            }
            if (this.usersearch.telephone_port !== this.telephoneclient) {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;

            }
        },

        onReset(evt) {
            evt.preventDefault();
            this.user.email = '';
            this.user.name = '';
            this.user.food = null;
            this.user.checked = [];
            this.show = false;
            this.$nextTick(() => {
                this.show = true
            });
        },
        //liste de touts les partenaires
        getListePartenaire: function () 
        {
            if (this.$store.state.user.role == 'Agent')
            {
                Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Habitation"})
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires[0].assureurs
                    }).catch(err => {})
            } else if (this.$store.state.user.role == 'SuperAdmin'  || this.$store.state.user.role == 'Admin') 
            {
                Api().get('/partenaire/assureur/')
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires
                    })
            } else if (this.$store.state.user.role == 'assureur') 
            {
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
            }

        },

        HubspotSend(param)
        {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param)
        {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        // Creation d'un user avant de faire son devis
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) 
            {
                const user = {
                        nom: this.nom,
                        prenom: this.prenom,
                        datenaissance: this.datenaissance,
                        profession: this.profession,
                        adresse: this.adresse,
                        sexe: this.genre,
                        telephone_port: this.telephoneclient,
                        email: this.emailclient,
                        password: this.password1,
                        confirmPass: this.password1,
                        role: this.userRole,
                        createdby:store.state.user._id,
                        photo: this.file,
                        piece_identite: this.piece_identite,
                        numero_piece: this.numero_piece,
                         plateforme : 'Dash',
                        created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                        .then(resultat => {
                            this.HubspotSend(user)
                            this.MailChimpInscriptionCompte(user)
                            if (resultat.status == 200) {
                                if(resultat.data.numero){
                                    this.$message.error(resultat.data.msg, this.AlertMessage)
                                }else{
                                    this.$store.dispatch('setClient', resultat.data.data)
                                    this.usersearch = resultat.data.data
                                    this.AddUser = false
                                    this.clientnonOk = false
                                    this.hidesearchClient = false
                                    this.e1 = 0
                                }
                            } else {
                                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                            }

                })
                .catch(err => {
                    this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                })
               
            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },

        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },

         //calclu date echéance
        dateDiff: function () {
            if (this.dateEffet === '' & this.duree === '') this.dateEcheance = ''
            let inter = dayjs(this.dateEffet).add(this.duree, 'month').format('YYYY-MM-DD')
            this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
        },

        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
        //Validation des elements du Stepper 1
        Stepper1Validator() {
            if (this.naturelogement) {
                if(this.nombrepiece){
                    if(this.nombreetage){
                        this.e1 = 2
                    }else {
                        this.$message.error("Nombre d'étage obligatoire !", this.AlertMessage)
                    }
                }else {
                    this.$message.error('Nombre de pièce obligatoire !', this.AlertMessage)
                }
            } else {
                this.$message.error('Nature du logement obligatoire !', this.AlertMessage)
            }
        },

        //Validation des ellements du Stepper 2
        Stepper2Validator() {
            if (this.naturecouverture) {
                if(this.environnement_immediat){
                    if(this.adresselogement){
                        this.e1 = 3
                    }else {
                        this.$message.error('Adresse du logement obligatoire !', this.AlertMessage)
                    }
                }else {
                    this.$message.error("Environnement immédiat obligatoire !", this.AlertMessage)
                }
            } else {
                this.$message.error('Type de toiture du logement obligatoire !', this.AlertMessage)
            }
        },

        //Validation des elements du Stepper 3
        Stepper3Validator() {
            if (this.qualiteassure) {
                if (this.qualiteassure == 'locataire') {
                    if(this.montantLoyer){
                         if (this.montantLoyer && Number(this.montantLoyer.replace(/[^0-9.-]+/g, "")) >= 50000) {
                            this.e1 = 4
                            this.calculeValeurBatiment()
                    } else {
                        this.$message.error('Montant du loyer doit être suppérieur à 50 000 CFA !', this.AlertMessage)
                    }
                    }else {
                        this.$message.error("Montant du loyer obligatoire !", this.AlertMessage)
                    }
                } else {
                    this.e1 = 4
                }
            } else {
                this.$message.error("Qualité de l'assuré obligatoire !", this.AlertMessage)
            }

        },

         //Validation des elements du Stepper 4
        Stepper4Validator() {
            if (this.valeurcontenu && Number(this.valeurcontenu.replace(/[^0-9.-]+/g, "")) >= 10000) {
                if (this.valeurbatiment && Number(this.valeurbatiment.replace(/[^0-9.-]+/g, "")) >= 10000) {
                    this.e1 = 5
                } else {
                    this.$message.error("Valeur du bâtiment obligatoire !", this.AlertMessage)
                }
            } else {
                this.$message.error("Valeur du contenu à garantir obligatoire !", this.AlertMessage)
            }

        },
        //Validation des elements du Stepper 4
        Stepper5Validator() {
            if (this.garanties) {
                this.e1 = 6
            } else {
                this.$message.error("Choisissez au moins un garantie !", this.AlertMessage)
            }
        },

        testVar:function(val){
             if (val!=null)
            {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },



        __resizeHanlder: _.throttle(function(e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),
    
    },
    created(){
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
