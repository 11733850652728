<template>
	<div class="page-ecommerce-product-detail scrollable only-y">
		
		<el-breadcrumb separator="/" class="themed">
			<el-breadcrumb-item>Devis</el-breadcrumb-item>
			<el-breadcrumb-item>Paiement</el-breadcrumb-item>
		</el-breadcrumb>

		<el-row class="mt-30">
			<el-col>
				<div class="item-box card-shadow--medium" style="margin-top:20px">
					<el-row class="border" v-if="!isMobile">
						<el-col class="el-col-lg-5 el-col-xl-5">
							<div class="gallery-box" style="margin-left: 20%;margin-top: 45%;">
								<div>
									<img width="130" height="90" :src="Devis_pour_souscription.partenaireid.logo">
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="16" :xl="16">
							<div class="detail-box">
                                <h2 class="title">Numéro Police : <span class="assuraf-orange">{{Devis_pour_souscription.numeroDevis}}</span></h2>
								<h2 class="title">Prime TTC: <span class="assuraf-orange">{{Devis_pour_souscription.prime_ttc}} FCFA</span></h2>
								<div class="description">
									frais de dossier inclus
								</div>
								<div class="actions-box">
                                    <el-button class="themed mr-10 mb-10 ml-0" type="button" @click="devisParMail(Devis_pour_souscription)"><i class="mdi mdi-email-outline"></i> Devis par email</el-button>
                                    <el-button v-loading.fullscreen.lock="modalgenerationloading" class="themed mr-10 mb-10 ml-0" type="button" @click="Downloa_devis(Devis_pour_souscription)"><i class="mdi mdi-download-outline"></i> Télécharger Devis</el-button>
                                    <el-button class="themed mr-10 mb-10 ml-0" type="button" @click="PaymentLink(Devis_pour_souscription)"><i class="mdi mdi-email-send-outline"></i> Lien de paiement</el-button>
								</div>
							</div>
						</el-col>
					</el-row>

                    <el-row class="card-base card-shadow--medium bg-white pb-5" v-if="isMobile" style="margin-top:20px">
						<el-col offset="8" justify="center" class="mt-5">
                            <div>
                                <img width="100" height="auto" :src="Devis_pour_souscription.partenaireid.logo" style="margin-top:15px">
                            </div>
						</el-col>
						<el-col class="text-center mt-2">
                            <h3 class="title">Numéro Police : <span class="assuraf-orange">{{Devis_pour_souscription.numeroDevis}}</span></h3>
                            <h3 class="title">Prime TTC: <span class="assuraf-orange">{{Devis_pour_souscription.prime_ttc}} FCFA</span></h3>
                            <div class="description">
                                frais de dossier inclus
                            </div>
						</el-col>
                        <div class="mt-2">
                            <div style="width:70%;margin-left:20%;">
                                <el-button class="mobile-btn ml-10" type="button" @click="devisParMail(Devis_pour_souscription)"><i class="mdi mdi-email-outline"></i> Devis par email</el-button>
                                <el-button v-loading.fullscreen.lock="modalgenerationloading" class="mobile-btn" type="button" @click="Downloa_devis(Devis_pour_souscription)"><i class="mdi mdi-download-outline"></i> Télécharger Devis</el-button>
                                <el-button class="mobile-btn" type="button" @click="PaymentLink(Devis_pour_souscription)"><i class="mdi mdi-email-send-outline"></i> Lien de paiement</el-button>
                            </div>
                        </div>
					</el-row>
				</div>

                <div class="item-box card-shadow--medium" style="margin-top:15px" v-if="brancheTocheck == 'Automobile' && !isMobile || brancheTocheck == 'Habitation' && !isMobile">
                    <h3 class="text-center">DÉTAILS DE L'ASSURANCE</h3>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                <el-card class="box-card" v-if="brancheTocheck == 'Automobile'">
                                    <div slot="header" class="clearfix">
                                        <span>Détails du véhicule</span>
                                    </div>
                                    <div   v-for="(auto,k) in Devis_pour_souscription.vehicules" :key="k">
                                        <ul style="list-style:none">
                                            <li>Marque : <span class="to-right assuraf-orange">{{auto.marque}}</span></li>
                                            <li>Modèle : <span class="to-right assuraf-orange">{{auto.model}}</span></li>
                                            <li>Immatriculation : <span class="to-right assuraf-orange">{{auto.immatriculation}}</span></li>
                                            <li v-if="auto.carosserie">Carosserie : <span class="to-right assuraf-orange">{{auto.carosserie}}</span></li>

                                            <li>Puissance fiscale : <span class="to-right assuraf-orange">{{auto.force}}</span></li>
                                            <li>Âge : <span class="to-right assuraf-orange">{{auto.age}}</span></li>
                                            <li>Nombre de place : <span class="to-right assuraf-orange">{{auto.nbplace}}</span></li>
                                            <li>Valeur à neuf : <span class="to-right assuraf-orange">{{auto.valeur}}</span></li>

                                            <li>Valeur vénale : <span class="to-right assuraf-orange">{{auto.valeur_venale}}</span></li>
                                            <li v-if="auto.capitalAvRec">Capital Avance sur Recours : <span class="to-right assuraf-orange">{{auto.capitalAvRec}}</span></li>
                                            <li>Categorie : <span class="to-right assuraf-orange">{{auto.categorie}}</span></li>
                                            <li v-if="auto.option_Individuel_Accident">Option Individuel Accident : <span class="to-right assuraf-orange">{{auto.option_Individuel_Accident}}</span></li>

                                            <li v-if="auto.tonnage">Tonnage : <span class="to-right assuraf-orange">{{auto.tonnage}}</span></li>
                                            <li v-if="auto.optionPersoTrans">Option Personne Transportées : <span class="to-right assuraf-orange">{{auto.optionPersoTrans}}</span></li>
                                            <li v-if="auto.volumetrie2_roues">Volumetrie 2 roues : <span class="to-right assuraf-orange">{{auto.volumetrie2_roues}}</span></li>
                                            <li>Carburation : <span class="to-right assuraf-orange">{{auto.carburation}}</span></li>
                                        </ul>
                                    </div>
                                </el-card>
                                <el-card class="box-card" v-if="brancheTocheck == 'Habitation'">
                                    <div slot="header" class="clearfix">
                                        <span>Détails du logement</span>
                                    </div>
                                    <div  >
                                        <ul style="list-style:none">
                                            <li>Type Bien : <span class="to-right assuraf-orange">{{Devis_pour_souscription.typeBien}}</span></li>
                                            <li>Nature couverture : <span class="to-right assuraf-orange">{{Devis_pour_souscription.naturecouverture}}</span></li>
                                            <li>Valeur bâtiment : <span class="to-right assuraf-orange">{{Devis_pour_souscription.valeurbatiment}} FCFA</span></li>
                                            <li>Valeur contenu : <span class="to-right assuraf-orange">{{Devis_pour_souscription.valeurcontenu}} FCFA</span></li>

                                            <li>Qualité du proposant : <span class="to-right assuraf-orange">{{Devis_pour_souscription.qualiteassure}}</span></li>
                                            <li>Nombre de pièce : <span class="to-right assuraf-orange">{{Devis_pour_souscription.nombrepiece}} pièces</span></li>
                                        </ul>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple-light" style="margin-bottom: 20px;margin-right: 20px;">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>Garantie(s) souscrite(s)</span>
                                    </div>
                                    
                                    <div class="text item" v-if="brancheTocheck == 'Automobile'">
                                         <ul style="list-style:none" v-for="gar in Devis_pour_souscription.vehicules[0].garanties" :key="gar">
                                            <li>{{gar}}</li>
                                        </ul>   
                                    </div>

                                     <div class="text item" v-if="brancheTocheck == 'Habitation'">
                                         <ul style="list-style:none" v-for="(gar,k) in listegaranties" :key="k">
                                            <li>{{gar.libele}}</li>
                                        </ul>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                 <div class="item-box card-shadow--medium" style="margin-top:15px" v-if="brancheTocheck == 'Automobile' && isMobile || brancheTocheck == 'Habitation' && isMobile">
                    <h3 class="text-center">DÉTAILS DE L'ASSURANCE</h3>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px; margin-right: 20px;">
                                <el-card class="box-card" v-if="brancheTocheck == 'Automobile'">
                                    <div slot="header" class="clearfix">
                                        <span>Détails du véhicule</span>
                                    </div>
                                    <div   v-for="(auto,k) in Devis_pour_souscription.vehicules" :key="k">
                                        <ul style="list-style:none">
                                            <li>Marque : <span class="to-right assuraf-orange">{{auto.marque}}</span></li>
                                            <li>Modèle : <span class="to-right assuraf-orange">{{auto.model}}</span></li>
                                            <li>Immatriculation : <span class="to-right assuraf-orange">{{auto.immatriculation}}</span></li>
                                            <li v-if="auto.carosserie">Carosserie : <span class="to-right assuraf-orange">{{auto.carosserie}}</span></li>

                                            <li>Puissance fiscale : <span class="to-right assuraf-orange">{{auto.force}}</span></li>
                                            <li>Âge : <span class="to-right assuraf-orange">{{auto.age}}</span></li>
                                            <li>Nombre de place : <span class="to-right assuraf-orange">{{auto.nbplace}}</span></li>
                                            <li>Valeur à neuf : <span class="to-right assuraf-orange">{{auto.valeur}}</span></li>

                                            <li>Valeur vénale : <span class="to-right assuraf-orange">{{auto.valeur_venale}}</span></li>
                                            <li v-if="auto.capitalAvRec">Capital Avance sur Recours : <span class="to-right assuraf-orange">{{auto.capitalAvRec}}</span></li>
                                            <li>Categorie : <span class="to-right assuraf-orange">{{auto.categorie}}</span></li>
                                            <li v-if="auto.option_Individuel_Accident">Option Individuel Accident : <span class="to-right assuraf-orange">{{auto.option_Individuel_Accident}}</span></li>

                                            <li v-if="auto.tonnage">Tonnage : <span class="to-right assuraf-orange">{{auto.tonnage}}</span></li>
                                            <li v-if="auto.optionPersoTrans">Option Personne Transportées : <span class="to-right assuraf-orange">{{auto.optionPersoTrans}}</span></li>
                                            <li v-if="auto.volumetrie2_roues">Volumetrie 2 roues : <span class="to-right assuraf-orange">{{auto.volumetrie2_roues}}</span></li>
                                            <li>Carburation : <span class="to-right assuraf-orange">{{auto.carburation}}</span></li>
                                        </ul>
                                    </div>
                                </el-card>
                                <el-card class="box-card" v-if="brancheTocheck == 'Habitation'">
                                    <div slot="header" class="clearfix">
                                        <span>Détails du logement</span>
                                    </div>
                                    <div  >
                                        <ul style="list-style:none">
                                            <li>Type Bien : <span class="to-right assuraf-orange">{{Devis_pour_souscription.typeBien}}</span></li>
                                            <li>Nature couverture : <span class="to-right assuraf-orange">{{Devis_pour_souscription.naturecouverture}}</span></li>
                                            <li>Valeur bâtiment : <span class="to-right assuraf-orange">{{Devis_pour_souscription.valeurbatiment}} FCFA</span></li>
                                            <li>Valeur contenu : <span class="to-right assuraf-orange">{{Devis_pour_souscription.valeurcontenu}} FCFA</span></li>

                                            <li>Qualité du proposant : <span class="to-right assuraf-orange">{{Devis_pour_souscription.qualiteassure}}</span></li>
                                            <li>Nombre de pièce : <span class="to-right assuraf-orange">{{Devis_pour_souscription.nombrepiece}} pièces</span></li>
                                        </ul>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div class="grid-content bg-purple-light" style="margin-bottom: 20px;margin-left: 20px; margin-right: 20px;">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>Garantie(s) souscrite(s)</span>
                                    </div>
                                    
                                    <div class="text item" v-if="brancheTocheck == 'Automobile'">
                                         <ul style="list-style:none" v-for="gar in Devis_pour_souscription.vehicules[0].garanties" :key="gar">
                                            <li>{{gar}}</li>
                                        </ul>   
                                    </div>

                                     <div class="text item" v-if="brancheTocheck == 'Habitation'">
                                         <ul style="list-style:none" v-for="(gar,k) in listegaranties" :key="k">
                                            <li>{{gar.libele}}</li>
                                        </ul>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div class="item-box card-shadow--medium" style="margin-top:15px" v-if="!isMobile">
                    <h3 class="text-center">PAIEMENT ET LIVRAISON</h3>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>Mode de livraison</span>
                                    </div>
                                    <div class="text item">
                                        <el-row justify="center">
                                            <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="mode_livraison" label="a_docimicile">A domicile (1000 fcfa à titre indicatif)</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_livraison" label="reseau_partenaire">Réseaux partenaire (500 fcfa )</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_livraison" label="locaux_assuraf">Locaux assuraf</el-radio>
                                            </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple-light" style="margin-bottom: 20px;margin-right: 20px;">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>Moyen de paiement</span>
                                    </div>
                                    <div class="text item">
                                        <el-row justify="center">
                                            <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="mode_payement" label="mobile_money">Mobile Money</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_payement" label="carte_bancaire">Carte Bancaire</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_payement" label="paiment_partenaire">Paiement au retrait Réseaux partenaires</el-radio>
                                            </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div class="item-box card-shadow--medium" style="margin-top:15px" v-if="isMobile">
                    <h3 class="text-center">PAIEMENT ET LIVRAISON</h3>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px; margin-right: 20px;">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>Mode de livraison</span>
                                    </div>
                                    <div class="text item">
                                        <el-row justify="center">
                                            <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="mode_livraison" label="a_docimicile">A domicile (1000 fcfa à titre indicatif)</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_livraison" label="reseau_partenaire">Réseaux partenaire (500 fcfa )</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_livraison" label="locaux_assuraf">Locaux assuraf</el-radio>
                                            </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div class="grid-content bg-purple-light" style="margin-bottom: 20px;margin-left: 20px; margin-right: 20px;">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span>Moyen de paiement</span>
                                    </div>
                                    <div class="text item">
                                        <el-row justify="center">
                                            <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="mode_payement" label="mobile_money">Mobile Money</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_payement" label="carte_bancaire">Carte Bancaire</el-radio>
                                            </div>
                                            </el-col>
                                            <el-col><div class="grid-content bg-purple-light">
                                                <el-radio v-model="mode_payement" label="paiment_partenaire">Paiement au retrait Réseaux partenaires</el-radio>
                                            </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                 <div class="item-box card-shadow--medium" v-show="mode_livraison" style="margin-top:15px">
                    <h3 class="text-center">Montant TTC : <span class="assuraf-orange">{{mention_ttc}} FCFA</span></h3>
                </div>
			</el-col>
		</el-row>

      
        <!-- POPUP LIEN DE PAIEMENT
             POPUP LIEN DE PAIEMENT
             POPUP LIEN DE PAIEMENT  -->
        <el-dialog title="LIEN DE PAIEMENT" :visible.sync="ThePaymentLinkModal">
            <el-form style="border-radius: 30px">
            <el-form-item label="Lien">
                <el-input readonly v-model="ThePaymentLink"></el-input>
            </el-form-item>
            </el-form>
        </el-dialog>
	</div>
</template>

<script>

import Api from '../../../services/Api'
import getUpay from '../../../services/getUpay'
import store from '../../../store'

export default {
	name: 'Paiement',
	data () {
		return {
            // Data of ASSURAF - Data of ASSURAF - Data of ASSURAF
            isMobile: false,
            mode_payement : null,
            mode_livraison : null,
            Devis_pour_souscription:[],
            assureurs:[],
            ThePaymentLinkModal: false,
            modalSendByMail : false,
            modalInfoPaiement:false,
            modalInfoVoyageur : false,
            branche:null,
            modalgeneration:false,
            mention_ttc:0,
            notif:null,

            nomclient:null,
            prenomclient:null,
            code_bancaire_client:null,

            nom_passager : null,
            prenom_passager :null,
            nationalite_passager:null,
            numero_ci_passager:null,
            date_delivrance :null,
            date_expiration :null,
            tableau_info_passager:[],
            continue_btn : false,
            listegaranties:[],
            brancheTocheck:null,
            sendBymailOk:false,
            listPassager:[],
            ToastTabSucess: {  
                    // Customized options for success toast
                    theme: "toasted-primary",
                    position: "top-center",
                    duration: 2000,
                    fitToScreen: true,
                    type: 'success',
                    className: 'custom-success-class'
                },
                ToastTabError: {  
                    // Customized options for error toast
                    theme: "toasted-primary",
                    position: "top-center",
                    duration: 2000,
                    fitToScreen: true,
                    type: 'error'
                },
            enteteTabPassager:[
              {key:'nom_passager',label:'Nom'},
              {key:'prenom_passager',label:'Prenom'},
              {key:'nationalite_passager',label:'Nationaliter'},
              {key:'numero_ci_passager',label:'Numero Passeport'},
              {key:'date_delivrance',label:'Date de delivrance'},
              {key:'date_expiration',label:'Date expiration'},
            ],
            modalgenerationloading : false,
            mode_paiement: null,
            mode_livraison: null,
            listdevis: [],
            assureurs: [],
            branche: null,
            modalgeneration: false,
            modalSendByMail: false,
            brancheTocheck: null,
            ThePaymentLink: null,
            ThePaymentLinkModal: false,
            ThePaymentLinkModalError: false,
            modalReductionSpeciale:false,
            DevisForReduction:null,
            CarDetailsArray:[],
            PourcentageReduction:null,
            newprime_ttc:0,
            oldPrimeTT:0,
            // FIN Data of ASSURAF - Data of ASSURAF - Data of ASSURAF
			rate: 4,
			qnt: 1,
			activeTab: 'description'
		}
	},
    methods : {
         init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },
         PayementSpecialVoyage(){
            this.modalInfoVoyageur=false
            getUpay(this.mention_ttc, this.mode_payement)
        },

          // Fonction start du process de paiement pour souscription
        payement_start: function () {

                if (this.mode_payement) {
                    if (this.mode_livraison) {
                           if (this.branche=='Voyage') {
                               this.modalInfoVoyageur = true
                           }else{
                               //this.modalInfoPaiement=true
                               getUpay(this.mention_ttc,this.mode_payement)
                           }
                    } else {
                        this.$message.error("Mode livraison obligatoire !", this.AlertMessage)
                    }
                } else {
                    this.$message.error("Mode de paiement obligatoire !", this.AlertMessage)
                }

        },

        // Fonction finalisation du process de paiement pour souscription
     payement_end: function (params) {
               const Contrat = {
                          numero_police: params.numeroDevis,
                          userid: params.userid,
                          brancheid: params.brancheid._id,
                          partenaireid: params.partenaireid._id,
                          devisid: params._id,
                          createur:params.createur,
                          dateEffet: params.dateEffet,
                          dateEcheance: params.dateEcheance,
                          dateSouscription: params.dateSouscription,
                          taxe: params.taxe,
                          prime_nette: params.prime_nette,
                          prime_ttc: params.prime_ttc,
                          bonus: params.bonus,
                          accessoires: params.accessoires,
                      }

       
               if (store.state.user.role=='Agent')
               {
                  Contrat.courtierid = store.state.user.societe
               }

               if(this.$store.state.devispayement.devis.brancheid.branche=='Automobile'){
                 Contrat.details = params.vehicules
               }

               if(this.$store.state.devispayement.devis.brancheid.branche=='Voyage')
               {
                   Contrat.details=this.tableau_info_passager
               }

               if (this.mode_payement=='carte_bancaire')
                {
                    if (this.nomclient && this.prenomclient && this.code_bancaire_client)
                     {
                         
                       Api().post('contrat/add', Contrat)
                        .then(response => {
                            this.dialog = false
                            this.notif = response.data.message
                            this.contratToStore = response.data.contrat
                            this.contratToStore.assureur = response.data.assureur
                            this.contratToStore.branche = response.data.Thebranche
                            this.$store.dispatch('setNewcontrat', this.contratToStore) 
                        })
                       
                    }else{
                       this.$toasted.show('Tous les (*) sont obligatoires ! ', this.ToastTabError)
                    }
                }else{

        
                Api().post('contrat/add', Contrat)
                    .then(response => {
                        this.dialog = false
                        this.notif = response.data.message
                        this.contratToStore = response.data.contrat
                        this.contratToStore.assureur = response.data.assureur
                        this.contratToStore.branche = response.data.Thebranche
                        this.$store.dispatch('setNewcontrat', this.contratToStore)
                    })

                }
            },

        AlertMessage() {
            this.$message({
                showClose: false,
                duration : 5000
            });
        },

        go_express_payment(contrat){
            localStorage.setItem("contrat_for_express_payment", contrat.police)
             this.$router.go(this.$router.push({name: 'paiementCash' }))
        },

        ReductionCalculation(gar)
        {
            let Reduction = (gar.prime * gar.PourcentageReduction)/100
            gar.primeAfterReduction = gar.prime - Reduction
           
            const NewDetails = this.DevisForReduction.vehicul
            let newPrimeNette = 0
            
            for(let v of NewDetails)
            {
                for(let det of v.Detail_Prime)
                {
                    if(det.primeAfterReduction >0)
                    {
                        newPrimeNette = newPrimeNette + det.primeAfterReduction
                    }else
                    {
                        newPrimeNette = newPrimeNette + det.prime
                    }
                }
            }
           
            let Newtaxe =  Math.ceil(((newPrimeNette + this.DevisForReduction.accessoires)*10)/100)
            this.newprime_ttc = newPrimeNette + Newtaxe + this.DevisForReduction.fga +this.DevisForReduction.accessoires 
        },

        //Special reduction Pop-up loader function
        PopupSpecialReductionLoading(devis){
            this.oldPrimeTT = devis.tarif
            this.DevisForReduction = devis
            this.CarDetailsArray = this.DevisForReduction.vehicul
            this.modalReductionSpeciale = true
        },

        passage_souscription (devis) {
            Api().get('devis/' + devis.id)
                .then(response => {
                    this.$store.dispatch('setDevisPayement', response.data)
                    this.$router.go(this.$router.push({
                        name: 'paiement'
                    }))
                })
        },

        // Fonction de download du devis en fichier pdf 
        Downloa_devis (devis) {
            const checker = devis.numeroDevis.split("/")
            switch (checker[0]){
            case "OL": 
                Api().get('assuranceOffline/download/' + devis._id)
                .then(response => {
                    this.modalgenerationloading = true;
                    let url = response.data.lien
                    let WinOpener = null
                    setTimeout(() => (
                        this.modalgenerationloading = false,  
                        WinOpener = window.open(),
                        WinOpener.location = url
                    ), 7000);
            })

            default :Api().get('devis/download/' + devis._id)
                .then(response => {
                    this.modalgenerationloading = true;
                    let url = response.data.lien
                    let WinOpener = null
                    setTimeout(() => (
                        this.modalgenerationloading = false,  
                        WinOpener = window.open(),
                        WinOpener.location = url
                    ), 7000);
                })
            break;
            }

        
        },
        // Fonction d'envois du devis par email
        devisParMail (params) {
            Api().get('devis/mydevisbyemail/' + params._id)
                .then(response => {
                    if (response.data.info) {
                        //this.modalSendByMail = true
                        this.$message.success("Votre devis vous a été envoyé par mail !", this.AlertMessage)
                    }
                })
        },

        PaymentLink: function (params) {
            let ObjetToSend = {
                userid: params.userid,
                devisid: params._id,
                brancheid: params.brancheid,
                partenaireid: params.partenaireid,
            }
            Api().get('/paymentLink/devis/' + params._id)
                .then(resp => {
                    if (resp.data.link.length == 1) {
                        this.ThePaymentLinkModal = true
                        this.ThePaymentLink = resp.data.link[0].contractLink
                    } else {
                        Api().post('/paymentLink/', ObjetToSend)
                            .then(response => {
                                if (response.data.message == 'OK') {
                                    this.modalSendByMail = true
                                    this.ThePaymentLink = response.data.link
                                }
                            })
                    }
                })
                .catch(e => {
                    this.ThePaymentLinkModalError = true
                    this.$message.error("Erreur de connexion, veuillez réessayer !", this.AlertMessage)
                })

        },

        //Update devis after Special Reduction
        UpdateDevisAfetrSpecialredution:function(){
            const NewDetails = this.DevisForReduction.vehicul
            let newPrimeNette = 0
            
            for(let v of NewDetails)
            {
                for(let det of v.Detail_Prime)
                {
                    if(det.primeAfterReduction)
                    {
                        newPrimeNette = newPrimeNette + det.primeAfterReduction
                        det.prime = det.primeAfterReduction
                    }else
                    {
                        newPrimeNette = newPrimeNette + det.prime
                        det.prime = det.prime
                    }
                }
            }
           
            let Newtaxe =  Math.ceil(((newPrimeNette + this.DevisForReduction.accessoires)*10)/100)
            this.newprime_ttc = newPrimeNette + Newtaxe + this.DevisForReduction.fga +this.DevisForReduction.accessoires            
            
            const DevisForUpdate = {
                id: this.DevisForReduction.id,
                primeTTC: this.newprime_ttc,
                primeNette: newPrimeNette,
                taxe: Newtaxe,
                vehicules: this.DevisForReduction.vehicul
            }
            Api().post('/devis/auto/special/reduction', DevisForUpdate)
            .then((result) => {
                if(result.data.status)
                {
                    this.$toasted.show(result.data.msg, this.ToastTabSucess)
                    setTimeout(() => (
                        this.$router.go(this.$router.push({  name: 'listdevis' })),
                        window.open(url)), 1000);
                }else{
                    this.$toasted.show(result.data.msg, this.ToastTabError)
                }
            }).catch((err) => {
                 console.log(err)
            });

        }
    },
    created() {
        this.init()
    },

    watch: {
        mode_livraison: function () {

                if (this.mode_livraison == 'a_docimicile') {
                    this.mention_ttc = 1000 + this.Devis_pour_souscription.prime_ttc
                } else if (this.mode_livraison == 'reseau_partenaire') {
                    this.mention_ttc = 500 + this.Devis_pour_souscription.prime_ttc
                } else if (this.mode_livraison == 'locaux_assuraf') {
                    this.mention_ttc = 250 + this.Devis_pour_souscription.prime_ttc
                }
         } ,
         notif: function () {
                if (this.notif == 'Contrat cree et envoye par email') {
                    this.$router.go(this.$router.push({name:'paiement_souscription'}))
                    this.notif = null
                } else if (this.notif == 'Contrat cree mais non envoye par email') {
                     this.$toasted.show("Votre contrat a bien été crée mais l'envoie par mail a échoué", this.ToastTabError)
                     this.$router.go(this.$router.push({name:'paiement_souscription'}))
                     this.notif = null
                }else if(this.notif == 'YET'){
                    this.$router.go(this.$router.push({name:'cumule_contrat_alert'}))
                }else{
                   this.$toasted.show('Erreur lors du paiement', this.ToastTabError)
                } 
         } 
    },

	mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
             if(this.$store.state.devispayement){
                this.Devis_pour_souscription = this.$store.state.devispayement.devis
                this.branche = this.$store.state.devispayement.devis.brancheid.branche
                this.brancheTocheck =  this.branche
                if (this.$store.state.devispayement.devis.brancheid.branche=='Automobile')
                {
                    for (let index = 0; index < this.Devis_pour_souscription.vehicules.length; index++)
                    {
                        this.listegaranties.push(this.Devis_pour_souscription.vehicules[index].garanties)
                    }
                }else{
                    this.listegaranties = this.Devis_pour_souscription.garanties
                }

                if (this.$store.state.devispayement.devis.brancheid.branche=='Voyage') 
                {
                   this.nbPassager = this.$store.state.devispayement.devis.nbpassager
                }
          } 
        } else {
            this.$router.go(this.$router.push('/login'))
        }

	}
}
</script>

<style lang="scss">
@import '../../../assets/scss/_variables';
.mobile-btn{
    width:180px;
    margin-top:5px;
}
.to-right {
    float: right!important;
}

.assuraf-orange {
    color: #ffa409!important
}

.page-ecommerce-product-detail {
	.item-box {
		border-radius: 4px;
		overflow: hidden;
        background : #fff;

		.main-photo {
			position: relative; 
			overflow: hidden;
			background: white;
			padding: 30px;

			img {
				width: 100%;
			}
		}
		.detail-box {
			padding: 30px;
			position: relative; 

			.title {
				margin: 0;
			}

			.price-box {
				margin-top: 20px;
				margin-bottom: 30px;

				.discounted-price {
					color: $text-color-accent;
					font-weight: bold;
					font-size: 25px;
					margin-right: 20px;
					display: inline-block;
				}
				.normal-price {
					opacity: .5;
					text-decoration: line-through;
					text-decoration-color: $text-color-accent;
					margin-right: 10px;
					display: inline-block;
				}
				.discount {
					color: $text-color-accent;
					display: inline-block;
				}
			}

			.actions-box {
				margin-top: 30px;

				.el-input-number {
					width: 100px;
			
					.el-input__inner {
						color: $text-color-accent;
						background-color: transparent;
						border-color: $text-color-accent;
						font-family: inherit;
						font-weight: bold;
						padding-left: 5px;
						padding-right: 45px;
					}
				}

				.el-button {
					font-family: inherit;
					margin-left: 0;
				}
			}
		}
	}
}

</style>


