import Proprietaire from "../proprietaire";
export default class MicroAuto {
    constructor() {
        this.durationInDay = [15, 30],
        this.branche = "Micro-habitation"
        this.assureur = "SUNU"
        this.dateEffet = null
        this.dateEcheance = null,
        this.pack1 = {
            namePack : "Pack_1",
            garantie : ['RESPONSABILITE CIVILE', 'INCENDIE']
        },
        this.pack2 = {
            namePack : "Pack_2",
            garantie : ['RESPONSABILITE CIVILE', 'INCENDIE','DEGAT DES EAUX']
        },
        this.Proprietaire = new Proprietaire()
    }
}