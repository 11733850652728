<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <div class="page-header">
            <h1>Liste de tous les <span style="color:#ffa409">contrats</span>
                <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>

        <div class="toolbar-box flex align-center" v-if="!isMobile">
            <div class="box grow">
                <el-input
                        placeholder="Rechercher contrat..."
                        prefix-icon="el-icon-search"
                        v-model="search"
                        clearable
                >
                </el-input>
            </div>
            <div class="box grow">
                <!--        A ne pas supprimer-->
            </div>
        </div>

        <div v-if="isMobile" style="margin-bottom:15px">
            <el-row :gutter="15">
                <el-col :span="24" style="margin-top:15px">
                    <el-input
                            placeholder="Rechercher contrat..."
                            prefix-icon="el-icon-search"
                            v-model="search"
                            clearable>
                    </el-input>
                </el-col>
            </el-row>
        </div>

        <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="!isMobile">
            <div class="box grow">
                <h3>Recherche par période de création</h3>
                <el-date-picker
                        v-model="du"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-date-picker
                        style="margin-left:15px"
                        v-model="au"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
            </div>
        </div>
        <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="isMobile">
            <div class="box grow">
                <h3>Recherche par période de création</h3>
                <el-date-picker
                        v-model="du"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-date-picker
                        style="margin-top:15px"
                        v-model="au"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date">
                </el-date-picker>
                <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
            </div>
        </div>

        <resize-observer @notify="handleResize" />

        <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    :height="height"
                    v-if="ready"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column label="Date Souscription" fixed min-width="150" prop="dateSouscription">
                    <template slot-scope="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="150" prop="client">
                    <template slot-scope="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.user)">
                    {{scope.row.user.prenom }} - {{scope.row.user.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Num devis" min-width="250" prop="numero_police">
                    <template slot-scope="scope">
                        <span class="sel-string" v-html="$options.filters.selected(scope.row.numero_police, search)"></span>
                    </template>
                </el-table-column>
                <el-table-column label="Branche" prop="brancheid.branche" min-width="200">
                    <template slot-scope="scope">
                        <span class="sel-string" v-html="$options.filters.selected(scope.row.brancheid.branche, search)"></span>
                    </template>
                </el-table-column>
                <el-table-column label="Code Partenaire" min-width="150" prop="codep">
                    <template slot-scope="scope">
                        <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe">
                    <template slot-scope="scope">
                        <span class="sel-string" v-html="$options.filters.selected(scope.row.partenaireid.societe, search)"></span>
                    </template>
                </el-table-column>
                <el-table-column label="Date effet" min-width="150" prop="dateEffet">
                    <template slot-scope="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope="scope">
                        <span class="date">{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="150" prop="courtierid.societe"></el-table-column>
                <el-table-column label="Créateur" min-width="150" prop="creator">
                    <template slot-scope="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
                        <span class="date" v-if="scope.row.createur">{{scope.row.createur.prenom }} - {{scope.row.createur.nom }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actions" prop="action" min-width="80">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>

        <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
            <el-table
                    :data="listInPage"
                    style="width: 100%"
                    v-if="ready"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column label="Date Souscription" min-width="150"  prop="dateSouscription" :fixed="!isMobile">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" min-width="150" prop="client">
                    <template slot-scope ="scope">
                <span class="date">
                  <a href="#" @click="SetDetailUser(scope.row.user)">
                    {{scope.row.user.prenom }} - {{scope.row.user.nom }}
                  </a>
                </span>
                    </template>
                </el-table-column>
                <el-table-column label="Numéro police" min-width="250" prop="numero_police">
                    <template slot-scope="scope">
                        <span class="sel-string" v-html="$options.filters.selected(scope.row.numero_police, search)"></span>
                    </template>
                </el-table-column>
                <el-table-column label="Branche" prop="brancheid.branche" min-width="200">
                    <template slot-scope="scope">
                        <span class="sel-string" v-html="$options.filters.selected(scope.row.brancheid.branche, search)"></span>
                    </template>
                </el-table-column>
                <el-table-column label="Code Partenaire" min-width="150" prop="codep">
                <template slot-scope ="scope">
                    <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
                </template>
                </el-table-column>
                <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
                  <template slot-scope ="scope">
                      <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe">
                    <template slot-scope="scope">
                        <span class="sel-string" v-html="$options.filters.selected(scope.row.partenaireid.societe, search)"></span>
                    </template>
                </el-table-column>
                <el-table-column label="Date effet" min-width="150" prop="dateEffet">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
                    <template slot-scope ="scope">
                        <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
                    <template slot-scope ="scope">
                        <span class="date">{{scope.row.prime_ttc.toLocaleString()}} CFA</span>
                    </template>
                </el-table-column>
                <el-table-column label="Partenaire" min-width="150" prop="courtierid.societe"></el-table-column>
                <el-table-column label="Créateur" min-width="150" prop="creator">
                    <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
                        <span class="date" v-if="scope.row.createur">{{scope.row.createur.prenom }} - {{scope.row.createur.nom }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Actions" prop="action" min-width="60">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    v-if="ready"
                    :small="pagination.small"
                    :current-page.sync="pagination.page"
                    :page-sizes="pagination.sizes"
                    :page-size.sync="pagination.size"
                    :layout="pagination.layout"
                    :total="total"
            ></el-pagination>
        </div>

        <el-dialog width="70%" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div>
                                <ul style="list-style:none">
                                    <li>Date création : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                                    <li>Numéro devis : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                                    <li>Date effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                                    <li v-if="detailcontrat.partner_creator != null">Partenaire : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                                </ul>
                            </div>
                            <hr>

                            <!-- VIsualisation Population Contrat Santé -->
                            <ul style="list-style:none" v-if="detailsContrat.brancheid.branche=='Santé'">
                                <p> <b>Population : </b> </p>
                                <li> Adulte Homme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Adulte.Homme}}</span></li>
                                <li> Adulte Femme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Adulte.Femme}}</span></li>
                                <li> Enfant Garçon :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Enfant.Garcon}}</span></li>
                                <li> Enfant Fille :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Enfant.Fille}}</span></li>
                                <li> Senior Homme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Senior.Homme}}</span></li>
                                <li> Senior Femme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Senior.Femme}}</span></li>
                                <li style="font-weight: bolder;"> 
                                    Total  :  <span class="to-right assuraf-orange">
                                        {{detailsContrat.devisid.details_pack_sante.assures.nombre_assures}}
                                    </span>
                                </li>
                            </ul>
                            <!-- VIsualisation Population Contrat Vie -->
                            <ul style="list-style:none" v-if="detailsContrat.brancheid.branche=='Vie'">
                                <p> <b>Population : </b> </p>
                                <li> 
                                    Assuré :  
                                    <span class="to-right assuraf-orange">
                                        {{detailsContrat.details[0].contractor.Prenom}} - {{detailsContrat.details[0].contractor.Nom}}
                                    </span>
                                </li>

                                <li> 
                                    Souscripteur :  
                                    <span class="to-right assuraf-orange">
                                        {{detailsContrat.details[0].customer.Prenom}} - {{detailsContrat.details[0].customer.Nom}}
                                    </span>
                                </li>

                                <li> 
                                    Bénéficiaire :  
                                    <span class="to-right assuraf-orange">
                                        {{detailsContrat.details[0].beneficiary.Prenom}} - {{detailsContrat.details[0].beneficiary.Nom}}
                                    </span>
                                </li>
                                <li style="font-weight: bolder;"> 
                                    Total  :  <span class="to-right assuraf-orange">{{countViePopulation(detailsContrat.details[0])}}</span>
                                </li>
                            </ul>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div>
                                <ul style="list-style:none">
                                    <li>Prime nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                                    <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>

            <el-row v-if="$store.state.user.email=='admin@assuraf.com' || $store.state.user.email=='mowencleopas@assuraf.com'">
                <el-col :span="24">
                    <div class="mb-5">
                        <el-button type="primary" round @click="RemoveContrat(detailsContrat)" v-if="$store.state.user.role=='Admin'">Supprimer ( Attention: Suppression définitive )</el-button>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="90%" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div>
                                <ul style="list-style:none">
                                    <li>Date création : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                                    <li>Numéro devis : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                                    <li>Date effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                                    <li v-if="detailcontrat.partner_creator != null">Partenaire : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                                </ul>
                            </div>
                              <!-- VIsualisation Population Contrat Santé -->
                            <ul style="list-style:none" v-if="detailsContrat.brancheid.branche=='Santé'">
                                <p> <b>Population : </b> </p>
                                <li> Adulte Homme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Adulte.Homme}}</span></li>
                                <li> Adulte Femme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Adulte.Femme}}</span></li>
                                <li> Enfant Garçon :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Enfant.Garcon}}</span></li>
                                <li> Enfant Fille :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Enfant.Fille}}</span></li>
                                <li> Senior Homme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Senior.Homme}}</span></li>
                                <li> Senior Femme :  <span class="to-right assuraf-orange">{{detailsContrat.devisid.details_pack_sante.assures.Senior.Femme}}</span></li>
                                <li style="font-weight: bolder;"> 
                                    Total  :  <span class="to-right assuraf-orange">
                                        {{detailsContrat.devisid.details_pack_sante.assures.nombre_assures}}
                                    </span>
                                </li>
                            </ul>
                            <!-- VIsualisation Population Contrat Vie -->
                            <ul style="list-style:none" v-if="detailsContrat.brancheid.branche=='Vie'">
                                <p> <b>Population : </b> </p>
                                <li> 
                                    Assuré :  
                                    <span class="to-right assuraf-orange">
                                        {{detailsContrat.details[0].contractor.Prenom}} - {{detailsContrat.details[0].contractor.Nom}}
                                    </span>
                                </li>

                                <li> 
                                    Souscripteur :  
                                    <span class="to-right assuraf-orange">
                                        {{detailsContrat.details[0].customer.Prenom}} - {{detailsContrat.details[0].customer.Nom}}
                                    </span>
                                </li>

                                <li> 
                                    Bénéficiaire :  
                                    <span class="to-right assuraf-orange">
                                        {{detailsContrat.details[0].beneficiary.Prenom}} - {{detailsContrat.details[0].beneficiary.Nom}}
                                    </span>
                                </li>
                                <li style="font-weight: bolder;"> 
                                    Total  :  <span class="to-right assuraf-orange">{{countViePopulation(detailsContrat.details[0])}}</span>
                                </li>
                            </ul>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div>
                                <ul style="list-style:none">
                                    <li>Prime nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                                    <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>

            <el-row v-if="$store.state.user.email=='admin@assuraf.com' || $store.state.user.email=='mowencleopas@assuraf.com'">
                <el-col :span="24">
                    <div class="mb-5">
                        <el-button type="primary" round @click="RemoveContrat(detailsContrat)" v-if="$store.state.user.role=='Admin'">Supprimer ( Attention: Suppression définitive )</el-button>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser==true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div>
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser==true && isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div>
                                <ul style="list-style:none">
                                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                                </ul>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div style="text-align: center!important;">
                                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

    </div>
</template>

<script>


    import _ from "lodash"
    import moment from "moment-timezone"

    //debut importation assuraf
    import dayjs from 'dayjs'
    import Api from '../../../services/Api'
    import store from '../../../store'
    //fin importation assuraf

    export default {
        name: "StyledTablePage",
        data() {
            return {
                optionsdate: {
                    format: 'YYYY/MM/DD',
                    useCurrent: false,
                },
                du: null,
                au: null,
                modalgeneration: false,
                devisclient: false,
                username: null,
                paramclient: null,
                data: {},
                html: '',
                showUser: false,
                detailsUser: null,
                detailsContrat: [],
                totalRows: null,
                perPage: 10,
                currentPage: 1,
                listuser: null,
                listcontrat: [],
                listcontratperiode: [],
                userid: null,
                userRole: null,
                showcontrat: false,
                filter: null,
                modalInfo: {
                    title: '',
                    content: ''
                },
                fields: [
                    {
                        key: 'dateSouscription',
                        label: 'Date Souscription'
                    },
                    {
                        key: 'brancheid.branche',
                        label: 'Branche'
                    },
                    {
                        key: 'numero_police',
                        label: 'Numero'
                    },
                    {
                        key: 'partenaireid.societe',
                        label: 'Assureur'
                    },
                    {
                        key: 'courtierid',
                        label: 'Courtier'
                    },
                    {
                        key: 'dateEffet',
                        label: 'Effet'
                    },
                    {
                        key: 'dateEcheance',
                        label: 'Echéance'
                    },
                    {
                        key: 'prime_ttc',
                        label: 'Prime TTC'
                    },
                    //{key: 'user.prenom', label: 'Prenom Client'},
                    {
                        key: 'client',
                        label: 'Client'
                    },
                    {
                        key: 'creator',
                        label: 'Createur'
                    },
                    {
                        key: 'action',
                        label: 'Action'
                    },

                ],







                modalgenerationloading : false,

                ldevis: [],
                listdevis: [],
                detailsDevis: [],
                showdevis: false,
                pageOptions: [5, 10, 15],
                fieldsFiltres: [
                    {
                        key: 'dateSouscription',
                        label: 'Date creation'
                    },
                    {
                        key: 'brancheid.branche',
                        label: 'Branche'
                    },
                    {
                        key: 'numeroDevis',
                        label: 'Num devis'
                    },
                    {
                        key: 'partenaireid.societe',
                        label: 'Assureur'
                    },
                    {
                        key: 'courtier.societe',
                        label: 'Courtier'
                    },
                    {
                        key: 'dateEffet',
                        label: 'Effet'
                    },
                    {
                        key: 'dateEcheance',
                        label: 'Echeance'
                    },
                    {
                        key: 'prime_ttc',
                        label: ' Prime TTC'
                    },
                    {
                        key: 'creator',
                        label: 'Createur'
                    },
                    {
                        key: 'action',
                        label: 'Action'
                    },

                ],



                editUser : false,
                Selectedbranche:null,
                selectedAssureur:[],
                configUserDevisAssureur:null,
                assureurs:[],
                branchelist:null,
                modalSociete: false,
                configHead:[
                    { key:"branche", label:"branche"},
                    { key:"assureurs", label:"assureur"},
                    { key:"action", label:"Action"}
                ],
                selectedUser:null,
                listeAssureurs: null,
                usermenu:[],
                collapse:null,
                menu_name:null,
                showConfigMenu:false,
                submenu:null,
                userSubmenu:[],
                UserFinalMenu:[],

                Mycounter: 0,
                dismissSecs: 3,
                UpdateOk: false,
                deleteConfirmation: false,
                modalDelete: false,
                dismissCountDown: 0,
                showuser: false,
                MenuHeader:[
                    {key: "theme",label: "Libéllé"},
                    {key: "collapse",label: "Collapse"},
                    {key: "sousmenu",label: "Sous menu"},
                    {key: "action", label:"action"}
                ],





                voidField: false,
                isMobile: false,
                ready: false,
                width: 0,
                height: "auto",
                loading: false,
                search: "",
                pagination: {
                    page: 1,
                    size: 100,
                    sizes: [10, 15, 20, 30, 50, 100],
                    layout: "total, ->, prev, pager, next, jumper, sizes",
                    small: false
                },
                list: this.listcontrat,//users,
                editMode: false,
                itemsChecked: [],
                dialogUserVisible: false,
                currentId: 0,
                moment
            }
        },
        computed: {
            listFiltered() {
                if(this.search.length >= 3){
                    return this.listcontrat.filter(obj => {
                        let ctrl = false
                        for (let k in obj)
                        {
                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                ctrl = true
                                /*on le push dans le tableau */
                                //this.listcontrat.push(obj)
                            }else{
                                /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                                if(Array.isArray(obj[k])){
                                    obj[k].filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true
                                                /* on le push dans le tableau */
                                                //this.listcontrat.push(obj)
                                            }
                                        }
                                    })
                                    /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                                }else if(!Array.isArray(obj[k]) && typeof obj[k]=="object" && obj[k]!=null){
                                    /*== On crée un nouveau tablequ vide ==*/
                                    let my_new_array = []

                                    /*== On push la valeur courante dans le nouveau tableau ==*/
                                    my_new_array.push(obj[k])

                                    /*On refiltre le nouveau tableau atrribut par attribut*/
                                    my_new_array.filter(nobj=>{
                                        for(let l in nobj){
                                            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                                            if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                                                ctrl = true

                                                /* on le push dans le tableau */
                                                //this.listcontrat.push(obj)
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        return ctrl
                    })
                }else{
                    return this.listcontrat.filter(obj => {
                        let ctrl = false
                        for (let k in obj)
                        {
                            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                                ctrl = true
                        }
                        return ctrl
                    })
                }

            },

            listSortered() {
                let prop = this.sortingProp
                let order = this.sortingOrder
                return [].concat(
                    this.listFiltered.sort((item1, item2) => {
                        let val1 = ""
                        let val2 = ""

                        val1 = item1[prop]
                        val2 = item2[prop]
                        if (order === "descending") {
                            return val2 < val1 ? -1 : 1
                        }
                        return val1 < val2 ? -1 : 1
                    })
                )
            },
            listInPage() {
                let from = (this.currentPage - 1) * this.itemPerPage
                let to = from + this.itemPerPage * 1
                //return this.listSortered.slice(from, to)
                return this.listFiltered.slice(from, to)
            },
            total() {
                return this.listcontrat.length
            },

            currentPage: {
                get() {
                    return this.pagination.page
                },
                set(val) {
                    this.pagination.page = val
                }
            },
            itemPerPage() {
                return this.pagination.size
            },
            selectedItems() {
                return this.itemsChecked.length || 0
            }
        },
        watch: {
            itemPerPage(val) {
                this.ready = false
                this.currentPage = 1

                setTimeout(() => {
                    this.ready = true
                }, 500)
            },
            search(val) {
                this.currentPage = 1
            }
        },
        methods: {
            countViePopulation(data){
                if(data.contractor.Nom==data.customer.Nom && data.contractor.Prenom==data.customer.Prenom){
                    return 2
                }else if(data.contractor.Nom==data.customer.Nom &&  data.contractor.Prenom==data.customer.Prenom && data.contractor.Nom==data.beneficiary.Nom && data.contractor.Prenom==data.beneficiary.Prenom){
                    return 1
                }else{
                    return 3
                }
            },
            SetDetailUser(details) {
                this.detailsUser = details
                this.showUser = true
            },
            exportToExcel(){
                Api().get('/export/contrat')
                    .then(resultat => {
                        this.modalgenerationloading = true;
                        let url = resultat.data.lien
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                                window.open(url)), 7000
                        );
                    });
            },
            detailcontrat(item) {
                this.detailsContrat = item

                console.log('Details du contrat', item)
                this.showcontrat = true
            },

            getContrat() {

                if (this.$store.state.user.role == 'agent général') {
                    Api().get('/contrat/assureur/' + this.$store.state.user._id)
                        .then(resultat => {
                            this.listcontrat = resultat.data.contrats
                            this.totalRows = resultat.data.contrats.length
                        });

                } else if (this.$store.state.user.role == 'Agent') {
                    Api().get('/contrat/courtier/' + this.$store.state.user._id)
                        .then(resultat => {
                            this.listcontrat = resultat.data.contrats
                            this.totalRows = resultat.data.contrats.length
                        });

                } else if (this.$store.state.user.role == 'assureur') {
                    Api().get('/contrat/assureur/' + this.$store.state.user._id)
                        .then(resultat => {
                            this.listcontrat = resultat.data.contrats
                            this.totalRows = resultat.data.contrats.length
                        });

                } else if (this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin') {
                    Api().get('/contrat')
                        .then(resultat => {
                            this.listcontrat = resultat.data.contrats
                            this.totalRows = resultat.data.contrats.length


                        });
                }

            },

            FiltreDate() {
                if (this.du && this.au) {
                    switch (store.state.user.role) {
                        case 'Admin':
                            Api().post('contrat/filtreContrat/periode', {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listcontrat = resultat.data.contrats
                                });
                            break;
                        
                        case 'SuperAdmin':
                            Api().post('contrat/filtreContrat/periode', {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listcontrat = resultat.data.contrats
                                });
                            break;

                        case 'Agent':
                            Api().post('contrat/filtreContrat/courtier/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listcontrat = resultat.data.contrats
                                });
                            break;

                        case 'assureur':
                            Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.societe, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listcontrat = resultat.data.contrats
                                });
                            break;
                        case 'agent général':
                            Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.assureur, {
                                du: this.du,
                                au: this.au
                            })
                                .then(resultat => {
                                    this.listcontrat = resultat.data.contrats
                                });
                            break;

                        default:
                            break;
                    }
                }
            },

            RemoveContrat(item)
            {
                Api().post('/contrat/remove/', {id:item._id})
                    .then((result) => {
                        if (result.data.rep.n == 1)
                        {
                            this.showcontrat = false
                            this.getContrat()
                        }
                    }).catch((err) => {
                    console.log('Oups !')
                });

            },


            isMobile: function () {
                if (screen.width <= 760) {
                    return true;
                } else {
                    return false;
                }
            },

            isTablette() {
                if (screen.width > 425 && screen.width <= 768) {
                    return true;
                } else {
                    return false;
                }
            },

            isDesktop: function () {
                if (screen.width > 768) {
                    return true;
                } else {
                    return false;
                }
            },

            detaildevis(item) {

                this.detailsDevis = item
                this.showdevis = true
            },

            // Fonction de download du devis en fichier pdf



            Remover() {
                Api().post('sinistre/remove')
                    .then(rep => {})
            },




            AddEtatUser() {
                Api().post('devis/addetatuser')
                    .then(resultat => {})
                    .catch(err => {})
            },
















            toggleDetails(item){
                this.detailsUser = item
                this.editUser = true
            },

            setCollapse(){
                this.collapse = "id_"+Date.now()+"_collapse"
            },

            MenuDelete(item)
            {
                Api().delete("/menu/"+item._id)
                    .then((result) => {
                        this.getUserMenu(this.detailsUser._id)
                    }).catch((err) => {

                });
            },

            pushMenu()
            {
                if(this.menu_name && this.userSubmenu.length >= 1)
                {
                    this.UserFinalMenu.push({
                        user : this.detailsUser.id,
                        theme:this.menu_name,
                        collapse:this.collapse,
                        config:this.userSubmenu
                    })
                    this.menu_name   = null
                    this.userSubmenu = []
                    this.collapse    = null
                }else{
                    this.$toasted.show("Remplissez les champs vides !", this.ToastTabError);
                }
            },

            create_user_menu()
            {
                if(this.UserFinalMenu.length >=1 )
                {
                    Api().post("/menu/add", this.UserFinalMenu)
                        .then((result) => {
                            this.$toasted.show("Opération effectuée !", this.ToastTabSucess);
                            this.UserFinalMenu = []
                        }).catch((err) => {
                        console.log(err)
                    });
                }else{
                    this.$toasted.show("Le menu ne doit pas être vide !", this.ToastTabError);
                }
            },

            merge(){
                Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
                    .then((result) => {
                        this.showuser = false
                        if(result.data.result){
                            alert('Opération réussie')
                        }
                    }).catch((err) => {
                    console.log('=======err=========')
                });
            },

            detailuser(item) {
                this.detailsUser = item
                this.showuser = true
                this.getFlette((item._id))
            },

            ready: function () {
                var self = this;

                setInterval(function () {
                    self.$data.ticker = Date.now();
                }, 1000);
            },

            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },

            showAlert() {
                this.dismissCountDown = this.dismissSecs
            },

            getUserList:function(){
                switch (this.$store.state.user.role) {
                    case 'Admin':
                        Api().get('/user/frontoffice/')
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;


                    case 'assureur':
                        Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
                            .then( resultat => {
                                this.listuser = resultat.data
                                this.totalRows = resultat.data.length
                            });
                        break;

                    default:
                        break;
                }
            },

            UpdateUser(item) {

                let idU = item.id
                Api().post('user/' + idU, item)
                    .then(response => {
                        if (response.data) {
                            this.showAlert()
                        }
                        this.showAlert()
                    })
                    .catch(err => {

                    })

            },

            DateFormater: function (TheDate) {
                if (TheDate) {
                    return dayjs(TheDate).format('DD-MM-YYYY')
                }
            },

            userDeleteConfirmation(item) {

                this.modalDelete = true
                this.detailsUser = item

            },
            modalDeleteCarShow(item){
                this.carToDelete    = item
                this.modalDeleteCar = true
            },

            confirmuserCarDeletion()
            {
                Api().post('/flette/delete', {id:this.carToDelete._id})
                    .then((result) => {
                        this.modalDeleteCar = false
                        this.getFlette( this.detailsUser._id)
                    }).catch((err) => {
                    console.log("ERROR")
                });

            },

            DeletingUser(id) {
                Api().delete('user/delete/' + id)
                    .then(resultat => {
                        if (resultat.status == 200) {
                            this.modalDelete = false
                            this.getUserList()
                        }
                    })
                    .catch(err => {})
            },

            getUserMenu(id){
                Api().post('/menu/user/menu', {userid:id})
                    .then((result) => {
                        this.usermenu = result.data.menus
                    }).catch((err) => {
                    console.log(err)
                });
            },








            calcDims() {
                const tableWrapper = document.getElementById("table-wrapper")
                if (tableWrapper) this.width = tableWrapper.clientWidth

                if (!this.isMobile && tableWrapper) {
                    this.height = tableWrapper.clientHeight - 44
                }

                if (this.width < 480) {
                    this.pagination.small = true
                    this.pagination.layout = "prev, pager, next"
                } else if (this.width >= 480 && this.width < 700) {
                    this.pagination.small = false
                    this.pagination.layout = "prev, pager, next, ->, sizes"
                } else {
                    this.pagination.small = false
                    this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
                }

                this.ready = true
            },
            handleResize: _.throttle(function(e) {
                this.ready = false
                this.width = 0
                setTimeout(this.calcDims, 1000)
            }, 500),
            handleSelectionChange(val) {
                this.itemsChecked = val
            },
            init() {
                if (window.innerWidth <= 768) this.isMobile = true
            }
        },

        filters: {
            selected: function(value, sel) {
                if (!value) return ""
                if (!sel) return value

                value = value.toString()
                sel = sel.toString()

                const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
                if (startIndex !== -1) {
                    const endLength = sel.length
                    const matchingString = value.substr(startIndex, endLength)
                    return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
                }

                return value
            }
        },
        created() {
            this.init()
            Api().get('/partenaire/courtier')
                .then(resultat => {
                    this.listpartenaire = resultat.data.partenaires
                    this.totalRows = resultat.data.partenaires.length
                });

        },
        mounted() {
            if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
                Api().get('/user/clients')
                    .then(resultat => {
                        this.listuser = resultat.data

                    });
                    this.getContrat()

            } else {
                this.$router.go(this.$router.push('/login'))
            }

            //ie fix
            if (!window.Number.parseInt) window.Number.parseInt = parseInt

            this.calcDims()
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/_variables";

    .page-table {
        &.overflow {
            overflow: auto;
        }

        .toolbar-box {
            &.hidden {
                visibility: hidden;
            }
        }

        .table-box {
            overflow: hidden;

            &.hidden {
                visibility: hidden;
            }
        }
    }
</style>

<style lang="scss">
    @import "../../../assets/scss/_variables";

    .page-table {
        .toolbar-box {
            margin-bottom: 10px;

            .card-shadow--medium {
                &.el-input,
                &.el-select {
                    border-radius: 4px;

                    input {
                        border-color: transparent;
                        background-color: lighten($background-color, 2%);
                        color: $text-color;

                        &:hover {
                            border-color: #c0c4cc;
                        }
                        &:focus {
                            border-color: $text-color-accent;
                        }
                    }
                }

                &.el-button {
                    border-color: transparent;
                }
            }
        }

        .clickable {
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
        }

        .sel-string {
            .sel {
                background: transparentize($text-color, 0.8);
                border-radius: 5px;
                //text-transform: uppercase;
            }
        }
    }

    @media (max-width: 768px) {
        .page-table {
            .toolbar-box {
                display: block;
                overflow: hidden;
                font-size: 80%;
                padding-bottom: 10px;

                & > * {
                    display: inline-block;
                    min-width: 120px;
                    height: 22px;
                    //background: rgba(0, 0, 0, 0.04);
                    margin-bottom: 16px;
                }
            }
        }
    }
    .button-filtre{
        padding: 12px!important;
        margin : 0 15px!important;
        background: #C0C7CF!important;
        border:none!important;
        color:#fff!important;
    }
</style>
