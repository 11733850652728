export default {
  SET_AUTH: 'SET_AUTH',
  GET_AUTH: 'GET_AUTH',
  SET_SITE: 'SET_SITE',
  GO_LOGIN: 'GO_LOGIN',

  SET_PAGE_HEADER: 'SET_PAGE_HEADER',
  FETCH_PAGE_HEADER: 'FETCH_PAGE_HEADER',
  SET_LOCALE: 'SET_LOCALE',
  FETCH_LOCALE: 'FETCH_LOCALE',
  SET_THEME: 'SET_THEME',
  
  FETCH_SITE: 'FETCH_SITE',
  DOWNLOAD: 'DOWNLOAD',

  SHOW_FILE_BROWSER: 'SHOW_FILE_BROWSER',
  FETCH_ONLINE_FILES: 'FETCH_ONLINE_FILES',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',

}