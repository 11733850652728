<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Ajout de <span style="color:#ffa409">cachet</span> <span style="color:#ffa409">partenaire</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <el-row :gutter="15">
        <el-col :span="8">
          <div class="mt-5 grid-content bg-purple">
            <el-card class="box-card">

              <div slot="header" class="clearfix">
                <span>Cachet en image (.png, .jpg, .jpeg)</span>
              </div>
              <div>
                <el-input v-model="file" type="file" ref="file" placeholder="charger image"></el-input>
                <!-- </el-input> -->
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="mt-5 grid-content bg-purple">
            <el-card class="box-card">
              <div style="margin-top:15px">
                <el-radio-group v-model="partnereid">
                  <el-radio  v-for="(assur,index) in listeAssureurs" :key="index" :label="assur.id">
                      <img width="50" height="40" :src="assur.logo">
                      <h3>{{assur.societe.substr(0, 5)}}</h3>
                  </el-radio>
                </el-radio-group>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="AddStamp()">Ajouter</el-button>
        </div>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="mt-5 grid-content bg-purple">
            <el-card class="box-card">

              <div slot="header" class="clearfix">
                <span>Cachet en image (.png, .jpg, .jpeg)</span>
              </div>
              <div>
                <el-input v-model="file" id="file" type="file" ref="file" placeholder="charger image"></el-input>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px;">
          <div class="mt-5 grid-content bg-purple">
            <el-card class="box-card">
              <div style="margin-top:15px">
                <el-radio-group v-model="partnereid">
                  <el-radio  v-for="(assur,index) in listeAssureurs" :key="index" :label="assur.id">
                      <img width="50" height="40" :src="assur.logo">
                      <h3>{{assur.societe.substr(0, 5)}}</h3>
                  </el-radio>
                </el-radio-group>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="AddStamp()">Ajouter</el-button>
        </div>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" style="margin-top: 15px">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date d'ajout" min-width="150" prop="created" :fixed="!isMobile">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>
       <el-table-column label="Societe" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="200" v-if="!isMobile">
          <template slot-scope="scope">
            <el-button @click="SetStampDetail(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button @click="DeleteStamp(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="200" v-if="isMobile">
          <template slot-scope="scope">
            <el-button @click="SetStampDetail(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button @click="DeleteStamp(scope.row)" type="danger" icon="el-icon-delete" circle title="Supprimer"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog width="60%!important" title="Détails tempon" :visible.sync="showStamp" v-if="showStamp==true">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails tempon</span>
              </div>
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(stampDetail.created)}}</span></li>
                  <li>Societé : <span class="to-right assuraf-orange">{{stampDetail.partenaireid.societe}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Tempon Partenaire</span>
              </div>
              <div style="text-align: center!important;">
                <img class="justify-center" :src="stampDetail.filepath" width="200" alt="tempon assureur">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Modification infos" :visible.sync="editUser" v-if="editUser==true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="8">
              <div>
                <label>Nom</label>
                <el-input v-model="detailsUser.nom" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Prénom</label>
                <el-input v-model="detailsUser.prenom" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Téléphone portable</label>
                <el-input v-model="detailsUser.telephone_port" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Téléphone bureau</label>
                <el-input v-model="detailsUser.telephone_bur" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Adresse</label>
                <el-input v-model="detailsUser.adresse" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Profession</label>
                <el-input v-model="detailsUser.profession" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Date de naissance</label>
                <el-input v-model="detailsUser.datenaissance" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Sexe</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.sexe" placeholder="Selectionner">
                  <el-option label="homme">Homme</el-option>
                  <el-option label="femme">Femme</el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Statut Marital</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.statut_marital" placeholder="Selectionner">
                  <el-option label="célibataire">célibataire</el-option>
                  <el-option label="marié">marié(e)</el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="8">
              <div>
                <label>Adresse mail</label>
                <el-input v-model="detailsUser.email" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Type piéce identité</label>
                <el-input v-model="detailsUser.piece_identite" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Numéro piéce</label>
                <el-input v-model="detailsUser.numero_piece" placeholder="Rechercher..." class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>

          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="24">
              <div>
                <label>Rôle</label>
                <el-select style="width: 100%!important;" v-model="detailsUser.role" placeholder="Selectionner">
                  <el-option label="Admin">Administrateur</el-option>
                  <el-option label="assureur">Assureur</el-option>
                  <el-option label="Agent">Agent</el-option>
                  <el-option label="agent général">Agent général</el-option>
                  <el-option label="assuraf">Assuraf</el-option>
                  <el-option label="User">Utilisateur simple</el-option>
                  <el-option label="ATPS User">Utilisateur ATPS</el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>

        </el-card>

        <!--        <div class="mb-5" style="margin-top: 15px">
                  <el-button type="primary" round @click="UpdateUser()">Modifier</el-button>
                  <el-button type="primary" round @click="showuser=false">Annuler</el-button>
                </div>-->
      </div>
    </el-dialog>

    <el-dialog title="Suppression de cette partenaire" width="max-content" :visible.sync="modalDelete" v-if="modalDelete==true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Vous confirmez la suppression de cette partenaire ?</span>
          </div>
          <div  >
            <ul style="list-style:none">
              <li> <h3>Societé : <span class="to-right assuraf-orange">{{detailsAssu.societe}}</span></h3></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="DeletingPartenaire(detailsAssu._id)">Oui</el-button>
        <el-button type="danger" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      file : null,
      isMobile: false,
      listeAssureurs: [],
      listecachet: [],
      partnereid: [],
      stampDetail: [],
      showStamp: false,
      Arrayfields: [
        {
          key: 'created',
          label: 'Date ajout',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'partenaireid.societe',
          label: 'Partenaire'
        },
        /* {
             key: 'filepath',
             label: 'Cachet'
         },*/

        {
          key: 'action',
          label: 'Action'
        },
      ],





      modalgeneration:false,
      partenaireId: null,
      testsoc: null,
      file: null,
      ErrorDelete: false,
      idP: null,
      listpartenaire: null,

      showpartenaire: false,
      detailsAssu: [],
      fields: [
        {
          key: 'societe',
          label: 'societe',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'adresse',
          label: 'Adresse'
        },
        {
          key: 'telephone_port',
          label: 'Telephone'
        },
        {
          key: 'ninea',
          label: 'Ninea'
        },
        {
          key: 'rccm',
          label: 'rccm'
        },
        {
          key: 'type_partenaire',
          label: 'Type partenaire'
        },
        {
          key: 'action',
          label: 'Action'
        },
      ],
      modalDeleteCar:false,
      userflette:[],
      fletteRows:null,
      carFields:[
        {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
        {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
        {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
        {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
        {key:'added_date',label:"Date d'ajout"},
        {key:'action',label:'Action'},
      ],
      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listecachet,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listecachet.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },

    listFilteredFlotte() {
      return this.userflette.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSorteredFlotte() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFilteredFlotte.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPageFlotte() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listSorteredFlotte.slice(from, to)
      return this.listFilteredFlotte.slice(from, to)
    },
    totalFlotte() {
      return this.listFilteredFlotte.length
    },

    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
     init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
     AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    getListePartenaire: function () {
      if (this.$store.state.user.role == 'Agent' || this.$store.state.user.role == 'Agent') {
        Api().get('/partenaire/courtier/' + this.$store.state.user.societe)
            .then(response => {
              this.listeAssureurs = response.data.partenaire[0].assureurs_partenaires
            }).catch(err => {})
      } else if (this.$store.state.user.role == 'agent général') {
        Api().get('/partenaire/' + this.$store.state.user.societe)
            .then(response => {
              this.listeAssureurs = response.data.partenaire
            })
      } else if (this.$store.state.user.role == 'Admin') {
        Api().get('/partenaire/assureur')
            .then(response => {
              this.listeAssureurs = response.data.partenaires //.assureurs_partenaires
            })
      } else if (this.$store.state.user.role == 'assureur') {
        Api().get('/partenaire/' + this.$store.state.user.societe)
            .then(response => {
              this.listeAssureurs = response.data.partenaire
            })
      }

    },

    DeleteStamp(item) {
      Api().delete('stamp/delete/' + item._id)
          .then((result) => {
            if (result.data.response.deletedCount > 0) {
              this.getListeCachet()
              this.$message.success('Opération réussiee !', this.AlertMessage)
            }
          }).catch((err) => {
        this.getListeCachet
        this.$message.eror('Opération échouée !', this.AlertMessage)
      });
    },

    SetStampDetail(item) {
      this.stampDetail = item
      this.showStamp = true
    },

    getListeCachet() {
      Api().get('stamp/listing/all')
          .then((result) => {
            this.listecachet = result.data.stamps
          }).catch((err) => {
        this.$toasted.show('Erreur de connexion ', this.ToastTabError)
      });
    },
    AddStamp() {

      if (this.partnereid) {
        alert('parteanire id : '+this.partnereid)
        this.file = this.$refs.file.files;
        console.log(this.partnereid)
        let formData = new FormData();
        formData.append("stamp", this.file);
        formData.append("partnereid", this.partnereid);

        Api().post('stamp/add', formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
            .then(response => {
              if (response.data) {
                this.getListeCachet()
                this.file = null
                this.$refs.file.files[0] = "";
                this.partnereid = null
              }
            }).catch(err => {})
      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },

    getListPartner() {
      Api().get('/partenaire')
          .then(resultat => {
            this.listpartenaire = resultat.data.partenaires
            this.totalRows = resultat.data.partenaires.length
          });
    },

    get_list_assureur(){
      Api().get('/partenaire/assureur')
          .then(resultat => {
            this.listeAssureurs = resultat.data.partenaires
          });
    },

  
    partenaireDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsAssu = item

    },
   
    DeletingPartenaire(id) {
      Api().delete('partenaire/delete/' + id)
          .then(resultat => {
            if (resultat.data.rep.n == 1) {
              this.modalDelete = false
              this.getListPartner()
            }
          })
          .catch(err => {})
    },




    toCreatePartenaire (){
      this.$router.go(this.$router.push('/partenaires/createpartenaire'))
    },
    partenaireUpdate(item) {

      Api().put('partenaire/', item)
          .then(resultat => {
            this.$store.dispatch("setPartenaire", resultat.data)

          });
    },
    fetch() {
      this.$http.get("home").then(({
                                     data
                                   }) => {
        this.data = data
      });
    },

    detailAssu(item) {
      this.detailsAssu = item
      this.showpartenaire = true
    },




    getFlette(userid)
    {
      this.userflette = []
      Api().post('/flette/listing', {userid:userid})
          .then( resultat => {
            this.userflette = resultat.data.cars
            this.fletteRows = resultat.data.cars.length
          });
    },

    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },







    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", {id:item._id})
          .then((result) => {
            Api().post("/branchecourtier/get/all/", {userid:item.userid})
                .then((response) => {
                  this.configUserDevisAssureur = response.data.partenaires
                })
                .catch((err) => {});
          }).catch((err) => {

      });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
          .then((result) => {
            this.configUserDevisAssureur = []
          }).catch((err) => {
        console.log(err)
      });
    },

    pushConfig() {
      if(this.Selectedbranche && this.selectedAssureur)
      {
        this.configUserDevisAssureur.push({
          branche:this.Selectedbranche,
          assureurs:this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche  = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", {userid:item.id})
          .then((response) => {
            this.configUserDevisAssureur = response.data.partenaires
          })
          .catch((err) => {});
    },

    setCollapse(){
      this.collapse = "id_"+Date.now()+"_collapse"
    },

    MenuDelete(item)
    {
      Api().delete("/menu/"+item._id)
          .then((result) => {
            this.getUserMenu(this.detailsUser._id)
          }).catch((err) => {

      });
    },

    pushMenu()
    {
      if(this.menu_name && this.userSubmenu.length >= 1)
      {
        this.UserFinalMenu.push({
          user : this.detailsUser.id,
          theme:this.menu_name,
          collapse:this.collapse,
          config:this.userSubmenu
        })
        this.menu_name   = null
        this.userSubmenu = []
        this.collapse    = null
      }else{
        this.$toasted.show("Remplissez les champs vides !", this.ToastTabError);
      }
    },

    create_user_menu()
    {
      if(this.UserFinalMenu.length >=1 )
      {
        Api().post("/menu/add", this.UserFinalMenu)
            .then((result) => {
              this.$toasted.show("Opération effectuée !", this.ToastTabSucess);
              console.log(result.data)
              this.UserFinalMenu = []
            }).catch((err) => {
          console.log(err)
        });
      }else{
        this.$toasted.show("Le menu ne doit pas être vide !", this.ToastTabError);
      }
    },

    merge(){
      Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
          .then((result) => {
            this.showuser = false
            if(result.data.result){
              alert('Opération réussie')
            }
          }).catch((err) => {
        console.log('=======err=========')
      });
    },

    detailuser(item) {
      this.detailsUser = item
      this.showuser = true
      this.getFlette((item._id))
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList:function(){
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
          .then(response => {
            if (response.data) {
              this.showAlert()
            }
            this.showAlert()
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },
    modalDeleteCarShow(item){
      this.carToDelete    = item
      this.modalDeleteCar = true
    },

    confirmuserCarDeletion()
    {
      Api().post('/flette/delete', {id:this.carToDelete._id})
          .then((result) => {
            this.modalDeleteCar = false
            this.getFlette( this.detailsUser._id)
          }).catch((err) => {
        console.log("ERROR")
      });

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
          .then(resultat => {
            if (resultat.status == 200) {
              this.modalDelete = false
              this.getUserList()
            }
          })
          .catch(err => {})
    },

    getUserMenu(id){
      Api().post('/menu/user/menu', {userid:id})
          .then((result) => {
            this.usermenu = result.data.menus
            console.log(result.data.menus)
          }).catch((err) => {
        console.log(err)
      });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
   
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    this.getListeCachet()
    this.getListePartenaire()

  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getListeCachet()
      this.getListePartenaire()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
