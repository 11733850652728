<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
        <div class="page-header">
            <h1>Cumule de <span style="color:#ffa409"> contrat</span>
                <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>

        <div class="card-form card-base pl-10">
            <div class="ma-10">
                <p>
                    Nous avons constaté que vous disposez déjà du même type de contrat
                    pour la période de souscription souhaitée, il n'est pas possible de souscrire
                    avant la date d'échéance du contrat encours
                </p>
            </div>
            <div class="mb-10">
                <el-button type="primary" round @click="goto()">Retour à la page de paiement</el-button>
            </div>
        </div>
    </div>
</template>
<script>

export default {

    data() {
        return {
            notif: null,
            Contrat_provisoire: null,
            user_info: null,
            ToastTabSucess: {  // Customized options for success toast
                theme: "toasted-primary",
                position: "top-center",
                duration: 2000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            },
            ToastTabError: {  // Customized options for error toast
                theme: "toasted-primary",
                position: "top-center",
                duration: 2000,
                fitToScreen: true,
                type: 'error'
            },
        }
    },

    methods: {
        goto(){
            this.$router.push('/contrat/paiement/cash')
        }

    },
    mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            let used = true
        } else {
            this.$router.go(this.$router.push('/login'))
        }
    }


}
</script>
<style>

</style>