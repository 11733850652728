<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Modification du devis <span style="color:#ffa409">Voyage</span>
      </h1>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20">
      <h3>Validation</h3>
        <el-table :data="ForUpdate[0].listPassager" style="width: 100%" v-if="ForUpdate[0]">
            <el-table-column
              fixed
              prop="adulte_enfant"
              label="Adulte / Enfant"
              width="max-content">
            </el-table-column>
            <el-table-column
              prop="age_passager"
              label="Âge du passager"
              width="max-content">
            </el-table-column>
        </el-table>

         <div>
            <h3>Liste des assureurs</h3>
            <el-row :gutter="20" type="flex" justify="center">
                <el-col :span="24">
                    <div class="grid-content bg-purple-dark">
                        <el-checkbox-group v-model="assureurs">
                            <el-checkbox v-for="(assur,index) in listeAssureurs" :key="index" :label="assur.societe">
                                <template>
                                    <img width="50" height="40" :src="assur.logo">
                                </template>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-col>
            </el-row>
        </div>


        <div>
            <h3>Période de couverture</h3>
             <el-row :gutter="20" v-if="!isMobile">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Date d'effet</label>
                        <el-input disabled placeholder="Date d'effet" v-model="ForUpdate[0].dateEffet">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Date d'échéance</label>
                        <el-input disabled placeholder="Date d'échéance" v-model="ForUpdate[0].dateEcheance">
                        </el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" v-if="isMobile">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Date d'effet</label>
                        <el-input disabled placeholder="Date d'effet" v-model="ForUpdate[0].dateEffet">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Date d'échéance</label>
                        <el-input disabled placeholder="Date d'échéance" v-model="ForUpdate[0].dateEcheance">
                        </el-input>
                    </div>
                </el-col>
            </el-row>
        </div>
        
       
          <div>
              <h3>Remboursement</h3>
              <div class="grid-content bg-purple">
                <el-radio v-model="remboursable" label="Non_remboursable">Non Remboursable</el-radio>
                <el-radio v-model="remboursable " label="remboursable">Remboursable</el-radio>
              </div>
          </div>

        <div style="margin-top:15px">
          <el-button style="float:left" @click="createdevisvoyage()" type="primary" round>Valider</el-button>
        </div>
        
    </div>

    <el-dialog width="70%!important" persistent title="Formulaire de modification" :visible.sync="showVoyage" v-if="showVoyage==true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="12">
              <div>
                <label>Pays d'origine</label>
                 <el-input disabled type="text" v-model="ForUpdate[0].pays_domicile" placeholder="Pays d'origine" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>Pays de destination</label>
                <el-select style="width: 100%!important;" v-model="ForUpdate[0].destination" filterable placeholder="Selectionner">
                  <el-option v-for="(items, idx) in listpays" :key="idx" :label="items.PAYS" :value="items.PAYS">{{items.PAYS}}</el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>

           <el-row :gutter="15" style="margin-top: 15px">
            <el-col :span="8">
              <div>
                <label>Date de départ</label>
               <el-date-picker
                            style="width: 100%!important;"
                            @change="DurationCalculation()"
                            v-model="ForUpdate[0].dateDepart"
                            type="date"
                            placeholder="Date d'effet"
                            :picker-options="dateAfterToday">
                        </el-date-picker>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Date d'arrivée</label>
                <el-date-picker
                            style="width: 100%!important;"
                            @change="DurationCalculation()"
                            v-model="ForUpdate[0].dateArrivee"
                            type="date"
                            placeholder="Date d'effet"
                            :picker-options="dateAfterToday">
                        </el-date-picker>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <label>Durée du voyage (en jours)</label>
                <el-input disabled type="number" v-model="ForUpdate[0].dureeVoyage" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>
         
        </el-card>
        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="fieldsValidation()">Modifier</el-button>
        </div>
      </div>

    </el-dialog>

    <el-dialog width="70%!important" persistent title="Formulaire de modification" :visible.sync="showVoyage" v-if="showVoyage==true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row :gutter="15">
            <el-col :span="24">
              <div>
                <label>Pays d'origine</label>
                 <el-input disabled type="text" v-model="ForUpdate[0].pays_domicile" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Pays de destination</label>
                <el-select style="width: 100%!important;" v-model="ForUpdate[0].destination" filterable placeholder="Selectionner">
                  <el-option v-for="(items, idx) in listpays" :key="idx" :label="items.PAYS" :value="items.PAYS">{{items.PAYS}}</el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Date de départ</label>
               <el-date-picker
                            style="width: 100%!important;"
                            @change="DurationCalculation()"
                            v-model="ForUpdate[0].dateDepart"
                            type="date"
                            placeholder="Date d'effet"
                            :picker-options="dateAfterToday">
                        </el-date-picker>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Date d'arrivée</label>
                <el-date-picker
                            style="width: 100%!important;"
                            @change="DurationCalculation()"
                            v-model="ForUpdate[0].dateArrivee"
                            type="date"
                            placeholder="Date d'effet"
                            :picker-options="dateAfterToday">
                        </el-date-picker>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div>
                <label>Durée du voyage (en jours)</label>
                <el-input disabled type="number" v-model="ForUpdate[0].dureeVoyage" placeholder="Nombre de place" class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>         
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="fieldsValidation()">Modifier</el-button>
        </div>
      </div>

    </el-dialog>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import pays from '../../../common/countries'
import _ from 'underscore'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
      isMobile: false,
        dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
        dateAfterToday: {
          disabledDate(time) {
            return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );                
          }
        },
      
        showVoyage : true,
        ForUpdate :[],
        listvoyage:[],
        assureurs:[],
         ToastTabSucess: {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 10000,
          fitToScreen: true,
          type: 'success',
          className: 'custom-success-class'
        }, // Customized options for success toast
        ToastTabError: {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 10000,
          fitToScreen: true,
          type: 'error'
        },// Customized options for error toast
        listpays : pays,
        listeAssureurs: [],
       enteteTabPassager:[
         {key:'adulte_enfant',label:'Adulte / Enfant'},
         {key:'age_passager',label:'Age passager'},
       ], 
        items: [
          {text: 'homme'},
          {text: 'femme'},
        ],
      voidField: false,
      Deb: null,
      selectedcarbu: '',
      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: [],
      saveDepartDate : null, //this.ForUpdate[0].dateDepart,
      saveArriveDate : null, //this.ForUpdate[0].dateArrivee
      remboursable: null,
      destination:null
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.ForUpdate.push(store.state.DevisReopen ) 
        this.ForUpdate[0].dateDepart    = dayjs(this.ForUpdate[0].dateDepart).format('YYYY-MM-DD')
        this.ForUpdate[0].dateArrivee   = dayjs(this.ForUpdate[0].dateArrivee).format('YYYY-MM-DD')
        this.ForUpdate[0].dateEffet     = dayjs(this.ForUpdate[0].dateEffet).format('YYYY-MM-DD')
        this.ForUpdate[0].dateEcheance  = dayjs(this.ForUpdate[0].dateEcheance).format('YYYY-MM-DD')
        this.saveDepartDate = this.ForUpdate[0].dateDepart,
        this.saveArriveDate = this.ForUpdate[0].dateArrivee
        this.getListePays()
        this.getListePartenaire()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

  },
    watch: {
   
  },

  methods: {
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
     getObjectPays(pays){
        let founder = null
        for(let v of this.listpays){
          if(v.PAYS==pays){
            founder = v
        }
      }
      return founder
    },

     DurationCalculation() 
     {
       
        if(dayjs(this.ForUpdate[0].dateArrivee).diff(this.ForUpdate[0].dateDepart, 'day') >=1 )
        {
              let dateEffet = this.ForUpdate[0].dateDepart
              let dateEcheance = this.ForUpdate[0].dateArrivee
              let date_tmp =  dayjs(dateEcheance).diff(dateEffet, 'day')
              this.ForUpdate[0].dureeVoyage = date_tmp + 1 
        }else{
            this.$message.error('Plage de date incorrecte !', this.AlertMessage)
            this.ForUpdate[0].dateArrivee = null
        }
      },

     /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },

      fieldsValidation() {
        if (this.ForUpdate[0].pays_domicile && this.ForUpdate[0].destination && 
        this.ForUpdate[0].dateDepart && this.ForUpdate[0].dateArrivee && 
        this.ForUpdate[0].dureeVoyage) {
         this.showVoyage = false
        } else {
            this.$message.error('Tout les champs sont obligatoires !', this.AlertMessage)
      } 
    },

     DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },
    
      createdevisvoyage: function () { 
        if(this.assureurs.length>0)
        {
            if(this.remboursable){
              const voyage = {
                user: this.ForUpdate[0].userid,
                assureurs: this.assureurs,
                createur:this.$store.state.user._id,
                nbpassager: this.ForUpdate[0].nbpassager,
                dateDepart: this.ForUpdate[0].dateDepart,
                dateArrivee: this.ForUpdate[0].dateArrivee,
                duree: this.ForUpdate[0].dureeVoyage,
                dateEffet: this.ForUpdate[0].dateEffet,
                dateEcheance: this.ForUpdate[0].dateEcheance,
                destination: this.getObjectPays(this.ForUpdate[0].destination),
                dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                pays_domicile: this.ForUpdate[0].pays_domicile,
                listPassager: this.ForUpdate[0].listPassager,
                branche: 'Voyage',
                mode_remboursement: this.remboursable,
              }
            if (this.$store.state.user.role=='Agent')
            {
              voyage.courtierid = this.$store.state.user.societe
            }
      
          
            Api().post('devis/add', voyage)
              .then(reslutat => {
                this.$store.dispatch("setDevis", reslutat.data)
                this.$router.go(this.$router.push({ name: "liste_devis_paiement" }));
            })
          }else{
            this.$message.error('Mode de remoursement requis !', this.AlertMessage)
          }
        }else{
          this.$message.error('Choisir au moins un assureur !', this.AlertMessage)
        }
         

      },

      getListePays: function () {
          Api().get('/pays')
            .then(response => { this.listpays = response.data  })
            .catch(err => { err   })
        },
  
     getListePartenaire: function () 
     {
        if (this.$store.state.user.role == 'Agent'){
                Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Voyage"})
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires[0].assureurs
                    }).catch(err => {})
          } else if (this.$store.state.user.role == 'agent général'){
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
          } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin'){
                Api().get('/partenaire/partners/voyage')
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires
                    })
          } else if (this.$store.state.user.role == 'assureur'){
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
          }
         
      }
   
  },
   created() {
        this.init()
    },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.rounded{
  border-radius: 1%;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
