import store from '../store.js'
//import store from  '../store/index' //"../store/store"
import crypto from 'crypto-js'
import url from '../libs'
import dayjs from 'dayjs'
/*
* appel de la fonction getupay dans n'impote quel composant
* elle prend deux parametres
* le montant TTC
* et le type de paiement
* ==>
 */
export default function getUpay (montant_ttc, modePaiement){

   /*=======On teste pour savoir si le devis pour paiement est bien dans le store==========*/    
   if(typeof store.state.devispayement !=='undefined' && store.state.devispayement!==null && store.state.user && store.state.user!==null)
   {

    if(montant_ttc && modePaiement)
    {

        "use strict"
        /** UPAY ATPS */
        let upayScript = document.createElement('script')
        upayScript.onload = ()=>{
            AmnPy.loadModal()
        }
        let private_key = "91368d9e84cf102a91a9fec9a0dbe1ce795ff89dfe0cb011545771c797c94637"
        let host = url.urlweb
        let merchantId = "900032"
        let accessKey = "7ed9a997262d104e79e3060ec7fad4d3"
        let order_id = merchantId + '-' + Math.random().toString(16).slice(-7)
        let products_ids =store.state.devispayement.devis.numeroDevis
        let description = "Paiement de l'assurance "+store.state.devispayement.devis.brancheid.branche + " No: " +store.state.devispayement.devis.numeroDevis +". Assureur: "+store.state.devispayement.devis.partenaireid.societe
        let montant = parseInt(montant_ttc) * 100
        let currency = "XOF"
        let client_name = store.state.Client.prenom +' '+store.state.Client.nom
        let client_phone = store.state.Client.telephone_port
        let client_email = store.state.Client.email
        let expiration_date = dayjs(store.state.devispayement.devis.dateEcheance).format('DD/MM/YYYY')
        let redirect_url = url.urlweb+'paiement/souscription/'
        let message = merchantId + ':' + order_id + ':' + montant + ':' + currency
        let hash = crypto.HmacSHA1(message, private_key).toString()
    
        /* on sauvegarde les elements
        * orderid: l'id de de la commande cote upay
        * modepaiement; soit mobile money, carte bancaire etc
        * */
       store.dispatch('setOrderId', order_id)
       store.dispatch('setmodePaiement', modePaiement)
    
        upayScript.type = `text/javascript`
        upayScript.src = "https://app-test.upay.africa:8443/widget.js"
    
        upayScript.setAttribute('id', 'upay-js')
        upayScript.setAttribute('data-host', host)
        upayScript.setAttribute('data-lang', 'fr')
        upayScript.setAttribute('data-mode', 'js')
        upayScript.setAttribute('data-merchant_id', merchantId)
        upayScript.setAttribute('data-access_key', accessKey)
        upayScript.setAttribute('data-order_id', order_id)
        upayScript.setAttribute('data-order_description', description)
        upayScript.setAttribute('data-products_ids', products_ids)
        upayScript.setAttribute('data-amount', montant)
        upayScript.setAttribute('data-currency', currency)
        upayScript.setAttribute('data-client_name', client_name)
        upayScript.setAttribute('data-client_phone', client_phone)
        upayScript.setAttribute('data-client_email', client_email)
        upayScript.setAttribute('data-expiration_date', expiration_date)
        upayScript.setAttribute('data-hmac', hash)
        upayScript.setAttribute('data-redirect_url', redirect_url)
        
        document.head.append(upayScript)

    }


   }
   
}
