<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">liens de paiement</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher lien paiement..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher lien paiement..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" fixed prop="createdDay">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.createdDay) }}
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Numéro devis" min-width="200" prop="devisid.numeroDevis"></el-table-column>
        <el-table-column label="Lien" min-width="600" prop="contractLink"></el-table-column>
        <el-table-column label="Client" min-width="150" prop="client">
          <template slot-scope="scope">
            <span class="sel-string">{{scope.row.userid.prenom }} - {{scope.row.userid.nom }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Status du lien" prop="expired" min-width="150">
          <template slot-scope="scope">
            <span class="sel-string" v-if="scope.row.expired == false">Non utilisé</span>
            <span class="sel-string" v-if="scope.row.expired == true">Déja utilisé</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="80">
          <template slot-scope="scope">
            <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Date création" min-width="150" prop="createdDay">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.createdDay) }}
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
         <el-table-column label="Numéro devis" min-width="200" prop="devisid.numeroDevis"></el-table-column>
        <el-table-column label="Lien" min-width="600" prop="contractLink"></el-table-column>
        <el-table-column label="Client" min-width="150" prop="client">
          <template slot-scope="scope">
            <span class="sel-string">{{scope.row.userid.prenom }} - {{scope.row.userid.nom }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Status du lien" prop="expired" min-width="150">
          <template slot-scope="scope">
            <span class="sel-string" v-if="scope.row.expired == false">Non utilisé</span>
            <span class="sel-string" v-if="scope.row.expired == true">Déja utilisé</span>
          </template>
        </el-table-column>
         <el-table-column label="Actions" prop="action" min-width="80">
          <template slot-scope="scope">
            <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails lien" :visible.sync="showdevis" v-if="showdevis==true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.createdDay)}}</span></li>
                  <li>Client : <span class="to-right assuraf-orange">{{detailsDevis.userid.prenom}} - {{ detailsDevis.userid.nom}}</span></li>
                  <li>Branche : <span class="to-right assuraf-orange">{{detailsDevis.brancheid.branche}}</span></li>
                  <li v-if="detailsDevis.expired == true">Status : <span class="to-right assuraf-orange">Déja utilisé</span></li>
                  <li v-if="detailsDevis.expired == false">Status : <span class="to-right assuraf-orange">Non utilisé</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Prime nette : <span class="to-right assuraf-orange">{{detailsDevis.devisid.prime_nette.toLocaleString()}} Fcfa</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsDevis.devisid.prime_ttc.toLocaleString()}} Fcfa</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{detailsDevis.devisid.taxe.toLocaleString()}} Fcfa</span></li>
                  <li>Accessoires <sapn class="to-right assuraf-orange">{{detailsDevis.devisid.accessoires.toLocaleString()}} Fcfa</sapn></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails lien" :visible.sync="showdevis" v-if="showdevis==true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.createdDay)}}</span></li>
                  <li>Client : <span class="to-right assuraf-orange">{{detailsDevis.userid.prenom}} - {{ detailsDevis.userid.nom}}</span></li>
                  <li>Branche : <span class="to-right assuraf-orange">{{detailsDevis.brancheid.branche}}</span></li>
                  <li v-if="detailsDevis.expired == true">Status : <span class="to-right assuraf-orange">Déja utilisé</span></li>
                  <li v-if="detailsDevis.expired == false">Status : <span class="to-right assuraf-orange">Non utilisé</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Prime nette : <span class="to-right assuraf-orange">{{detailsDevis.devisid.prime_nette.toLocaleString()}} Fcfa</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsDevis.devisid.prime_ttc.toLocaleString()}} Fcfa</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{detailsDevis.devisid.taxe.toLocaleString()}} Fcfa</span></li>
                  <li>Accessoires <sapn class="to-right assuraf-orange">{{detailsDevis.devisid.accessoires.toLocaleString()}} Fcfa</sapn></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      ldevis: [],
      du: null,
      au: null,
      listPaymentLink: [],
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],
      fields: [
        {
          key: 'createdDay',
          label: 'Date création'
        },
        {
          key: 'brancheid.branche',
          label: 'Branche'
        },
        {
          key: 'contractLink',
          label: 'Lien'
        },
        {
          key: 'client',
          label: 'Client'
        },
        {
          key: 'expired',
          label: 'Etat du lien'
        },
        {
          key: 'action',
          label: 'Action'
        },

      ],
      fieldsFiltres: [
        {
          key: 'dateSouscription',
          label: 'Date création'
        },
        {
          key: 'brancheid.branche',
          label: 'Branche'
        },
        {
          key: 'devisid.numeroDevis',
          label: 'Num devis'
        },
        {
          key: 'partenaireid.societe',
          label: 'Assureur'
        },
        {
          key: 'courtier.societe',
          label: 'Courtier'
        },
        {
          key: 'dateEffet',
          label: 'Effet'
        },
        {
          key: 'dateEcheance',
          label: 'Echeance'
        },
        {
          key: 'prime_ttc',
          label: ' Prime TTC'
        },
        {
          key: 'expired',
          label: 'Etat du lien'
        },
        {
          key: 'action',
          label: 'Action'
        },

      ],
      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listPaymentLink,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.listPaymentLink.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    /*listFiltered() {
      return this.list.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },*/
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
    detaildevis(item) {

      this.detailsDevis = item
      this.showdevis = true
    },

    Remover() {
      Api().post('sinistre/remove')
          .then(rep => {})
    },

    getPaymentLink() {

      Api().get('/paymentLink/listing')
          .then(resultat => {
            this.listPaymentLink = resultat.data.result
            this.totalRows = resultat.data.result.length
          });

    },

    devisDeleteConfirmation(item) {
      this.modalDelete = true
      this.detailsDevis = item
    },

    ArchivateDevis(devis) {
      let id = devis._id
      Api().post('devis/archivate/' + id)
          .then(resp => {
            this.getPaymentLink()
          })
    },
    AddEtatUser() {
      Api().post('devis/addetatuser')
          .then(resultat => {})
          .catch(err => {})
    },
    DeletingDevis(id) {
      Api().delete('devis/delete/' + id)
          .then(resultat => {
            if (resultat.data.rep.n == 1) {
              this.modalDelete = false
              this.getPaymentLink()
            }
          })
          .catch(err => {})
    },

    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },







    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", {id:item._id})
          .then((result) => {
            Api().post("/branchecourtier/get/all/", {userid:item.userid})
                .then((response) => {
                  this.configUserDevisAssureur = response.data.partenaires
                })
                .catch((err) => {});
          }).catch((err) => {

      });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
          .then((result) => {
            this.configUserDevisAssureur = []
          }).catch((err) => {
        console.log(err)
      });
    },

    pushConfig() {
      if(this.Selectedbranche && this.selectedAssureur)
      {
        this.configUserDevisAssureur.push({
          branche:this.Selectedbranche,
          assureurs:this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche  = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", {userid:item.id})
          .then((response) => {
            this.configUserDevisAssureur = response.data.partenaires
          })
          .catch((err) => {});
    },

    setCollapse(){
      this.collapse = "id_"+Date.now()+"_collapse"
    },

    MenuDelete(item)
    {
      Api().delete("/menu/"+item._id)
          .then((result) => {
            this.getUserMenu(this.detailsUser._id)
          }).catch((err) => {

      });
    },

    pushMenu()
    {
      if(this.menu_name && this.userSubmenu.length >= 1)
      {
        this.UserFinalMenu.push({
          user : this.detailsUser.id,
          theme:this.menu_name,
          collapse:this.collapse,
          config:this.userSubmenu
        })
        this.menu_name   = null
        this.userSubmenu = []
        this.collapse    = null
      }else{
        this.$toasted.show("Remplissez les champs vides !", this.ToastTabError);
      }
    },

    create_user_menu()
    {
      if(this.UserFinalMenu.length >=1 )
      {
        Api().post("/menu/add", this.UserFinalMenu)
            .then((result) => {
              this.$toasted.show("Opération effectuée !", this.ToastTabSucess);
              console.log(result.data)
              this.UserFinalMenu = []
            }).catch((err) => {
          console.log(err)
        });
      }else{
        this.$toasted.show("Le menu ne doit pas être vide !", this.ToastTabError);
      }
    },

    merge(){
      Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
          .then((result) => {
            this.showuser = false
            if(result.data.result){
              alert('Opération réussie')
            }
          }).catch((err) => {
        console.log('=======err=========')
      });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.showuser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList:function(){
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listuser = resultat.data
                this.totalRows = resultat.data.length
              });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
          .then(response => {
            if (response.data) {
              this.showAlert()
            }
            this.showAlert()
          })
          .catch(err => {

          })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
          .then(resultat => {
            if (resultat.status == 200) {
              this.modalDelete = false
              this.getUserList()
            }
          })
          .catch(err => {})
    },

    getUserMenu(id){
      Api().post('/menu/user/menu', {userid:id})
          .then((result) => {
            this.usermenu = result.data.menus
            console.log(result.data.menus)
          }).catch((err) => {
        console.log(err)
      });
    },








    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    this.getPaymentLink()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getPaymentLink()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
