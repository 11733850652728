<template>
    <vue-scroll class="page-devis-voyage">
        <div class="page-header">
            <h1>Avenant de <span style="color:#ffa409">Renouvellement</span>
            </h1>
        </div>

        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <h3>Validation :</h3>
                <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper">
                    <el-table :data="ForUpdate.vehicules" style="width: 100%">
                        <el-table-column :fixed="!isMobile" prop="marque" label="Marque" width="150">
                        </el-table-column>
                        <el-table-column prop="model" label="Modèle" width="150">
                        </el-table-column>
                        <el-table-column prop="immatriculation" label="Immatricule" width="150">
                        </el-table-column>
                        <el-table-column prop="categorie" label="Catégorie" width="150">
                        </el-table-column>
                        <el-table-column prop="dateCircule" label="Date 1ére mise en cir..." width="250">
                            <template slot-scope="scope">
                                <span class="date">{{ DateFormater(scope.row.dateCircule) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="force" label="Puissance Fiscale" width="150">
                        </el-table-column>
                        <el-table-column prop="nbplace" label="Nbre place" width="150">
                        </el-table-column>
                        <el-table-column prop="action" label="Modifier">
                            <template slot-scope="scope">
                                <el-button @click="DetailVehicule(scope.row)" type="primary" icon="el-icon-edit" size="mini"
                                    circle title="Modifier"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div>
                    <h3>Propiétaire</h3>
                    <el-row :gutter="20" v-if="!isMobile">
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Prenom</label>
                                <el-input placeholder="Date d'échéance" v-model="ForUpdate.proprietaire.prenom">
                                </el-input>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>nom</label>
                                <el-input placeholder="Date d'échéance" v-model="ForUpdate.proprietaire.nom">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <h3>Période de couverture</h3>
                    <el-row :gutter="20" v-if="!isMobile">
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Durée de la garantie (en mois)</label>
                                <el-select @change="dateDiff()" style="width:100%" v-model="duree"
                                    placeholder="Selectionner">
                                    <el-option v-for="item in DureeMois" :key="item" :label="item"
                                        :value="item"></el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker style="width: 100%!important;" @change="dateDiff()" v-model="dateEffet"
                                    type="date" placeholder="Date d'effet" :picker-options="dateAfterToday(dateAfter)">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="20" v-if="isMobile">
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <label>Durée de la garantie (en mois)</label>
                                <el-select @change="dateDiff()" style="width:100%" v-model="duree"
                                    placeholder="Selectionner">
                                    <el-option v-for="item in DureeMois" :key="item" :label="item"
                                        :value="item"></el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker style="width: 100%!important;" @change="dateDiff()" v-model="dateEffet"
                                    type="date" placeholder="Date d'effet" :picker-options="dateAfterToday(dateAfter)">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div style="margin-top:15px">
                    <el-button style="float:left" @click="SendData()" type="primary" round>Valider</el-button>
                </div>
            </div>
        </div>

        <el-dialog :show-close="false" :close-on-click-modal="false" width="70%!important" title="Avenant de renouvellement"
            :visible.sync="showvehicule" v-if="showvehicule == true && !isMobile">
            <div class="grid-content bg-purple">
                <el-card class="box-card">

                    <el-row :gutter="15">
                        <el-col :span="8">
                            <div>
                                <label>Marque</label>
                                <el-select disabled @change="onChangeSelectedMarque" style="width: 100%!important;"
                                    v-model="vehiculeForChange.marque" placeholder="Selectionner">
                                    <el-option v-for="(items, index) in listMarque" :key="index" :value="items.Marque">{{
                                        items.Marque
                                    }}</el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Modèle</label>
                                <el-select disabled style="width: 100%!important;" v-model="vehiculeForChange.model"
                                    placeholder="Selectionner">
                                    <el-option v-for="(items, index) in listeModeles" :key="index" :value="items.Type">{{
                                        items.Type
                                    }}</el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Nombre de place</label>
                                <el-input disabled type="number" v-model="vehiculeForChange.nbplace"
                                    placeholder="Nombre de place" class="input-with-select"></el-input>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="15" style="margin-top:15px">
                        <el-col :span="8">
                            <div>
                                <label>Date 1ére mise en circulation</label>
                                <el-date-picker disabled style="width: 100%!important;" @change="yearsDiff()"
                                    v-model="vehiculeForChange.dateCircule" type="date" placeholder="Choississez une date">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Âge du véhicule</label>
                                <el-input disabled :value="vehiculeForChange.age" v-model="vehiculeForChange.age"
                                    placeholder="Âge du véhicule" class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Puissance fiscale</label>
                                <el-input disabled type="number" v-model="vehiculeForChange.force"
                                    placeholder="Puissance fiscale" class="input-with-select"></el-input>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="15" style="margin-top:15px">
                        <el-col :span="8">
                            <div>
                                <label>Valeur à neuf</label>
                                <el-input disabled type="number" v-model="vehiculeForChange.valeur"
                                    placeholder="Valeur à neuf" class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Valeur vénale</label>
                                <el-input type="number" :value="vehiculeForChange.valeur_venale"
                                    v-model="vehiculeForChange.valeur_venale" placeholder="Valeur vénale"
                                    class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Immatriculation</label>
                                <el-input v-model="vehiculeForChange.immatriculation"
                                    placeholder="Immatriculation du véhicule" class="input-with-select"></el-input>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="15" style="margin-top:15px">
                        <el-col :span="8">
                            <div>
                                <label>Carburation</label>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio disabled v-model="vehiculeForChange.carburation" size="medium"
                                            label="Essence">Essence</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio disabled v-model="vehiculeForChange.carburation"
                                            label="Diésel">Diésel</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio disabled v-model="vehiculeForChange.carburation" size="medium"
                                            label="Electrique">Eléctrique</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio disabled v-model="vehiculeForChange.carburation"
                                            label="Hybride">Hybride</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Option utilitaire</label>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio :disabled="vehiculeForChange.categorie == 'categorie 5'"
                                            @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.optionUtilitaire" size="medium"
                                            label="materiel_perso">Matériel personel</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio :disabled="vehiculeForChange.categorie == 'categorie 5'"
                                            @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.optionUtilitaire" label="materiel_tiers">Matériel
                                            tierce</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Carosserie</label>
                                <el-col v-show="vehiculeForChange.optionUtilitaire != 'materiel_tiers'">
                                    <div class="grid-content bg-purple-light">
                                        <el-radio :disabled="vehiculeForChange.categorie == 'categorie 5'"
                                            @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.tonnage_carosserie"
                                            label="carosserie tourisme">Carosserie Tourisme</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio :disabled="vehiculeForChange.categorie == 'categorie 5'"
                                            @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.tonnage_carosserie" size="medium"
                                            label="Autre carosserie jusqu'à 3,5 tonnes">Autre carosserie jusqu'à 3,5
                                            tonnes</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio :disabled="vehiculeForChange.categorie == 'categorie 5'"
                                            @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.tonnage_carosserie"
                                            label="Autre carosserie plus de 3,5 tonnes">Autre carosserie plus de 3,5
                                            tonnes</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="15" style="margin-top:15px" v-if="vehiculeForChange.categorie == 'categorie 5'">
                        <el-col :span="8">
                            <div>
                                <label>Volumetrie 2 roues</label>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues" size="medium"
                                            label="50">Jusqu'à 50
                                            cm3</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues" label="125">Jusqu'à 125
                                            cm3</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues" size="medium"
                                            label="126">Plus de 125
                                            cm3</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues"
                                            label="401">Side-car</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Flotte/Mono</label>
                                <el-input disabled :value="vehiculeForChange.flotte_mono"
                                    v-model="vehiculeForChange.flotte_mono" placeholder="Rechercher..."
                                    class="input-with-select"></el-input>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="15" style="margin-top:15px">
                        <el-col :span="8">
                            <div>
                                <label>Tonnage actuelle : </label>
                                <span class="font-weight-black">
                                    {{ vehiculeForChange.tonnage }}
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <label>Carosserie actuelle : </label>
                                <span class="font-weight-black">{{ vehiculeForChange.carosserie }}
                                </span>
                            </div>
                        </el-col>
                    </el-row>

                    <el-divider></el-divider>


                    <el-row :gutter="15" style="margin-top:15px">
                        <el-col :span="24">
                            <div>
                                <label>Garanties souscrites</label>
                                <el-checkbox-group v-model="vehiculeForChange.garanties">
                                    <el-checkbox label="RESPONSABILITE CIVILE">Responsabilité Civile</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="INCENDIE">Incendie</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="AVANCE SUR RECOURS" @change="AvRecModalFunction()">Avance sur
                                        Recours</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="DEFENSE ET RECOURS">Défense et
                                        Recours</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="VOL">Vol</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="BRIS DE GLACES">Bris de
                                        glace</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="PERSONNES TRANSPORTEES" @change="PersoTransporteModalFunction()">Personnes
                                        transportées</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="TIERCE COMPLETE" @change="tierceControlAddGarantie()">Tierce
                                        Complète</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="TIERCE COLLISION" @change="tierceControlAddGarantie()">Tierce
                                        Collision</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie == 'categorie 5'"
                                        label="DEFENSE ET RECOURS">Défense et
                                        Recours</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie == 'categorie 5'"
                                        label="PERSONNES TRANSPORTEES">Personnes Transportées</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-col>
                    </el-row>

                </el-card>

                <div class="mb-5" style="margin-top: 15px">
                    <el-button type="primary" round @click="fieldsValidation()">Modifier</el-button>
                </div>
            </div>

        </el-dialog>

        <el-dialog :before-close="fieldsValidation()" width="90%!important" title="Avenant de renouvellement"
            :visible.sync="showvehicule" v-if="showvehicule == true && isMobile">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                    <el-row :gutter="15">
                        <el-col :span="24">
                            <div>
                                <label>Marque</label>
                                <el-select style="width: 100%!important;" v-model="vehiculeForChange.marque"
                                    placeholder="Selectionner">
                                    <el-option v-for="(items, index) in listMarque" :key="index" :value="items.Marque">{{
                                        items.Marque
                                    }}</el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div>
                                <label>Modèle</label>
                                <el-select style="width: 100%!important;" v-model="vehiculeForChange.model"
                                    placeholder="Selectionner">
                                    <el-option v-for="(items, index) in listeModeles" :key="index" :value="items.Type">{{
                                        items.Type
                                    }}</el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie != 'categorie 5'">
                                <label>Nombre de place</label>
                                <el-input type="number" v-model="vehiculeForChange.nbplace" placeholder="Nombre de place"
                                    class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie != 'categorie 5'">
                                <label>Valeur à neuf</label>
                                <el-input type="number" v-model="vehiculeForChange.valeur" placeholder="Valeur à neuf"
                                    class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie != 'categorie 5'">
                                <label>Valeur vénale</label>
                                <el-input type="number" :value="vehiculeForChange.valeur_venale"
                                    v-model="vehiculeForChange.valeur_venale" placeholder="Valeur vénale"
                                    class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie != 'categorie 5'">
                                <label>Puissance fiscale</label>
                                <el-input type="number" v-model="vehiculeForChange.force" placeholder="Puissance fiscale"
                                    class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div>
                                <label>Date 1ére mise en circulation</label>
                                <el-date-picker disabled style="width: 100%!important;" @change="yearsDiff()"
                                    v-model="vehiculeForChange.dateCircule" type="date" placeholder="Choississez une date">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie != 'categorie 5'">
                                <label>Âge du véhicule</label>
                                <el-input disabled :value="vehiculeForChange.age" v-model="vehiculeForChange.age"
                                    placeholder="Âge du véhicule" class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div>
                                <label>Immatriculation</label>
                                <el-input v-model="vehiculeForChange.immatriculation"
                                    placeholder="Immatriculation du véhicule" class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie != 'categorie 5'">
                                <label>Carburation</label>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="vehiculeForChange.carburation" size="medium"
                                            label="Essence">Essence</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio v-model="vehiculeForChange.carburation" label="Diésel">Diésel</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="vehiculeForChange.carburation" size="medium"
                                            label="Electrique">Eléctrique</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio v-model="vehiculeForChange.carburation" label="Hybride">Hybride</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie != 'categorie 5'">
                                <label>Option utilitaire</label>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.optionUtilitaire" size="medium"
                                            label="materiel_perso">Matériel personel</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.optionUtilitaire" label="materiel_tiers">Matériel
                                            tierce</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div>
                                <label>Corosserie</label>
                                <el-col v-show="vehiculeForChange.optionUtilitaire != 'materiel_tiers'">
                                    <div class="grid-content bg-purple-light">
                                        <el-radio @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.tonnage_carosserie"
                                            label="carosserie tourisme">Carosserie Tourisme</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.tonnage_carosserie" size="medium"
                                            label="Autre carosserie jusqu'à 3,5 tonnes">utre carosserie jusqu'à 3,5
                                            tonnes</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio @click="Deduction_tonnage_carosserie"
                                            v-model="vehiculeForChange.tonnage_carosserie"
                                            label="Autre carosserie plus de 3,5 tonnes">Autre carosserie plus de 3,5
                                            tonnes</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div v-if="vehiculeForChange.categorie == 'categorie 5'">
                                <label>Volumetrie 2 roues</label>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues" size="medium"
                                            label="50">Jusqu'à 50
                                            cm3</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues" label="125">Jusqu'à 125
                                            cm3</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues" size="medium"
                                            label="126">Plus de 125
                                            cm3</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio v-model="vehiculeForChange.volumetrie2_roues"
                                            label="401">Side-car</el-radio>
                                    </div>
                                </el-col>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div>
                                <label>Flotte/Mono</label>
                                <el-input disabled :value="vehiculeForChange.flotte_mono"
                                    v-model="vehiculeForChange.flotte_mono" placeholder="Rechercher..."
                                    class="input-with-select"></el-input>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div>
                                <label>Tonnage actuelle : </label>
                                <span class="font-weight-black">
                                    {{ vehiculeForChange.tonnage }}
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px">
                            <div>
                                <label>Carosserie actuelle : </label>
                                <span class="font-weight-black">{{ vehiculeForChange.carosserie }}
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div>
                                <label>Garanties souscrites</label>
                                <el-checkbox-group v-model="vehiculeForChange.garanties">
                                    <el-checkbox label="RESPONSABILITE CIVILE">Responsabilité Civile</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="INCENDIE">Incendie</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="AVANCE SUR RECOURS" @change="AvRec = true">Avance sur Recours</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="DEFENSE ET RECOURS">Défense et
                                        Recours</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="VOL">Vol</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="BRIS DE GLACES">Bris de
                                        glace</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="PERSONNES TRANSPORTEES" @change="PersoTransporteModalFunction()">Personnes
                                        transportées</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="TIERCE COMPLETE" @change="tierceControlAddGarantie()">Tierce
                                        Complète</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie != 'categorie 5'"
                                        label="TIERCE COLLISION" @change="tierceControlAddGarantie()">Tierce
                                        Collision</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie == 'categorie 5'"
                                        label="DEFENSE ET RECOURS">Défense et
                                        Recours</el-checkbox>
                                    <el-checkbox v-show="vehiculeForChange.categorie == 'categorie 5'"
                                        label="PERSONNES TRANSPORTEES">Personnes Transportées</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>

                <div class="mb-5" style="margin-top: 15px">
                    <el-button type="primary" round @click="fieldsValidation()">Modifier</el-button>
                </div>
            </div>

        </el-dialog>

        <!-- ========= DIALOG VALEUR A NEUF ============ -->
        <!-- ========= DIALOG VALEUR A NEUF ============ -->
        <el-dialog :show-close="false" title="Valeur à neuf" :visible.sync="VNeuf">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col :span="24">
                        <el-form-item label="Valeur à neuf">
                            <el-input v-money="money" placeholder="Valeur à neuf (CFA)"
                                v-model="vehiculeForChange.valeur"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div>
                <el-button @click="CheckValeurNeuf()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog :show-close="false" title="Choix du Capital Avance sur Recours" :visible.sync="AvRec">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-radio v-model="vehiculeForChange.capitalAvRec" label="500000">500 000 CFA</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="vehiculeForChange.capitalAvRec" label="1000000">1 000 000 CFA</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio disabled v-model="vehiculeForChange.capitalAvRec" label="0">Plus de 1 million CFA
                                <span>Nous
                                    consulter</span></el-radio>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button @click="AvRecValidator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog :show-close="false" title="Personnes Transportées" :visible.sync="PersoTransporteModal">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                        <div class="grid-content bg-purple">
                            <el-radio v-model="vehiculeForChange.optionPersoTrans" label="option 1">Option 1</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="vehiculeForChange.optionPersoTrans" label="option 2">Option 2</el-radio>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                            <el-radio v-model="vehiculeForChange.optionPersoTrans" label="option 3">Option 3</el-radio>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button @click="PersonnesTransValidator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog title="Ajout véhicule" :visible.sync="popupAutre">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
                <el-row :gutter="15">
                    <el-col :span="12">
                        <el-form-item label="Marque du véhicule">
                            <el-input placeholder="Marque de votre véhicule" v-model="vehiculeForChange.marque"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Modéle du véhicule">
                            <el-input placeholder="Modèle de votre véhicule" v-model="vehiculeForChange.model"></el-input>
                        </el-form-item>
                    </el-col>


                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button type="primary" round @click="validMarqueModel">Valider</el-button>
            </div>
        </el-dialog>
    </vue-scroll>
</template>
  
<script>

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../services/Api'
import store from '../../store'
import MarqueVehicule from '../../common/marqueauto'
import _ from 'underscore'
import avenantsService from '../../services/avenants.service'
//fin importation assuraf

export default {
    name: "avenant_de_renouvellement",
    data() {
        return {
            dateAfter: null,
            AvRec: false,
            PersoTransporteModal: false,
            valid: null,
            popupAutre: false,
            isMobile: false,
            dateAfterToday: (dateEffet) => ({
                disabledDate(time) {
                    return time.getTime() < (dayjs(new Date(dateEffet))).toDate() //dayjs.datediff(Date.now(), );
                }
            }),
            VNeuf: false,
            capital_AvRec: null,
            optionsdate: {
                format: "YYYY/MM/DD",
                useCurrent: false,
                minDate: new Date(),
            },
            DureeMois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            showvehicule2roues: false,
            showvehicule: false,
            vehiculeForChange: [],
            ShowCapitalAvRec: false,
            ForUpdate: {},
            annee: null,
            year: null,
            yearCarForChange: null,
            suivant: false,
            categorie: null,
            assureurs: [],
            capital_AvRec: null,
            listeModeles: null,
            listeAssureurs: [],
            listMarque: [],
            listMarqueprime: [],
            selectedMarque: null,
            duree: null,
            dateEffet: new Date().toISOString().substr(0, 10),
            dateEcheance: null,
            options: [
                { text: "Tourisme", value: "tourisme" },
                { text: "2 roues", value: "2roues" },
                { text: "Utilitaires", value: "utilitaire" },
            ],
            options2: [
                { text: "Essence", value: "essence" },
                { text: "Diesel", value: "diesel" },
                { text: "Hybride", value: "hybride" },
                { text: "Electrique", value: "elecetrique" },
            ],
            HeaderTableauVehicules: [
                { key: "marque", label: "Marque" },
                // {key: 'categorie', label: 'Categorie'},
                { key: "model", label: "Model" },
                { key: "age", label: "Age" },
                { key: "force", label: "Puissance Fiscale" },
                { key: "nbplace", label: "Nombre de place" },
                { key: "valeur", label: "Valeur neuf" },
                { key: "valeur_venale", label: "Valeur venale" },
                { key: "immatriculation", label: "Immatriculation" },
                { key: "carburation", label: "Carburation" },
                { key: "action", label: "Action" },
            ],
            money: {
                thousands: " ",
                precision: false,
                masked: false,
            },
            stepper: 0,

            show: true,
            voidField: false,
            Deb: null,
            selectedcarbu: '',

            show: true,
            //fin initialisation des models d'assuraf
        }
    },
    mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getListePartenaire();
            this.listMarque = _.uniq(MarqueVehicule, _.property('Marque'))
            this.listeModeles = _.uniq(MarqueVehicule, _.property('Type'))
            this.ForUpdate = store.state.DevisReopen;
            if (this.ForUpdate.vehicules.length == 1) {
                this.vehiculeForChange = this.ForUpdate.vehicules[0]
                this.showvehicule = true;
            } else {
                this.vehiculeForChange = this.ForUpdate.vehicules
            }

            for (let marquetour of this.listMarque) {
                this.listMarqueprime.push(marquetour.Marque);
            }
        } else {
            this.$router.go(this.$router.push('/login'))
        }

    },
    watch: {
        marque(val) {
            this.onChangeSelectedMarque(val)
        },
    },

    methods: {

        tierceControlAddGarantie() {
            let cpt = 0;
            console.log(this.vehiculeForChange.garanties)
            for (let index = 0; index < this.vehiculeForChange.garanties.length; index++) {
                if (this.vehiculeForChange.garanties[index].trim() == 'TIERCE COLLISION' || this.vehiculeForChange.garanties[index].trim() == 'TIERCE PLAFONNEE') {
                    cpt = cpt + 1
                } else if (this.vehiculeForChange.garanties[index].trim() == 'TIERCE COLLISION' || this.vehiculeForChange.garanties[index].trim() == 'TIERCE COMPLETE') {
                    cpt = cpt + 1
                } else if (this.vehiculeForChange.garanties[index].trim() == 'TIERCE COMPLETE' || this.vehiculeForChange.garanties[index].trim() == 'TIERCE PLAFONNEE') {
                    cpt = cpt + 1
                } else if (this.vehiculeForChange.garanties[index].trim() == 'TIERCE COLLISION' || this.vehiculeForChange.garanties[index].trim() == 'TIERCE PLAFONNEE' || this.vehiculeForChange.garanties[index].trim() == 'TIERCE COMPLETE') {
                    cpt = cpt + 2
                }
            }

            if (cpt == 2) {
                this.$message.error('Impossible de prendre 2 garanties tierces en même temps !', this.AlertMessage)
                this.vehiculeForChange.garanties.pop()
                console.log(this.vehiculeForChange.garanties)
            } else if (cpt == 3) {
                this.$message.error('Impossible de prendre 3 garanties tierces en même temps !', this.AlertMessage)
                this.vehiculeForChange.garanties.pop()
                console.log(this.vehiculeForChange.garanties)
            }

        },

        PersonnesTransValidator() {
            if (this.vehiculeForChange.optionPersoTrans) {
                this.PersoTransporteModal = false
            } else {
                this.$message.error('Veuillez choisir un option !', this.AlertMessage)
            }
        },
        AvRecValidator() {
            if (this.vehiculeForChange.capitalAvRec) {
                this.AvRec = false
            } else {
                this.$message.error('Veuillez choisir un option !', this.AlertMessage)
            }
        },
        PersoTransporteModalFunction() {
            let trouver = 0;
            for (let index = 0; index < this.vehiculeForChange.garanties.length; index++) {
                if (this.vehiculeForChange.garanties[index] == 'PERSONNES TRANSPORTEES') {
                    trouver = 1
                }
            }
            if (trouver == 1) {
                this.PersoTransporteModal = true
            } else {
                this.optionPersoTrans = null
            }
        },

        AvRecModalFunction() {
            if (!this.vehiculeForChange) {
                let trouver = 0
                for (let index = 0; index < this.vehiculeForChange.garanties.length; index++) {
                    if (this.garanties[index] == 'AVANCE SUR RECOURS') {
                        trouver = 1
                    }
                }
                if (trouver == 1) {
                    this.AvRec = true
                } else {
                    this.vehiculeForChange.capital_AvRec = null
                }
            } else {
                let trouver = 0;
                for (let index = 0; index < this.vehiculeForChange.garanties.length; index++) {
                    if (this.vehiculeForChange.garanties[index] == 'AVANCE SUR RECOURS') {
                        trouver = 1
                    }
                }
                if (trouver == 1) {
                    this.AvRec = true
                } else {
                    this.vehiculeForChange.capital_AvRec = null
                }
            }

        },

        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format('DD-MM-YYYY')
            }
        },
        AlertMessage() {
            this.$message({
                showClose: false,
                duration: 5000
            });
        },
        validMarqueModel() {
            if (this.categorie == "2_roues") {
                if (this.vehiculeForChange.marque && this.vehiculeForChange.model) {
                    this.popupAutre = false;
                } else {
                    this.$message.error('Champs obligatoires !', this.AlertMessage)
                }
            } else {
                if (this.vehiculeForChange.marque && this.vehiculeForChange.model) {
                    this.popupAutre = false;
                } else {
                    this.$message.error('Champs obligatoires !', this.AlertMessage)
                }
            }
        },

        /*============= Fonction pour calculer la date 1ere mise en circulation ===========*/
        yearsDiff() {
            const date1 = dayjs(this.vehiculeForChange.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, "days");
            this.vehiculeForChange.age = daydiff;

            if (this.vehiculeForChange.age < 0) {
                this.vehiculeForChange.age = null;
                this.vehiculeForChange.annee_mois = "";
                this.$message.error('Erreur, date antérieure à la date actuelle !', this.AlertMessage)
                this.vehiculeForChange.dateCircule = null;
            } else if (this.vehiculeForChange.age <= 29) {
                this.an = this.vehiculeForChange.annee_mois = "jour(s)";
            } else if (
                (this.vehiculeForChange.age >= 30, this.vehiculeForChange.age <= 360)
            ) {
                let year = Math.floor(this.vehiculeForChange.age / 30);
                this.vehiculeForChange.age = year;
                this.an = this.vehiculeForChange.annee_mois = "mois";
            } else if (this.vehiculeForChange.age >= 360) {
                let year = Math.floor(this.vehiculeForChange.age / 360);
                this.vehiculeForChange.age = year;
                this.annee = year;
                this.year = this.vehiculeForChange.age;
                this.an = this.vehiculeForChange.annee_mois = "an(s)";
            }
        },
        /*============= fonction pour controler les garanties tierce a ne cocher plus d'un ===========*/
        checkingVal() {
            let cpt = 0;

            for (let g of this.vehiculeForChange.garanties) {
                if (
                    g.trim() == "TIERCE COLLISION" ||
                    g.trim() == "TIERCE COMPLETE" ||
                    g.trim() == "TIERCE PLAFONNEE"
                ) {
                    cpt++;
                }
            }

            if (cpt > 1) {
                this.$message.error('Impossible de prendre 2 garanties tierces en même temps !', this.AlertMessage)
                this.suivant = true;
            } else {
                this.suivant = false;
                this.VNeuf = true;
            }
        },

        ValeurNeufModalFunction() {
            let trouver = 0;
            for (
                let index = 0;
                index < this.vehiculeForChange.garanties.length;
                index++
            ) {
                if (this.vehiculeForChange.garanties[index] == "TIERCE COMPLETE") {
                    trouver = 1;
                } else if (
                    this.vehiculeForChange.garanties[index] == "TIERCE COLLISION"
                ) {
                    trouver = 2;
                } else {
                    trouver = 3;
                }
            }
            if (trouver == 1 || trouver == 2) {
                this.VNeuf = true;
            } else {
                this.VNeuf = false;
                this.vehiculeForChange.valeur = this.neant;
            }
        },

        CheckValeurNeuf: function () {
            let trouver = 0;
            for (
                let index = 0;
                index < this.vehiculeForChange.garanties.length;
                index++
            ) {
                if (
                    this.vehiculeForChange.garanties[index] == "TIERCE COMPLETE" ||
                    this.vehiculeForChange.garanties[index] == "TIERCE COLLISION"
                ) {
                    trouver = 1;
                }
            }

            if (
                (trouver == 1 && !this.vehiculeForChange.valeur) ||
                this.vehiculeForChange.valeur == 0
            ) {
                this.$message.error('Veuillez saisir la valeur à neuf du véhicule !', this.AlertMessage)
            } else if (
                trouver == 1 &&
                this.vehiculeForChange.valeur &&
                this.vehiculeForChange.valeur != 0
            ) {
                if (
                    this.vehiculeForChange.valeur_venale <= this.vehiculeForChange.valeur
                ) {
                    this.VNeuf = false;
                } else {
                    this.$message.error('La valeur à neuf et la valeur actuelle du véhicule ne sont pas cohérentes !', this.AlertMessage)
                }
            } else {
                this.VNeuf = false;
            }
        },

        DetailVehicule(item) {
            this.vehiculeForChange = item;
            this.yearCarForChange = dayjs(Date.now()).diff(this.vehiculeForChange.dateCircule, "y"); //.add(this.duree, 'month').format('YYYY-MM-DD');
            this.showvehicule = true;
        },

        ControlSuivre() {
            this.DetailVehicule.bind[0];
        },

        onChangeSelectedMarque: function (mark) {
            if (typeof mark == "string") {
                if (mark == "AUTRE") {
                    this.popupAutre = true;
                    this.marque = "";
                } else {
                    let selMarque = _.where(MarqueVehicule, {
                        Marque: mark,
                    });
                    this.listeModeles[0] = "AUTRE";
                    this.listeModeles = _.uniq(selMarque, _.property("Type"));
                    this.selectedMarque = mark.Marque;
                }
            } else if (typeof mark == "object") {
                if (mark.Marque == "AUTRE") {
                    this.popupAutre = true;
                    this.marque = "";
                } else {
                    let selMarque = _.where(MarqueVehicule, {
                        Marque: mark.Marque,
                    });
                    this.listeModeles = selMarque;
                    this.listeModeles[0] = "AUTRE";
                    this.selectedMarque = mark.Marque;
                    this.marque = this.selectedMarque;
                }
            }
        },

        //liste de touts les partenaires
        getListePartenaire: function () {
            if (this.$store.state.user.role == "Agent") {
                Api().post("/branchecourtier/get/partenaire", { userid: this.$store.state.user._id, branche: "Automobile" })
                    .then((response) => { this.listeAssureurs = response.data.partenaires[0].assureurs })
                    .catch((err) => { });
            } else if (this.$store.state.user.role == "SuperAdmin" || this.$store.state.user.role == 'Admin') {
                Api()
                    .get("/partenaire/partners/auto")
                    .then((response) => {
                        this.listeAssureurs = response.data.partenaires;
                    });
            } else if (this.$store.state.user.role == "assureur") {
                Api()
                    .get("/partenaire/" + this.$store.state.user.societe)
                    .then((response) => {
                        this.listeAssureurs = response.data.partenaire;
                    });
            }
        },

        /*=== On check le capital Avance Recours ===*/
        check_AvRec() {
            let checker = 0;
            for (let v of this.vehiculeForChange.garanties) {
                if (v == "AVANCE SUR RECOURS") {
                    checker = 1;
                }
            }
            return checker;
        },

        calculDuree() {
            let first = new Date(this.ForUpdate.dateEffet)
            this.dateEffet = new Date(dayjs(new Date(this.ForUpdate.dateEcheance)).add(1, "day"))
            this.duree = (this.dateEffet.getFullYear() - first.getFullYear()) * 12;
            this.duree -= first.getMonth();
            this.duree += this.dateEffet.getMonth();
            this.dateAfter = this.dateEffet
            this.dateDiff()

        },

        fieldsValidation() {
            this.Deduction_tonnage_carosserie();
            if (this.vehiculeForChange.categorie == "categorie 5") {
                if (!this.vehiculeForChange.marque || !this.vehiculeForChange.model) {
                    this.$message.error('Marque et modèle obligatoires !', this.AlertMessage)
                } else if (!this.vehiculeForChange.immatriculation) {
                    this.$message.error('Immatriculation obligatoire !', this.AlertMessage)
                } else {
                    this.calculDuree()
                    this.showvehicule = false;
                }
            } else {
                if (!this.vehiculeForChange.marque || !this.vehiculeForChange.model) {
                    this.$message.error('Marque et modèle obligatoires !', this.AlertMessage)
                } else if (!this.vehiculeForChange.dateCircule || !this.vehiculeForChange.nbplace || !this.vehiculeForChange.force) {
                    this.$message.error('Âge, Puissance Fiscale et Nombre de place obligatoires !', this.AlertMessage)
                } else if (!this.vehiculeForChange.immatriculation || !this.vehiculeForChange.carburation) {
                    this.$message.error('Immatriculation et carburation obligatoires !', this.AlertMessage)
                } else if (this.check_AvRec() == 1 && !this.vehiculeForChange.capitalAvRec) {
                    for (let v of this.vehiculeForChange.Detail_Prime) {
                        if (v.garantie == "AVANCE SUR RECOURS" && v.capital != null && v.capital.length >= 6) {
                            this.vehiculeForChange.capitalAvRec = Number(v.capital)
                            this.calculDuree()
                            this.showvehicule = false
                        } else {
                            this.$message.error('Renseignez un capital Avance Sur Recours !', this.AlertMessage)
                            this.AvRec = true
                        }
                    }

                } else {
                    this.calculDuree()
                    this.showvehicule = false
                }
            }
        },

        //calclu date echéance
        dateDiff: function () {
            if (this.dateEffet && this.duree > 0) {
                let inter = dayjs(this.dateEffet)
                    .add(this.duree, "month")
                    .format("YYYY-MM-DD");
                this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
            } else {
                this.$message.error('La durée du contrat est obligatoire !', this.AlertMessage)
            }
        },

        Deduction_tonnage_carosserie() {
            if (this.vehiculeForChange.optionUtilitaire == "tourisme") {
                this.vehiculeForChange.categorie = "categorie 1";
            } else if (this.vehiculeForChange.optionUtilitaire == "materiel_perso") {
                this.vehiculeForChange.categorie = "categorie 2";
                if (
                    this.vehiculeForChange.tonnage_carosserie ==
                    "Autre carosserie jusqu'à 3,5 tonnes"
                ) {
                    this.vehiculeForChange.tonnage = "3,5 Tonnes";
                    this.vehiculeForChange.carosserie = "autres carosseries";
                } else if (
                    this.vehiculeForChange.tonnage_carosserie ==
                    "Autre carosserie plus de 3,5 tonnes"
                ) {
                    this.vehiculeForChange.tonnage = "Plus de 3,5 Tonnes";
                    this.vehiculeForChange.carosserie = "autres carosseries";
                } else if (
                    this.vehiculeForChange.tonnage_carosserie == "carosserie tourisme"
                ) {
                    this.vehiculeForChange.tonnage = "3,5 Tonnes";
                    this.vehiculeForChange.carosserie = "carosserie tourisme";
                }
            } else if (this.vehiculeForChange.optionUtilitaire == "materiel_tiers") {
                this.vehiculeForChange.categorie = "categorie 3";
                if (
                    this.vehiculeForChange.tonnage_carosserie ==
                    "Autre carosserie jusqu'à 3,5 tonnes"
                ) {
                    this.vehiculeForChange.tonnage = "3,5 Tonnes";
                    this.vehiculeForChange.carosserie = "autres carosseries";
                } else if (
                    this.vehiculeForChange.tonnage_carosserie ==
                    "Autre carosserie plus de 3,5 tonnes"
                ) {
                    this.vehiculeForChange.tonnage = "Plus de 3,5 Tonnes";
                    this.vehiculeForChange.carosserie = "autres carosseries";
                }
            } else if (this.categorie == "2_roues") {
                this.categorieFInale = "categorie 5";
            }
        },

        AutreCarburation(carburantChecked) {
            if (carburantChecked == "Hybride" || carburantChecked == "Electrique") {
                return "Essence";
            } else {
                return carburantChecked;
            }
        },

        deleteOldValues() {
            for (let v of this.ForUpdate.vehicules) {
                delete v.Detail_Prime
                delete v.partenaire
                delete v.taxe
                delete v.fond_garantie_auto
                delete v.prime_nette
                delete v.prime_ttc
                v.capitalAvRec = Number(v.capitalAvRec)
            }
        },

        SendData: function () {
            this.deleteOldValues() // suppression des anciennes valeurs stockée pendants les précedentes calculs

            if (store.state.isUserLoggedIn && this.ForUpdate.proprietaire.prenom && this.ForUpdate.proprietaire.prenom) {
                const OneautoToSave = {
                    numero_police: this.ForUpdate.numeroDevis,
                    userid: this.ForUpdate.userid,
                    dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
                    dateEcheance: dayjs(this.dateEcheance).format("YYYY-MM-DD"),
                    createur: this.$store.state.user._id,
                    branche: "Automobile",
                    duree: this.duree,
                    dateSouscription: dayjs(new Date()).format("YYYY-MM-DD"),
                    vehicule: this.ForUpdate.vehicules,
                    assureurs: [this.ForUpdate.partenaireid.societe],
                    proprietaire: this.ForUpdate.proprietaire,
                    plateforme: 'Dash',
                    type_avenant: this.ForUpdate.type_avenant || null
                };

                if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
                    OneautoToSave.courtier = this.$store.state.user.societe;
                } else {
                    OneautoToSave.courtier = null
                }

                if (this.duree && this.dateEffet && this.dateEcheance) {
                    avenantsService.renouvellement(OneautoToSave)
                        .then((result) => {
                            if (result.data.message && result.data.message == "Devis modifié !") {
                                localStorage.removeItem('DevisReopen')
                                this.$store.dispatch("setDevis", result.data);
                                this.$router.go(this.$router.push({ name: "liste_devis_paiement" }));
                            } else {
                                this.$message.error("Erreur serveur !", this.AlertMessage)
                            }
                        });
                } else {
                    this.$message.error("Durée et date d'effet sont obligatoires !", this.AlertMessage)
                }
            }
            else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }
        }


    },
    created() {
        this.init()
    },
    computed: {
        sortOptions() {
            return this.listuser
                .filter(f => f.sortable)
                .map(f => { return { text: f.label, value: f.key } })
        }
    }
}
</script>
  
<style lang="scss">
@import "../../assets/scss/_variables";

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }

                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }

                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }

                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
  