import { render, staticRenderFns } from "./front_office_user.vue?vue&type=template&id=5d061790&scoped=true"
import script from "./front_office_user.vue?vue&type=script&lang=js"
export * from "./front_office_user.vue?vue&type=script&lang=js"
import style0 from "./front_office_user.vue?vue&type=style&index=0&id=5d061790&prod&lang=scss&scoped=true"
import style1 from "./front_office_user.vue?vue&type=style&index=1&id=5d061790&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d061790",
  null
  
)

export default component.exports