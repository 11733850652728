<template>
    <vue-scroll class="page-devis-voyage" >
      <div class="page-header">
        <h1>Nouveau config <span style="color:#ffa409">partenaire</span>
          <theme-picker style="float:right"></theme-picker>
        </h1>
      </div>
  
      <resize-observer @notify="__resizeHanlder"/>
  
      <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
        <div style="margin-top:20px">
          <h3>Configurations <span style="color:#ffa409">Importantes</span></h3>
          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="6" v-for="(v,i) in config_array" :key="i" style="margin-top:15px">
              <div class="grid-content bg-purple">
                <label>{{v.libelle}}</label>
                <el-input :type="v.type" v-model="v.config_value">
                </el-input>
              </div>
            </el-col>
          </el-row>
          <v-divider></v-divider>
          <h3>Rendu des documents <span style="color:#ffa409">PDF</span></h3>
          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="24">
              <div class="grid-content bg-purple">
                <table border="1" width="100%">
                    <tr>
                    <td colspan="2" width="50%">Logo</td>
                    <td colspan="2">
                        {{config_array[0].config_value}}
                    </td> 
                    </tr>
                    <tr> 
                    <td 
                        colspan="4" 
                        :style="`background:${config_array[2].config_value};color:${config_array[4].config_value}!important`"> 
                            Title
                    </td> 
                    </tr>
                    <tr v-for="(v,i) in 8" :key="i">
                    <td>1</td> <td>1</td> <td>1</td> <td>1</td> 
                    </tr>
                    <tr> 
                    <td 
                        colspan="4" 
                        :style="`background:${config_array[2].config_value};color:${config_array[4].config_value}!important`"> 
                            Title
                    </td> 
                    </tr>
                    <tr v-for="(v,i) in 8" :key="i">
                    <td>1</td> <td>1</td> <td>1</td> <td>1</td> 
                    </tr>

                    <tr>
                    <td colspan="4" width="50%" style="text-align:center">
                        {{config_array[1].config_value}}
                    </td> 
                    </tr>
              </table>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-top:15px">
            <el-col :span="6" style="margin-top:15px">
              <div class="grid-content bg-purple">
                <label>Logo (.png, .jpg, .jpeg)</label>
                <el-input type="file" ref="file" name="file">
                </el-input>
              </div>
            </el-col>
          </el-row>
          <div style="margin-top:15px">
            <el-button @click="CreatePartenaire()" type="primary" round>Créer</el-button>
          </div>
        </div>
      </div>
    </vue-scroll>
  </template>
  
  <script>
  import * as echarts from "echarts"
  import Chance from "chance"
  const chance = new Chance()
  
  //debut importation assuraf
  import dayjs from 'dayjs'
  import Api from '../../../services/Api'
  import store from '../../../store'
  import pays from '../../../common/countries'
  //fin importation assuraf
  
  export default {
    name: "creer_assureur",
    data() {
      return {
        date: new Date(),
      options: {
        format: "YYYY/MM/DD",
        useCurrent: false,
      },
      config_array:[
        {
          config_name:"doc_header",
          config_value :"",
          libelle:"Entête des documents PDF",
          type:"text"
        },
        {
          config_name :"doc_bottom",
          config_value :"",
          libelle:"Pied de page des documents PDF",
          type:"text"
        },
        {
          config_name : "line_background_color",
          config_value :"",
          libelle:"Code couleur d'arrière plan des titres (*)",
          type:"color"
        },
        {
          config_name :"text_color",
          config_value :"#ff0000s",
          libelle:"Code couleur des text (noir par defaut)",
          type:"color"
        },
        {
          config_name :"text_color_on_background",
          config_value :"",
          libelle:"Code couleur des text sur fond (cf *)",
          type:"color"
        },
        {
          config_name :"notification_email",
          config_value :"",
          libelle:"Adresse email noreplay",
          type:"email"
        },
        {
          config_name :"smtp_host",
          config_value :"",
          libelle:"Host SMTP",
          type:"text" 
        },
        {
          config_name :"smtp_port",
          config_value :"",
          libelle:"Port SMTP",
          type:"text" 
        },
        {
          config_name :"smtp_secure",
          config_value :"",
          libelle:"Sécurité SMTP",
          type:"text" 
        },
        {
          config_name :"smtp_password",
          config_value :"",
          libelle:"Mot de passe",
          type:"password" 
        },
        {
         config_name :"devis_vie_prefix",
         config_value :"",
         libelle:"Préfix pour les devis vie",
         type:"text"
        },
        {
          config_name :"devis_auto_prefix",
          config_value :"",
          libelle:"Préfix pour les devis auto",
          type:"text" 
        },
        {
          config_name :"devis_voyage_prefix",
          config_value :"",
          libelle:"Préfix pour les devis voyage",
          type:"text" 
        },
        {
          config_name :"devis_habitation_prefix",
          config_value :"",
          libelle:"Préfix pour les devis habitation",
          type:"text" 
        },
        {
          config_name :"devis_sante_prefix",
          config_value :"",
          libelle:"Préfix pour les devis santé",
          type:"text" 
        }
      ],
      verification:[
        "devis_vie_prefix",
        "devis_auto_prefix",
        "devis_voyage_prefix",
        "devis_habitation_prefix",
        "devis_sante_prefix",
      ],
      hideTiers: true,
      dismissSecs: 3,
      used: false,
      dismissCountDown: 0,
      societe: null,
      type_partenaire: null,
      adresse: null,
      telephone_port: null,
      telephone_bur: null,
      email: null,
      ninea: null,
      etat: "enabled",
      file: null,
      optionEtat: [
        { value: "enabled", text: "En marche" },
        { value: "disabled", text: "BLoqué" },
      ],
      rccm: null,
      pays: null,
      listpays: pays,
      numero_agrement: null,
      date_agrement: null,







          isMobile: false,
          dateBeforeToday: {
              disabledDate(time) {
              return time.getTime() > Date.now();
              }
          },
          listeAssureurs: null,
          listeBaranches: null,
          partenaireid :[],
          ToastTabSucess: {
                  theme: 'toasted-primary',
                  position: 'top-center',
                  duration: 10000,
                  fitToScreen: true,
                  type: 'success',
                  className: 'custom-success-class'
                  }, // Customized options for success toast
                  ToastTabError: {
                  theme: 'toasted-primary',
                  position: 'top-center',
                  duration: 10000,
                  fitToScreen: true,
                  type: 'error'
                  },// Customized options for error toast
          branche:null,        
          montant:null,        
          partenaireid:null,
  
  
  
        voidField: false,
  
        Deb: null,
        selectedcarbu: '',
  
        types: [
          'text',
          'text',
          'number',
        ],
        show: true,
        //fin initialisation des models d'assuraf
        chart: null,
        pie: null,
        gridData: []
      }
    },
    mounted() {
      if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
          this;used = true
      } else {
        this.$router.go(this.$router.push('/login'))
      }
  
      window.addEventListener("resize", this.__resizeHanlder)
    },
  
    methods: {

    voidConfigObj(){
      for(let v of this.config_array){
        v.config_value = ""
      }
    },

        onChange(type_partenaire) {
      if (type_partenaire == "tiers") {
        this.hideTiers = false;
      } else {
        this.hideTiers = true;
      }
      console.log(type_partenaire);
    },

    Inarray(val, theTable)
    {
      let searcher = false
      for(let v of theTable){
        if(v==val)
        {
          searcher = true
        }
      }
      return searcher
    },
    
    CreatePartenaire: function (e) {
        // if (!this.date_agrement) {
        //   this.date_agrement = new Date();
        // }
        this.file = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("logo", this.file);
        formData.append("societe", this.societe.toUpperCase());
        formData.append("type_partenaire", this.type_partenaire);
        formData.append("adresse", this.adresse);
        formData.append("etat", this.etat);
        formData.append("telephone_port", this.telephone_port);
        formData.append("telephone_bur", this.telephone_bur);
        formData.append("email", this.email);
        formData.append("ninea", this.ninea);
        formData.append("rccm", this.rccm);
        formData.append("pays", this.pays);
        formData.append("numero_agrement", this.numero_agrement);
        formData.append("date_agrement", this.date_agrement);
        formData.append("namePartner", this.societe.toUpperCase());
        formData.append("url", "");
        formData.append("password", "");
        formData.append("user", "root"); 
   
        for (var i = 0; i < this.config_array.length; i++) 
        {
          
            if(this.Inarray(this.config_array[i].config_name, this.verification)==true)
            {
              this.config_array[i].config_value = (this.config_array[i].config_value).replace(/[\W_]/g, "")
            }
            formData.append('config[]', JSON.stringify(this.config_array[i]));
        }
       
        Api().post("partenaires/create", formData, {headers: { "Content-Type": "multipart/form-data" },})
          .then((response) => {
            if (response.data) 
            {
              alert("Compte creer")
              this.file = "";
              this.societe = "";
              this.type_partenaire = "";
              this.adresse = "";
              this.pays = "";
              this.telephone_port = "";
              this.telephone_bur = "";
              this.email = "";
              this.ninea = "";
              this.etat = "enabled";
              this.rccm = "";
              this.numero_agrement = "";
              this.date_agrement = "";
              this.$refs.file.files[0] = null;
            }
           
          })
          .catch((err) => {
            console.log(err);
          });
    },




       init() {
        if (window.innerWidth <= 768) this.isMobile = true
      },
      AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
  
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
  
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
  
      __resizeHanlder: _.throttle(function(e) {
        if (this.chart) {
          this.chart.resize()
        }
        if (this.pie) {
          this.pie.resize()
        }
      }, 700),
     
     
      initGridData() {
        const year = new Date().getFullYear()
        const status_list = ["Complete", "Pending", "Returned", "Paid"]
  
        _.times(10, number => {
          let price = chance.floating({ min: 1, max: 100, fixed: 2 })
          let qnt = chance.integer({ min: 1, max: 5 })
          let amount = price * qnt
  
          this.gridData.push({
            customer: chance.name(),
            photo: "/static/images/shop/" + chance.integer({ min: 0, max: 19 }) + ".jpg",
            city: chance.city(),
            address: chance.address(),
            email: chance.email(),
            product: chance.sentence({ words: 3 }),
            price: _.replace(price.toFixed(2).toString(), ".", ","),
            qnt,
            amount: _.replace(amount.toFixed(2).toString(), ".", ","),
            status: status_list[chance.integer({ min: 0, max: 3 })],
            date: chance.date({ string: true, year: year }),
            id: number
          })
        })
      }
    },
     created() {
      this.init()
    },
    computed : {
      sortOptions () {
        return this.listuser
            .filter(f => f.sortable)
            .map(f => { return { text: f.label, value: f.key } })
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import "../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  
  .page-ecommerce-dashboard {
    .widget {
      .icon-box {
        font-size: 30px;
      }
    }
  
    .table-box {
      .item-box {
        &.item-product {
          .product-image {
            width: 50px;
            margin-right: 15px;
            float: left;
  
            img {
              width: 100%;
            }
          }
        }
  
        &.item-status {
          padding: 5px 10px;
  
          &.status- {
            &Complete {
              background: rgba(44, 196, 120, 0.25);
            }
            &Pending {
              background: rgba(247, 186, 42, 0.25);
            }
            &Returned {
              background: rgba(243, 24, 71, 0.25);
            }
            &Paid {
              background: rgba(45, 109, 211, 0.25);
            }
          }
        }
      }
    }
  }
  </style>
  