<template>
    <vue-scroll class="page-devis-voyage" >
        <div class="page-header">
			<h1>Création de devis <span style="color:#ffa409">Assur'Care</span>
				<theme-picker style="float:right"></theme-picker>
			</h1>
		</div>

        <resize-observer @notify="__resizeHanlder"/>

        <div v-if="hidesearchClient && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="10">
                  <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
              </el-row>
            </div>

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>

        <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="24">
                  <el-input v-if="NumberInput" type="number" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                  <el-col :span="24" style="margin-top:15">
                   <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  </el-col>
              </el-row>
            </div>

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>
        
        <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <h3>Choix Pack :</h3>
                 <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="8">
                        <div>
                           <el-card style="width: 100%">
                                <div slot="header" class="clearfix">
                                    <span>Pack Essentiel - 70%</span>
                                    <el-button style="float:right;margin-top:-7px!important" type="primary" round @click="choix()">Choisir</el-button>
                                </div>
                                <div class="text item">
                                    <el-collapse v-model="DetailsListFirst">
                                    <el-collapse-item title="Avantages" name="1">
                                        <div>
                                            <ul>
                                                <li>Couverture incluant les consultations, les frais médicaux et pharmaceutiques, la radiologie, les analyses,
                                                    <br> les frais d'hospitalisation,</li>
                                                <li>Tarif accessible et forfaitaire par assuré,</li>
                                                <li>Plafond annuel de couverture de <br> 2 000 000 FCFA par assuré,</li>
                                                <li>Une carte de santé par assuré pour une utilisation autonome,</li>
                                                <li>Un large réseau de prestataires de santé.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="Conditions particulieres" name="2">
                                        <div>
                                            <ul>
                                                <li>Hospitalisation uniquement dans les hôpitaux du Sénégal,</li>
                                                <li>Couverture valable pour un an et par personne agé(e) de moins de 60 ans,</li>
                                                <li>Tarif forfaitaire annuel,</li>
                                                <li>Questionnaire médical à renseigner.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <span class="mt-1" style="font-size:12px;position:absolute">10 pers. minimum</span>
                                    </el-collapse>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                           <el-card style="width: 100%">
                                <div slot="header" class="clearfix">
                                    <span>Pack Sérénité - 80%</span>
                                    <el-button style="float:right;margin-top:-7px!important" type="primary" round @click="choix2()">Choisir</el-button>
                                </div>
                                <div class="text item">
                                    <el-collapse v-model="DetailsListSecond">
                                    <el-collapse-item title="Avantages" name="1">
                                        <div>
                                            <ul>
                                                <li>Couverture incluant les consultations, les frais médicaux et pharmaceutiques, la radiologie, les analyses, les frais d'hospitalisation, les auxiliaires médicaux et
                                                    <br>le transport par ambulance,</li>
                                                <li>les soins dentaires, frais d'optique et de maternité</li>
                                                <li>Tarif attractif forfaitaire par assuré,</li>
                                                <li>Plafond annuel de couverture jusqu'à 5 000 000 FCFA par assuré</li>
                                                <li>Une carte de santé par assuré pour une utilisation autonome,</li>
                                                <li>Un large réseau de prestataires de santé,</li>
                                                <li>La possibilité, à partir de 4 assurés, de souscrire pour les ascendants.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="Conditions particulieres" name="2">
                                        <div>
                                            <ul>
                                                <li>Couverture valable pour un an et par personne agé(e) de moins de 60 ans,</li>
                                                <li>Tarif forfaitaire annuel,</li>
                                                <li>Questionnaire médical à renseigner,</li>
                                                <li>Limite d'âge pour les ascendants <br> à 75 ans.</li>
                                                <li>Pour la maternité, la bénéficiaire doit attester ne pas être déjà en état de grossesse au moment de la souscription</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    </el-collapse>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                     <el-col :span="8">
                        <div>
                           <el-card style="width: 100%">
                                <div slot="header" class="clearfix">
                                    <span>Pack Confort - 90%</span>
                                    <el-button style="float:right;margin-top:-7px!important" type="primary" round @click="choix3()">Choisir</el-button>
                                </div>
                                <div class="text item">
                                    <el-collapse v-model="DetailsListThirst">
                                    <el-collapse-item title="Avantages" name="1">
                                        <div>
                                            <ul>
                                                <li>Couverture incluant les consultations, les frais médicaux et pharmaceutiques, la radiologie, les analyses,
                                                    <br>les frais d'hospitalisation, <br>les soins dentaires, les frais d'optique, la maternité <br>
                                                    <br>les auxiliaires médicaux et le transport par ambulance,</li>
                                                <li>Un plafond annuel de couverture allant jusqu'à 8 000 000 FCFA par assuré,</li>
                                                <li>Une carte de santé par assuré pour <br>une utilisation autonome, </li>
                                                <li>Un large réseau de prestataires de santé.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="Conditions particulieres" name="2">
                                        <div>
                                           <ul>
                                                <li>Couverture valable pour un an et <br>par personne agé(e) de moins de 60 ans</li>
                                                <li>Questionnaire médical à renseigner</li>
                                                <li>Pour la maternité, la bénéficiaire doit attester ne pas être déjà en état de grossesse au moment de la souscription.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    </el-collapse>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
            </el-row>
            </div>
        </div>

         <div v-if="e1 == 1 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <h3>Choix Pack :</h3>
                 <el-row :gutter="15">
                      <el-col :span="24">
                        <div>
                           <el-card style="width: 100%">
                                <div slot="header" class="clearfix">
                                    <span>Pack Essentiel - 70%</span>
                                </div>
                                <div class="text item">
                                    <el-collapse v-model="DetailsListFirst">
                                    <el-collapse-item title="Avantages" name="1">
                                        <div>
                                            <ul>
                                                <li>Couverture incluant les consultations, les frais médicaux et pharmaceutiques, la radiologie, les analyses,
                                                    <br> les frais d'hospitalisation,</li>
                                                <li>Tarif accessible et forfaitaire par assuré,</li>
                                                <li>Plafond annuel de couverture de <br> 2 000 000 FCFA par assuré,</li>
                                                <li>Une carte de santé par assuré pour une utilisation autonome,</li>
                                                <li>Un large réseau de prestataires de santé.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="Conditions particulieres" name="2">
                                        <div>
                                            <ul>
                                                <li>Hospitalisation uniquement dans les hôpitaux du Sénégal,</li>
                                                <li>Couverture valable pour un an et par personne agé(e) de moins de 60 ans,</li>
                                                <li>Tarif forfaitaire annuel,</li>
                                                <li>Questionnaire médical à renseigner.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <span class="mt-1" style="font-size:12px;position:absolute">10 pers. minimum</span>
                                    </el-collapse>
                                </div>
                                 <div style="margin-top:20px">
                                    <el-button type="primary" round @click="choix()">Choisir</el-button>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div>
                           <el-card style="width: 100%">
                                <div slot="header" class="clearfix">
                                    <span>Pack Sérénité - 80%</span>
                                </div>
                                <div class="text item">
                                    <el-collapse v-model="DetailsListSecond">
                                    <el-collapse-item title="Avantages" name="1">
                                        <div>
                                            <ul>
                                                <li>Couverture incluant les consultations, les frais médicaux et pharmaceutiques, la radiologie, les analyses, les frais d'hospitalisation, les auxiliaires médicaux et
                                                    <br>le transport par ambulance,</li>
                                                <li>les soins dentaires, frais d'optique et de maternité</li>
                                                <li>Tarif attractif forfaitaire par assuré,</li>
                                                <li>Plafond annuel de couverture jusqu'à <br>5 000 000 FCFA par assuré,</li>
                                                <li>Une carte de santé par assuré pour une utilisation autonome,</li>
                                                <li>Un large réseau de prestataires de santé,</li>
                                                <li>La possibilité, à partir de 4 assurés, de souscrire pour les ascendants.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="Conditions particulieres" name="2">
                                        <div>
                                            <ul>
                                                <li>Couverture valable pour un an et par personne agé(e) de moins de 60 ans,</li>
                                                <li>Tarif forfaitaire annuel,</li>
                                                <li>Questionnaire médical à renseigner,</li>
                                                <li>Limite d'âge pour les ascendants <br> à 75 ans.</li>
                                                <li>Pour l'option maternité, la bénéficiaire doit attester ne pas être déjà en état de grossesse au moment de la souscription</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    </el-collapse>
                                </div>
                                <div style="margin-top:15px">
                                    <el-button type="primary" round @click="choix2()">Choisir</el-button>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
                     <el-col :span="24" style="margin-top:15px!important">
                        <div>
                           <el-card style="width: 100%">
                                <div slot="header" class="clearfix">
                                    <span>Pack Confort - 90%</span>
                                </div>
                                <div class="text item">
                                    <el-collapse v-model="DetailsListThirst">
                                    <el-collapse-item title="Avantages" name="1">
                                        <div>
                                            <ul>
                                                <li>Couverture incluant les consultations, les frais médicaux et pharmaceutiques, la radiologie, les analyses,
                                                    <br>les frais d'hospitalisation, <br>les soins dentaires, les frais d'optique, la maternité <br>
                                                    <br>les auxiliaires médicaux et le transport par ambulance,</li>
                                                <li>Un plafond annuel de couverture allant jusqu'à 8 000 000 FCFA par assuré,</li>
                                                <li>Une carte de santé par assuré pour <br>une utilisation autonome, </li>
                                                <li>Un large réseau de prestataires de santé.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="Conditions particulieres" name="2">
                                        <div>
                                           <ul>
                                                <li>Couverture valable pour un an et <br>par personne agé(e) de moins de 60 ans</li>
                                                <li>Questionnaire médical à renseigner</li>
                                                <li>Pour la maternité, la bénéficiaire doit attester ne pas être déjà en état de grossesse au moment de la souscription.</li>
                                            </ul>
                                        </div>
                                    </el-collapse-item>
                                    </el-collapse>
                                </div>
                                 <div style="margin-top:15px">
                                  <el-button type="primary" round @click="choix3()">Choisir</el-button>
                                </div>
                            </el-card>
                        </div>
                    </el-col>
            </el-row>
            </div>
        </div>

        <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'adulte(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="5">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteHomme" readonly>
                                <el-button @click="decrementAdulteHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteFemme" readonly>
                                <el-button @click="decrementAdulteFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="adulteTotal" readonly>
                                <template slot="append">adulte(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Prix</label>
                            <el-input placeholder="Entrez quelque chose" :value="prixNetforAdulte.toLocaleString()" readonly>
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'enfant(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="5">
                        <div class="grid-content bg-purple">
                            <label>Garçon(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantGarcon" readonly>
                                <el-button @click="decrementEnfantGarcon()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantGarcon()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Fille(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantFille" readonly>
                                <el-button @click="decrementEnfantFille()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantFille()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="enfantTotal" readonly>
                                <template slot="append">enfant(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Prix</label>
                            <el-input placeholder="Entrez quelque chose" :value="prixNetforEnfant.toLocaleString()" readonly>
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider v-if="isSenior"></el-divider>
                <h3 style="font-weight:400;font-size: 20px;" v-if="isSenior">Nombre de sénior(s)</h3>
                <el-row :gutter="20" v-if="isSenior" style="margin-top:-20px">
                    <el-col :span="5">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorHomme" readonly>
                                <el-button @click="decrementSeniorHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorFemme" readonly>
                                <el-button @click="decrementSeniorFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="seniorTotal" readonly>
                                <template slot="append">sénior(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content bg-purple-light">
                            <label>Prix</label>
                            <el-input placeholder="Entrez quelque chose" :value="prixNetforSenior" readonly>
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider v-if="this.pack == 'serenite' || this.pack == 'confort'"></el-divider>
                <h3 style="font-weight:400;font-size: 20px;" v-if="this.pack == 'serenite' || this.pack == 'confort'">Zone de couverture</h3>
                <div v-if="this.pack == 'serenite' || this.pack == 'confort'" style="margin-top:-20px">
                    <el-radio @change="calculPrimeZone()" v-for="item in zoneList" :key="item" v-model="zone" :label="item.value"><span style="position:relative;float:right">{{item.name}} <br> <span class="prix_zone">{{ item.prix.toLocaleString() }} CFA</span></span></el-radio>
                </div>
                <el-divider></el-divider>
                <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px">
                                    <el-descriptions-item label="Pack"><span style="color:#ffa409" v-if="pack == 'essentiel'">Essentiel 70%</span><span style="color:#ffa409" v-if="pack == 'serenite'">Sérénité 80%</span><span style="color:#ffa409" v-if="pack == 'confort'">Confort 90%</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                    <el-descriptions-item label="Nombre d'assurés"><span style="color:#ffa409">{{AllAssure}} personne(s)</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="prixNetTotal !== null" label="Prime TTC"><span style="color:#ffa409">{{ttc.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="BacktoFirstStep()" round>Retour</el-button>
                <el-button @click="nextStepToOption()" type="primary" round>Suivant</el-button>
            </div>
        </div>

         <div v-if="e1 == 2 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div>
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'adulte(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteHomme" readonly>
                                <el-button @click="decrementAdulteHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreAdulteFemme" readonly>
                                <el-button @click="decrementAdulteFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementAdulteFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="adulteTotal" readonly>
                                <template slot="append">adulte(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Prix</label>
                            <el-input placeholder="Entrez quelque chose" :value="prixNetforAdulte.toLocaleString()" readonly>
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                
                <!-- <h3>Nombre d'enfant(s) :</h3> -->
                <h3 style="font-weight:400;font-size: 20px;">Nombre d'enfant(s)</h3>
                <el-row :gutter="20" style="margin-top:-20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Garçon(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantGarcon" readonly>
                                <el-button @click="decrementEnfantGarcon()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantGarcon()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Fille(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreEnfantFille" readonly>
                                <el-button @click="decrementEnfantFille()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementEnfantFille()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="enfantTotal" readonly>
                                <template slot="append">enfant(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Prix</label>
                            <el-input placeholder="Entrez quelque chose" :value="prixNetforEnfant.toLocaleString()" readonly>
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <!-- <h3 v-if="isSenior">Nombre de sénior(s)</h3> -->
                <el-divider v-if="isSenior"></el-divider>
                <h3 style="font-weight:400;font-size: 20px;" v-if="isSenior">Nombre d'adulte(s)</h3>
                <el-row :gutter="20" v-if="isSenior" style="margin-top:-20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Homme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorHomme" readonly>
                                <el-button @click="decrementSeniorHomme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorHomme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Femme(s)</label>
                            <el-input placeholder="Entrez quelque chose" :value="nbreSeniorFemme" readonly>
                                <el-button @click="decrementSeniorFemme()" slot="prepend" icon="el-icon-minus"></el-button>
                                <el-button @click="incrementSeniorFemme()" slot="append" icon="el-icon-plus"></el-button>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Total</label>
                            <el-input placeholder="Entrez quelque chose" :value="seniorTotal" readonly>
                                <template slot="append">sénior(s)</template>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Prix</label>
                            <el-input placeholder="Entrez quelque chose" :value="prixNetforSenior" readonly>
                                <template slot="append">CFA</template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-divider v-if="this.pack == 'serenite' || this.pack == 'confort'"></el-divider>
                <h3 style="font-weight:400;font-size: 20px;" v-if="this.pack == 'serenite' || this.pack == 'confort'">Zone de couverture</h3>
                <div v-if="this.pack == 'serenite' || this.pack == 'confort'" style="margin-top:-20px;display:grid">
                    <el-radio @change="calculPrimeZone()" v-for="item in zoneList" :key="item" v-model="zone" :label="item.value"><span style="position:relative">{{item.name}} <br> <span class="prix_zone">{{ item.prix.toLocaleString() }} CFA</span></span></el-radio>
                </div>

                <el-divider></el-divider>
                <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px">
                                    <el-descriptions-item label="Pack"><span style="color:#ffa409" v-if="pack == 'essentiel'">Essentiel 70%</span><span style="color:#ffa409" v-if="pack == 'serenite'">Sérénité 80%</span><span style="color:#ffa409" v-if="pack == 'confort'">Confort 90%</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px">
                                    <el-descriptions-item label="Nombre d'assurés"><span style="color:#ffa409">{{AllAssure}} personne(s)</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px">
                                <el-descriptions-item v-if="prixNetTotal !== null" label="Total Pack(s)"><span style="color:#ffa409">{{prixNetTotal.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div style="margin-top:15px">
                <el-button style="float:left" @click="BacktoFirstStep()" round>Retour</el-button>
                <el-button @click="nextStepToOption()" type="primary" round>Suivant</el-button>
            </div>
        </div>

        <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
               <h3>Souscripteur :</h3>
               <el-row :gutter="20">
                    <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Sexe</label>
                         <div class="grid-content bg-purple">
                            <el-radio v-model="ContractantFields.Genre" label="homme">Monsieur</el-radio>
                            <el-radio v-model="ContractantFields.Genre" label="femme">Madame</el-radio>
                            <el-radio v-model="ContractantFields.Genre" value="entreprise">Entreprise</el-radio>

                        </div>
                    </div>
                    </el-col>
                    <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Situation</label>
                         <div class="grid-content bg-purple">
                            <el-radio v-model="ContractantFields.Situation" label="marié">Marié(e)</el-radio>
                            <el-radio v-model="ContractantFields.Situation" label="célibataire">Célibataire</el-radio>
                        </div>
                    </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="ContractantFields.Nom"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                        <label>Prénom</label>
                        <el-input placeholder="Votre prénom" v-model="ContractantFields.Prenom"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                        <label>Date de naissance</label>
                        <el-date-picker
                            style="width: 100%!important;"
                            @change="yearsDiffContractant()"
                            v-model="ContractantFields.dateNaissance"
                            type="date"
                            placeholder="Choississez une date"
                            :picker-options="dateBeforeToday">
                        </el-date-picker>
                    </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                        <label>Lieu de naissance</label>
                        <el-input placeholder="Votre lieu de naissance" v-model="ContractantFields.LieuNaissance"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                        <label>Numéro de téléphone</label>
                        <el-input type="number" placeholder="Votre numéro de tél" v-model="ContractantFields.Telephone"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="8">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="Votre adresse e-mail" v-model="ContractantFields.Email"></el-input>
                    </div>
                    </el-col>
                </el-row>

                 <div style="margin-top:15px">
                <el-button style="float:left" @click="BacktoSecondStep()" round>Retour</el-button>
                <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
            </div>
           </div>
        </div>

         <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
               <h3>Souscripteur :</h3>
               <el-row :gutter="20">
                    
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="24">
                    <div class="grid-content bg-purple-light">
                        <label>Sexe</label>
                         <div class="grid-content bg-purple">
                            <el-radio v-model="ContractantFields.Genre" label="homme">Monsieur</el-radio>
                            <el-radio v-model="ContractantFields.Genre" label="femme">Madame</el-radio>
                            <el-radio v-model="ContractantFields.Genre" value="entreprise">Entreprise</el-radio>
                        </div>
                    </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple-light">
                        <label>Situation</label>
                         <div class="grid-content bg-purple">
                            <el-radio v-model="ContractantFields.Situation" label="marié">Marié(e)</el-radio>
                            <el-radio v-model="ContractantFields.Situation" label="célibataire">Célibataire</el-radio>
                        </div>
                    </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple-light">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="ContractantFields.Nom"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:14px!important">
                    <div class="grid-content bg-purple-light">
                        <label>Prénom</label>
                        <el-input placeholder="Votre prénom" v-model="ContractantFields.Prenom"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:14px!important">
                    <div class="grid-content bg-purple-light">
                        <label>Date de naissance</label>
                        <el-date-picker
                            style="width: 100%!important;"
                            @change="yearsDiffContractant()"
                            v-model="ContractantFields.dateNaissance"
                            type="date"
                            placeholder="Choississez une date"
                            :picker-options="dateBeforeToday">
                        </el-date-picker>
                    </div>
                    </el-col>
                     <el-col :span="24" style="margin-top:14px!important">
                    <div class="grid-content bg-purple-light">
                        <label>Lieu de naissance</label>
                        <el-input placeholder="Votre lieu de naissance" v-model="ContractantFields.LieuNaissance"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:14px!important">
                    <div class="grid-content bg-purple-light">
                        <label>Numéro de téléphone</label>
                        <el-input type="number" placeholder="Votre numéro de tél" v-model="ContractantFields.Telephone"></el-input>
                    </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:14px!important">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="Votre adresse e-mail" v-model="ContractantFields.Email"></el-input>
                    </div>
                    </el-col>
                </el-row>

                 <div style="margin-top:15px">
                <el-button style="float:left" @click="BackToSecondStep()" round>Retour</el-button>
                <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
            </div>
           </div>
        </div>

        <div v-if="e1 == 6 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
                <h3 style="font-weight:400;font-size: 20px;">Détails de l'assurance</h3>
                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <h3 style="font-weight:400;font-size: 20px;">Souscripteur</h3>
                            <!-- <label>Souscripteur</label> -->
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top: -20px;">
                                <li style="list-style: none;font-size:14px">Genre :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Genre}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Situation :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Situation}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Nom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Nom}}</span>
                                </li>


                                <li style="list-style: none;font-size:14px">Prénom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Prenom}}</span>
                                </li>

                                <li  style="list-style: none;font-size:14px">Date de naissance :
                                    <span class="font-weight-bold to-right">{{DateFormater(ContractantFields.dateNaissance)}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Lieu de naissance :
                                    <span class="font-weight-bold to-right">{{ContractantFields.LieuNaissance}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Téléphone :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Telephone}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Email :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Email}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <h3 style="font-weight:400;font-size: 20px;">Assuré(s)</h3>
                            <!-- <label>Assuré(s)</label> -->
                            <ul class="to-left mb-3 policeLato" style="margin-left:-40px;margin-top: -20px;">
                                <p class="justify-center row font-weight-bold" style="color: #ffa409;font-size: 20px;margin-left:0px;" v-if="this.adulteTotal > 0">Adulte(s)</p>
                                <li class="font-weight-bold subheading" style="list-style: none;margin-top: -20px" v-if="this.nbreAdulteHomme > 0">Homme(s) :
                                    <span class="to-right">{{nbreAdulteHomme}}</span>
                                </li>
                                <li class="font-weight-bold subheading" v-if="this.nbreAdulteFemme > 0" style=";margin-left:0px;list-style: none;">Femme(s) :
                                    <span class="to-right">{{nbreAdulteFemme}}</span>
                                </li>

                                <p class="justify-center row font-weight-bold" style="color: #ffa409;margin-left:0px;font-size: 20px" v-if="this.enfantTotal > 0">Enfant(s)</p>

                                <li class="font-weight-bold subheading" style=";margin-left:0px;list-style: none;margin-top: -20px" v-if="this.nbreEnfantGarcon > 0">Garçon(s) :
                                    <span class="to-right">{{nbreEnfantGarcon}}</span>
                                </li>
                                <li class="font-weight-bold subheading" style=";margin-left:0px;list-style: none" v-if="this.nbreEnfantFille > 0">Fille(s) :
                                    <span class="to-right">{{nbreEnfantFille}}</span>
                                </li>

                                <p v-if="isSenior" class="justify-center row font-weight-bold" style="color: #ffa409;font-size: 20px">Sénior(s)</p>
                                <li v-if="isSenior" class="font-weight-bold subheading" style="margin-left:0px;list-style:none;margin-top: -20px">Homme(s) sénior :
                                    <span class="to-right">{{nbreSeniorHomme}}</span>
                                </li>
                                <li v-if="isSenior" style="margin-left:0px;list-style: none;" class="font-weight-bold subheading">Femme(s) sénior :
                                    <span class="to-right">{{nbreSeniorFemme}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-light">
                            <h3 style="font-weight:400;font-size: 20px;">Garanties</h3>
                            <!-- <label>Garanties</label> -->
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top: -20px;">
                                <!-- <p class="justify-center row font-weight-bold" style="color: #ffa409;font-size: 20px">Pack choisi</p> -->
                                <li style="list-style: none;font-size:14px">Nom Pack :
                                    <!--<span class="to-right">{{pack}}</span>-->
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'essentiel'">{{packBasic.nomPack}}</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'serenite'">{{packEssentiel.nomPack}}</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'confort'">{{packConfort.nomPack}}</span>
                                </li>


                                <li style="list-style: none;font-size:14px">Prix de base /pers :
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'essentiel'">150 000</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'serenite'">290 000</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'confort'">370 000</span>
                                </li>

                                <li style="list-style: none;font-size:14px" v-if="frais !== null">Frais :
                                    <span class="font-weight-bold to-right">{{frais.toLocaleString()}} CFA</span>
                                </li>

                                <li style="list-style: none;font-size:14px" v-if="taxe !== null">Taxe :
                                    <span class="font-weight-bold to-right">{{taxe.toLocaleString()}} CFA</span>
                                </li>

                                <li style="list-style: none;font-size:14px" v-if="zone !== null">Zone :
                                    <span class="font-weight-bold to-right">{{zone}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px" v-if="ttc !== null">Prime ttc :
                                    <span class="font-weight-bold to-right">{{ttc.toLocaleString()}} CFA</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>

                <div>
                    <h3 style="font-weight:400;font-size: 20px;">Période de couverture</h3>
                    <!-- <h3>Période de couverture</h3> -->
                    <el-row :gutter="20" style="margin-top: -20px;">
                        <el-col :span="12">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div justify="center" style="margin-top:30px">
                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="24">
                            <el-descriptions style="font-size:20px">
                                    <el-descriptions-item label="Pack"><span style="color:#ffa409" v-if="pack == 'essentiel'">Essentiel 70%</span><span style="color:#ffa409" v-if="pack == 'serenite'">Sérénité 80%</span><span style="color:#ffa409" v-if="pack == 'confort'">Confort 90%</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                    <el-descriptions-item label="Nombre d'assurés"><span style="color:#ffa409">{{AllAssure}} personne(s)</span></el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions style="font-size:20px;margin-top:-10px">
                                <el-descriptions-item v-if="prixNetTotal !== null" label="Prime TTC"><span style="color:#ffa409">{{ttc.toLocaleString()}} CFA</span></el-descriptions-item>
                            </el-descriptions>
                        </el-col>
                    </el-row>
                </div>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="BackToStepAssure()">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="SendData()" type="primary" round>Valider</el-button>
                </div>
           </div>
        </div>

        <div v-if="e1 == 6 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
           <div>
               <h3 style="font-weight:400;font-size: 20px;">Détails de l'assurance :</h3>
                <el-row :gutter="20" style="margin-top: 15px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Souscripteur</label>
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top:5px;">
                                <li style="list-style: none;font-size:14px">Genre :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Genre}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Situation :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Situation}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Nom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Nom}}</span>
                                </li>


                                <li style="list-style: none;font-size:14px">Prénom :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Prenom}}</span>
                                </li>

                                <li  style="list-style: none;font-size:14px">Date de naissance :
                                    <span class="font-weight-bold to-right">{{DateFormater(ContractantFields.dateNaissance)}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Lieu de naissance :
                                    <span class="font-weight-bold to-right">{{ContractantFields.LieuNaissance}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Téléphone :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Telephone}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px">Email :
                                    <span class="font-weight-bold to-right">{{ContractantFields.Email}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Assuré(s)</label>
                            <ul class="to-left mb-3 policeLato" style="margin-left:-40px;margin-top: 5px;">
                                <p class="justify-center row font-weight-bold" style="color: #ffa409;font-size: 20px" v-if="this.adulteTotal > 0">Adulte(s)</p>
                                <li class="font-weight-bold subheading" v-if="this.nbreAdulteHomme > 0">Homme(s) :
                                    <span class="to-right">{{nbreAdulteHomme}}</span>
                                </li>

                               
                                <li class="font-weight-bold subheading" v-if="this.nbreAdulteFemme > 0" style="list-style: none;">Femme(s) :
                                    <span class="to-right">{{nbreAdulteFemme}}</span>
                                </li>


                                <p class="justify-center row font-weight-bold" style="color: #ffa409;font-size: 20px" v-if="this.enfantTotal > 0">Enfant(s)</p>

                                <li class="font-weight-bold subheading" v-if="this.nbreEnfantGarcon > 0">Garçon(s) :
                                    <span class="to-right">{{nbreEnfantGarcon}}</span>
                                </li>

                                <li class="font-weight-bold subheading" style="list-style: none" v-if="this.nbreEnfantFille > 0">Fille(s) :
                                    <span class="to-right">{{nbreEnfantFille}}</span>
                                </li>

                                <p v-if="isSenior" class="justify-center row font-weight-bold" style="color: #ffa409;font-size: 20px">Sénior(s)</p>
                                <li v-if="isSenior" class="font-weight-bold subheading">Homme(s) sénior :
                                    <span class="to-right">{{nbreSeniorHomme}}</span>
                                </li>

                                <li v-if="isSenior" class="font-weight-bold subheading">Femme(s) sénior :
                                    <span class="to-right">{{nbreSeniorFemme}}</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                        <div class="grid-content bg-purple-light">
                            <label>Garanties</label>
                            <ul class="to-left mb-3 policeLato" style="margin-left: -40px;margin-top:5px;">
                                <p class="justify-center row font-weight-bold" style="color: #ffa409;font-size: 20px">Pack choisi</p>
                                <li style="list-style: none;font-size:14px">Nom Pack :
                                    <!--<span class="to-right">{{pack}}</span>-->
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'essentiel'">{{packBasic.nomPack}}</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'serenite'">{{packEssentiel.nomPack}}</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'confort'">{{packConfort.nomPack}}</span>
                                </li>


                                <li style="list-style: none;font-size:14px">Prix de base /pers :
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'essentiel'">150 000</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'serenite'">290 000</span>
                                    <span class="font-weight-bold to-right" v-if="this.pack == 'confort'">370 000</span>
                                </li>
                                <li style="list-style: none;font-size:14px" v-if="frais !== null">Frais :
                                    <span class="font-weight-bold to-right">{{frais.toLocaleString()}} CFA</span>
                                </li>

                                <li style="list-style: none;font-size:14px" v-if="taxe !== null">Taxe :
                                    <span class="font-weight-bold to-right">{{taxe.toLocaleString()}} CFA</span>
                                </li>

                                <li style="list-style: none;font-size:14px" v-if="zone !== null">Zone :
                                    <span class="font-weight-bold to-right">{{zone}}</span>
                                </li>

                                <li style="list-style: none;font-size:14px" v-if="ttc !== null">Prime ttc :
                                    <span class="font-weight-bold to-right">{{ttc.toLocaleString()}} CFA</span>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>

                <div>
                    <h3 style="font-weight:400;font-size: 20px;">Période de couverture</h3>
                    <el-row :gutter="20" style="margin-top: 0px;">
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                        <el-col :span="24" style="margin-top:15px!important">
                            <div class="grid-content bg-purple">
                                <label>Date d'échéance</label>
                                <el-input disabled placeholder="Date d'échéance" v-model="dateEcheance">
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                 <div style="margin-top:15px">
                    <el-button style="float:left" round @click="BackToStepAssure()">Retour</el-button>
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="SendData()" type="primary" round>Valider</el-button>
                </div>
           </div>
        </div>

         <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

          <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
            <el-form ref="form" :model="valid" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px!important">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px!important">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px!important">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
                    <el-col :span="24" style="margin-top:15px!important">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>


    </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import pays from '../../../common/countries'
//fin importation assuraf
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
  if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }

};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire!"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
    name: "devis_voyage",
    
    data() {
        return {
                prixSenegal : 0,
                prixAfrique : 0,
                prixEurope : 0,
                prixMonde : 0,
                zone : null,
                zoneList: [
                    {
                        name: 'Sénégal',
                        value: 'Senegal',
                        prix : 0,
                        alt :'senegal'
                    },
                    {
                        name: 'Afrique',
                        value: 'Afrique',
                        prix : 0,
                        alt :'afrique'
                    },
                    {
                        name: 'Afrique + UE',
                        value: 'Europe',
                        prix : 0,
                        alt :'europe'
                    },
                    {
                        name: 'Monde (Hors USA)',
                        value: 'Monde',
                        prix : 0,
                        alt :'monde'
                    }
                ],
                modalgenerationloading : false,
                isMobile : false,
                DetailsListFirst : ['0'],
                DetailsListSecond : ['0'],
                DetailsListThirst : ['0'],
                pack: null,
                showAdulte : true,
                showEnfant : true,
                showSenior : true,
                ageAssure : null,
                disabled_checkbox : false,
                foundForHomme : null,
                foundForFemme : null,
                foundForGarcon : null,
                foundForFille : null,
                foundForHommeSenior : null,
                foundForFemmeSenior :null,
                basic_pack_price:null,
                dateEffet:null,
                dateEcheance:null,
                money: {
                    thousands: ' ',
                    precision: false,
                    masked: false,
                    suffix: ' CFA'
                },
                disabled_homme : false,
                disabled_femme : false,
                disabled_garcon : false,
                disabled_fille : false,
                disabled_homme_senior :false,
                disabled_femme_senior : false,
                totalHorsTaxe : 0,
                ttc : 0,
                pdfBasic : false,
                pdfEssentiel : false,
                pdfConfort : false,
                frais: 0,
                taxe : 0,
                disabled_dentaire : false,
                disabled_optique : false,
                flotte_mono: null,
                HideNbVehicule : true,
                nombreAssures : null,
                itemsFlotte: ['2', '3', '4'],
                valeur_venale :  null,
                immatriculation : null,
                nbAssureAdded : 0,
                ListAssure : [],
                prixTotalOptions : 0,
                AllAssure : 0,
                errors: {},
                activeStep: 0,
                isContractant: true,
                isAssure : false,
                // Contractant fields
                ContractantFields: {
                    Cni: null,
                    Genre: null,
                    Situation: null,
                    Nom: null,
                    Prenom: null,
                    dateNaissance: null,
                    LieuNaissance: null,
                    Telephone: null,
                    Email: null
                },
                // Assure fields
                AssureFields: {
                    Nom: null,
                    Prenom: null,
                    dateNaissance: null,
                    CNI :null,
                    age : null,
                },
                formSteps: [
                    {
                        title: "SOUSCRIPTEUR",
                        fields: [
                            { valid: true },
                        ]
                    },
                    {
                        title: "ASSURE(S)",
                        fields: [
                            { valid: true },
                        ]
                    }
                ],

                questions:[
                    {num:1, q:'votre taille'},
                    {num:2, q:'votre poids'},
                    {num:3, q:'votre nom'},
                    {num:4, q:'votre prenom'}
                ],
                customers:[
                    {
                        nom:'Mowen',
                        prenom:'Cleopas',
                        questions:[
                            {num:1, q:'votre taille', reponse:''},
                            {num:2, q:'votre poids', reponse:''},
                            {num:3, q:'votre nom', reponse:''},
                            {num:4, q:'votre prenom', reponse:''}
                        ]
                    },
                    {
                        nom:'Mowen',
                        prenom:'Melissa',
                        questions:[
                            {num:1, q:'votre taille', reponse:''},
                            {num:2, q:'votre poids', reponse:''},
                            {num:3, q:'votre nom', reponse:''},
                            {num:4, q:'votre prenom', reponse:''}
                        ]
                    },
                    {
                        nom:'Mowen',
                        prenom:'Berion',
                        questions:[
                            {num:1, q:'votre taille', reponse:''},
                            {num:2, q:'votre poids', reponse:''},
                            {num:3, q:'votre nom', reponse:''},
                            {num:4, q:'votre prenom', reponse:''}
                        ]
                    },
                    {
                        nom:'Mowen',
                        prenom:'Weenie',
                        questions:[
                            {num:1, q:'votre taille', reponse:''},
                            {num:2, q:'votre poids', reponse:''},
                            {num:3, q:'votre nom', reponse:''},
                            {num:4, q:'votre prenom', reponse:''}
                        ]
                    }
                ],
                fraisDentaire : null,
                fraisOptique: null,
                maternite : null,
                isMaternite : false,
                isDentaire : false,
                isOptique : false,

                prixDessusTrois: 100000,
                prixDessusTroisSenior : 225000,
                prixNetTotal : 0,
                firstMajor : 0,
                secondMajor : 0,
                thirstMajor : 0,
                fourtMajor : 0,
                isBasic : false,
                isEssentiel : false,
                isConfort : false,
                basic : null,
                serenite: null,
                confort: null,
                pack:null,
                packBasic : {
                    nomPack : 'Essentiel 70%',
                    prix: 150000,
                },
                packEssentiel : {
                    nomPack : 'Serenite 80%',
                    prix :  290000
                },
                packConfort : {
                    nomPack : 'Confort 90%',
                    prix: 370000
                },
                optionMaternite : {
                    num : 1,
                    nom : 'Maternite',
                    prix : 100000
                },
                optionDentaire : {
                    num : 2,
                    nom : 'Frais dentaire',
                    prix : 50000
                },
                optionOptique : {
                    num : 2,
                    nom : 'Frais optique',
                    prix : 50000
                },

                datePickerAssureBirth : false,
                dateNaissance : null,
                age : '',
                nbassure: null,
                ChangeAgeAssure: false,
                adulte_enfant: null,


                prixNetforAdulte: 0,
                prixNetforEnfant: 0,
                prixNetforSenior : 0,

                nbreAdulteHomme : 0,
                nbreAdulteFemme : 0,
                nbreEnfantGarcon : 0,
                nbreEnfantFille : 0,
                nbreSeniorHomme : 0,
                nbreSeniorFemme : 0,
                total : null,

                adulteTotal : null,
                enfantTotal :  null,
                seniorTotal :  null,

                isSenior: false,
                e1 : 0,
                isHidden : true,

                genre: null,
                elevation: 2,
                supplementPreventionSiHomme: 175000,
                supplementPreventionSiFomme: 57000,
                sagefemme: false,
                dialogSageFemme: false,
                btnnext: null,
                dialog1: false,
                dialog: false,
                testDialo: false,
                optionPrevention: false,
                showLoginForm: false,
                packChoisis: [],
                listPack: [],
                options : [],
                optionCardio: false,
                datePickerEffet: false,
                checkbox: false,
                payement: null,
                items: ['2', '3'],
          errors : {},
            dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            dateAfterDateDepart : {
                disabledDate(time) {
                    return time.getTime() > this.dateDepart;
                }
            },
            remboursable:null,
            optionsdateDepart: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },

            optionsdateArrivee: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            PassengerBirthOptionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            countriesSn: 'Sénégal',
            telephoneC: null,
            showId: false,
            nom: null,
            prenom: null,
            email: null,
            duree: null,
            profession:null,
            genre:null,
            datenaissance: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            emailclient: null,
            emailC: this.emailclient,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            hideForm: false,
            popupListePassager: false,
            steps: [{
                    label: 'Etape 1',
                    completed: false,
                },
                {
                    label: 'Etape 2',
                    completed: false,
                },
                {
                    label: 'Etape 3',
                    completed: false,
                },
            ],
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            adulte_enfant: null,
            seen: true,
            selected: [],
            nexted: false,
            selectpays: '',
            listpays: pays,
            dateDepart: null,
            dateEffet: null,
            dateEcheance: null,
            pays_domicile: null,
            dateArrivee: null,
            nbpassager: null,
            garantie: [],
            age: null,
            assureurs: [],
            listpaysprime: [],
            usersearch: [],
            telephoneclient: '',
            destination: null,
            listeAssureurs: [],
            listvoyage: [],
            listPassager: [],
            datenaissancePassager: null,
            enteteTabPassager: [{
                    key: 'adulte_enfant',
                    label: 'Adulte / Enfant'
                },
                {
                    key: 'age_passager',
                    label: 'Age passager'
                },
            ],

            items: [{
                    text: 'homme'
                },
                {
                    text: 'femme'
                },
            ],
         
            NumberInput : false,
            EmailInput : false,
            
            radio: '1',
           
       
           
            optionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            optionsdateMEC: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                maxDate: new Date(),
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            e1: 0,
           
            telephoneclient: null,
            emailclient: null,
            emailC: this.emailclient,
            telephoneC: this.telephoneclient,
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            showId: false,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            seen: true,
            hideDive_vehicule: null,
           
            password1: null,
           
            garanties: [],
            assureurs: [],
        
         
            voidField: false,
          
            Deb: null,
            selectedcarbu: '',
           
            types: [
                'text',
                'text',
                'number',
            ],
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            gridData: [],
            userRole : null
        }
    },
    mounted() {
        this.pays_domicile = this.countriesSn;
        this.getUserRoles()
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') 
        {
            this.getListePartenaire();
            this.duree = 1
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
   
    methods: {
        BacktoFirstStep(){
            for(let p of this.zoneList){
                p.prix = 0
            }
            this.zone = null;
            this.e1 = 1
        },
        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },

        init() {
        if (window.innerWidth <= 768) this.isMobile = true
        },
         setListassure_tostore(item){
                let listAssure = []
                for(let i of item){
                    listAssure.push({
                        nom: i.Nom,
                        prenom: i.Prenom,
                        datenaissance:i.DateNaissance,
                        numero_cni: i.CNI,
                        genre: i.Age,
                    })
                }
                return listAssure
        },

         SendData: function () {
            if (this.dateEffet) {
                let ASSURCARE = {
                    details : this.setListassure_tostore(this.ListAssure),
                    userid:this.usersearch._id,
                    createur:store.state.user._id,
                    Pack: this.pack,
                    Assure : {
                        Adulte : {
                            Homme : this.nbreAdulteHomme,
                            Femme : this.nbreAdulteFemme
                        },
                        Enfant : {
                            Garcon : this.nbreEnfantGarcon,
                            Fille : this.nbreEnfantFille,
                        },
                        Senior : {
                            Homme : this.nbreSeniorHomme,
                            Femme : this.nbreSeniorFemme
                        },
                        nombre_assures:  this.nbreAdulteHomme + this.nbreAdulteFemme + this.nbreEnfantGarcon + this.nbreEnfantFille + this.nbreSeniorHomme + this.nbreSeniorFemme
                    },
                    dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                    ListAssure:this.ListAssure,
                    dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
                    dateEcheance: this.dateEcheance,
                    MontantNet: this.prixNetTotal,
                    prime_ttc : this.ttc,
                    plateforme : 'Dash',
                    taxe: this.taxe,
                    accessoire : this.frais,
                    zone : this.zone
                }


                if (this.$store.state.user.role == 'Agent'){
                    ASSURCARE.courtierid = this.$store.state.user.societe
                }

                Api().post('/assurcare/add', ASSURCARE)
                    .then(result => {
                        Api().get('devis/' + result.data.devis._id)
                            .then(response => {
                            this.modalgenerationloading = true
                            this.$store.dispatch('setDevisPayement', response.data);
                             setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'paiement'
                                }))), 4000
                                );
                    })
                })


            } else {
                this.$message.error("La date d'effet est obligatoire !", this.AlertMessage)
            }
        },

        /*==========Fonction de nettoyage du formulaire apres recuperation des datas========*/
            ClearFormAssure: function () {
                if(this.checkbox == true){
                    this.disabled_checkbox = true
                }else if(this.checkbox == false){
                    this.disabled_checkbox = true
                }
                this.AssureFields.Nom = null,
                    this.AssureFields.Prenom = null ,
                    this.AssureFields.dateNaissance= null,
                    this.AssureFields.CNI = null
                this.AssureFields.age = null

            },

        toggleCheckbox() {
                if(this.checkbox == true){
                    this.AssureFields.Nom = this.ContractantFields.Nom
                    this.AssureFields.Prenom = this.ContractantFields.Prenom
                    this.AssureFields.dateNaissance = this.ContractantFields.dateNaissance
                    this.dateNaiss = this.ContractantFields.dateNaissance
                    this.disableDateAssure = true
                    this.yearsDiffAssure()
                }else if(this.checkbox == false){
                    this.disableDateAssure = false
                    this.ClearForm()
                }
            },

            /*=================Creation d'une liste de vehicules pour affichage Recap===============*/
            CreateListAssure () {
                if(this.AssureFields.Nom && this.AssureFields.Prenom && this.AssureFields.dateNaissance && this.AssureFields.age){
                    const Assure = {
                        Nom: this.AssureFields.Nom,
                        Prenom : this.AssureFields.Prenom,
                        DateNaissance : this.AssureFields.dateNaissance,
                        CNI : this.AssureFields.CNI,
                        Age :  this.AssureFields.age,
                        Homme : this.nbreAdulteHomme,
                        Femme : this.nbreAdulteFemme
                    };


                    if(this.AssureFields.age == 'garcon' || this.AssureFields.age == 'fille'){
                        if(this.ageAssure <= 21){
                            this.ListAssure.push(Assure);
                            this.getHommeAssure()
                            this.nbAssureAdded = this.nbAssureAdded + 1;

                            if (this.nombreAssures > 0) {
                                if (this.nbAssureAdded < this.nombreAssures) {
                                    this.$message.success('Ajouté avec succes !', this.AlertMessage)
                                    this.ClearFormAssure();
                                    this.disableDateAssure =  false
                                }
                                if (this.nbAssureAdded == this.nombreAssures) {
                                    this.e1 = 6
                                }
                            }else {
                                this.voidField = true
                            }
                        }else {
                            this.$message.error('Date de naissance choisie ne correspond pas à un enfant !', this.AlertMessage)
                        }
                    }else if(this.AssureFields.age == 'Adulte_homme' || this.AssureFields.age == 'Adulte_femme'){
                        if(this.ageAssure > 21 && this.ageAssure < 60){
                            this.ListAssure.push(Assure);
                            this.getHommeAssure()

                            this.nbAssureAdded = this.nbAssureAdded + 1;

                            if (this.nombreAssures > 0) {
                                if (this.nbAssureAdded < this.nombreAssures) {
                                    this.$message.success('Ajouté avec succes !', this.AlertMessage)
                                    this.ClearFormAssure();
                                    this.disableDateAssure =  false
                                }
                                if (this.nbAssureAdded == this.nombreAssures) {
                                    this.e1 = 6
                                }
                            }else {
                                this.voidField = true
                            }
                        }else {
                            this.$message.error('date de naissance choisie ne correspond pas à un adulte !', this.AlertMessage)
                        }
                    }else if(this.AssureFields.age == 'homme' || this.AssureFields.age == 'femme'){
                        if(this.ageAssure > 60 && this.ageAssure < 75){
                            this.ListAssure.push(Assure);
                            this.getHommeAssure()
                            this.nbAssureAdded = this.nbAssureAdded + 1;

                            if (this.nombreAssures > 0) {
                                if (this.nbAssureAdded < this.nombreAssures) {
                                    this.$message.success('Ajouté avec succes', this.AlertMessage)
                                    this.ClearFormAssure();
                                    this.disableDateAssure =  false
                                }
                                if (this.nbAssureAdded == this.nombreAssures) {
                                    this.e1 = 6
                                }
                            }else {
                                this.voidField = true
                            }
                        } else {
                            this.$message.error('Date de naissance choisie ne correspond pas un sénior !', this.AlertMessage)
                        }
                    }


                }else {
                    this.$message.error('Tous les champs sont obligatoires sauf CNI !', this.AlertMessage)
                }

            },

            // fonction pour formattage de Date (:-)
            DateFormater: function (TheDate) {
                if (TheDate) {
                    return dayjs(TheDate).format("DD-MM-YYYY");
                }
            },

            ClearForm: function () {
                this.AssureFields.Nom = null
                this.AssureFields.Prenom = null
                this.AssureFields.dateNaissance = null
                this.AssureFields.LieuNaissance = null
                this.AssureFields.age = null
            },

            DevisNextStep(){
                if (!store.state.isUserLoggedIn) {
                    this.showLoginForm = true
                } else if(store.state.isUserLoggedIn){
                    this.isContractant = true
                    this.ContractantFields.Nom = this.usersearch.nom
                    this.ContractantFields.Prenom = this.usersearch.prenom
                    this.ContractantFields.Telephone = this.usersearch.telephone_port
                    this.ContractantFields.Email = this.usersearch.email
                    this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                    this.ContractantFields.Genre = this.$store.state.user.sexe
                    this.ContractantFields.Situation = this.$store.state.user.statut_marital
                    this.ContractantFields.dateNaissance = this.dateNaiss

                    this.e1 = 4

                    this.calculTaxe()
                    this.calculTTC()
                    if(this.fraisDentaire){
                        this.options.push(this.optionDentaire)
                    }
                    if(this.fraisOptique){
                        this.options.push(this.optionOptique)
                    }
                    if(this.maternite){
                        this.options.push(this.optionMaternite)
                    }
                }
                this.ContractantFields.dateNaissance = this.usersearch.datenaissance
            },

            ContractControl() {
                this.errors = {}
                if(this.ContractantFields.Genre && this.ContractantFields.Situation){
                    if (this.ContractantFields.Nom && this.ContractantFields.Prenom) {
                        if(this.ContractantFields.dateNaissance && this.ContractantFields.LieuNaissance){
                            if(this.ContractantFields.Telephone && this.ContractantFields.Email){
                                // const validPhone = validatePhone(this.ContractantFields.Telephone);
                                // this.errors.phone = validPhone.error;

                                // const validEmail = validateEmail(this.ContractantFields.Email);
                                // this.errors.email = validEmail.error;
                                 this.e1 = 6
                                if(validEmail.valide == true && validPhone.valide == true){

                                   
                                    //this.nextStep();




                                }
                            }else {
                                this.$message.error('Téléphone et E-mail obligatoires !', this.AlertMessage)
                                this.animation = 'animate-wrong';
                                setTimeout(() => {
                                    this.animation = '';
                                }, 400);
                            }

                        }else {
                            this.$message.error('Date et Lieu de naissance obligatoires !', this.AlertMessage)
                        }
                    } else {
                        this.$message.error('Nom et Prénom obligatoires !', this.AlertMessage)
                    }
                }else {
                    this.$message.error('Genre et Situation obligatoires !', this.AlertMessage)
                }

            },

            nextStep2() {
                this.animation = 'animate-out';
                setTimeout(() => {
                    this.animation = 'animate-in';
                    this.isContractant = false,
                        this.isAssure = false,
                        this.e1 = 6
                }, 600);
            },

            AssureControl() {
                this.errors = {}
                if (this.AssureFields.Nom && this.AssureFields.Prenom && this.AssureFields.dateNaissance &&
                    this.AssureFields.age) {
                    this.nextStep2();
                }else {
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }
            },

            yearsDiffContractant() {
                const date1 = dayjs(this.ContractantFields.dateNaissance);
                const date2 = dayjs(new Date().toISOString().substr(0, 10));
                let daydiff = date2.diff(date1, 'days');
                this.age = daydiff;

                if (this.age <0) {
                    this.age = null;
                    this.$message.error('Erreur, date antérieure à la date actuelle !', this.AlertMessage)
                    this.ContractantFields.dateNaissance = null

                } else if (this.age) {
                    let year = Math.floor(this.age / 360);
                    this.age = year;
                    if(this.age < 18 || this.age <= 0){
                        this.$message.error("Erreur, l'age doit etre supperieur à 18 ans !", this.AlertMessage)
                        this.ContractantFields.dateNaissance = null
                    }
                }
                else if (this.age<=0) {
                    let year = Math.floor(this.age / 360);
                    this.age = year;
                    if(this.age < 18 || this.age <= 0){
                        this.$message.error("Erreur l'age doit etre supperieur à 18 ans !", this.AlertMessage)
                        this.ContractantFields.dateNaissance = null
                    }
                }
            },

            nextStep() {
                this.animation = 'animate-out';
                setTimeout(() => {
                    this.animation = 'animate-in';
                    this.activeStep += 1;
                    this.isContractant = false,
                        this.isAssure = true
                }, 600);
            },

            BackToStep1(){
                this.animation = 'animate-out-back';
                setTimeout(() => {
                    this.animation = 'animate-in-back';
                    this.e1 = 4;
                    this.activeStep -= 1;
                    this.isContractant = true,
                        this.isAssure = false
                }, 500);
            },

            uncheckMaternite (){
                if(this.fraisOptique == false && this.fraisDentaire == false){
                    this.maternite = false
                }
            },

            addOptionMaternite(){
                if(this.pack == 'serenite'){
                    if(this.maternite){
                        this.totalHorsTaxe = (this.optionMaternite.prix * this.nbreAdulteFemme) + this.prixNetTotal
                    }else{
                        this.totalHorsTaxe = this.prixNetTotal - this.optionMaternite.prix * this.nbreAdulteFemme
                    }
                }else if(this.pack == 'confort'){
                    if(this.maternite){
                        this.optionMaternite.prix = 150000
                        this.totalHorsTaxe = (this.optionMaternite.prix * this.nbreAdulteFemme) + this.prixNetTotal
                    }else {
                        this.totalHorsTaxe = this.prixNetTotal - this.optionMaternite.prix * this.nbreAdulteFemme
                    }

                }

                this.getPrixOption()
                this.calculPrimeHorsTaxe()
            },

            addOptionOptique(){

                if(this.pack == 'serenite'){
                    if(this.fraisOptique || this.fraisDentaire){
                        if(this.nbreAdulteFemme > 0 && this.nbreAdulteHomme > 0){
                            this.isMaternite = true
                        }else if(this.nbreAdulteFemme > 0 && this.nbreEnfantGarcon > 0){
                            this.isMaternite = true
                        }else if(this.nbreAdulteFemme > 0 && this.nbreEnfantFille > 0){
                            this.isMaternite = true
                        }
                        else {
                            this.isMaternite = false

                        }
                        let Totale = this.adulteTotal + this.enfantTotal + this.seniorTotal
                        this.totalHorsTaxe = this.prixNetTotal + this.optionOptique.prix * Totale
                    } else{
                        this.uncheckMaternite()
                        this.isMaternite = false
                        let Totale = this.adulteTotal + this.enfantTotal + this.seniorTotal
                        this.totalHorsTaxe = this.prixNetTotal - this.optionOptique.prix * Totale
                    }


                }
                this.getPrixOption()
                this.calculPrimeHorsTaxe()

            },

            addOptionDentaire(){

                if(this.pack == 'serenite'){
                    if(this.fraisDentaire || this.fraisOptique){
                        let Totale = this.adulteTotal + this.enfantTotal + this.seniorTotal
                        this.totalHorsTaxe = this.prixNetTotal + this.optionDentaire.prix * Totale
                        if(this.nbreAdulteFemme > 0 && this.nbreAdulteHomme > 0){
                            this.isMaternite = true
                        }else if(this.nbreAdulteFemme > 0 && this.nbreEnfantGarcon > 0){
                            this.isMaternite = true
                        }else if(this.nbreAdulteFemme > 0 && this.nbreEnfantFille > 0){
                            this.isMaternite = true
                        } else {
                            this.isMaternite = false
                        }
                    } else{
                        this.uncheckMaternite()
                        this.isMaternite = false
                        let Totale = this.adulteTotal + this.enfantTotal + this.seniorTotal
                        this.totalHorsTaxe = this.prixNetTotal - this.optionDentaire.prix * Totale
                    }
                }


                this.getPrixOption()
                this.calculPrimeHorsTaxe()
            },

            /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
            onlyNumber($event) {
                let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
                if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                    $event.preventDefault();
                }
            },

          yearsDiffAssure() {
                const date1 = dayjs(this.AssureFields.dateNaissance);
                const date2 = dayjs(new Date().toISOString().substr(0, 10));
                let daydiff = date2.diff(date1, 'days');
                this.ageAssure = daydiff;

                if (this.ageAssure < 0) {
                    this.ageAssure = null;
                    this.$message.error('Erreur, date antérieure à la date actuelle !', this.AlertMessage)
                    this.AssureFields.dateNaissance = null
                }
                if(this.pack == 'essentiel' || this.pack == 'serenite' || this.pack == "confort"){
                    if (this.ageAssure) {
                        let year = Math.floor(this.ageAssure / 360);
                        this.ageAssure = year;
                    }
                }
            },

            //calclu date echéance
            dateDiff: function () {
                if (this.dateEffet === '' && this.duree === '') this.dateEcheance = '';
                let inter = dayjs(this.dateEffet).add(1, 'year').format('YYYY-MM-DD');
                this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
            },

            calculTTC (){
                if(this.pack == 'essentiel'){
                    this.ttc = (this.prixNetTotal + this.frais + this.taxe)
                }else if(this.pack == 'serenite' || this.pack == 'confort'){
                    this.ttc = (this.prixNetTotal + this.frais + this.taxe)
                    for(let p of this.zoneList){
                        // ------------- Majoration Senegal ------------------
                        if(p.value == 'Senegal'){
                            p.prix = this.ttc
                            this.prixSenegal = p.prix
                        }
                        // ------------- Majoration Afrique ------------------
                        if(p.value == 'Afrique'){
                            p.prix = this.ttc + (this.ttc * 0.2)
                            this.prixAfrique = p.prix
                        }
                        // ------------- Majoration Europe ------------------
                        if(p.value == 'Europe'){
                            p.prix = this.ttc + (this.ttc * 0.3)
                            this.prixEurope = p.prix
                        }
                        // ------------- Majoration Monde ------------------
                        if(p.value == 'Monde'){
                            p.prix = this.ttc + (this.ttc * 0.5)
                            this.prixMonde = p.prix
                        }
                    }
                }
            },

            calculPrimeZone(){
                if(this.zone == 'Senegal'){
                    this.ttc = this.prixSenegal;
                } else if(this.zone == 'Afrique'){
                    this.ttc = this.prixAfrique;
                } else if(this.zone == 'Europe'){
                    this.ttc = this.prixEurope
                } else if(this.zone == 'Monde'){
                    this.ttc = this.prixMonde
                }
            },

            calculPrimeHorsTaxe(){
                this.totalHorsTaxe = this.prixNetTotal + this.prixTotalOptions
            },

            calculTaxe(){
                if(this.pack == 'essentiel'){
                    this.frais = 25000;
                    this.taxe = (this.prixNetTotal + this.frais) *0.14
                }else if(this.pack ==  'serenite' || this.pack ==  'confort'){
                    this.frais = 10000;
                    this.taxe = (this.prixNetTotal + this.frais) * 0.14
                }else {
                    this.frais = 0;
                    this.ttc = 0;
                    this.taxe = 0;
                }
            },

            openPdfBasic(){
                this.dialog = true,
                    this.pdfBasic = true,
                    this.pdfEssentiel = false
                this.pdfConfort = false
            },

            openPdfEssentiel(){
                this.dialog = true,
                    this.pdfBasic = false,
                    this.pdfEssentiel = true
                this.pdfConfort = false
            },

            openPdfConfort(){
                this.dialog = true,
                    this.pdfBasic = false,
                    this.pdfEssentiel = false
                this.pdfConfort = true
            },

            getPrixOption(){
                let Totale = this.adulteTotal + this.enfantTotal + this.seniorTotal
                let maternite = this.nbreAdulteFemme
                let firstOption = this.optionDentaire.prix * Totale
                let secondOption = this.optionOptique.prix * Totale

                let thirtOption = this.optionMaternite.prix * maternite
                let Total = firstOption + secondOption

                if(this.fraisOptique){
                    this.prixTotalOptions = secondOption
                }else if(this.fraisDentaire){
                    this.prixTotalOptions = firstOption
                }else if(this.maternite){
                    this.prixTotalOptions = thirtOption
                } else {
                    this.prixTotalOptions = 0
                }

                if(this.fraisDentaire && this.fraisOptique){
                    this.prixTotalOptions = Total
                }else if(this.fraisDentaire && this.maternite){
                    this.prixTotalOptions = firstOption + thirtOption
                }else if(this.fraisOptique && this.maternite){
                    this.prixTotalOptions = secondOption + thirtOption
                }

                if(this.fraisDentaire && this.fraisOptique && this.maternite){
                    this.prixTotalOptions = firstOption + secondOption + thirtOption
                }
            },


            getHommeAssure()
            {
                if(this.AssureFields.age == 'Adulte_femme'){
                    this.foundForFemme++
                }

                if(this.AssureFields.age == 'Adulte_homme') {
                    this.foundForHomme++
                }

                if(this.AssureFields.age == 'garcon') {
                    this.foundForGarcon++
                }

                if(this.AssureFields.age == 'fille') {
                    this.foundForFille++
                }

                if(this.AssureFields.age == 'homme') {
                    this.foundForHommeSenior++
                }

                if(this.AssureFields.age == 'femme'){
                    this.foundForFemmeSenior++
                }

                if(this.foundForHomme == this.nbreAdulteHomme){
                    this.disabled_homme = true
                }

                if(this.foundForFemme ==  this.nbreAdulteFemme){
                    this.disabled_femme = true
                }

                if(this.foundForGarcon ==  this.nbreEnfantGarcon){
                    this.disabled_garcon = true
                }

                if(this.foundForFille ==  this.nbreEnfantFille){
                    this.disabled_fille = true
                }

                if(this.foundForHommeSenior == this.nbreSeniorHomme){
                    this.disabled_homme_senior = true
                }

                if(this.foundForFemmeSenior == this.nbreSeniorFemme){
                    this.disabled_femme_senior = true
                }

            },


            /*Passenger Age calculation based on passenger birthday*/
            PassengerAgeCalculation() {
                let TodayDate = new Date()
                this.age = dayjs(TodayDate).diff(this.dateNaissance, 'year')

                let PassengerObject = {
                    dateNaissance: this.dateNaissance,
                    age: this.age
                }

                localStorage.setItem('passenger-birthady-age', JSON.stringify(PassengerObject))
            },

            PassengerAgeChange() {
                let Unpassager = {};
                if (this.listAssure.length === parseInt(this.nbassure)) {

                    if (this.age < 18) {
                        Unpassager.adulte_enfant = 'Enfant'
                    } else if (this.age >= 18) {
                        Unpassager.adulte_enfant = 'Adulte'
                    }

                    if (this.age != Unpassager.age_assure) {
                        Unpassager.age_assure = this.age
                        Unpassager.dateNaissance = this.dateNaissance
                        this.listAssure.pop(Unpassager)
                        this.listAssure.push(Unpassager)
                        this.age = this.age
                        this.e1 = 5
                    }
                }
            },

            choix(){
                this.pack = 'essentiel'
                this.e1 = 2
                this.dialog1 =  false
                this.options = []
                this.prixNetforAdulte = 0
                this.prixNetforEnfant = 0
                this.prixNetforSenior = 0
                this.nbreAdulteHomme = 0
                this.nbreAdulteFemme = 0

                this.adulteTotal = 0
                this.enfantTotal = 0
                this.seniorTotal = 0

                this.prixNetTotal = 0

                this.nbreEnfantGarcon = 0
                this.nbreEnfantFille = 0
                this.total = 0
                this.isSenior = false
                this.ttc = 0
                this.isBasic = true
                this.isEssentiel = false
                this.isConfort = false
                this.AllAssure = 0
                this.disabled_dentaire = false,
                    this.disabled_optique = false
            }, //Choix pour le pack Basic

            choix2(){
                this.pack = 'serenite'
                this.options = []

                this.e1 = 2
                this.prixNetforAdulte = 0
                this.prixNetforEnfant = 0
                this.prixNetforSenior = 0
                this.nbreAdulteHomme = 0
                this.nbreAdulteFemme = 0
                this.options = []
                this.isMaternite = false
                this.nbreEnfantGarcon = 0
                this.nbreEnfantFille = 0

                this.adulteTotal = 0
                this.enfantTotal = 0
                this.seniorTotal = 0
                this.total = 0
                this.prixNetTotal = 0
                this.ttc = 0
                this.isSenior = false

                this.isBasic = false
                this.isEssentiel = true
                this.isConfort = false
                this.AllAssure = 0
                this.disabled_dentaire = false,
                    this.disabled_optique = false
            }, //Choix pour le pack Essentiel

            choix3(){
                this.pack = 'confort'
                this.options = []

                this.e1 = 2
                this.prixNetforAdulte = 0
                this.prixNetforEnfant = 0
                this.prixNetforSenior = 0
                this.nbreAdulteHomme = 0
                this.nbreAdulteFemme = 0

                this.adulteTotal = 0
                this.enfantTotal = 0
                this.seniorTotal = 0
                this.prixNetTotal = 0

                this.nbreEnfantGarcon = 0
                this.nbreEnfantFille = 0
                this.total = 0
                this.ttc = 0
                this.isSenior = false

                this.isBasic = false
                this.isEssentiel = false
                this.isConfort = true
                this.AllAssure = 0
                this.disabled_dentaire = false,
                    this.disabled_optique = false
            }, //Choix pour le pack Confort

            /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'adultes hommes  ----------- */
            incrementAdulteHomme () {
                let Assure
                Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal + 1
                this.AllAssure =  Assure

                this.nbreAdulteHomme++
                this.CalculAllAdulte()
                this.ShowOrNotSénior()
            },

            decrementAdulteHomme () {
                if(this.nbreAdulteHomme === 0 || this.nbreAdulteHomme === null) {
                    this.$message.error("Nombre d'homme ne peut pas etre négatif !", this.AlertMessage)
                    this.nbreAdulteHomme = 0
                    if(this.nbreAdulteHomme == null && this.nbreAdulteFemme == null){
                        this.adulteTotal = null
                    }

                } else {
                    let Assure
                    Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                    this.AllAssure =  Assure

                    this.nbreAdulteHomme--
                    this.CalculAllAdulte()
                    this.ShowOrNotSénior()
                }
            },

            /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'adultes femmes  ----------- */
            incrementAdulteFemme () {
                let Assure
                Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal + 1
                this.AllAssure =  Assure
                this.nbreAdulteFemme++
                this.CalculAllAdulte()
                this.ShowOrNotSénior()
            },

            decrementAdulteFemme () {
                if(this.nbreAdulteFemme === 0 || this.nbreAdulteFemme === null) {
                    this.$message.error("Nombre de femme ne peut pas étre négatif !", this.AlertMessage)
                    if(this.nbreAdulteHomme == null && this.nbreAdulteFemme == null){
                        this.adulteTotal = null
                    }
                } else {
                    let Assure
                    Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                    this.AllAssure =  Assure
                    this.nbreAdulteFemme--
                    this.CalculAllAdulte()
                    this.ShowOrNotSénior()

                }
            },

            /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'enfants garcons  ----------- */
            incrementEnfantGarcon () {
                let Assure
                Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal + 1
                this.AllAssure =  Assure
                this.nbreEnfantGarcon++
                this.CalculAllEnfant()
                this.ShowOrNotSénior()
            },

            decrementEnfantGarcon () {
                if(this.nbreEnfantGarcon === 0 || this.nbreEnfantGarcon === null) {
                    this.$message.error("Nombre de garçn ne peut pas étre négatif !", this.AlertMessage)
                    if(this.nbreEnfantGarcon == null && this.nbreEnfantFille == null){
                        this.enfantTotal = null
                    }
                    this.nbreEnfantGarcon = 0
                } else {
                    let Assure
                    Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                    this.AllAssure =  Assure
                    this.nbreEnfantGarcon--
                    this.CalculAllEnfant()
                    this.ShowOrNotSénior()
                }
            },

            /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre d'enfants filles  ----------- */
            incrementEnfantFille () {
                let Assure
                Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal + 1
                this.AllAssure =  Assure
                this.nbreEnfantFille++
                this.CalculAllEnfant()
                this.ShowOrNotSénior()
                //this.calculTaxe()
                //this.calculTTC()
            },

            decrementEnfantFille () {
                if(this.nbreEnfantFille === 0 || this.nbreEnfantFille === null) {
                    this.$message.error("Nombre de fille ne peut pas étre négatif !", this.AlertMessage)
                    if(this.nbreEnfantGarcon == null && this.nbreEnfantFille == null){
                        this.enfantTotal = null
                    }
                } else {
                    let Assure
                    Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                    this.AllAssure =  Assure
                    this.nbreEnfantFille--
                    this.CalculAllEnfant()
                    this.ShowOrNotSénior()
                }
            },

            /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre de senior hommes  ----------- */
            incrementSeniorHomme () {
                let Assure
                Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal + 1
                this.AllAssure =  Assure
                this.nbreSeniorHomme++
                if(this.nbreSeniorHomme <= 2){
                    this.CalculAllSenior()
                    //this.calculTaxe()
                    //this.calculTTC()
                }else if(this.nbreSeniorHomme >2){
                    this.nbreSeniorHomme = 2
                    this.AllAssure =  Assure -1
                    this.CalculAllSenior()
                    //this.calculTaxe()
                    //this.calculTTC()
                    this.$message.error('Impossible de choisir plus de 2 hommes séniors !', this.AlertMessage)
                }
                /*else {
                    this.CalculAllSenior()
                }*/

            },

            decrementSeniorHomme () {
                if(this.nbreSeniorHomme === 0 || this.nbreSeniorHomme === null) {
                    this.$message.error('Nombre de sénior homme ne peut pas étre négatif !', this.AlertMessage)
                    this.nbreSeniorHomme = 0
                    if(this.nbreSeniorHomme == null && this.nbreSeniorFemme == null){
                        this.seniorTotal = null
                    }
                } else {
                    let Assure
                    Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                    this.AllAssure =  Assure
                    this.nbreSeniorHomme--
                    this.CalculAllSenior()
                    //this.calculTaxe()
                    //this.calculTTC()
                }
            },

            /* --------- Fonctions qui gerent la decrementation et l'incrementation du nombre de senior femmes  ----------- */
            incrementSeniorFemme () {
                let Assure
                Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal + 1
                this.AllAssure =  Assure
                this.nbreSeniorFemme++
                if(this.nbreSeniorFemme <= 2){
                    this.CalculAllSenior()
                    //this.calculTaxe()
                    //this.calculTTC()
                }else if(this.nbreSeniorFemme >2){
                    this.nbreSeniorFemme = 2
                    this.AllAssure =  Assure -1
                    this.CalculAllSenior()
                    //this.calculTaxe()
                    //this.calculTTC()
                    this.$message.error('Impossible de choisir plus de 2 femmes séniors !', this.AlertMessage)
                }
            },

            decrementSeniorFemme () {
                if(this.nbreSeniorFemme === 0 || this.nbreSeniorFemme === null) {
                    this.$message.error('Nombre de sénior femme ne peut pas étre négatif !', this.AlertMessage)
                    this.nbreSeniorFemme = 0
                    if(this.nbreSeniorHomme == null && this.nbreSeniorFemme == null){
                        this.seniorTotal = null
                    }
                } else {
                    let Assure
                    Assure = this.adulteTotal + this.enfantTotal + this.seniorTotal - 1
                    this.AllAssure =  Assure
                    this.nbreSeniorFemme--
                    this.CalculAllSenior()
                    //this.calculTaxe()
                    //this.calculTTC()
                }
            },

            /* --------- Fonctions qui totalise le nombre d'adultes  ----------- */
            CalculAllAdulte(){
                let Adulte
                Adulte = this.nbreAdulteHomme + this.nbreAdulteFemme
                this.total =  Adulte
                if(this.pack == 'essentiel'){
                    this.prixNetforAdulte = this.packBasic.prix  * this.total
                }else if(this.pack == 'serenite'){
                    this.prixNetforAdulte = this.packEssentiel.prix * this.total
                }else if(this.pack == 'confort'){
                    this.packConfort.prix = 370000
                    this.prixNetforAdulte = this.packConfort.prix * this.total
                }

                this.adulteTotal = this.total
                this.ToPay()
            },

            /* --------- Fonctions qui totalise le nombre d'enfants  ----------- */
            CalculAllEnfant() {
                let Enfant
                Enfant = this.nbreEnfantFille + this.nbreEnfantGarcon
                this.total = Enfant
                this.enfantTotal = this.total
                /* --------- POUR LE PACK BASIC ENFANTS  ----------- */
                if (this.pack == 'essentiel') {
                    this.prixNetforEnfant = this.packBasic.prix * this.total
                    /* --------- POUR LE PACK ESSENTIEL ENFANTS  ----------- */
                } else if (this.pack == 'serenite') {
                    this.prixNetforEnfant = this.packEssentiel.prix * this.total
                    /* --------- POUR LE PACK CONFORT ENFANTS  ----------- */
                }else if(this.pack == 'confort'){
                    this.packConfort.prix = 270000
                    this.prixNetforEnfant = this.packConfort.prix * this.total
                }
                this.ToPay()
            },

            /* --------- Fonctions qui totalise le nombre de seniors  ----------- */
            CalculAllSenior(){
                const firstMajor = 290000;
                const secondMajor = 217500;
                const thirstMajor = 145000


                let Senior
                Senior = this.nbreSeniorFemme + this.nbreSeniorHomme
                this.total =  Senior
                this.seniorTotal = this.total
                /* --------- POUR LE PACK ESSENTIEL SENIOR  ----------- */
                if (this.pack == 'serenite') {
                    if (this.seniorTotal == 1) {
                        this.packEssentiel.prix = 290000 + firstMajor
                        this.prixNetforSenior = this.packEssentiel.prix
                    } else if (this.seniorTotal == 2) {
                        this.packEssentiel.prix = 290000 + secondMajor
                        this.prixNetforSenior = this.packEssentiel.prix + 580000

                    }else if(this.seniorTotal == 3){
                        this.packEssentiel.prix = 290000 + thirstMajor
                        this.prixNetforSenior = this.packEssentiel.prix + 1087500
                    }else if(this.seniorTotal == 4){
                        this.packEssentiel.prix = 290000 + thirstMajor
                        this.prixNetforSenior = this.packEssentiel.prix + 1522500
                    }else if(this.seniorTotal == 0){
                        this.packEssentiel.prix = 0
                        this.prixNetforSenior = this.packEssentiel.prix * this.total
                    }
                }
                this.ToPay()
            },

            ToPay(){
                if(this.pack != 'serenite'){
                    this.prixNetTotal = (this.prixNetforAdulte + this.prixNetforEnfant)
                    this.calculTaxe()
                    this.calculTTC()
                }else {
                    if(this.isSenior == false){
                        this.prixNetTotal = (this.prixNetforAdulte + this.prixNetforEnfant)
                        this.calculTaxe()
                        this.calculTTC()
                    }else if(this.isSenior == true){
                        this.prixNetTotal = (this.prixNetforSenior + this.prixNetforAdulte + this.prixNetforEnfant)
                        this.calculTaxe()
                        this.calculTTC()
                    }else {
                        this.prixNetTotal = (this.prixNetforAdulte + this.prixNetforEnfant)
                        this.calculTaxe()
                        this.calculTTC()
                    }
                }

            },

            /* --------- Fonctions qui affiche la partie senior si adulte = 2 et enfant = 2, ce qui fait 4  ----------- */
            ShowOrNotSénior(){
                if(this.pack == 'serenite'){
                    if(this.enfantTotal >= 2 && this.adulteTotal >= 2){
                        this.isSenior =  true
                    }else {
                        this.AllAssure = this.adulteTotal + this.enfantTotal
                        this.seniorTotal = 0
                        this.nbreSeniorHomme = 0
                        this.nbreSeniorFemme = 0
                        this.isSenior = false
                        this.prixNetforSenior = null
                    }
                }

            },

            BackToStepAssure(){
                this.animation = 'animate-out-back';
                setTimeout(() => {
                    this.animation = 'animate-in-back';
                    this.e1 = 3;
                    this.activeStep -= 1;
                    this.isContractant = true
                }, 500);
            },

            BacktoSecondStep(){
                this.e1 = 2;

            },

            /* --------- Fonctions qui affiche les donnees pour la partie recap  ----------- */
            nextStepToOption(){
                if(this.adulteTotal || this.enfantTotal){
                    this.basic_pack_price = this.prixNetTotal
                   if(this.pack == 'essentiel'){
                        if (!store.state.isUserLoggedIn) {
                            this.showLoginForm = true
                        } else if(store.state.isUserLoggedIn){
                            if(this.AllAssure >= 10){
                                this.isContractant = true
                                this.e1 = 3
                                this.ContractantFields.Nom = this.usersearch.nom
                                this.ContractantFields.Prenom = this.usersearch.prenom
                                this.ContractantFields.Telephone = this.usersearch.telephone_port
                                this.ContractantFields.Email = this.usersearch.email
                                this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                                this.ContractantFields.Genre = this.$store.state.user.sexe
                                this.ContractantFields.Situation = this.$store.state.user.statut_marital
                                this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                                this.AssureFields.dateNaissance = this.dateNaiss
                                this.calculTaxe()
                                this.calculTTC()
                            }else {
                                this.$message.error('Il vous faut 10 perssonnes pour ce parck !', this.AlertMessage)
                            }
                           
                        }
                        this.ContractantFields.dateNaissance = this.usersearch.datenaissance

                    }else if(this.pack == 'serenite'){
                            if (!store.state.isUserLoggedIn) {
                                this.showLoginForm = true
                            } else if(store.state.isUserLoggedIn){
                                if(this.zone != null){
                                    this.isContractant = true
                                    this.e1 = 3
                                    this.ContractantFields.Nom = this.usersearch.nom
                                    this.ContractantFields.Prenom = this.usersearch.prenom
                                    this.ContractantFields.Telephone = this.usersearch.telephone_port
                                    this.ContractantFields.Email = this.usersearch.email
                                    this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                                    this.ContractantFields.Genre = this.$store.state.user.sexe
                                    this.ContractantFields.Situation = this.$store.state.user.statut_marital
                                    this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                                    this.AssureFields.dateNaissance = this.dateNaiss
                                    // this.calculTaxe()
                                    // this.calculTTC()
                                }else {
                                    this.$message.error('Choix zone obligatoire !', this.AlertMessage)
                                }
                             
                            }
                            this.ContractantFields.dateNaissance = this.usersearch.datenaissance

                       
                    }else if(this.pack == 'confort'){
                            if (!store.state.isUserLoggedIn) {
                                this.showLoginForm = true
                            } else if(store.state.isUserLoggedIn){
                                if(this.zone != null){
                                    this.isContractant = true
                                    this.e1 = 3
                                    this.ContractantFields.Nom = this.usersearch.nom
                                    this.ContractantFields.Prenom = this.usersearch.prenom
                                    this.ContractantFields.Telephone = this.usersearch.telephone_port
                                    this.ContractantFields.Email = this.usersearch.email
                                    this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                                    this.ContractantFields.Genre = this.$store.state.user.sexe
                                    this.ContractantFields.Situation = this.$store.state.user.statut_marital
                                    this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                                    this.AssureFields.dateNaissance = this.dateNaiss
                                    // this.calculTaxe()
                                    // this.calculTTC()
                                }else {
                                    this.$message.error('Choix zone obligatoire !', this.AlertMessage)
                                }
                            }
                            this.ContractantFields.dateNaissance = this.usersearch.datenaissance
                    }
                }else {
                    this.$message.error('Adultes ou enfants obligatoires !', this.AlertMessage)
                }
                this.nombreAssures = this.AllAssure

            },







        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },
        AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
        errorNotif(){
			this.$notify({
				title: 'Error',
				message: 'Numéro obligatoire !',
				position: 'top-right',
				type: 'error'
			});
		},
        errorNotifCreateUser(){
			this.$notify({
				title: 'Error',
				message: 'Tout les Champs sont obligatoires !',
				position: 'top-right',
				type: 'error'
			});
		},
        successNotifCreateUser(){
			this.$notify({
				title: 'Success',
				message: 'Utilisateur crée avec succés !',
				position: 'top-right',
				type: 'succes'
			});
		},
        check_client_search(){
            if(this.clientsearch == 'téléphone'){
                this.NumberInput = true 
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if(this.clientsearch == 'email'){
                this.NumberInput = false 
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },
        isMobile: function () {
            if (screen.width <= 760) {
                return true
            }else {
                return false
            }
        },

      rechercherClient() {
        if(this.telephoneclient){
          const validPhone = validatePhone(this.telephoneclient);
          this.errors.phone = validPhone.error;

          if(validPhone.valid == true){
            Api().get('/user/phone/' + this.telephoneclient)
                .then(resultat => {
                  if (resultat.data.telephone_port === this.telephoneclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)
                  }

                })
                .catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.telephoneC = this.telephoneclient;
                })
          } else {
            this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
          }
        }else {
          this.$message.error('Numéro de obligatoire !', this.AlertMessage)
        }


      },

      rechercheClientByMail(){
        if(this.emailclient){

          const validEmail = validateEmail(this.emailclient);
          this.errors.email = validEmail.error;

          if(validEmail.valid == true){
            Api().get('/user/email/' +this.emailclient)
                .then(resultat =>{
                  if (resultat.data.email === this.emailclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;
                    this.$store.dispatch('setClient', resultat.data)
                  }
                }).catch(e => {
              this.clientnonOk = true
              this.clientOk = false
              this.emailC = this.emailclient;
            })
          } else {
            this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
          }
        }else {
          this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

        }
      },

        yearsDiff() {
            const date1 = dayjs(this.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, 'days');
            this.age = daydiff

           if (this.age <= 29) {
                this.an = this.annee_mois = 'jour(s)'
            } else if (this.age >= 30, this.age <= 360) {
                let year = Math.floor(this.age / 30)
                this.age = year;
                this.an = this.annee_mois = 'mois'
            } else if (this.age >= 360) {
                let year = Math.floor(this.age / 360)
                this.age = year;
                this.an = this.annee_mois = 'an(s)'
            }

        },

        showidclient() {
            this.showId = false
            this.showconfirmclient = true
            this.shownameclient = false

        },

        hidesearch() {
            this.hidesearchClient = false
            this.e1 = 1
        },

        rechercherbyphone() {
            if (this.usersearch.telephone_port === this.telephoneclient) {
                this.clientOk = true
                this.clientnonOk = false

            }
            if (this.usersearch.telephone_port !== this.telephoneclient) {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;

            }
        },

        onReset(evt) {
            evt.preventDefault();
            this.user.email = '';
            this.user.name = '';
            this.user.food = null;
            this.user.checked = [];
            this.show = false;
            this.$nextTick(() => {
                this.show = true
            });
        },


        //liste de touts les partenaires
        getListePartenaire: function () 
        {
            if (this.$store.state.user.role == 'Agent')
            {
                Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Automobile"})
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires[0].assureurs
                    }).catch(err => {})
            } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') 
            {
                Api().get('/partenaire/assureur')
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires
                    })
            } else if (this.$store.state.user.role == 'assureur') 
            {
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
            }

        },

        HubspotSend(param)
        {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param)
        {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        // Creation d'un user avant de faire son devis
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) 
            {
                const user = {
                        nom: this.nom,
                        prenom: this.prenom,
                        datenaissance: this.datenaissance,
                        profession: this.profession,
                        adresse: this.adresse,
                        sexe: this.genre,
                        telephone_port: this.telephoneclient,
                        email: this.emailclient,
                        password: this.password1,
                        confirmPass: this.password1,
                        role: this.userRole,
                        createdby:store.state.user._id,
                        photo: this.file,
                        piece_identite: this.piece_identite,
                        numero_piece: this.numero_piece,
                         plateforme : 'Dash',
                        created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                        .then(resultat => {
                            this.HubspotSend(user)
                            this.MailChimpInscriptionCompte(user)
                            if (resultat.status == 200) {
                                if(resultat.data.numero){
                                    this.$message.error(resultat.data.msg, this.AlertMessage)
                                }else{
                                    this.$store.dispatch('setClient', resultat.data.data)
                                    this.usersearch = resultat.data.data
                                    this.AddUser = false
                                    this.clientnonOk = false
                                    this.hidesearchClient = false
                                    this.e1 = 1
                                }
                                
                            } else {
                                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                            }

                })
                .catch(err => {
                    this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                })
               
            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },

        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)

        },

        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
        //Validation des elements du Stepper 1
        Stepper1Validator() {
            if (this.destination) {
                this.e1 = 2
            } else {
                this.$message.error('Pays de destination obligatoire !', this.AlertMessage)
            }
        },

        //Validation des ellements du Stepper 2
        Stepper2Validator() {
            if (this.dateDepart) {
                if(this.dateArrivee){
                    if(this.nbpassager){
                        if(this.remboursable){
                            this.e1 = 3
                            this.dateEffet = this.dateDepart
                        }else {
                            this.$message.error('Choix remboursement obligatoire !', this.AlertMessage)
                        }
                    }else {
                        this.$message.error('Nombre de passager obligatoire !', this.AlertMessage)
                    }
                }else {
                    this.$message.error("Date d'arrivée obligatoire !", this.AlertMessage)
                }
            } else {
                this.$message.error('Date de départ obligatoire !', this.AlertMessage)
            }
        },

       

        testVar:function(val){
             if (val!=null)
            {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },



        __resizeHanlder: _.throttle(function(e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700)
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
 .quantity-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        border-radius: 30px;

        margin-left: 20px;
    }
    .quantity-toggle-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        border-radius: 30px;
        padding: 1rem 1.6rem;

        margin-left: 20px;
    }

    .quantity-toggle-total input {
        border: 0;
        width: 2.5rem;
        text-align: center;
    }
    .quantity-toggle input {
        border: 0;
        border-top: 2px solid #ddd;
        border-bottom: 2px solid #ddd;
        width: 2.5rem;
        color: #ffa409;
        text-align: center;
        padding: 0 0.5rem;
    }
    .quantity-toggle button {
        /*border: 2px solid #ddd;*/
        padding: 0.5rem;
        /*background: #f5f5f5;*/
        color: #888;
        font-size: 1rem;
        cursor: pointer;
    }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
.prix_zone {
    font-weight:800;
}
</style>
