<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouveau <span style="color:#ffa409">testimonial</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
         <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Provenance</label>
              <el-select style="width: 100%" v-model="provenance" placeholder="Selectionner">
                <el-option label="Twitter" value="Twitter"></el-option>
                <el-option label="Facebook" value="Facebook"></el-option>
                <el-option label="Linkedin" value="Linkedin"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom complet de l'utilisateur</label>
              <el-input type="primary" placeholder="Nom et prénom" v-model="utilisateur">
              </el-input>
            </div>
          </el-col>
           <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Notation étoilées de 1 à 5</label>
              <el-input type="number" placeholder="Saisir nombre" min="1" max="5" v-model="notation">
              </el-input>
            </div>
          </el-col>
        </el-row>
         <el-row :gutter="15" style="margin-top:15px">
         <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>État</label>
              <el-select style="width: 100%" v-model="etat" placeholder="Selectionner">
                <el-option label="Activé" value="activate"></el-option>
                <el-option label="Desactivé" value="deactivate"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Date de publication</label>
              <el-input type="primary" placeholder="Format: 6 janvier 2019" v-model="date_pub">
              </el-input>
            </div>
          </el-col>
           <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Contenu du testimonial</label>
              <el-input type="textarea" placeholder="Saisir quelque chose..." v-model="contenu">
              </el-input>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="CreatTestimonial()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
         <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Provenance</label>
              <el-select style="width: 100%" v-model="provenance" placeholder="Selectionner">
                <el-option label="Twitter" value="Twitter"></el-option>
                <el-option label="Facebook" value="Facebook"></el-option>
                <el-option label="Linkedin" value="Linkedin"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:24px">
            <div class="grid-content bg-purple">
              <label>Nom complet de l'utilisateur</label>
              <el-input type="primary" placeholder="Nom et prénom" v-model="utilisateur">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:24px">
            <div class="grid-content bg-purple">
              <label>Nombre d'étoile(s) de 1 à 5</label>
              <el-input type="number" placeholder="Saisir nombre" min="1" max="5" v-model="notation">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:24px">
            <div class="grid-content bg-purple">
              <label>État</label>
              <el-select style="width: 100%" v-model="etat" placeholder="Selectionner">
                <el-option label="Activé" value="activate"></el-option>
                <el-option label="Desactivé" value="deactivate"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:24px">
            <div class="grid-content bg-purple">
              <label>Date de publication</label>
              <el-input type="primary" placeholder="Format: 6 janvier 2019" v-model="date_pub">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:24px">
            <div class="grid-content bg-purple">
              <label>Contenu du testimonial</label>
              <el-input type="textarea" placeholder="Saisir quelque chose..." v-model="contenu">
              </el-input>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="CreatTestimonial()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
        isMobile: false,
         provenance: null,
        utilisateur:null,
        notation :null,
        contenu :null,
        date_pub:null,
        showpack:false,
        listTestomo:[],
        detailTestimo:null,
        etat: null,
        filter: null,
        totalRows: null,
        currentPage: 1,
        ToastTabSucess: {
                    theme: 'toasted-primary',
                    position: 'top-center',
                    duration: 2000,
                    fitToScreen: true,
                    type: 'success',
                    className: 'custom-success-class'
                  }, // Customized options for success toast
        ToastTabError: {
                    theme: 'toasted-primary',
                    position: 'top-center',
                    duration: 2000,
                    fitToScreen: true,
                    type: 'error'
        },// Customized options for error toast
        perPage: 3,
        pageOptions: [5, 10, 15],
        fields: [
          {key: 'created', label: 'Date creation'},
          {key: 'provenance', label: 'Provenance'},
          {key: 'utilisateur', label: 'Utilisateur'}, 
          {key: 'notation', label: 'Notation'},
          {key: 'etat', label: 'Etat testimonial'},
          {key: 'contenu', label: 'Contenu'}, 
          {key: 'action', label: 'Action'},

        ],



      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.getListPartenaire()
        this.getListBranche()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
     init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    CreatTestimonial(){
        if (this.provenance) {
          if (this.utilisateur) {
             if (this.notation) {
                if (this.contenu) {
                   if (this.etat) {
                    
                    if (this.date_pub) {

                          const testimonial = {
                            provenance: this.provenance,
                            utilisateur: this.utilisateur,
                            notation : this.notation,
                            contenu : this.contenu,
                            etat: this.etat,
                            date_pub : this.date_pub
                        }

                        Api().post('/testimonial/add', testimonial)
                        .then(res=>{
                            this.provenance =''
                            this.utilisateur =''
                            this.notation =''
                            this.contenu =''
                            this.etat =''
                            this.$message.success("Opération réussie !", this.AlertMessage)
                        })

                    } else {
                        this.$message.error("La date de publication est obligatoire !", this.AlertMessage)
                    }
                   
                   } else {
                    this.$message.error("L'état est obligatoire !", this.AlertMessage)
                   }
                } else {
                    this.$message.error("Le contenu est obligatoire !", this.AlertMessage)
                }
             } else {
                this.$message.error("La notation est obligatoire !", this.AlertMessage)
             }
          } else {
            this.$message.error("L'utilisateur est obligatoire !", this.AlertMessage)
          }
        } else {
            this.$message.error("La provenance du testimonial est obligatoire !", this.AlertMessage)
        }
      },


      
    DateFormater: function(TheDate) {
        if (TheDate) { return dayjs(TheDate).format('DD-MM-YYYY') }
    }, 

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),
  
  },
   created() {
    this.init()
  },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
