<template>
	<div class="page-profile-edit">
		<el-form ref="form" label-width="120px" :label-position="labelPosition">
			<el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Username">
						<el-input v-model="username.prenom"/>
					</el-form-item>
				</el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Email">
						<el-input v-model="username.email" type="email"/>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="First name">
						<el-input v-model="username.nom"/>
					</el-form-item>
				</el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Date de naiss..">
						<el-date-picker type="date" placeholder="Pick a date" v-model="username.datenaissance" style="width: 100%;"></el-date-picker>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Phone">
						<el-input v-model="username.telephone_port" placeholder="+xx xxx xxx xxxx"/>
					</el-form-item>
				</el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Phone bureau">
						<el-input v-model="username.telephone_bur" placeholder=""/>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Type de pièce">
						<el-select class="select-wide" v-model="username.piece_identite" placeholder="Selectionner">
							<el-option label="Passeport" value="passeport"></el-option>
							<el-option label="CIN" value="Carte CI"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Numéro pièce">
						<el-input v-model="username.numero_piece"/>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Profession">
						<el-input v-model="username.profession"/>
					</el-form-item>
				</el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Address">
						<el-input v-model="username.adresse"/>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Sexe">
						<el-select
							class="select-wide"
							v-model="username.sexe"
							placeholder="Choose tags for your skills">
							<el-option label="Homme" value="Homme"></el-option>
							<el-option label="Femme" value="Femme"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" :md="12" :sm="24" :xs="24" class="col-p">
					<el-form-item label="Statut">
						<el-select class="select-wide" v-model="username.statut_marital" placeholder="Selectionner">
							<el-option label="Marié" value="marié"></el-option>
							<el-option label="Célibataire" value="célibataire"></el-option>
							<el-option label="Veuf" value="veuf"></el-option>
							<el-option label="Veuve" value="veuve"></el-option>
							<el-option label="Divorcé" value="divorcé"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-col>
			<el-col class="col-p">
				<el-form-item>
					<el-button type="primary" @click="UpdateUser">Modifier</el-button>
					<el-button @click="toHome">Annuler</el-button>
				</el-form-item>
			</el-col>
		</el-form>
	</div>
</template>

<script>
import Api from '../../services/Api'
import store from '../../store'

export default {
	name: 'ProfileEdit',
	data() {
		return {
			dismissSecs: 3,
            deleteConfirmation: false,
            UpdateMyPwd:false,
            modalDelete: false,
            dismissCountDown: 0,
            modalShow: false,
            listuser: null,
            Myprofile: false,
            username: null,
            nom: null,
            seenuser: true,
            currentuser: null,
            LoggedUser: null,
			password:null, 
            password_confirmation:null,



			form: {
			



				// username: 'aShenton',
				// email: 'ashenton@mail.com',
				// firstName: 'Aurora',
				// lastName: 'Shenton',
				// birthday: '1991-02-13T23:00:00.000Z',
				// phone: '',
				// website: '',
				// hobbies: [],
				// skills: ['JavaScript', 'HTML', 'CSS', 'Vue.js'],
				// gender: 'Female',
				// address: '',
				// city: '',
				// country: '',
				// postalCode: '',
				// aboutMe: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus posuere libero, nec convallis arcu ullamcorper a. Vestibulum diam neque, egestas scelerisque arcu a, fermentum ornare mauris. Ut venenatis vulputate maximus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur scelerisque quis turpis ac tempus. Quisque dolor dolor, fermentum nec magna eget, fringilla auctor lacus. Aenean sagittis est ac ligula pharetra, quis imperdiet ante dignissim. Ut vehicula nec nisl a pretium. Quisque faucibus auctor viverra. Sed ultricies convallis magna. In blandit eros id efficitur vulputate. Duis efficitur sollicitudin dui non vehicula. Nullam ut eros fermentum, dapibus metus non, accumsan neque. Mauris sed pellentesque felis. Suspendisse viverra risus sit amet congue consectetur.'
			},
			hobbies: [
				{
					value: 'Model building',
					label: 'Model building'
				}, {
					value: 'Drawing',
					label: 'Drawing'
				}, {
					value: 'Snowboarding',
					label: 'Snowboarding'
				}
			],
			skills: [
				{
					value: 'HTML',
					label: 'HTML'
				}, {
					value: 'CSS',
					label: 'CSS'
				}, {
					value: 'JavaScript',
					label: 'JavaScript'
				}
			],
			labelPosition: 'right'
		}
	},
	methods: {
		  AlertMessage() {
			this.$message({
				showClose: false,
				duration : 5000
			})
		  },

		toHome() {
      		this.$router.go(this.$router.push('/home'))
		},
        Logout() {
            Api().get('user/logout/' + store.state.user._id)
                .then(response => {
                    if (response.data.info == 'ok') {
                        store.state.user = null
                        store.state.devis = null
                        store.state.devispayement = null
                        store.state.Newcontrat = null
                        store.state.DevisReopen = null
                        store.state.Client = null
                        store.state.orderid = null
                        store.state.modePaiement = null
                        store.state.isUserLoggedIn = false
                        store.state.listeBeneficiaire = null
                        this.$router.go(this.$router.push({
                            name: 'login'
                        }))
                      
                    } else {
                        store.state.user = null
                        store.state.devis = null
                        store.state.devispayement = null
                        store.state.Newcontrat = null
                        store.state.DevisReopen = null
                        store.state.Client = null
                        store.state.orderid = null
                        store.state.modePaiement = null
                        store.state.isUserLoggedIn = false
                        store.state.listeBeneficiaire = null
                        this.$router.go(this.$router.push({
                            name: 'login'
                        }))
                    
                    }
                })
        },

		UpdateUser() {

			if (this.username.nom && this.username.prenom && this.username.email) {
				if (this.username.statut_marital && this.username.telephone_port && this.username.telephone_bur && this.username.piece_identite) {
					if (this.username.numero_piece && this.username.profession) {
						Api().post('user/' + this.username._id, this.username)
								.then(response => {
									if (response.data) {
										this.$message.success('Profile modififé avec succés !', this.AlertMessage)
									}
								}).catch(err =>{ console.log(err)})
					} else {
						this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
					}
				} else {
					this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
				}
			} else {
				this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
			}

		},

		resizeLabelPosition() {
			if(window.innerWidth <= 768) {
				this.labelPosition = 'top'	
			}
		}
	},
	mounted() {
		if(this.$store.state.isUserLoggedIn && this.$store.state.user!=null){
			this.username = this.$store.state.user
			Api().get('/user/current/'+ this.username._id)
					.then(resultat => {
						//console.log(resultat)
						this.currentuser = resultat.data;

					})
		}else{
			this.$store.dispatch("setUser", {});
			this.$router.go(this.$router.push("/login"));
		}

		this.resizeLabelPosition();
		window.addEventListener('resize', this.resizeLabelPosition);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeLabelPosition);
	}
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.page-profile-edit {
	.label-switch-box {
		display: block;
		clear: both;
		width: 100%;
		text-align: right;
		margin-bottom: 20px;
	}
	.col-p {
		padding: 0 10px;
		box-sizing: border-box;
	}
	.select-wide {
		width: 100%;
	}
}
</style>
