<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Nouvelle Adresse de <span style="color:#ffa409">Notification</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="!isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
        <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Email du partenaire</label>
              <el-input type="primary" placeholder="Adresse mail" v-model="emailadresse">
              </el-input>
            </div>
          </el-col>
         <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Choisir un partenaire</label>
              <el-select style="width: 100%" v-model="partenaireid" placeholder="Selectionner">
                <el-option v-for="assur in listeAssureurs" :key="assur.societe" :label="assur.societe" :value="assur.id"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="AddEmailAdresse()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
      <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="isMobile">
      <div style="margin-top:20px">
        <el-row :gutter="15">
        <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Email du partenaire</label>
              <el-input type="primary" placeholder="Adresse mail" v-model="emailadresse">
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top:15px">
         <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Choisir un partenaire</label>
              <el-select style="width: 100%" v-model="partenaireid" placeholder="Selectionner">
                <el-option v-for="assur in listeAssureurs" :key="assur.societe" :label="assur.societe" :value="assur.id"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top:15px">
          <el-button @click="AddEmailAdresse()" type="primary" round>Créer</el-button>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "creer_assureur",
  data() {
    return {
      isMobile: false,
listeAssureurs: null,
            partenaireid: [],
            ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            }, // Customized options for success toast
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
            }, // Customized options for error toast
            emailadresse: null,
            entreprise_ou_individuel: null,
            prixpack: null,
            nb_personne_min: null,
            periode: null,
            groupe_individuel: null,
            nb_personne_max: null,
            partenaireid: null,
            branche: null,
            montant: null,
            partenaireid: null,
            listeAssureurs: null,
            listeBaranches: null,
            fields: [{
                    key: 'partenaireid.societe',
                    label: 'Partenaire'
                },
                {
                    key: 'email_adresse',
                    label: 'Adresse Email'
                },
                {
                    key: 'action',
                    label: 'Action'
                },

            ],
            listemail: [],
            detailFrais: [],
            showpack: false,
            deleteConfirmation: false,
            modalDelete: false,
            filter: null,
            totalRows: null,
            currentPage: 1,
            perPage: 4,
            pageOptions: [5, 10, 15],



      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: []
    }
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.getListPartenaire()
    } else {
      this.$router.go(this.$router.push('/login'))
    }
    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },


    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

     AddEmailAdresse() {
            if (this.emailadresse && this.partenaireid) {
                const email = {
                    email_adresse: this.emailadresse,
                    userid: store.state.user._id,
                    partenaireid: this.partenaireid
                }
                Api().post('/emailadresse/create', email)
                    .then(response => {
                        if (response.data.status == 1) {
                           
                            this.partenaireid = null
                            this.$message.success("Opération réussie !", this.AlertMessage)
                            this.emailadresse = null
                        }
                    }).catch(e => {
                        this.$message.error("Network Error !", this.AlertMessage)
                    })
            } else {
                this.$message.error("Tout les champs sont obligatoires !", this.AlertMessage)
            }
        },
        
        getListPartenaire: function () {
            if (this.$store.state.user.role == 'Agent') {
                Api().get('/partenaire/courtier/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire[0].assureurs_partenaires
                    }).catch(err => {})
            } else if (this.$store.state.user.role == 'agent général') {
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
            } else if (this.$store.state.user.role == 'Admin' || this.$store.state.user.role=='SuperAdmin') {
                Api().get('/partenaire/')
                    .then(response => {
                        this.listeAssureurs = response.data.partenaires //.assureurs_partenaires
                    })
            } else if (this.$store.state.user.role == 'assureur') {
                Api().get('/partenaire/' + this.$store.state.user.societe)
                    .then(response => {
                        this.listeAssureurs = response.data.partenaire
                    })
            }
        },


      
    DateFormater: function(TheDate) {
        if (TheDate) { return dayjs(TheDate).format('DD-MM-YYYY') }
    }, 

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700),

  },
  created() {
    this.init()
  },
  computed : {
    sortOptions () {
      return this.listuser
          .filter(f => f.sortable)
          .map(f => { return { text: f.label, value: f.key } })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
