<template>
  <vue-scroll class="page-devis-voyage" >
    <div class="page-header">
      <h1>Création de devis <span style="color:#ffa409">TRC</span> <span style="font-size : 1rem"> (Tous Risques Chantier)</span></h1>
    </div>

    <resize-observer @notify="__resizeHanlder"/>

    <div v-if="hidesearchClient &&!isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div style="margin-top: 15px;">
        <h3>Rechercher client</h3>
        <el-row :gutter="15" justify="center">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
              <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top:15px">
          <el-col :span="10">
            <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
              <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
              <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 15px;" v-if="clientOk">
          <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
      </div>

      <div style="margin-top: 15px;" v-if="clientnonOk">
          <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
      </div>
    </div>

     <div v-if="hidesearchClient && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top: 15px;">
                <h3>Rechercher client</h3>
              <el-row :gutter="15" justify="center">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                    <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top:15px">
                <el-col :span="24">
                  <el-input v-if="NumberInput" type="number" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                  <el-col :span="24" style="margin-top:15">
                   <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  </el-col>
              </el-row>
            </div>

             <div style="margin-top: 15px;" v-if="clientOk">
                <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
            </div>

            <div style="margin-top: 15px;" v-if="clientnonOk">
                <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
            </div>
        </div>


    <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div style="margin-top:15px">
        <h3>Simulation :</h3>
        <el-row :gutter="15" type="flex" class="row-bg" justify="center">
          <el-col :span="24">
            <div>
              <el-form>
                <el-form-item>
                  <el-radio-group v-model="choix_structure" @change="switchStructure">
                    <el-radio label="structure_a">Structure A (portée béton de 10 à 20m)</el-radio>
                    <el-radio label="structure_b">Structure B (portée béton < à 10m)</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <div>
        <div v-show="StructureA">
          <h3 style="margin-bottom:5px">Structure A :</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Valeur TTC en FCFA *</label>
                <el-input type="number" min="10000" placeholder="Votre valeur TTC" @change="CheckFieldChange()" v-model="structure_a.valeurTTC">
                </el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                  <label>Nombre d'étages *</label>
                  <el-select style="width: 100%!important;" v-model="structure_a.nbreEtage" placeholder="Selectionner" @change="checkNombreEtage(structure_a.nbreEtage)">
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                      <el-option label="4" value="4"></el-option>
                      <el-option label="5" value="5"></el-option>
                      <el-option label="plus de 5 étages" value="plus"></el-option>
                  </el-select>
              </div>
              <span v-if="this.nbreEtageAutre != null">Nombre d'étages : <span class="font-weight-bold">{{this.nbreEtageAutre}}</span></span>
          </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top:15px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                  <label>Type de structure *</label>
                  <el-select style="width: 100%!important;" v-model="structure_a.type_structure" placeholder="Selectionner">
                      <el-option label="Structure 1" value="1"></el-option>
                      <el-option disabled label="Structure 2" value="2"></el-option>
                  </el-select>
              </div>
              <span v-if="this.structure_a.type_structure == '1'">Exemple : <span class="font-weight-bold">habitation, locaux administratifs, hôtel, bureaux, hôpitaux, magasins, écoles, salles de spectacles</span></span>
              <span v-if="this.structure_a.type_structure == '2'">Exemple : <span class="font-weight-bold">Bâtiments publics ou industriels, aéroports, entrepôts industriels, stades</span></span>
          </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Durée chantier en mois *</label>
                <el-input type="number" min="10000" @change="CheckFieldChange()" placeholder="Durée du chantier" v-model="structure_a.duree">
                </el-input>
              </div>
            </el-col>
          </el-row>

          <div class="text-center" v-if="monthly_payment != null">
            <h4>Prime : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
          </div>
        </div>

        <div v-show="StructureB">
          <h3 style="margin-bottom:5px">Structure B :</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Valeur TTC en FCFA *</label>
                <el-input type="number" min="10000" placeholder="Votre valeur TTC" @change="CheckFieldChange()" v-model="structure_b.valeurTTC">
                </el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                  <label>Nombre d'étages *</label>
                  <el-select style="width: 100%!important;" v-model="structure_b.nbreEtage" placeholder="Selectionner" @change="checkNombreEtage(structure_b.nbreEtage)">
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                      <el-option label="4" value="4"></el-option>
                      <el-option label="5" value="5"></el-option>
                      <el-option label="plus de 5 étages" value="plus"></el-option>
                  </el-select>
              </div>
              <span v-if="this.nbreEtageAutre != null">Nombre d'étages : <span class="font-weight-bold">{{this.nbreEtageAutre}}</span></span>
          </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top:15px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                  <label>Type de structure *</label>
                  <el-select style="width: 100%!important;" v-model="structure_b.type_structure" placeholder="Selectionner">
                      <el-option label="Structure 1" value="1"></el-option>
                      <el-option disabled label="Structure 2" value="2"></el-option>
                  </el-select>
              </div>
              <span v-if="this.structure_b.type_structure == '1'">Exemple : <span class="font-weight-bold">habitation, locaux administratifs, hôtel, bureaux, hôpitaux, magasins, écoles, salles de spectacles</span></span>
              <span v-if="this.structure_b.type_structure == '2'">Exemple : <span class="font-weight-bold">Bâtiments publics ou industriels, aéroports, entrepôts industriels, stades</span></span>
          </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Durée chantier en mois *</label>
                <el-input type="number" min="10000" @change="CheckFieldChange()" placeholder="Durée du chantier" v-model="structure_b.duree">
                </el-input>
              </div>
            </el-col>
          </el-row>

          <div class="text-center" v-if="monthly_payment != null">
            <h4>Prime : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
          </div>
        </div>
      </div>

      <div style="margin-top:15px">
        <el-button v-if="Simule" @click="fieldsControl" type="primary" round>Simuler</el-button>
        <el-button v-if="SkipSimule" @click="DevisNextStep" type="primary" round>Suivant</el-button>
      </div>
    </div>

     <div v-if="e1 == 1 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div style="margin-top:15px">
        <h3>Simulation :</h3>
        <el-row :gutter="15" type="flex" class="row-bg" justify="center">
          <el-col :span="24">
            <div>
              <el-form>
                <el-form-item>
                  <el-radio-group v-model="choix_structure" @change="switchStructure">
                    <el-radio label="structure_a">Structure A (portée béton de 10 à 20m)</el-radio>
                    <el-radio label="structure_b">Structure B (portée béton < à 10m)</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <div>
        <div v-show="StructureA">
          <h3 style="margin-bottom:-10px">Structure A :</h3>
          <el-row :gutter="20">
               <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple">
                <label>Valeur TTC en FCFA *</label>
                <el-input type="number" min="10000" placeholder="Votre valeur TTC" @change="CheckFieldChange()" v-model="structure_a.valeurTTC">
                </el-input>
              </div>
            </el-col>
            <el-col :span="24" style="margin-top:15px">
              <div class="grid-content bg-purple">
                  <label>Nombre d'étages *</label>
                  <el-select style="width: 100%!important;" v-model="structure_a.nbreEtage" placeholder="Selectionner" @change="checkNombreEtage(structure_a.nbreEtage)">
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                      <el-option label="4" value="4"></el-option>
                      <el-option label="5" value="5"></el-option>
                      <el-option label="plus de 5 étages" value="plus"></el-option>
                  </el-select>
              </div>
              <span v-if="this.nbreEtageAutre != null">Nombre d'étages : <span class="font-weight-bold">{{this.nbreEtageAutre}}</span></span>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
                <label>Type de structure *</label>
                <el-select style="width: 100%!important;" v-model="structure_a.type_structure" placeholder="Selectionner">
                    <el-option label="Structure 1" value="1"></el-option>
                    <el-option disabled label="Structure 2" value="2"></el-option>
                </el-select>
            </div>
            <span v-if="this.structure_a.type_structure == '1'">Exemple : <span class="font-weight-bold">habitation, locaux administratifs, hôtel, bureaux, hôpitaux, magasins, écoles, salles de spectacles</span></span>
            <span v-if="this.structure_a.type_structure == '2'">Exemple : <span class="font-weight-bold">Bâtiments publics ou industriels, aéroports, entrepôts industriels, stades</span></span>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <label>Durée chantier en mois *</label>
            <el-input type="number" min="10000" @change="CheckFieldChange()" placeholder="Durée du chantier" v-model="structure_a.duree">
            </el-input>
          </div>
        </el-col>
          </el-row>

          <div class="text-center" v-if="monthly_payment != null">
            <h4>Prime : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
          </div>
        </div>

        <div v-show="StructureB">
          <h3 style="margin-bottom:-5px">Structure B :</h3>
          <el-row :gutter="20">
            <el-col :span="24" style="margin-top:15px">
           <div class="grid-content bg-purple">
             <label>Valeur TTC en FCFA *</label>
             <el-input type="number" min="10000" placeholder="Votre valeur TTC" @change="CheckFieldChange()" v-model="structure_b.valeurTTC">
             </el-input>
           </div>
         </el-col>
         <el-col :span="24" style="margin-top:15px">
           <div class="grid-content bg-purple">
               <label>Nombre d'étages *</label>
               <el-select style="width: 100%!important;" v-model="structure_b.nbreEtage" placeholder="Selectionner" @change="checkNombreEtage(structure_b.nbreEtage)">
                   <el-option label="1" value="1"></el-option>
                   <el-option label="2" value="2"></el-option>
                   <el-option label="3" value="3"></el-option>
                   <el-option label="4" value="4"></el-option>
                   <el-option label="5" value="5"></el-option>
                   <el-option label="plus de 5 étages" value="plus"></el-option>
               </el-select>
           </div>
           <span v-if="this.nbreEtageAutre != null">Nombre d'étages : <span class="font-weight-bold">{{this.nbreEtageAutre}}</span></span>
       </el-col>
       <el-col :span="24" style="margin-top:15px">
         <div class="grid-content bg-purple">
             <label>Type de structure *</label>
             <el-select style="width: 100%!important;" v-model="structure_b.type_structure" placeholder="Selectionner">
                 <el-option label="Structure 1" value="1"></el-option>
                 <el-option disabled label="Structure 2" value="2"></el-option>
             </el-select>
         </div>
         <span v-if="this.structure_b.type_structure == '1'">Exemple : <span class="font-weight-bold">habitation, locaux administratifs, hôtel, bureaux, hôpitaux, magasins, écoles, salles de spectacles</span></span>
         <span v-if="this.structure_b.type_structure == '2'">Exemple : <span class="font-weight-bold">Bâtiments publics ou industriels, aéroports, entrepôts industriels, stades</span></span>
     </el-col>
     <el-col :span="24" style="margin-top:15px">
       <div class="grid-content bg-purple">
         <label>Durée chantier en mois *</label>
         <el-input type="number" min="10000" @change="CheckFieldChange()" placeholder="Durée du chantier" v-model="structure_b.duree">
         </el-input>
       </div>
     </el-col>
       </el-row>
          <div class="text-center" v-if="monthly_payment != null">
            <h4>Prime : <span style="color:#ffa409">{{ monthly_payment.toLocaleString()}} FCFA</span></h4>
          </div>
        </div>
      </div>

      <div style="margin-top:15px">
        <el-button v-if="Simule" @click="fieldsControl" type="primary" round>Simuler</el-button>
        <el-button v-if="SkipSimule" @click="DevisNextStep" type="primary" round>Suivant</el-button>
      </div>
    </div>

    <div v-if="e1 == 2 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <h3>Contractant :</h3>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom *</label>
              <el-input placeholder="Votre nom" v-model="ContractantFields.nom_souscripteur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Prénom *</label>
              <el-input placeholder="Votre prenom" v-model="ContractantFields.prenom_souscripteur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Adresse *</label>
              <el-input type="text" placeholder="Votre adresse" v-model="ContractantFields.adresse">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone *</label>
              <el-input type="number" placeholder="Votre numéro de téléphone" v-model="ContractantFields.telephone">
              </el-input>
            </div>
          </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <label>Email *</label>
                <el-input placeholder="Adresse e-mail" v-model="ContractantFields.email">
                </el-input>
              </div>
            </el-col>
         
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Situation du chantier *</label>
              <el-input placeholder="Situation de votre chantier" v-model="situation_chantier">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top:15px">
        <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
        <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
      </div>
    </div>

     <div v-if="e1 == 2 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <h3>Contractant :</h3>
        <el-row :gutter="20">
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Nom *</label>
              <el-input placeholder="Votre nom" v-model="ContractantFields.nom_souscripteur">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Prénom *</label>
              <el-input placeholder="Votre prenom" v-model="ContractantFields.prenom_souscripteur">
              </el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Téléphone *</label>
              <el-input type="number" placeholder="Votre numéro de téléphone" v-model="ContractantFields.telephone">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Email *</label>
              <el-input placeholder="Adresse e-mail" v-model="ContractantFields.email">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Adresse *</label>
              <el-input placeholder="Votre adresse" v-model="ContractantFields.adresse">
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Situation du chantier *</label>
              <el-input placeholder="Situation de votre chantier" v-model="situation_chantier">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top:15px">
        <el-button style="float:left" @click="e1 =1" round>Retour</el-button>
        <el-button @click="ContractControl()" type="primary" round>Suivant</el-button>
      </div>
    </div>

    <div v-if="e1 == 3 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Contractant</span>
                      </div>
                      <div  >
                        <ul style="list-style:none;">
                          <li>Nom : <span class="to-right assuraf-orange">{{ContractantFields.nom_souscripteur}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{ContractantFields.prenom_souscripteur}}</span></li>  
                          <li>Adresse : <span class="to-right assuraf-orange">{{ContractantFields.adresse}}</span></li>
                          <li>Téléphone : <span class="to-right assuraf-orange">{{ContractantFields.telephone}}</span></li>
                          <li>Email : <span class="to-right assuraf-orange">{{ContractantFields.email}}</span></li>
                          <li>Situation chantier : <span class="to-right assuraf-orange">{{situation_chantier}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
               
               
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="24">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Primes</span>
                      </div>
                      <div  >
                        <ul style="list-style:none">
                          <li v-if="this.choixOffreFinale == 'StructureOptionB'">Durée chantier: <span class="to-right assuraf-orange">{{structure_b.duree}} mois(s)</span></li>
                          <li v-else>Durée chantier: <span class="to-right assuraf-orange">{{structure_a.duree}} mois(s)</span></li>
                          <li v-if="majorationPrimeNette != null">Prime nette : <span class="to-right assuraf-orange">{{this.majorationPrimeNette.toLocaleString()}} CFA</span></li>
                          <li v-else>Prime nette : <span class="to-right assuraf-orange">{{this.prime_nette.toLocaleString()}} CFA</span></li>
                          <li>Taxe : <span class="to-right assuraf-orange">{{this.taxe.toLocaleString()}} CFA</span></li>
                          <li>Accessoires : <span class="to-right assuraf-orange">{{this.accessoire.toLocaleString()}} CFA</span></li>
                          <li>Prime TTC : <span class="to-right assuraf-orange">{{this.monthly_payment.toLocaleString()}} CFA</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px;;margin-left: 20px;margin-right: 20px;">
              <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="12">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                    <label>Date d'effet</label>
                    <el-date-picker
                        @change="dateDiff"
                        style="width: 100%!important;"
                        v-model="dateEffet"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date"
                        :picker-options="dateAfterToday">
                    </el-date-picker>
                  </div>
                </el-col>

                <el-col :span="12">
                  <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;margin-right: 20px;">
                    <label>Date d"échéance</label>
                    <el-input placeholder="Date d'échéance" v-model="dateEcheance" readonly>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
          <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary" round>Valider</el-button>
        </div>
      </div>
    </div>

    <div v-if="e1 == 3 && isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
      <div>
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS SUR L'ASSURANCE</h3>
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Contractant</span>
                      </div>
                      <div  >
                        <ul style="list-style:none;margin-left: -10%;">
                          <li>Nom : <span class="to-right assuraf-orange">{{ContractantFields.nom_souscripteur}}</span></li>
                          <li>Prénom : <span class="to-right assuraf-orange">{{ContractantFields.prenom_souscripteur}}</span></li>  
                          <li>Adresse : <span class="to-right assuraf-orange">{{ContractantFields.adresse}}</span></li>
                          <li>Téléphone : <span class="to-right assuraf-orange">{{ContractantFields.telephone}}</span></li>
                          <li>Email : <span class="to-right assuraf-orange">{{ContractantFields.email}}</span></li>
                          <li>Situation chantier : <span class="to-right assuraf-orange">{{situation_chantier}}</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
               
               
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">DÉTAILS</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <span>Primes</span>
                      </div>
                      <div  >
                        <ul style="list-style:none;margin-left: -10%;">
                          <li>Durée : <span class="to-right assuraf-orange">{{duree}} mois</span></li>
                          <li>Prime nette : <span class="to-right assuraf-orange">{{this.prime_nette.toLocaleString()}} CFA</span></li>
                          <li>Taxe : <span class="to-right assuraf-orange">{{this.taxe.toLocaleString()}} CFA</span></li>
                          <li>Accessoires : <span class="to-right assuraf-orange">{{this.accessoire.toLocaleString()}} CFA</span></li>
                          <li>Prime TTC : <span class="to-right assuraf-orange">{{this.monthly_payment.toLocaleString()}} CFA</span></li>
                        </ul>
                      </div>
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="item-box card-shadow--medium" style="margin-top:15px">
              <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
              <el-row :gutter="24" style="margin-top:20px">
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <label>Date d'effet</label>
                    <el-date-picker
                        @change="dateDiff"
                        style="width: 100%!important;"
                        v-model="dateEffet"
                        type="date"
                        format="yyyy/MM/dd"
                        placeholder="Choississez une date"
                        :picker-options="dateAfterToday">
                    </el-date-picker>
                  </div>
                </el-col>

                <el-col :span="24" style="margin-top:15px">
                  <div class="grid-content bg-purple">
                    <label>Date d"échéance</label>
                    <el-input placeholder="Date d'échéance" v-model="dateEcheance" readonly>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button style="float:left" round @click="e1 = 2">Retour</el-button>
          <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="CreateDevis" type="primary" round>Valider</el-button>
        </div>
      </div>
    </div>


    <el-dialog width="70%" title="Ajouter nombre d'étages" :visible.sync="NbreEtageModal">
      <el-form ref="form" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nombre d'étages</label>
              <el-input type="number" min="6" placeholder="Nombre étage" v-model="nbreEtageAutre"></el-input>
            </div>
          </el-col>
        </el-row>
       
      </el-form>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="ValidNbreEtage()">Valider</el-button>
      </div>
    </el-dialog>



    <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
      <el-form ref="form" :model="valid" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="nom"></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Numéro de téléphone</label>
              <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Adresse e-mail</label>
              <el-input placeholder="votre adresse e-mail" v-model="EmailInput"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Sexe</label>
              <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                <el-option label="Homme" value="homme"></el-option>
                <el-option label="Femme" value="femme"></el-option>
                <el-option label="entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="createuser()">Créer</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
      <el-form ref="form" :model="valid" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="nom"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Numéro de téléphone</label>
              <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple-light">
              <label>Adresse e-mail</label>
              <el-input placeholder="votre adresse e-mail" v-model="EmailInput"></el-input>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <label>Sexe</label>
              <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                <el-option label="Homme" value="homme"></el-option>
                <el-option label="Femme" value="femme"></el-option>
                <el-option label="entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="createuser()">Créer</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Modalité des prochains paiements" :visible.sync="PaymentFuturMode">
      <el-form ref="form" :model="valid" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple-light">
              <label>Choix paiement</label>
              <el-select style="width:100%" v-model="PaymentFutur" placeholder="Selectionner une modalité">
                <el-option value="null" selected disabled="">choix paiement</el-option>
                <el-option value="cash">Cash</el-option>
                <el-option value="cheque">Cheque</el-option>
                <el-option value="prelevement">Prelevement bancaire</el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="ValidPaymentFutur">Valider</el-button>
      </div>
    </el-dialog>
  </vue-scroll>
</template>

<script>
import Chance from "chance"  
//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../../services/Api'
import store from '../../../../store'
import pays from '../../../../common/countries'
import configMrpConfig from '../../../../common/mrp_config.json'
//fin importation assuraf
import customPassword from "../../../../services/password_generator"


const validatePhone = phone => {
  if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }

};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire!"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
  name: "devis_sunu_assur",

  data() {
    return {
    structure_a : {
        valeurTTC : null,
        nbreEtage : null,
        type_structure : null,
        duree : null,
    },
    structure_b : {
        valeurTTC : 0,
        nbreEtage : null,
        type_structure : null,
        duree : null,
    },
    nbreEtageAutre : null,
    NbreEtageModal : false,
    accessoire : 5000,
    taxe : null, 
    duree : null,
    prime_nette : null, 
    prime_ttc : null,
    majorationPrimeNette : null,
    StructureA: true,
    StructureB: false,
    listegaranties: ['DOMMAGE SUR OUVRAGE ', 'MAINTENANCE VISITE : (12 mois)', 'GARANTIE DE PARFAIT ACHEVEMENT : (1 an)', 'GARANTIE DE BON FONCTIONNEMENT : (2 ans)'],






      test_model : null,
      configMpr : null,
      valeur_location : null,
      valeur_proprio : null,
      chiffre_affaire : null,
      qualiteassure : 'occupant',
      rc : false,
      accessoire : 5000,
      taxe : null, 
      duree : 12,
      prime_nette : null, 
      prime_ttc : null,
      primeRc : null,
      valeur_mobilier : null,

      valeur_locationRc : null,
      valeur_proprioRc : null,
      chiffre_affaireRc : null,
      valeur_mobilierRc : null,


      modalgenerationloading : false,
      isMobile : false,

      optionsdate: {
        format: "YYYY/MM/DD",
        useCurrent: false,
        maxDate: new Date(),
      },
      optionsdateEffet: {
        format: "YYYY/MM/DD",
        useCurrent: false,
        minDate: new Date(),
      },
      first_questionnaire: true,
      isCapital: true,
      oldDate: null,
      bottomPushNotif: false,
      disableDateAssure: true,
      Modalite: null,
      Assureur: "",
      Simule: true,
      SkipSimule: false,
      money: {
        thousands: " ",
        precision: false,
        masked: false,
      },
      choixOffre: null,
      choixOffreFinale: null,
      dateNaiss: null,
    
      Capi_souhaite: null,
      pourcent: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      choixCapital: {
        Capital: null,
        Mensuel: null,
      },
      dateEffet: null,
      dateEcheance: null,
      e1: 0,
      elevation: 2,
      activeStep: 0,
      checkbox: false,
      errors: {},
      aStorMap: false,
      disable: false,
      valid: null,
      age: null,
      // Contractant fields
      ContractantFields: {
          nom_souscripteur: null,
          prenom_souscripteur: null,
          adresse: null,
          telephone: null,
          email: null
      },
      situation_chantier : null,

      formSteps: [
        {
          title: "CONTRACTANT",
          fields: [{ valid: true }],
        }
      ],
      CreatedDevis: null,
      usersearch: null,
      QuestionnaireTab: [],
      



















      choix_structure : 'structure_a',

      buttons: false,
      buttons_2: false,
      buttons_3: false,
      buttons_4: false,
      buttons_5: false,
      first_page: false,
      second_page: false,
      thirst_page: false,
      fourt_page: false,
      five_page: false,
      questionTest: "",
      PaymentFutur: null,
      PaymentFuturMode: false,


      // numTelephone : '' ,
      amenities: [1, 4],
      genre: null,

      fraisLiv: 0,
      fraisPaiement: 0,
      Testons: false,
      adresselivraison: "",
      autoHeight: true,
      x: null,
      dialogAlert: false,
      Devis_pour_souscription: [],
      mode_livraison: null,

      menuDelivrance: false,
      monthly_payment: null,
      countries: null,
      quartier: "",
      frais_livraison: null,
      branche: null,
      dialog: false,
      checked: false,

      animation: "animate-in",
      isContractant: true,
      isAssure: false,
      isBenef: false,
      Questionnaire: false,
      QuestionnaireSuite: false,
      isPayment: false,
      menu: false,
      Capi: 0,
      Mensu: 0,
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dateAfterToday: {
        disabledDate(time) {
          return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
        }
      },
      dateAfterDateDepart : {
        disabledDate(time) {
          return time.getTime() > this.dateDepart;
        }
      },
      remboursable:null,
      optionsdateDepart: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        minDate: new Date(),
      },

      optionsdateArrivee: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        minDate: new Date(),
      },
      PassengerBirthOptionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      optionsdateUser: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
        maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
      },
      countriesSn: 'Sénégal',

      hideForm: false,
      popupListePassager: false,
      steps: [{
        label: 'Etape 1',
        completed: false,
      },
        {
          label: 'Etape 2',
          completed: false,
        },
        {
          label: 'Etape 3',
          completed: false,
        },
      ],
      clientnonOk: false,
      clientOk: false,
      clientsearch: null,
      adulte_enfant: null,
      seen: true,
      selected: [],
      nexted: false,
      selectpays: '',
      ToastTabSucess: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'success',
        className: 'custom-success-class'
      }, // Customized options for success toast
      ToastTabError: {
        theme: 'toasted-primary',
        position: 'top-center',
        duration: 10000,
        fitToScreen: true,
        type: 'error'
      }, // Customized options for error toast
      listpays: pays,
      dateDepart: null,
      pays_domicile: null,
      dateArrivee: null,
      garantie: [],
      assureurs: [],
      listpaysprime: [],
      telephoneclient: '',
      destination: null,
      listeAssureurs: [],
      listvoyage: [],
      listPassager: [],
     

      items: [{
        text: 'homme'
      },
        {
          text: 'femme'
        },
      ],

      NumberInput : false,
      EmailInput : false,

      radio: '1',




      optionsdateMEC: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
        maxDate: new Date(),
      },
      emailclient: null,
      emailC: this.emailclient,
      telephoneC: this.telephoneclient,
      showId: false,
      showconfirmclient: false,
      recupIdClient: '',
      shownameclient: true,
      hidesearchClient: true,
      nom: null,
      prenom: null,
      email: null,
      profession: null,
      AddUser: false,
      adresse: null,
      piece_identite: null,
      numero_piece: null,
      hideDive_vehicule: null,

      password1: null,

      garanties: [],


      voidField: false,

      Deb: null,
      selectedcarbu: '',

      types: [
        'text',
        'text',
        'number',
      ],
      show: true,
      //fin initialisation des models d'assuraf
      chart: null,
      pie: null,
      gridData: [],
      userRole: null,
    }
  },
  mounted() {
    this.getUserRoles()
    window.addEventListener("resize", this.__resizeHanlder)
  },

  methods: {
    ValidNbreEtage(){
        //if(this.Capital == true) {
            if(this.nbreEtageAutre){
                if(this.nbreEtageAutre > 5){
                    if(this.nbreEtageAutre > 15) {
                      this.$message.error("Nombre d'étages limités à 15, veuiller nous contacter !", this.AlertMessage)
                    }else {
                        this.NbreEtageModal = false
                    }
                    
                }else  {
                  this.$message.error("Nombre d'étages doit être suppérieur à 5 !", this.AlertMessage)
                }
                
            }else {
              this.$message.error("Nombre d'étage obligtoire !", this.AlertMessage)
            }
        // }else if(this.Mensualite == true ) {
        //     if(this.nbreEtageAutre){
        //         if(this.nbreEtageAutre > 15) {
        //                 this.$message.error("Nombre d'étages limités à 15, veuiller nous contacter !", this.AlertMessage)
        //             }else {
        //                 this.NbreEtageModal = false
        //             }
                
        //     }else {
        //       this.$message.error("Nombre d'étages obligtoire !", this.AlertMessage)
        //     }
        // }
    },
    checkNombreEtage(item) {
      console.log(item)
          //if(this.choixOffreFinale == 'StructureOptionA'){
            

              if (item == "plus") {
                  this.NbreEtageModal = true
              } else {
                  this.nbreEtageAutre = null
              }
          // } else if(this.choixOffreFinale == 'StructureOptionB'){
          //     if (item == "plus") {
          //         this.NbreEtageModal = true
          //     }else {
          //         this.nbreEtageAutre = null
          //     }
          // }   
          this.Simule = true
          this.SkipSimule = false
      },
     getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
      },

    init() {
        if (window.innerWidth <= 768) this.isMobile = true
    },
    
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },

    /*On check le numero de la question dans le tableau avant de pusher*/
    CheckValue(numero) {
      let checker = 0;
      for (let v of this.QuestionnaireTab) {
        if (v.num === numero) {
          checker = 1;
        }
      }
      return checker;
    },

  /*========== Fonction pour faire choix entre capital ========*/
  Choix() {
  if ((this.choixOffre = this.StructureA)) {
      this.choixOffreFinale = "StructureOptionA";
      this.choixCapital.Mensuel = null;
      this.clearValeurForm;
  } else if ((this.choixOffre = this.StructureB)) {
      this.choixCapital.Capital = null;
      this.choixOffreFinale = "StructureOptionB";
      this.clearValeurForm;
  }
  },

  calculePrimeStructure_a(){
                if(this.structure_a.type_structure == 1){
                    if(this.structure_a.duree <= 12) {
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_a.valeurTTC * 2.40 / 1000)
                            let majoration = this.structure_a.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            ///this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }else {
                            this.prime_nette = (this.structure_a.valeurTTC * 2.40 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    } else if (this.structure_a.duree > 12 && this.structure_a.duree <= 18){
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_a.valeurTTC * 3 / 1000)
                            //let majoration = prime_nette * 0.05;
                            let majoration = this.structure_a.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }else {
                            this.prime_nette = (this.structure_a.valeurTTC * 3 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    } else if (this.structure_a.duree > 18 && this.structure_a.duree <= 24){
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_a.valeurTTC * 3.6 / 1000)
                            let majoration = this.structure_a.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }else {
                            this.prime_nette = (this.structure_a.valeurTTC * 3.6 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    } else if(this.structure_a.duree > 24){
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_a.valeurTTC * 4.2 / 1000)
                            let majoration = this.structure_a.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }else {
                            this.prime_nette = (this.structure_a.valeurTTC * 4.2 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    }else {
                        this.$message.error('Veuillez nous consulter !', this.AlertMessage)
                        this.monthly_payment = null
                    }
                }
            },

            calculePrimeStructure_b(){
                if(this.structure_b.type_structure == 1){
                    if(this.structure_b.duree <= 12) {
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_b.valeurTTC * 2.64 / 1000)
                            let majoration = this.structure_b.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire

                        }else {
                            this.prime_nette = (this.structure_b.valeurTTC * 2.64 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this. monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    } else if (this.structure_b.duree > 12 && this.structure_b.duree <= 18){
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_b.valeurTTC * 3.36 / 1000)
                            let majoration = this.structure_b.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }else {
                            this.prime_nette = (this.structure_b.valeurTTC * 3.36 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this. monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    } else if (this.structure_b.duree > 18 && this.structure_b.duree <= 24){
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_b.valeurTTC * 4.20 / 1000)
                            let majoration = this.structure_b.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }else {
                            this.prime_nette = (this.structure_b.valeurTTC * 4.20 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this. monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    } else if(this.structure_b.duree > 24){
                        if(this.nbreEtageAutre > 5){
                            let prime_nette = (this.structure_b.valeurTTC * 4.80 / 1000)
                            let majoration = this.structure_b.valeurTTC * 0.05 / 1000;
                            let difNbreEtage = this.nbreEtageAutre - 4;
                            let majorationDefinitive = majoration * difNbreEtage
                            this.majorationPrimeNette = majorationDefinitive;
                            this.prime_nette = prime_nette + majorationDefinitive;
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this.monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }else {
                            this.prime_nette = (this.structure_b.valeurTTC * 4.80 / 1000)
                            this.taxe = (this.prime_nette + this.accessoire) * 14 / 100;
                            this. monthly_payment = this.prime_nette + this.taxe + this.accessoire
                        }
                    } else {
                        this.$message.error('Veuillez nous consulter !', this.AlertMessage)
                        this.monthly_payment = null
                    }
                }
            },

    /*===Send of data to API allowing create proposition for the customer===*/
    CreateDevis: function () {
      let duree_chantier;
      let structure;
      let nbreEtage;
      let categorie;
        let categorie_type;
      if (this.dateEffet) {

          if(this.choixOffreFinale == 'StructureOptionA') {
            duree_chantier =  this.structure_a.duree
            structure = this.structure_a.type_structure
            nbreEtage = this.structure_a.nbreEtage
            if(this.structure_a.type_structure == '1'){
                categorie_type = 1;
                categorie = ['habitation', 'locaux administratifs', 'hôtel', 'bureaux', 'hôpitaux', 'magasins', 'écoles', 'salles de spectacles']
            }else{
                categorie = ['Bâtiments publics ou industriels', 'aéroports', 'entrepôts industriels', 'stades']
                categorie_type = 2;
            }
          }else {
            duree_chantier =  this.structure_b.duree
            structure = this.structure_b.type_structure
            nbreEtage = this.structure_b.nbreEtage
            if(this.structure_b.type_structure == '1'){
                categorie = ['habitation', 'locaux administratifs', 'hôtel', 'bureaux', 'hôpitaux', 'magasins', 'écoles', 'salles de spectacles']
                categorie_type = 1;
            }else{
                categorie = ['Bâtiments publics ou industriels', 'aéroports', 'entrepôts industriels', 'stades']
                categorie_type = 2;
            }
          }

            const Trc = {
              souscripteur: this.ContractantFields,
              Modalite: this.Modalite,
              duree: duree_chantier,
              branche: "Tous Risques Chantier",
              assureurs: "FINAFRICA",
              userid: this.usersearch._id, //store.state.user._id,
              clientid: this.usersearch._id, //store.state.user._id,
              createur: store.state.user._id,
              dateEffet: dayjs(this.dateEffet).format("YYYY-MM-DD"),
              dateEcheance: this.dateEcheance,
              dateSouscription: new Date(),
              taxe : this.taxe,
              accessoires : this.accessoire,
              prime_nette : this.prime_nette,
              prime_ttc: this.monthly_payment,
              garanties :this.listegaranties,
              FuturPaiement: null,
              nbre_etage : nbreEtage,
              type_structure : structure,
              duree_chantier :duree_chantier,
              adresse_chantier : this.situation_chantier,
              categorie : categorie,
              categorie_type : categorie_type,
              plateforme : 'Dash',
            };

            if (this.$store.state.user.role == "Courtier" || this.$store.state.user.role == "Agent") {
              Sunu.courtierid = this.$store.state.user.societe;
            }
            console.log('::::::::::::::: TRC Data :::::::::::::::::', Trc)

            Api()
                .post("/devis/add/trc", Trc)
                .then((result) => {
                  Api()
                      .get("/devis/" + result.data.result.devis._id)
                      .then((response) => {
                        this.modalgenerationloading = true
                        this.$store.dispatch("setDevisPayement", response.data);
                         setTimeout(() => (
                              this.modalgenerationloading = false,
                              this.$router.go(this.$router.push({
                                name: 'paiement'
                            }))), 4000
                          );
                      });
                })
                .catch((err) => {
                  console.log("error");
                });
          
        
      } else {
        this.$message.error('Date d\'effet obligatoire !', this.AlertMessage)
      }
    },

    //Control des champs pour validation
    fieldsControl() {
      this.Choix();
      if (this.choixOffreFinale == "StructureOptionA") {
              if(this.structure_a.valeurTTC ){
                  if (this.structure_a.nbreEtage){
                      if(this.structure_a.type_structure){
                        if(this.structure_a.duree) {
                          this.calculePrimeStructure_a();
                          if(this.monthly_payment != null) {
                              this.SkipSimule = true,
                              this.Simule = false,
                              this.branche = 'TRC'
                              this.Assureur = 'FINAFRICA'
                          }else {
                              this.SkipSimule = false,
                              this.Simule = true,
                              this.branche = 'TRC'
                              this.Assureur = 'FINAFRICA'
                          }
                        }else {
                          this.$message.error('Durée obligatoire !', this.AlertMessage)
                        }
                      }else {
                        this.$message.error('Type structure obligatoire !', this.AlertMessage)
                      }
                  } else {
                      this.$message.error('Nombre étage obligatoire !', this.AlertMessage)
                  }
              } else {
                  this.$message.error('Valeur TTC obligatoire !', this.AlertMessage)
              }
      } else if (this.choixOffreFinale == "StructureOptionB") {
        if(this.structure_b.valeurTTC ){
                  if (this.structure_b.nbreEtage){
                      if(this.structure_b.type_structure){
                        if(this.structure_b.duree) {
                          this.calculePrimeStructure_b();
                          if(this.monthly_payment != null) {
                              this.SkipSimule = true,
                              this.Simule = false,
                              this.branche = 'TRC'
                              this.Assureur = 'FINAFRICA'
                          }else {
                              this.SkipSimule = false,
                              this.Simule = true,
                              this.branche = 'TRC'
                              this.Assureur = 'FINAFRICA'
                          }
                        }else {
                          this.$message.error('Durée obligatoire !', this.AlertMessage)
                        }
                      }else {
                        this.$message.error('Type structure obligatoire !', this.AlertMessage)
                      }
                  } else {
                      this.$message.error('Nombre étage obligatoire !', this.AlertMessage)
                  }
              } else {
                  this.$message.error('Valeur TTC obligatoire !', this.AlertMessage)
              }
      }
    },

    CheckFieldChange() {
      console.log('show simule button')
      /* if(this.dateNaiss = !this.dateNaiss){*/
      this.Simule = true;
      this.SkipSimule = false;
      /*}*/
      //this.DynamicSimule();
    },

    DevisNextStep() {
      // const date1 = dayjs(this.BenefiFields.dateNaissance);
      // const date2 = dayjs(new Date().toISOString().substr(0, 10));
      // let daydiff = date2.diff(date1, "days");
      // let year = Math.floor(daydiff / 360);
      // this.oldDate = this.dateNaiss;

      // let toDay = year;
      // let dureeTotal = Number(this.duree);

      // if (this.choixOffreFinale == "Mrp_value") {
        if (this.monthly_payment >= 10000) {
          
              if (store.state.isUserLoggedIn) {
                this.ContractantFields.nom_souscripteur = this.usersearch.nom;
                this.ContractantFields.prenom_souscripteur = this.usersearch.prenom;
                this.ContractantFields.telephone = this.usersearch.telephone_port;
                this.ContractantFields.email = this.usersearch.email;
                this.ContractantFields.adresse = this.$store.state.user.adresse;
                this.e1 = 2;
                //this.ContractantFields.dateNaissance = this.dateNaiss;
              } else {

              }
        } else {
          this.$message.error("La prime doit etre au minimum 10 000 CFA !", this.AlertMessage)
        }
    },
    switchStructure() {
     if (this.choix_structure == 'structure_a'){
        this.switchStructureA()
      } else if(this.choix_structure == 'structure_b') {
        this.switchStructureB()
      }
    },

    switchStructureA() {
      this.Choix();
      this.StructureA = true;
      this.StructureB = false;
      this.monthly_payment = null;
      this.CheckFieldChange();
      this.clearValeurForm();
    },

    switchStructureB() {
      this.Choix();
      this.StructureB = true;
      this.StructureA = false;
      this.monthly_payment = null;
      this.CheckFieldChange();
      this.clearValeurForm();
      
    },

    // fonction pour formattage de Date (:-)
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD-MM-YYYY");
      }
    },

    
    clearValeurForm() {
      this.structure_a.valeurTTC = null;
      this.structure_a.nbreEtage = null
      this.structure_a.type_structure = null
      this.structure_a.duree = null
      this.structure_b.valeurTTC = null;
      this.structure_b.nbreEtage = null
      this.structure_b.type_structure = null
      this.structure_b.duree = null
    },

    /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
    onlyNumber($event) {
      let NumOnly = $event.NumOnly ? $event.NumOnly : $event.which;
      if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
        $event.preventDefault();
      }
    },

    dateDiff: function () {
      let duree_couverture;
      if (this.choixOffreFinale == 'StructureOptionA'){
          duree_couverture = this.structure_a.duree;
      } else if(this.choixOffreFinale == 'StructureOptionB') {
          duree_couverture = this.structure_b.duree;
      } 

      if (this.dateEffet === "" && duree_couverture === "") this.dateEcheance = "";
      let inter = dayjs(this.dateEffet)
          .add(duree_couverture, "month")
          .format("YYYY-MM-DD");
      this.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
    },

    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    isDesktop: function () {
      if (screen.width > 768) {
        return true;
      } else {
        return false;
      }
    },

    nextStep() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false), (this.isAssure = true);
      }, 600);
    },

    nextStep2() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false),
            (this.isAssure = false),
            (this.isBenef = true);
      }, 600);
    },

    nextStep3() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false),
            (this.isAssure = false),
            (this.isBenef = false),
            (this.QuestionnaireSuite = true);
      }, 600);
    },

    nextStep4() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
        (this.isContractant = false),
            (this.isAssure = false),
            (this.isBenef = false),
            (this.QuestionnaireSuite = false);
        this.isPayment = true;
      }, 600);
    },

    ContractControl() {
        if (this.ContractantFields.nom_souscripteur && this.ContractantFields.prenom_souscripteur) {
              if (this.ContractantFields.adresse) {
                  if (this.ContractantFields.telephone && this.ContractantFields.email) {
                      if(this.situation_chantier) {
                                  this.e1 = 3;
                      } else  {
                          this.$message.error('Situation du chantier obligatoires!', this.AlertMessage)
                      }

                  } else {
                      this.$message.error('Téléphone et Email obligatoires !', this.AlertMessage)
                  }
              } else {
                this.$message.error('Adresse obligatoire !', this.AlertMessage)
              }
        } else {
          this.$message.error('Nom et Prénom obligatoires !', this.AlertMessage)
        }
    },

    validField() {
      if (this.question_7.principal_response === "oui") {
        if (this.question_7.resp_date) {
          this.QuestionnaireTab.push(this.question_7);
          this.e1 = 6;
          this.nextStep4();
          this.bottomPushNotif = false;
        } else {
          this.$toasted.show("Champ obligatoire! ", this.ToastTabError);
        }
      } else if (this.question_7.principal_response === "non") {
        this.QuestionnaireTab.push(this.question_7);
        this.e1 = 6;
        this.nextStep4();
        this.bottomPushNotif = false;
      }
    },

    generatePassword: function () {
       this.password1 = customPassword.customPassword(this.nom, this.prenom)
    },


    nextPage() {
      this.second_page = true;
      this.first_page = false;
      this.QuestionNext.Q8 = true;
    },

    nextPageToThirst() {
      this.second_page = false;
      this.first_page = false;
      this.thirst_page = true;
      this.QuestionNext.Q15 = true;
    },

    nextPageToFourt() {
      this.second_page = false;
      this.first_page = false;
      this.thirst_page = false;
      this.fourt_page = true;
      this.QuestionNext.Q22 = true;
    },

    nextPageToFive() {
      this.second_page = false;
      this.first_page = false;
      this.thirst_page = false;
      this.fourt_page = false;
      this.five_page = true;
      this.QuestionNext.Q29 = true;
    },

    ValidPaymentFutur() {
      if (this.PaymentFutur) {
        this.PaymentFuturMode = false;
      } else {
        this.$toasted.show("Choix obligatoire !", this.ToastTabError);
      }
    },

    getListePays: function () {
      Api().get('/pays')
          .then(response => {
            this.listpays = response.data
          })
          .catch(err => {
            err
          })
    },

    remplissage_passager() {
      this.popupListePassager = true
    },

    DurationCalculation() {
      this.dateEffet = this.dateDepart
      this.dateEcheance = this.dateArrivee
      let date_tmp =  dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
      this.duree = date_tmp + 1  //dayjs(this.dateArrivee).diff(this.dateDepart, 'day')
    },







    /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
    onlyNumber($event) {
      let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
      if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
        $event.preventDefault();
      }
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },
    errorNotif(){
      this.$notify({
        title: 'Error',
        message: 'Numéro obligatoire !',
        position: 'top-right',
        type: 'error'
      });
    },
    errorNotifCreateUser(){
      this.$notify({
        title: 'Error',
        message: 'Tout les Champs sont obligatoires !',
        position: 'top-right',
        type: 'error'
      });
    },
    successNotifCreateUser(){
      this.$notify({
        title: 'Success',
        message: 'Utilisateur crée avec succés !',
        position: 'top-right',
        type: 'succes'
      });
    },
    check_client_search(){
      if(this.clientsearch == 'téléphone'){
        this.NumberInput = true
        this.EmailInput = false
        this.emailclient = null
        this.clientOk = false
        this.clientnonOk = false
      } else if(this.clientsearch == 'email'){
        this.NumberInput = false
        this.EmailInput = true
        this.telephoneclient = null
        this.clientOk = false
        this.clientnonOk = false
      }
    },
    isMobile: function () {
      if (screen.width <= 760) {
        return true
      }else {
        return false
      }
    },

    rechercherClient() {
      if(this.telephoneclient){
        const validPhone = validatePhone(this.telephoneclient);
        this.errors.phone = validPhone.error;

        if(validPhone.valid == true){
          Api().get('/user/phone/' + this.telephoneclient)
              .then(resultat => {
                if (resultat.data.telephone_port === this.telephoneclient) {
                  this.clientOk = true
                  this.clientnonOk = false
                  this.usersearch = resultat.data;
                  this.$store.dispatch('setClient', resultat.data)
                }

              })
              .catch(e => {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;
              })
        } else {
          this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
        }
      }else {
        this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
      }


    },

    rechercheClientByMail(){
      if(this.emailclient){

        const validEmail = validateEmail(this.emailclient);
        this.errors.email = validEmail.error;

        if(validEmail.valid == true){
          Api().get('/user/email/' +this.emailclient)
              .then(resultat =>{
                if (resultat.data.email === this.emailclient) {
                  this.clientOk = true
                  this.clientnonOk = false
                  this.usersearch = resultat.data;
                  this.$store.dispatch('setClient', resultat.data)
                }
              }).catch(e => {
            this.clientnonOk = true
            this.clientOk = false
            this.emailC = this.emailclient;
          })
        } else {
          this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
        }
      }else {
        this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

      }
    },

    showidclient() {
      this.showId = false
      this.showconfirmclient = true
      this.shownameclient = false

    },

    hidesearch() {
      this.hidesearchClient = false
      this.e1 = 1
    },

    rechercherbyphone() {
      if (this.usersearch.telephone_port === this.telephoneclient) {
        this.clientOk = true
        this.clientnonOk = false

      }
      if (this.usersearch.telephone_port !== this.telephoneclient) {
        this.clientnonOk = true
        this.clientOk = false
        this.telephoneC = this.telephoneclient;

      }
    },

    onReset(evt) {
      evt.preventDefault();
      this.user.email = '';
      this.user.name = '';
      this.user.food = null;
      this.user.checked = [];
      this.show = false;
      this.$nextTick(() => {
        this.show = true
      });
    },


    //liste de touts les partenaires
    getListePartenaire: function ()
    {
      if (this.$store.state.user.role == 'Agent')
      {
        Api().post('/branchecourtier/get/partenaire/',{userid:this.$store.state.user._id, branche:"Automobile"})
            .then(response => {
              this.listeAssureurs = response.data.partenaires[0].assureurs
            }).catch(err => {})
      } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin')
      {
        Api().get('/partenaire/assureur')
            .then(response => {
              this.listeAssureurs = response.data.partenaires
            })
      } else if (this.$store.state.user.role == 'assureur')
      {
        Api().get('/partenaire/' + this.$store.state.user.societe)
            .then(response => {
              this.listeAssureurs = response.data.partenaire
            })
      }

    },

    HubspotSend(param)
    {
      let data = {
        "email": param.email,
        "firstname": param.prenom,
        "lastname": param.nom,
        "phone": param.telephone_port,
        "inscrit": "Dash"
      };
      Api().post('/testimonial/hubspot', data)
          .then(resp => {
            (resp.data)
          })
          .catch(e => {
            (e)
          })
    },

    MailChimpInscriptionCompte(param)
    {
      let data = {
        "email": param.email,
        "FNAME": param.prenom,
        "LNAME": param.nom,
        "PHONE": param.telephone_port,
      };
      Api().post('/testimonial/mailchimp', data)
          .then(resp => {
            (resp.data)
          })
          .catch(e => {
            (e)
          })
    },
    // Creation d'un user avant de faire son devis
    createuser: function () {

      this.generatePassword()
      if (this.nom && this.prenom && this.EmailInput && this.genre && this.telephoneclient)
      {
        const user = {
          nom: this.nom,
          prenom: this.prenom,
          datenaissance: this.datenaissance,
          profession: this.profession,
          adresse: this.adresse,
          sexe: this.genre,
          telephone_port: this.telephoneclient,
          email: this.EmailInput,
          password: this.password1,
          confirmPass: this.password1,
          role: this.userRole,
          createdby:store.state.user._id,
          photo: this.file,
          piece_identite: this.piece_identite,
          numero_piece: this.numero_piece,
          plateforme : 'Dash',
          created: dayjs(new Date()).format('YYYY-MM-DD')
        }
        Api().post('user/register', user)
            .then(resultat => {
                this.HubspotSend(user)
                this.MailChimpInscriptionCompte(user)
              if (resultat.status == 200) 
              {
                if(resultat.data.numero){
                    this.$message.error(resultat.data.msg, this.AlertMessage)
                }else{
                  this.$store.dispatch('setClient', resultat.data.data)
                  this.usersearch = resultat.data.data
                  this.AddUser = false
                  this.clientnonOk = false
                  this.hidesearchClient = false
                  this.e1 = 1
                }
              } else {
                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
              }

            })
            .catch(err => {
              this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
            })

      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },




    testVar:function(val){
      if (val!=null)
      {
        return Number(val.replace(/[^0-9.-]+/g, ""));
      } else {
        return "0";
      }
    },



    __resizeHanlder: _.throttle(function(e) {
      if (this.chart) {
        this.chart.resize()
      }
      if (this.pie) {
        this.pie.resize()
      }
    }, 700)
  },
  created() {
        this.init()
        this.configMrp = configMrpConfig[0].mrp
    },

  watch : {
    usersearch(val)
    {
      if(val!=null){
        this.dateNaiss = val.datenaissance
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets../../../assets/scss/_variables";
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.page-ecommerce-dashboard {
  .widget {
    .icon-box {
      font-size: 30px;
    }
  }

  .table-box {
    .item-box {
      &.item-product {
        .product-image {
          width: 50px;
          margin-right: 15px;
          float: left;

          img {
            width: 100%;
          }
        }
      }

      &.item-status {
        padding: 5px 10px;

        &.status- {
          &Complete {
            background: rgba(44, 196, 120, 0.25);
          }
          &Pending {
            background: rgba(247, 186, 42, 0.25);
          }
          &Returned {
            background: rgba(243, 24, 71, 0.25);
          }
          &Paid {
            background: rgba(45, 109, 211, 0.25);
          }
        }
      }
    }
  }
}
</style>
